import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Label, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { ApproveStatus } from "../../models/common";
import { getStatusColor, getStatusLabel } from "../../models/common";
import { IBuyRequest } from "../../models/buyRequests";
import { completeBuyRequest, deleteBuyRequest, submitBuyRequest } from "../../api/buyRequests";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";

interface IProps {
    buyRequest: IBuyRequest;
    user: UserData;
    fetchBuyRequests: () => void;
}

export const BuyRequestRow = ({ buyRequest, user, fetchBuyRequests }: IProps) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleCompleteBuyRequest = async () => {
        setLoading(true);
        try {
            await completeBuyRequest(buyRequest.guid);
            toast("Buy request successfully completed", true);
            fetchBuyRequests();
        } catch {
            toast("Unable to complete buy request", false);
        } finally {
            setLoading(false);
        }
    };

    const handleSendRequest = async () => {
        setLoading(true);
        try {
            await submitBuyRequest(buyRequest.guid);
            toast("Buy request successfully submitted", true);
            fetchBuyRequests();
        } catch {
            toast("Unable to submit buy request", false);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteRequest = async () => {
        try {
            await deleteBuyRequest(buyRequest.guid);
            toast(" Buy request successfully deleted", true);
            fetchBuyRequests();
        } catch {
            toast("Unable to delete buy request", false);
        }
    };

    const onRowClick = () => {
        const path = `/requests/buy-requests/edit/${buyRequest.guid}`;
        history.push(path);
    };

    return (
        <Table.Row onClick={onRowClick} className="clickable-row">
            <Table.Cell>
                <Label
                    size="large"
                    circular
                    color={getStatusColor(buyRequest.status)}
                    content={getStatusLabel(buyRequest.status)}
                />
            </Table.Cell>
            <Table.Cell>{buyRequest.user}</Table.Cell>
            <Table.Cell>{buyRequest.title}</Table.Cell>
            <Table.Cell>
                {buyRequest.description.length > 50
                    ? `${buyRequest.description.substring(0, 50)}...`
                    : buyRequest.description}
            </Table.Cell>
            <Table.Cell>{getDisplayDateFormat(buyRequest.dateOfRequest)}</Table.Cell>
            <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                {buyRequest.status === ApproveStatus.Draft &&
                    (user.role === UserRole.Employee ||
                        user.role === UserRole.Student ||
                        user.role === UserRole.External) && (
                        <Button
                            color="green"
                            content="Send request"
                            disabled={loading}
                            loading={loading}
                            icon="send"
                            onClick={() => handleSendRequest()}
                        />
                    )}
                {user.role === UserRole.Admin && (
                    <Button
                        as={Link}
                        to={`/invoice-processing/invoices/${buyRequest.invoiceGuid}`}
                        color="yellow"
                        content="Invoice"
                        icon="edit"
                        disabled={buyRequest.invoiceGuid === ""}
                    />
                )}
                {user.role === UserRole.Admin && (
                    <Button
                        color="olive"
                        content="Complete"
                        icon="check"
                        onClick={() => handleCompleteBuyRequest()}
                        disabled={buyRequest.status === ApproveStatus.Completed}
                    />
                )}
                <Button
                    color="red"
                    content="Delete"
                    icon="close"
                    disabled={
                        (buyRequest.status === ApproveStatus.Approved ||
                            buyRequest.status === ApproveStatus.Rejected) &&
                        user.role === UserRole.Employee
                    }
                    onClick={() => handleDeleteRequest()}
                />
            </Table.Cell>
        </Table.Row>
    );
};
