import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useLocation } from "react-router";
import { Loader, Segment, Select } from "semantic-ui-react";
import { DocumentType, documentTypeProps, userDocumentTypes } from "../../models/document";
import { baseStyle, activeStyle, acceptStyle, rejectStyle, disabledStyle } from "./dropzoneStyles";

const getAcceptType = (val: string | undefined) => {
    switch (val) {
        case "xml":
            return "application/xml, text/xml";
        case "pdf":
            return "application/pdf";
        default:
            return "*";
    }
};

interface IProps {
    title: string;
    loading: boolean;
    accept?: string;
    handleUploadFiles: (files: File[], type?: number) => void;
    selectType?: boolean;
}

export const FileDropzone: React.FC<IProps> = ({
    title,
    loading,
    accept,
    handleUploadFiles,
    selectType,
}) => {
    const location = useLocation();
    const [dropdownOptions, setDropdownOptions] = useState(documentTypeProps);
    const [type, setType] = useState<DocumentType>(-1);
    const onDrop = useCallback(
        (files: File[]) => handleUploadFiles(files, type),
        [handleUploadFiles, type]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: accept ? getAcceptType(accept) : undefined,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            ...(selectType && +type === -1 ? disabledStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept, selectType, type]
    ) as React.CSSProperties;

    useEffect(() => {
        if (location.pathname.includes("/users/"))
            setDropdownOptions((d) =>
                d.filter((x) => userDocumentTypes.includes(x.value as number))
            );
    }, [location.pathname]);

    return title === "" ? (
        <div className="dropzone-container" style={{ width: "100%" }}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {loading ? (
                    <Loader active inline="centered" size="big" />
                ) : isDragReject ? (
                    <p>Unsupported file type</p>
                ) : isDragAccept ? (
                    <p>Drop the files here</p>
                ) : (
                    <p>Drag and drop the files here or click to select files</p>
                )}
            </div>
        </div>
    ) : (
        <Segment.Group className="file-dropzone-segment">
            <Segment>
                <h2>{title}</h2>
                {selectType && (
                    <Select
                        placeholder="Select document type"
                        selectOnBlur={false}
                        value={type}
                        options={dropdownOptions}
                        onChange={(e, data: any) => setType(+data.value)}
                    />
                )}
            </Segment>
            <Segment>
                <div className="dropzone-container" style={{ width: "100%" }}>
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        {loading ? (
                            <Loader active inline="centered" size="big" />
                        ) : isDragReject ? (
                            <p>Unsupported file type</p>
                        ) : isDragAccept ? (
                            <p>Drop the files here</p>
                        ) : (
                            <p>Drag and drop the files here or click to select files</p>
                        )}
                    </div>
                </div>{" "}
            </Segment>
        </Segment.Group>
    );
};
