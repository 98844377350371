import { Dispatch, SetStateAction } from "react";
import { Segment, Table } from "semantic-ui-react";
import { UserData } from "../../actions/authentificationActions";
import { UserPointRow } from "./UserPointRow";

interface IProps {
    title: string;
    users: UserData[];
    refreshUsers: Dispatch<SetStateAction<UserData[]>>;
}

export const UsersPointsTable = ({ title, users, refreshUsers }: IProps) => {
    return (
        <Segment.Group className="users-table-container">
            <Segment>
                <h2>{title}</h2>
            </Segment>
            <Segment className="table-container">
                <Table basic="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Positive points</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Negative points</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {users.map(user => (
                            <UserPointRow key={user.guid} user={user} users={users} refreshUsers={refreshUsers} />
                        ))}
                        <Table.Row/>
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};
