import { Reducer } from "redux";
import {
    SENDING_LOGIN_REQUEST,
    REQUEST_LOGIN_ERROR,
    SET_AUTH,
    CLEAR_LOGIN_ERROR,
    AuthentificationActionType,
    USER_DISABLED_ERROR,
} from "../actions/authentificationActions";

export interface LoginState {
    error?: string;
    currentlySending: boolean;
    loggedIn: boolean;
    isDisabled: boolean;
}

let savedState = sessionStorage.getItem("loggedIn");

let initialState: LoginState = {
    currentlySending: false,
    loggedIn: savedState ? JSON.parse(savedState) : false,
    isDisabled: false,
};

export const loginReducer: Reducer<LoginState> = (
    state: LoginState = initialState,
    action: AuthentificationActionType
): LoginState => {
    switch (action.type) {
        case SET_AUTH:
            return { ...state, loggedIn: action.newAuthState };
        case SENDING_LOGIN_REQUEST:
            return { ...state, currentlySending: action.sending };
        case REQUEST_LOGIN_ERROR:
            return { ...state, error: action.error };
        case CLEAR_LOGIN_ERROR:
            return { ...state, error: undefined, isDisabled: false };
        case USER_DISABLED_ERROR:
            return { ...state, isDisabled: true };
        default:
            return state;
    }
};
