import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
    Button,
    Checkbox,
    FlagNameValues,
    Label,
    Modal,
    Table,
    Flag,
    Popup,
} from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { IBankStatement } from "../../models/bankStatement";
import { deleteBankStatement } from "../../services/bankStatementsService";
import { AppState } from "../../store/configureStore";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";

interface IProps {
    bs: IBankStatement;
    selected: boolean;
    handleForExport: (bs: IBankStatement) => void;
    handleRemoveBankStatement: (bs: IBankStatement) => void;
    page: string;
}

export const BankStatementRow = ({
    bs,
    selected,
    handleForExport,
    handleRemoveBankStatement,
    page,
}: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteBankStatement = async () => {
        const res = await deleteBankStatement(bs.guid);
        if (!res.ok) toast("Failed to delete bank statement", false);
        else {
            handleRemoveBankStatement(bs);
            toast("Successfully deleted bank statement", true);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `bank-statements/${bs.guid}`,
            state: page,
        });
    };

    return (
        <Fragment>
            <Table.Row
                key={bs.name + bs.amount + bs.date}
                onClick={onRowClick}
                className="clickable-row"
            >
                <Table.Cell onClick={(e: any) => e.stopPropagation()} textAlign="center" collapsing>
                    <Checkbox checked={selected} onClick={() => handleForExport(bs)} />
                </Table.Cell>
                <Table.Cell>{getDisplayDateFormat(bs.date)}</Table.Cell>
                <Table.Cell>{getDisplayDateFormat(bs.created, true)}</Table.Cell>
                <Table.Cell>
                    <Label
                        size="large"
                        circular
                        color={bs.isProcessed ? "green" : "red"}
                        content={bs.isProcessed ? "Processed" : "Not processed"}
                    />
                </Table.Cell>
                <Table.Cell>
                    <p>{bs.name}</p>
                    <span>{bs.additionalRemittanceInformation}</span>
                    <span style={{ float: "right" }}>
                        {bs.invoice?.invoiceCountryString.toLowerCase() !== "" &&
                        bs.invoice?.invoiceCountryString.toLowerCase() !== "hr" ? (
                            <span>
                                <Popup
                                    content={bs.invoice?.invoiceCountryFullName}
                                    trigger={
                                        <Flag
                                            name={
                                                bs.invoice?.invoiceCountryString.toLowerCase() as FlagNameValues
                                            }
                                        />
                                    }
                                />
                            </span>
                        ) : null}
                        {bs.invoice?.vehicle != null ? (
                            <span style={{ float: "right" }}>
                                <Popup
                                    content={
                                        bs.invoice.vehicle.manufacturer +
                                        " " +
                                        bs.invoice.vehicle.model
                                    }
                                    trigger={
                                        <Label color="blue">
                                            {bs.invoice.vehicle.registrationPlate}
                                        </Label>
                                    }
                                />
                            </span>
                        ) : null}
                    </span>
                </Table.Cell>
                <Table.Cell textAlign="right">
                    {(bs.amount = bs.amount.replace(/[,.]/g, m => (m === "," ? "." : ",")))}
                </Table.Cell>
                <Table.Cell textAlign="right">
                    {bs.invoice?.invoiceCategoryName && (
                        <Label
                            className="bank-statement-label"
                            size="large"
                            circular
                            color={"olive"}
                            content={bs.invoice.invoiceCategoryName}
                        />
                    )}
                </Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    <Button
                        as={Link}
                        to={"/invoice-processing/invoices/" + bs.invoiceGuid}
                        color="yellow"
                        content="Invoice"
                        icon="edit"
                    />
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this bank statement?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteBankStatement} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
