import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Modal } from "semantic-ui-react";
import { clearPasswordRecoveryToken } from "../../actions/authentificationActions";
import { toast } from "../..";
import { resetPassword } from "../../services/authenticationService";
import { AppState } from "../../store/configureStore";
import "./ResetPasswordFirstLogin.css";

export const ResetPasswordFirstLogin = () => {
    const user = useSelector((state: AppState) => state.user);
    const [sendingRequest, setSendingRequest] = useState(false);
    const [password, setPassword] = useState("");
    const [repeatedPassword, setRepeatedPassword] = useState("");
    const [passwordDontMatch, setPasswordDontMatch] = useState(false);
    const disabled = password.length === 0 || repeatedPassword.length === 0 || sendingRequest;
    const dispatch = useDispatch();

    const handleResetPassword = async () => {
        setSendingRequest(true);
        const userDto = { token: user.passwordRecoveryToken, password: password };
        const res = await resetPassword(userDto);
        if (res.ok) {
            toast("Password changed successfully", true);
            dispatch(clearPasswordRecoveryToken());
            sessionStorage.setItem("count", "1");
        }
        setSendingRequest(false);
    };

    useEffect(() => {
        sessionStorage.getItem("count") !== null && dispatch(clearPasswordRecoveryToken());
    }, []);

    useEffect(() => {
        if (password !== "" && repeatedPassword !== "" && password !== repeatedPassword)
            setPasswordDontMatch(true);
        else setPasswordDontMatch(false);
    }, [password, repeatedPassword]);
    return (
        <Modal
            size="tiny"
            dimmer="blurring"
            open={user.passwordRecoveryToken !== undefined && user.passwordRecoveryToken !== null}
            className="reset-password-modal"
        >
            <Modal.Header id="reset-password-modal-container">
                This is your first log in. Please change your password.
            </Modal.Header>
            <Modal.Content id="reset-password-modal-content">
                <Input
                    placeholder="New password"
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                    }
                />
                <Input
                    placeholder="Repeated new password"
                    id="repeated-password"
                    type="password"
                    value={repeatedPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setRepeatedPassword(e.target.value)
                    }
                />
                <p id="login-error-message">{passwordDontMatch && "Passwords don't match."}</p>
            </Modal.Content>
            <Modal.Actions id="reset-password-modal-container">
                <Button
                    id="reset-password-modal-button"
                    secondary
                    content="Reset password"
                    loading={sendingRequest}
                    disabled={disabled || passwordDontMatch}
                    onClick={handleResetPassword}
                />
            </Modal.Actions>
        </Modal>
    );
};
