import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import { toast } from "../..";
import { IRecipient } from "../../models/accessRight";
import { getRecipient } from "../../services/accessRightsService";
import { EditRecipient } from "./EditRecipient";
import { HistoryNotesDetails } from "./HistoryNotesDetails";

export const RecipientDetails = () => {
    const [loading, setLoading] = useState(false);
    const loc = useLocation();
    const [recipient, setRecipient] = useState<IRecipient>();
    const location = useLocation();

    let getCurrentAccessRightGuid = () => {
        let partsArr = location.pathname.split("/");
        return partsArr[3];
    };

    let getRecipientGuid = () => {
        let partsArr = location.pathname.split("/");
        return partsArr[5];
    };

    let fetchRecipient = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getRecipient(getCurrentAccessRightGuid(), getRecipientGuid());
            res.recipientGuid && setRecipient(res);
        } catch {
            toast("Failed to load recepients", false);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchRecipient();
    }, [fetchRecipient]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="recipient-details-container">
            <div className="container-header">
                <h1>Recipient details</h1>
                <Button
                    as={Link}
                    to={{
                        pathname: "/company/access-rights/" + getCurrentAccessRightGuid(),
                        state: loc.state,
                    }}
                    secondary
                    content="Back to access right details"
                    icon="arrow left"
                />
            </div>
            {recipient ? (
                <div>
                    <EditRecipient
                        recipient={recipient}
                        setRecipient={setRecipient}
                        accessRightsGuid={getCurrentAccessRightGuid()}
                    />
                    <HistoryNotesDetails recipient={recipient} />
                </div>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Recipient not found
                </h2>
            )}
        </div>
    );
};
