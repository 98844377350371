export interface INavbarItem {
    slug: string;
    title: string;
}

export const adminItems: INavbarItem[] = [
    {
        slug: "/administration/users",
        title: "Users",
    },
    {
        slug: "/administration/documents",
        title: "Documents",
    },
    {
        slug: "/requests/buy-requests",
        title: "Buy requests",
    },
    {
        slug: "/requests/ideas",
        title: "Idea box",
    },
    {
        slug: "/administration/users-points",
        title: "Users points",
    },
    {
        slug: "/administration/settings",
        title: "Settings",
    },
];

export const employeeDataAsAdminItems: INavbarItem[] = [
    {
        slug: "/employee-data/vacations",
        title: "Vacations",
    },
    {
        slug: "/employee-data/paid-leaves",
        title: "Paid leaves",
    },
    {
        slug: "/employee-data/parental-leaves",
        title: "Parental leaves",
    },
    {
        slug: "/employee-data/sick-leaves",
        title: "Sick leaves",
    },
    {
        slug: "/employee-data/remote-work",
        title: "Remote work",
    },
    {
        slug: "/student-plans",
        title: "Student plans",
    },
    {
        slug: "/external-plans",
        title: "External plans",
    },
    {
        slug: "/employee-data/monthly-report",
        title: "Monthly report",
    },
    {
        slug: "/employee-data/educations",
        title: "Educations",
    },
];

export const employeeDataItems: INavbarItem[] = [
    {
        slug: "/employee-data/vacations",
        title: "Vacations",
    },
    {
        slug: "/employee-data/paid-leaves",
        title: "Paid leaves",
    },
    {
        slug: "/employee-data/parental-leaves",
        title: "Parental leaves",
    },
    {
        slug: "/employee-data/sick-leaves",
        title: "Sick leaves",
    },
    {
        slug: "/employee-data/remote-work",
        title: "Remote work",
    },
    {
        slug: "/employee-data/monthly-report",
        title: "Monthly report",
    },
    {
        slug: "/employee-data/educations",
        title: "Educations",
    },
    {
        slug: "/administration/documents",
        title: "Documents",
    },
];

export const studentItems: INavbarItem[] = [
    {
        slug: "/student-plans",
        title: "Student plans",
    },
    {
        slug: "/employee-data/monthly-report",
        title: "Monthly report",
    },
    {
        slug: "/administration/documents",
        title: "Documents",
    },
];

export const externalItems: INavbarItem[] = [
    {
        slug: "/external-plans",
        title: "External plans",
    },
    {
        slug: "/employee-data/monthly-report",
        title: "Monthly report",
    },
    {
        slug: "/administration/documents",
        title: "Documents",
    },
];

export const companyItems: INavbarItem[] = [
    {
        slug: "/company/news-feed",
        title: "News feed",
    },
    {
        slug: "/company/assignments",
        title: "Assignments",
    },
    {
        slug: "/company/departments",
        title: "Departments",
    },

    {
        slug: "/company/inventories",
        title: "Inventories",
    },
    {
        slug: "/company/travel-warrants",
        title: "Travel warrants",
    },
    {
        slug: "/company/paychecks",
        title: "Paychecks",
    },
    {
        slug: "/company/access-rights",
        title: "Access rights",
    },
    {
        slug: "/company/vehicles",
        title: "Vehicles",
    },
    {
        slug: "/company/locations",
        title: "Locations",
    },
];

export const projectsItems: INavbarItem[] = [
    {
        slug: "/company/projects",
        title: "Projects",
    },
    {
        slug: "/company/reports",
        title: "Reports",
    },
    {
        slug: "/company/minutes",
        title: "Minutes of the meetings",
    },
];

export const invoiceProcessingItems: INavbarItem[] = [
    {
        slug: "/invoice-processing/bank-statements",
        title: "Bank statements",
    },
    {
        slug: "/invoice-processing/invoices",
        title: "Invoices",
    },
    {
        slug: "/invoice-processing/invoice-categories",
        title: "Invoice categories",
    },
];

export const clientsAndVendorsItems: INavbarItem[] = [
    {
        slug: "/company/clients",
        title: "Clients and vendors",
    },
    {
        slug: "/company/client-contacts",
        title: "Client contacts",
    },
    {
        slug: "/company/vendor-contacts",
        title: "Vendor contacts",
    },
];
export const requestItems: INavbarItem[] = [
    {
        slug: "/requests/buy-requests",
        title: "Buy requests",
    },
    {
        slug: "/requests/ideas",
        title: "Idea box",
    },
];
