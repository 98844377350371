import React from "react";

type Props = {
    allocated: number;
    available: number;
    label: string;
    unallocated?: boolean;
};

export const UtilizationBar = ({ allocated, available, label, unallocated }: Props) => {
    const percentage = (isNaN(allocated / available) ? 0 : allocated / available) * 100;

    const getBarColor = () => {
        if (unallocated) return "#ddd";
        else if (percentage <= 20) return "#c15f5f";
        else if (percentage > 20 && percentage < 80) return "#d1ba5a";
        else return "#99bfbe";
    };

    return (
        <div className="utilization-bar">
            <span>
                {allocated}h/{percentage.toFixed(0)}%
            </span>
            <div
                style={{ height: `calc(${percentage}% + 0.5em)`, backgroundColor: getBarColor() }}
            ></div>
            <span>
                <strong>{label}</strong>
            </span>
        </div>
    );
};
