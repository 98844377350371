import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Modal, Popup, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { IDocument, documentClassificationProps, documentTypeProps } from "../../models/document";
import { deleteDocument, downloadDocument } from "../../services/documentsService";
import { AppState } from "../../store/configureStore";

interface IProps {
    doc: IDocument;
    handleRemoveDocument: (doc: IDocument) => void;
    page: string;
}

export const DocumentRow: React.FC<IProps> = ({ doc, handleRemoveDocument, page }) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteDocument = async () => {
        const res = await deleteDocument(doc.guid);
        if (!res.ok) toast("Failed to delete document", false);
        else {
            handleRemoveDocument(doc);
            toast("Successfully deleted document", true);
        }
        setModalOpen(false);
    };

    const onRowClick = () => {
        history.push({
            pathname: `documents/${doc.guid}`,
            state: page,
        });
    };

    return (
        <Fragment>
            <Table.Row key={doc.guid} onClick={onRowClick} className="clickable-row">
                <Table.Cell>{doc.name}</Table.Cell>
                <Table.Cell>{doc.externalGuid ? doc.externalGuid : doc.guid}</Table.Cell>
                <Table.Cell>
                    {documentTypeProps.find(p => p.value === doc.documentType)?.text}
                </Table.Cell>
                <Table.Cell>
                    {
                        documentClassificationProps.find(
                            p => p.value === doc.documentClassification
                        )?.text
                    }
                </Table.Cell>
                <Table.Cell>{getDisplayDateFormat(doc.date, true)}</Table.Cell>
                <Table.Cell>{doc.username}</Table.Cell>
                <Table.Cell>{doc.userOfDocument}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    <Popup
                        content="Download"
                        position="top center"
                        trigger={
                            <Button
                                color="purple"
                                icon="download"
                                onClick={() => downloadDocument(doc.guid)}
                                disabled={doc.createdWithoutUpload ? !doc.hasUploadedFile : false}
                            />
                        }
                    />
                    {user.role === UserRole.Admin && (
                        <Popup
                            content="Delete"
                            position="top center"
                            trigger={
                                <Button
                                    color="red"
                                    icon="delete"
                                    onClick={() => setModalOpen(true)}
                                />
                            }
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this document?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteDocument} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
