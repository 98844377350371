import axios from "axios";
import { IPaginationWrapper } from "../models/common";
import { IVendorContact } from "../models/vendorContact";

export const getVendorContacts = async (page: number, vendorGuid?: string | undefined) => {
    const response = await axios.get(`/vendorContacts?page=${page}&vendorGuid=${vendorGuid}`);
    return response.data as IPaginationWrapper;
};

export const getVendorContact = async (guid: string) => {
    const response = await axios.get(`/vendorContacts/${guid}`);
    return response.data as IVendorContact;
};

export const createVendorContact = async (vendorContactDto: Partial<IVendorContact>) => {
    const response = await axios.post("/vendorContacts", vendorContactDto);
    return response;
};

export const updateVendorContact = async (vendorcontactDto: IVendorContact) => {
    const response = await axios.put(`/vendorContacts/${vendorcontactDto.guid}`, vendorcontactDto);
    return response;
};

export const deleteVendorContact = async (guid: string) => {
    const response = await axios.delete(`/vendorContacts/${guid}`);
    return response;
};