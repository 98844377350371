import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Button, Popup, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { deleteVendor } from "../../api/vendors";
import { IVendor } from "../../models/vendor";
import { AppState } from "../../store/configureStore";

interface IProps {
    vendor: IVendor;
    handleRemoveVendor: () => void;
    page: string;
}

export const VendorRow = ({ vendor, handleRemoveVendor, page }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteVendor = async () => {
        try {
            await deleteVendor(vendor.guid);
            handleRemoveVendor();
            toast("Successfully deleted vendor", true);
        } catch {
            toast("Failed to delete vendor", false);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `vendors/${vendor.guid}`,
            state: page
        });
    }

    return (
        <Fragment>
            <Table.Row key={vendor.guid} onClick={onRowClick} className="clickable-row">
                <Table.Cell>{vendor.companyName}</Table.Cell>
                <Table.Cell>{vendor.email}</Table.Cell>
                <Table.Cell>{vendor.phoneNumber}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Popup
                            trigger={
                                <span>
                                    <Button
                                        color="red"
                                        content="Delete"
                                        icon="delete"
                                        onClick={() => setModalOpen(true)}
                                    />
                                </span>
                            }
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this vendor?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteVendor} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
