export interface INewsFeedItem {
    guid: string;
    timestamp: string;
    actionType: NewsFeedAction;
    user: string;
    userGuid: string;
    project: string;
    projectGuid: string;
    reportGuid: string;
    minutesGuid: string;
    additionalText: string;
    gitlabEvent: GitlabEvent;
}

export enum NewsFeedAction {
    ProjectCreated,
    ProjectDetailsModified,
    UserAddedReport,
    GitlabWebhookEvent,
    UserClosedZohoTask,
    MinutesOfTheMeetingCreated,
    ProjectRolesModified,
    MinutesOfTheMeetingModified,
    CommentAdded,
}
export interface GitlabEvent {
    id: string;
    eventType: string;
    action: string;
    additionalAction: string;
    repository: string;
    user: string;
    gitlabItems: GitlabItem[];
}
export interface GitlabItem {
    id: string;
    text: string;
    urlText: string;
    url: string;
    gitlabEventGuid: string;
}

export interface INewsFeedFilters {
    projectGuid: string;
    userGuid: string;
    types: string[];
    page: number
}

export const newsFeedFiltersInit: INewsFeedFilters = {
    projectGuid: "",
    userGuid: "",
    types: [],
    page: 1
};
