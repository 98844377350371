import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Loader, Button, Icon } from "semantic-ui-react";
import { getBuyRequest } from "../../api/buyRequests";
import { IBuyRequest } from "../../models/buyRequests";
import { EditBuyRequest } from "./EditBuyRequest";



export const BuyRequestDetails = () => {
    const loc = useLocation();
    const params: { guid: string } = useParams();
    const [loading, setLoading] = useState(true);
    const [buyRequest, setBuyRequest] = useState<IBuyRequest>();

    const fetchBuyRequest = useCallback(async id => {
        setLoading(true);
        const res = await getBuyRequest(id);
        if (res) {
            setBuyRequest(res);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchBuyRequest(params.guid);
    }, [fetchBuyRequest, params.guid]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="buy-request-container">
            <div className="container-header">
                <h1>Buy request details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/requests/buy-requests", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {buyRequest ? (
                <Fragment>
                    <EditBuyRequest
                        buyRequest={buyRequest}
                        setBuyRequest={setBuyRequest}
                    />
                </Fragment>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Buy request not found
                </h2>
            )}
        </div>
    );
};
