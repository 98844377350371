import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Loader, Message, Segment, Select, Table } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { formatDisplayDate } from "../../models/common";
import { IReport, reportTypeOptions } from "../../models/report";
import { getUserReportsSummaryForDate } from "../../api/reports";
import { AppState } from "../../store/configureStore";

export const ReportsSummary = () => {
    const user = useSelector((state: AppState) => state.user);
    const staff = useSelector((state: AppState) => state.staff);
    const projects = useSelector((state: AppState) => state.projects);
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState<IReport[]>([]);
    const [date, setDate] = useState(getISODateFormat(new Date()));
    const [userGuid, setUserGuid] = useState("");
    const [projectGuid, setProjectGuid] = useState("");

    const fetchReports = useCallback(async () => {
        setLoading(true);
        const res = await getUserReportsSummaryForDate(date, projectGuid, userGuid);
        Array.isArray(res) && setReports(res);
        setLoading(false);
    }, [date, userGuid, projectGuid]);

    useEffect(() => {
        fetchReports();
    }, [fetchReports]);

    return user.role !== UserRole.Admin ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="reports-summary-container">
            <div className="container-header">
                <h1>Reports summary for {formatDisplayDate(date)}</h1>
                <div>
                    <Select
                        clearable
                        placeholder="Select project..."
                        selectOnBlur={false}
                        options={projects}
                        value={projectGuid}
                        onChange={(e, data: any) => setProjectGuid(data.value)}
                    />
                    <Select
                        clearable
                        placeholder="Select user..."
                        selectOnBlur={false}
                        options={staff}
                        value={userGuid}
                        onChange={(e, data: any) => setUserGuid(data.value)}
                    />
                    <CalendarInput
                        date={date}
                        handleDateChange={e => setDate(getISODateFormat(e))}
                    />
                </div>
            </div>
            {reports.length === 0 ? (
                <Message
                    icon="info circle"
                    header="No reports found for selected date"
                    floating
                    size="small"
                />
            ) : loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <div className="reports-summary-display">
                    {reports.map(r => (
                        <Segment.Group key={r.guid}>
                            <Segment>
                                <h2>
                                    {r.user}'s report for {r.project ?? "Other project"}
                                </h2>
                            </Segment>
                            <Segment>
                                <Table basic="very" celled compact>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell width={2}>Hours</Table.Cell>
                                            <Table.Cell>{r.hours}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell width={2}>Type</Table.Cell>
                                            <Table.Cell>
                                                {
                                                    reportTypeOptions.find(x => x.value === r.type)
                                                        ?.text
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell width={2}>Text</Table.Cell>
                                            <Table.Cell>{r.text}</Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Segment.Group>
                    ))}
                </div>
            )}
        </div>
    );
};
