import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Segment, Loader, Button } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { getAllProjectsWeeklyCapacityPlannings } from "../../api/capacityPlanning";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { getMondayDate, IProjectWeeklyCapacityPlanning } from "../../models/capacityPlanning";
import { AppState } from "../../store/configureStore";
import { UtilizationBar } from "./UtilizationBar";
import "./CapacityPlanning.css";

export const ProjectsAllocation = () => {
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles: string[] = [UserRole.Admin, UserRole.SuperAdmin];
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState<IProjectWeeklyCapacityPlanning[]>([]);
    const [totalAvailability, setTotalAvailability] = useState(0);
    const [date, setDate] = useState(getISODateFormat(getMondayDate(new Date())));

    const fetchProjectsWeeklyCapacityPlannings = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getAllProjectsWeeklyCapacityPlannings(date);
            setProjects(res.rows as IProjectWeeklyCapacityPlanning[]);
            setTotalAvailability(res.totalRows);
        } catch {
            toast("Unable to fetch projects' weekly capacity plannings", false);
        } finally {
            setLoading(false);
        }
    }, [date]);

    useEffect(() => {
        fetchProjectsWeeklyCapacityPlannings();
    }, [fetchProjectsWeeklyCapacityPlannings]);

    return !allowedRoles.includes(user.role ?? "") ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div>
            <div className="container-header">
                <h1>Projects allocation</h1>
            </div>
            <Segment.Group>
                <Segment>
                    <CalendarInput
                        date={date}
                        handleDateChange={(e: any) => setDate(getISODateFormat(getMondayDate(e)))}
                    />
                </Segment>
                <Segment>
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <div className="utilization-charts-container">
                            <span>
                                <strong>Total staff hours</strong>
                                {totalAvailability}
                            </span>
                            <div className="utilization-charts">
                                {projects.map(p => (
                                    <section key={p.projectGuid}>
                                        <UtilizationBar
                                            allocated={p.allocatedWeeklyHours}
                                            available={totalAvailability}
                                            label={p.projectName}
                                        />
                                        <Button
                                            secondary
                                            content="Add more hours"
                                            onClick={() =>
                                                history.push(
                                                    "/company/projects/employee-utilization"
                                                )
                                            }
                                        />
                                    </section>
                                ))}
                            </div>
                        </div>
                    )}
                </Segment>
                <Segment></Segment>
            </Segment.Group>
        </div>
    );
};
