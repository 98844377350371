import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, DropdownItemProps, Icon, Modal, Select } from "semantic-ui-react";
import { toast } from "../../..";
import { addRoleToProject } from "../../../api/projects";
import { IProjectProjectRole, projectProjectRoleInit } from "../../../models/projectRole";
import "../Projects.css";

interface IProps {
    projectGuid: string;
    open: boolean;
    projectRoles: IProjectProjectRole[];
    fetchProjectRoles: () => void;
    setNewProjectRoleModalOpen: Dispatch<SetStateAction<boolean>>;
    projectRolesOptions: DropdownItemProps[];
    assigneesOptions: DropdownItemProps[];
    closeModal: () => void;
}

export const AddProjectRoleForm = ({
    projectGuid,
    open,
    projectRoles,
    fetchProjectRoles,
    setNewProjectRoleModalOpen,
    projectRolesOptions,
    assigneesOptions,
    closeModal,
}: IProps) => {
    const [newProjectRoleDto, setNewProjectRoleDto] =
        useState<IProjectProjectRole>(projectProjectRoleInit);
    const history = useHistory();

    const handleCreateProjectRole = async () => {
        try {
            const res = await addRoleToProject({ ...newProjectRoleDto, projectGuid: projectGuid });
            toast("Role added sucessfully", true);
        } catch {
            toast("Failed to add role", false);
        } finally {
            setNewProjectRoleDto(projectProjectRoleInit);
            fetchProjectRoles();
            closeModal();
        }
    };

    const handleAssigneesChange = (e: any, data: any) => {
        if (e.key === "Enter") return;
        setNewProjectRoleDto({ ...newProjectRoleDto, assignees: data.value });
    };

    const handleKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter" && newProjectRoleDto.roleGuid !== "") {
                e.preventDefault();
                handleCreateProjectRole();
            }
        },
        [handleCreateProjectRole]
    );

    return (
        <Modal
            open={open}
            onClose={() => {
                setNewProjectRoleDto(projectProjectRoleInit);
                setNewProjectRoleModalOpen(false);
            }}
            dimmer="blurring"
        >
            <Modal.Header>
                <h2>Add new project role</h2>
                <Icon
                    name="close"
                    onClick={() => {
                        setNewProjectRoleDto(projectProjectRoleInit);
                        setNewProjectRoleModalOpen(false);
                    }}
                />
            </Modal.Header>
            <Modal.Content>
                <form className="add-project-role-form">
                    <div className="input-container">
                        <label>Role</label>
                        <Select
                            placeholder="Select role..."
                            selectOnBlur={false}
                            defaultValue={newProjectRoleDto.roleGuid}
                            options={projectRolesOptions}
                            onChange={(e, data) => {
                                setNewProjectRoleDto({
                                    ...newProjectRoleDto,
                                    roleGuid: data.value!.toString(),
                                    roleName: data.text!,
                                });
                            }}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                e.preventDefault();
                                handleKeyPress(e);
                            }}
                        />
                        {projectRoles
                            .map(role => role.roleGuid)
                            .includes(newProjectRoleDto.roleGuid) && (
                            <span>
                                Role already exists in this project. Selected assignees will be
                                added to the existing role.
                            </span>
                        )}
                    </div>
                    <div className="input-container">
                        <label>Assignees</label>
                        <Select
                            placeholder="Add assignees..."
                            selectOnBlur={false}
                            fluid
                            multiple
                            search
                            value={newProjectRoleDto.assignees}
                            options={assigneesOptions}
                            onChange={(e, data) => handleAssigneesChange(e, data)}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleKeyPress(e);
                            }}
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    secondary
                    content="Edit company project roles"
                    icon="edit"
                    onClick={() => history.push("/projects/project-roles")}
                />
                <Button
                    color="green"
                    content="Confirm"
                    disabled={newProjectRoleDto.roleGuid === ""}
                    onClick={handleCreateProjectRole}
                />
            </Modal.Actions>
        </Modal>
    );
};
