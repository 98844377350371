import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, Modal, Table } from "semantic-ui-react";
import { toast } from "../../..";
import { UserRole } from "../../../actions/authentificationActions";
import { getDisplayDateFormat } from "../../../components/CalendarSelector/getAllDatesHelper";
import { IWorkAttendance } from "../../../models/workAttendance";
import { deleteWorkAttendance } from "../../../services/workAttendancesService";
import { AppState } from "../../../store/configureStore";

interface IProps {
    parentalLeave: IWorkAttendance;
    handleRemoveParentalLeave: () => void;
    page: string;
}

export const ParentalLeaveRow: React.FC<IProps> = ({
    parentalLeave,
    handleRemoveParentalLeave,
    page,
}) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const loc = useLocation();
    const history = useHistory();

    const handleDeleteParentalLeave = async () => {
        const res = await deleteWorkAttendance(parentalLeave.guid);
        if (!res.ok) toast("Failed to delete parental leave", false);
        else {
            handleRemoveParentalLeave();
            toast("Successfully deleted parental leave", true);
        }
    };

    const onRowClick = () => {
        user.role === UserRole.Admin &&
            history.push({
                pathname: `${loc.pathname}/edit/${parentalLeave.guid}`,
                state: {employeeGuid: parentalLeave.userGuid}
            });
    };

    return (
        <Fragment>
            <Table.Row key={parentalLeave.guid} onClick={onRowClick} className="clickable-row">
                <Table.Cell>{parentalLeave.user}</Table.Cell>
                <Table.Cell>{getDisplayDateFormat(parentalLeave.startDate)}</Table.Cell>
                <Table.Cell>{getDisplayDateFormat(parentalLeave.endDate)}</Table.Cell>
                <Table.Cell>{parentalLeave.duration}</Table.Cell>
                <Table.Cell onClick={(e:any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="close"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this parental leave?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteParentalLeave} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
