import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import {
    Button,
    Input,
    Loader,
    Message,
    Segment,
    Select,
    TextArea,
    Radio,
} from "semantic-ui-react";
import { toast } from "../..";
import { IReport, reportTypeOptions } from "../../models/report";
import { getReport, saveUserReports } from "../../api/reports";
import { AppState } from "../../store/configureStore";
import { TextInput } from "../../components/TextInput/TextInput";
import { UserRole } from "../../actions/authentificationActions";

export const EditReport = () => {
    const user = useSelector((state: AppState) => state.user);
    const params: { guid: string } = useParams();
    const history = useHistory();
    const loc = useLocation();
    const [userReport, setUserReport] = useState<IReport>();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const unauthorized = !userReport ? true : userReport.userGuid !== user.guid ? true : false;
    const disabled =
        userReport?.hours.toString() === "" ||
        (parseInt(userReport?.hours + "") > 0 && userReport?.text.length === 0);

    const fetchUserReport = useCallback(async () => {
        setLoading(true);
        const res = await getReport(params.guid);
        res.guid && setUserReport(res);
        setLoading(false);
    }, [params.guid]);

    const handleSaveChanges = async () => {
        setSaving(true);
        try {
            const arr: IReport[] = [];
            await saveUserReports(arr.concat(userReport!));
            toast("Successfully saved changes", true);
            history.push("/company/reports");
        } catch {
            toast("Problem saving changes", false);
        } finally {
            setSaving(false);
        }
    };

    const handleInputChange = (e: any) =>
        setUserReport({ ...userReport!, [e.target.name]: e.target.value });

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleSaveChanges}
                disabled={saving || disabled}
                loading={saving}
            />
        ) : null;

    useEffect(() => {
        fetchUserReport();
    }, [fetchUserReport]);

    return (
        <div className="user-reports-container">
            <div className="container-header">
                <h1>Edit report</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/company/reports", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : !userReport ? (
                <Message icon="warning circle" header="Report not found" floating />
            ) : (
                <Segment.Group>
                    <Segment>
                        <h2>Report for {userReport.project ?? "Other project"}</h2>
                        <SaveButton />
                    </Segment>
                    <Segment className="user-report-container">
                        <section>
                            <div className="input-container">
                                <label>Hours</label>
                                <TextInput
                                    name="hours"
                                    error={
                                        userReport.hours === -1 ||
                                        userReport.hours.toString() === ""
                                    }
                                    value={userReport.hours}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </div>
                            <div className="input-container">
                                <label>Type</label>
                                <Select
                                    selectOnBlur={false}
                                    value={userReport.type}
                                    options={reportTypeOptions}
                                    onChange={(e, data: any) =>
                                        setUserReport({ ...userReport, type: +data.value })
                                    }
                                />
                            </div>
                        </section>
                        <div className="input-container">
                            <label>Description</label>
                            <TextInput
                                name="text"
                                value={userReport.text}
                                handleInputChange={handleInputChange}
                                disabled={unauthorized}
                            />
                        </div>
                        <div className="input-container">
                            <label>Ticket URL</label>
                            <Input
                                name="ticketUrl"
                                value={userReport.ticketUrl ?? ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="input-container">
                            <label>Commit URL</label>
                            <Input
                                name="commitUrl"
                                value={userReport.commitUrl ?? ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="input-container">
                            <label>Billable Hours</label>
                            <Radio
                                toggle
                                name="billableHours"
                                checked={userReport.billableHours}
                                onChange={(e, data: any) =>
                                    setUserReport({
                                        ...userReport,
                                        billableHours: !userReport.billableHours,
                                    })
                                }
                                disabled={false}
                            />
                        </div>
                        {user.role === UserRole.Admin && (
                            <div className="input-container">
                                <label>Different Customer Description</label>
                                <Radio
                                    toggle
                                    name="differentCustomerDescription"
                                    checked={userReport.differentCustomerDescription}
                                    onChange={(e, data: any) =>
                                        setUserReport({
                                            ...userReport,
                                            differentCustomerDescription:
                                                !userReport.differentCustomerDescription,
                                        })
                                    }
                                    disabled={false}
                                />
                            </div>
                        )}
                        <div
                            className="input-container"
                            hidden={!userReport.differentCustomerDescription}
                        >
                            <label hidden={!userReport.differentCustomerDescription}>
                                Customer Description
                            </label>
                            <TextArea
                                rows={5}
                                name="customerDescription"
                                hidden={!userReport.differentCustomerDescription}
                                value={userReport.customerDescription ?? ""}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Segment>
                    <Segment>
                        <SaveButton />
                    </Segment>
                </Segment.Group>
            )}
        </div>
    );
};
