import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, DropdownItemProps, Icon, Loader } from "semantic-ui-react";
import { toast } from "../..";
import { DocumentsComponentTable } from "../../components/DocumentsComponentTable/DocumentsComponentTable";
import { DocumentType } from "../../models/document";
import { IProjectNote } from "../../models/projectNote";
import {
    getProjectNote,
    getProjectNoteAttendeesAndClientAttendees,
} from "../../services/projectNotesService";
import { EditProjectNote } from "./EditProjectNote";

export const ProjectNoteDetails = () => {
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(true);
    const [projectNote, setProjectNote] = useState<IProjectNote>();
    const [attendees, setAttendees] = useState<DropdownItemProps[]>([]);
    const [clientAttendees, setClientAttendees] = useState<DropdownItemProps[]>([]);

    const fetchProjectNote = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getProjectNote(params.guid);
            res.guid && setProjectNote(res);
        } catch {
            toast("Failed to load minutes of the meeting", false);
        } finally {
            setLoading(false);
        }
    }, [params.guid]);

    const fetchAllAttendees = useCallback(async () => {
        if (projectNote) {
            const res = await getProjectNoteAttendeesAndClientAttendees(projectNote.projectGuid);
            if (res.attendees) {
                setAttendees(res.attendees);
                setClientAttendees(res.clientAttendees);
            }
            setLoading(false);
        }
    }, [projectNote]);

    useEffect(() => {
        fetchProjectNote();
    }, [fetchProjectNote]);

    useEffect(() => {
        fetchAllAttendees();
    }, [fetchAllAttendees]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="project-note-details-container">
            <div className="container-header">
                <h1>Minutes of the meeting - details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/company/minutes", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {projectNote ? (
                <Fragment>
                    <EditProjectNote
                        projectNote={projectNote}
                        setProjectNote={setProjectNote}
                        attendees={attendees}
                        clientAttendees={clientAttendees}
                    />
                    <DocumentsComponentTable
                        entityGuid={projectNote.guid}
                        documentType={DocumentType.Minutes}
                        handleIsProcessed={() => null}
                    />
                </Fragment>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Minutes of the meeting not found
                </h2>
            )}
        </div>
    );
};
