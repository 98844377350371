import { DropdownItemProps } from "semantic-ui-react";
import { UserData } from "../actions/authentificationActions";
import { IInvoice } from "./invoice";

export interface IInventoryItem extends Record<string, any> {
    guid: string;
    name: string;
    itemId?: bigint;
    date?: string;
    quantity?: string;
    value: string;
    location?: string;
    description?: string;
    owner?: string;
    project?: string;
    assignedToGuid?: string;
    assignedStaff?: UserData;
    userGuid?: string;
    serialNo: string;
    invoiceGuid?: string;
    isComputer: boolean;
    computerName?: string;
    category?: number;
    buyRequestGuid?: string;
}

export interface IInventoryItemFilters { 
    query: string;
};

export const inventoryItemFiltersInit: IInventoryItemFilters = {
    query: "",
};

export const inventoryItemEmpty: IInventoryItem = {
    guid: "",
    name: "",
    description: "",
    date: "",
    serialNo: "",
    value: "",
    isComputer: false,
};

export const inventoryItemTypes: DropdownItemProps[] = [
    { key: 0, text: "Unknown", value: 0 },
    { key: 1, text: "Patents/Goodwill", value: 1 },
    { key: 2, text: "Computer programs", value: 2 },
    { key: 3, text: "Computers", value: 3 },
    { key: 4, text: "Land  and buildings", value: 4 },
    { key: 5, text: "Production plants", value: 5 },
    { key: 6, text: "Equipment", value: 6 },
    { key: 7, text: "Vehicles", value: 7 },
    { key: 8, text: "Tools, inventory, furniture", value: 8 },
    { key: 9, text: "Agricultural equipment and machinery", value: 9 },
    { key: 10, text: "Personal automobiles", value: 10 },
    { key: 11, text: "Other long-term assets", value: 11 },
    { key: 12, text: "Ships", value: 12 },
    { key: 13, text: "Biological assets - Livestock units", value: 13 },
    { key: 14, text: "Biological assets - Perrenial units", value: 14 },
    { key: 15, text: "Real estate investments", value: 15 }
];
