import { DropdownItemProps } from "semantic-ui-react";

export interface IClientContract {
    guid: string;
    startDate: string;
    endDate: string;
    value?: number;
    responsiblePerson?: string;
    contractType: number;
    isSigned: boolean;
    clientGuid: string;
    documentGuid: string;
    contractIsExpiring: boolean;
    contractExpired: boolean;
}

export const clientContractInit: IClientContract = {
    guid: "",
    startDate: "",
    endDate: "",
    contractType: 2,
    isSigned: false,
    clientGuid: "",
    documentGuid: "",
    contractIsExpiring: false,
    contractExpired: false,
};

export const contractOptions: DropdownItemProps[] = [
    { key: 2, text: "Unknown", value: 2 },
    { key: 3, text: "Buyer", value: 3 },
    { key: 4, text: "Seller", value: 4 },
];
