import { ApproveStatus } from "./common";

export interface IBuyRequest {
    guid: string;
    dateOfRequest?: string;
    status: ApproveStatus;
    user: string;
    userGuid: string;
    reason: string;
    title: string;
    description: string;
    purchaseAssigneeGuid: string;
    estimatedValue: string;
    rejectReason: string;
    invoiceGuid?: string;
    inventoryItemGuids?: string[];
}

export const initRequest: IBuyRequest = {
    guid: "",
    status: ApproveStatus.Draft,
    user: "",
    userGuid: "",
    reason: "",
    title: "",
    description: "",
    purchaseAssigneeGuid: "",
    estimatedValue: "0",
    rejectReason: "",
};
