import { IClient } from "../models/client";
import { getAuthorizationProperties } from "./authorizationHelper";
import { DropdownItemProps } from "semantic-ui-react";
import { IPaginationWrapper } from "../models/common";

export async function getClients(page: number): Promise<IPaginationWrapper> {
    return await fetch("/api/clients?page=" + page, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function getClient(guid: string): Promise<IClient> {
    return await fetch("/api/clients/" + guid, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function getClientContactsAsProps(
    clientGuid: string | undefined
): Promise<DropdownItemProps[]> {
    return await fetch("/api/clients/contacts/props/" + clientGuid, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function getClientsAsProps(): Promise<DropdownItemProps[]> {
    return await fetch("/api/clients/props", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function editClient(client: IClient): Promise<Response> {
    return await fetch("/api/clients/" + client.guid, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(client),
    });
}

export async function deleteClient(guid: string): Promise<Response> {
    return await fetch("/api/clients/" + guid, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function createClient(userDto: Partial<IClient>): Promise<Response> {
    return await fetch("/api/clients/", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(userDto),
    });
}
