import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, DropdownItemProps, Radio, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import { TextInput } from "../../components/TextInput/TextInput";
import { IAccessRight } from "../../models/accessRight";
import { editAccessRight } from "../../services/accessRightsService";
import { getClientContactsAsProps, getClientsAsProps } from "../../services/clientsService";
import { AppState } from "../../store/configureStore";
import { Recipients } from "../Recipients/Recipients";
import "./AccessRights.css";

interface IProps {
    accessRight: IAccessRight;
    setAccessRight: Dispatch<SetStateAction<IAccessRight | undefined>>;
}

export const EditAccessRight = ({ accessRight, setAccessRight }: IProps) => {
    const staff = useSelector((state: AppState) => state.staff);
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [saving, setSaving] = useState(false);
    const [clientContacts, setClientContacts] = useState<DropdownItemProps[]>([]);
    const [clients, setClients] = useState<DropdownItemProps[]>([]);
    const unauthorized = user.role !== UserRole.Admin;

    const handleInputChange = (e: any) =>
        setAccessRight({ ...accessRight!, [e.target.name]: e.target.value });

    const handleUpdateAccessRight = async () => {
        try {
            setSaving(true);
            await editAccessRight(accessRight);
            toast("Successfully saved changes", true);
            history.push("/company/access-rights");
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    const fetchClients = useCallback(async () => {
        const clients = await getClientsAsProps();
        Array.isArray(clients) && setClients(clients);
    }, []);

    const fetchClientContacts = useCallback(async () => {
        if (!accessRight.isInternal) {
            const res = await getClientContactsAsProps(accessRight.clientGuid);
            Array.isArray(res) && setClientContacts(res);
        }
    }, [accessRight.clientGuid, accessRight.isInternal]);

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateAccessRight}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    useEffect(() => {
        fetchClients();
        fetchClientContacts();
        return () => {};
    }, [fetchClientContacts, fetchClients]);

    return (
        <React.Fragment>
            <Segment.Group>
                <Segment className="edit-header">
                    <h2>Edit access right</h2>
                    <SaveButton />
                </Segment>
                <Segment>
                    <Table basic="very" celled compact>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Claim</Table.Cell>
                                <Table.Cell>
                                    <TextInput
                                        name="claim"
                                        value={accessRight.claim}
                                        handleInputChange={handleInputChange}
                                        disabled={unauthorized}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Is internal</Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        toggle
                                        checked={accessRight.isInternal}
                                        onClick={() =>
                                            setAccessRight({
                                                ...accessRight,
                                                isInternal: !accessRight.isInternal,
                                            })
                                        }
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Client</Table.Cell>
                                <Table.Cell>
                                    <DropdownInput
                                        placeholder="Select client"
                                        search
                                        fluid
                                        disabled={accessRight.isInternal || unauthorized}
                                        value={accessRight.isInternal ? "" : accessRight.clientGuid}
                                        options={clients}
                                        onChange={(e, data: any) =>
                                            setAccessRight({
                                                ...accessRight,
                                                clientGuid: data.value,
                                            })
                                        }
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Responsible person</Table.Cell>
                                <Table.Cell>
                                    <DropdownInput
                                        placeholder="Choose responsible person..."
                                        search
                                        fluid
                                        value={accessRight.assigneeGuid}
                                        options={accessRight.isInternal ? staff : clientContacts}
                                        onChange={(e, data: any) =>
                                            setAccessRight({
                                                ...accessRight,
                                                assigneeGuid: data.value,
                                            })
                                        }
                                        disabled={unauthorized}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Segment>
                <Segment className="edit-footer">
                    <SaveButton />
                </Segment>
            </Segment.Group>
            <Recipients accessRight={accessRight} setAccessRight={setAccessRight} />
        </React.Fragment>
    );
};
