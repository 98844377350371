import { Label, Loader, Menu, Tab } from "semantic-ui-react";
import { NewsFeed } from "../NewsFeed/NewsFeed";
import { HomeDashboard } from "./HomeDashboard";
import SearchBar from "./SearchBar";
import { useCallback, useEffect, useState } from "react";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../api/users";
import { AppState } from "../../store/configureStore";
import { ReactComponent as Points } from "../../icons/Points.svg";
import { BoardList } from "../Board/BoardList";
import { ICompany, newCompanyInit } from "../../models/company";
import { getMyCompany } from "../../api/companies";
import { toast } from "../..";
import { fetchUnreadBoardNews } from "../../store/unreadBoardNews";
import "./Home.css";

export const Home = () => {
    const user = useSelector((state: AppState) => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState<UserData>();
    const [company, setCompany] = useState<ICompany>(newCompanyInit);
    const unreadMessages = useSelector((state: AppState) => state.unreadBoardNews.count);

    const fetchUser = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getUser(user.guid!);
            setUserDetails(res);
            setCompany(await getMyCompany());
        } catch (error) {
            toast("Unable to load info", false);
        } finally {
            setLoading(false);
        }
    }, [user.guid]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const fetchUnreadUserBoardNews = useCallback(() => {
        fetchUnreadBoardNews(dispatch);
    }, [dispatch]);

    useEffect(() => {
        fetchUnreadUserBoardNews();
    }, [fetchUnreadUserBoardNews]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="home-container">
            {user.role !== UserRole.Admin && user.role !== UserRole.SuperAdmin ? (
                <div className="hompage-employee-searchbar">
                    {company.gamificationAllowed && (
                        <div className="homepage-points-container">
                            <h3>{userDetails?.points}</h3>
                            <Points />
                            <h3>{userDetails?.negativePoints}</h3>
                        </div>
                    )}
                    <div className="homepage-searchbar">
                        <SearchBar />
                    </div>
                </div>
            ) : (
                <div className="homepage-searchbar">
                    <SearchBar />
                </div>
            )}

            <Tab
                menu={{ secondary: true }}
                panes={[
                    {
                        menuItem: "Dashboard",
                        render: () => (
                            <Tab.Pane attached={false}>
                                <HomeDashboard />
                            </Tab.Pane>
                        ),
                    },
                    {
                        menuItem: (
                            <Menu.Item key="board">
                                Board
                                {unreadMessages > 0 && (
                                    <Label
                                        className="unread-board-news-label"
                                        circular
                                        size="large"
                                        color="red"
                                    >
                                        {unreadMessages}
                                    </Label>
                                )}
                            </Menu.Item>
                        ),
                        render: () => (
                            <Tab.Pane attached={false}>
                                <BoardList />
                            </Tab.Pane>
                        ),
                    },
                    {
                        menuItem: "News feed",
                        render: () => (
                            <Tab.Pane attached={false}>
                                <NewsFeed />
                            </Tab.Pane>
                        ),
                    },
                ]}
            />
        </div>
    );
};