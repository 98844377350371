import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Segment, Table } from "semantic-ui-react";
import { DropdownItemProps } from "semantic-ui-react/src/modules/Dropdown/DropdownItem";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import { TextInput } from "../../components/TextInput/TextInput";
import { IClient } from "../../models/client";
import { editClient } from "../../services/clientsService";
import { AppState } from "../../store/configureStore";
import "./Clients.css";

interface IProps {
    client: IClient;
    setClient: Dispatch<SetStateAction<IClient | undefined>>;
    clientContacts: DropdownItemProps[];
}

export const EditClient = ({ client, setClient, clientContacts }: IProps) => {
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [saving, setSaving] = useState(false);
    const unauthorized = user.role !== UserRole.Admin;

    const handleInputChange = (e: any) =>
        setClient({ ...client!, [e.target.name]: e.target.value });

    const handleUpdateClient = async () => {
        try {
            setSaving(true);
            await editClient(client);
            toast("Successfully saved changes", true);
            history.push("/company/clients");
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateClient}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    return (
        <Segment.Group>
            <Segment>
                <h2>Edit client details</h2>
                <SaveButton />
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={3}>Company name</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="companyName"
                                    value={client.companyName}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Address</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="address"
                                    value={client.address}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Contact person</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={client.contactPersonGuid}
                                    options={clientContacts}
                                    onChange={(e, data: any) => {
                                        setClient({ ...client, contactPersonGuid: data.value });
                                    }}
                                    disabled={true}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Contact person email</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="email"
                                    value={client.email}
                                    handleInputChange={handleInputChange}
                                    disabled
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Phone number</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="oib"
                                    value={client.oib}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>VAT number</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="vaTnumber"
                                    value={client.vaTnumber}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                <SaveButton />
            </Segment>
        </Segment.Group>
    );
};
