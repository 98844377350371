import { IPaginationWrapper } from "../models/common";
import { IUnselectableDates } from "../models/date";
import { IStaffMonthlySummary } from "../models/staffMonthlySummary";
import { CurrentWorkAttendance } from "../models/workAttendance";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getWorkAttendances(
    page: number,
    type: string,
    status: number
): Promise<IPaginationWrapper> {
    const params = new URLSearchParams({ page: page + "", type: type, status: status + "" });
    return await fetch("/api/workAttendances?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getCurrentWorkAttendance(guid: string): Promise<CurrentWorkAttendance> {
    return await fetch(`/api/workAttendances/current/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getYearlyVacationPlan(
    page: number,
    type: string,
    status: number
): Promise<IPaginationWrapper> {
    const params = new URLSearchParams({
        page: page + "",
        type: "yearly-vacation-plan",
        status: status + "",
    });
    return await fetch("/api/workAttendances?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getYearlyVacationPlanExist(): Promise<boolean> {
    return await fetch("/api/workAttendances/plan-exist?", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getYearlyVacationPlansHeatMapData() {
    return await fetch("/api/workAttendances/heatmap", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getUserPreviousWorkAttendanceDates(
    type: number,
    guid?: string,
    employeeGuid?: string
): Promise<IUnselectableDates> {
    const params = new URLSearchParams({ type: type.toString() });
    guid && params.append("guid", guid);
    employeeGuid && params.append("employeeGuid", employeeGuid);
    return await fetch(`/api/workAttendances/previousDates?${params.toString()}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function createWorkAttendance(
    type: number,
    dates: string[],
    accountable: boolean,
    employee: string,
    reason: string,
    allEmployeesGuids: (string | number | boolean | undefined)[]
): Promise<Response> {
    const params = new URLSearchParams({
        type: type.toString(),
        accountable: accountable.toString(),
        employee: employee.toString(),
        allEmployeesGuids: allEmployeesGuids.toString(),
    });
    return await fetch("/api/workAttendances?" + params.toString(), {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify({
            dates,
            reason,
        }),
    });
}

export async function editWorkAttendance(
    guid: string,
    type: number,
    dates: string[],
    accountable: boolean,
    reason: string
): Promise<Response> {
    const params = new URLSearchParams({ type: type + "", accountable: accountable + "" });
    return await fetch(`/api/workAttendances/${guid}?` + params.toString(), {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify({
            dates,
            reason,
        }),
    });
}

export async function addWorkAttendanceDocuments(guid: string, files: File[]): Promise<Response> {
    const formData = new FormData();

    files.forEach(file => {
        formData.append("files", file, file.name);
    });
    return await fetch(`/api/workAttendances/${guid}/file`, {
        method: "POST",
        ...getAuthorizationProperties(),
        body: formData,
    });
}

export async function submitWorkAttendanceRequest(
    guid: string,
    deletion: boolean
): Promise<Response> {
    return await fetch(`/api/workAttendances/${guid}?deletion=${deletion}`, {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function processWorkAttendanceRequests(
    guids: string[],
    choice: boolean
): Promise<Response> {
    return await fetch("/api/workAttendances/requests?choice=" + choice, {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(guids),
    });
}

export async function deleteWorkAttendance(guid: string): Promise<Response> {
    return await fetch("/api/workAttendances/" + guid, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function getStaffMonthlySummary(
    year: number,
    month: number,
    userGuid: string
): Promise<IStaffMonthlySummary> {
    const params = new URLSearchParams({ year: year + "", month: month + "" });
    userGuid !== "" && params.append("userGuid", userGuid);
    return await fetch("/api/workAttendances/monthly-summary?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function downloadMonthlyReport(year: number, month: number, userGuid: string) {
    const params = new URLSearchParams({ year: year + "", month: month + "" });
    userGuid && params.append("userGuid", userGuid);
    const res = await fetch("/api/workAttendances/download?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    if (!res.ok) return false;
    const blob = await res.blob();
    const url = window.URL.createObjectURL(new Blob([blob], { type: "application/zip" }));
    const link = document.createElement("a");
    link.href = url;
    const fileName =
        "Aconto_monthly_report_" +
        month.toString().padStart(2, "0") +
        "_" +
        year.toString().substring(2);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
    return true;
}
