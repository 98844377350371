import { Reducer } from "redux";
import { DropdownItemProps } from "semantic-ui-react";
import {
    SET_USER,
    CLEAR_USER,
    AuthentificationActionType,
    UserData,
    CLEAR_PASSWORD_RECOVERY_TOKEN,
} from "../actions/authentificationActions";

var savedState: UserData | null =
    sessionStorage.getItem("user") !== null
        ? JSON.parse(sessionStorage.getItem("user") || "")
        : null;

const initialState: UserData = {
    guid: undefined,
    username: undefined,
    firstName: "",
    lastName: "",
    token: undefined,
    role: undefined,
    isActive: false,
    priorExperience: "",
};

export const userRoleOptions: DropdownItemProps[] = [
    { key: "ALFC Admin", text: "ALFC Admin", value: "ALFC Admin" },
    { key: "ALFC WG Member", text: "ALFC WG Member", value: "ALFC WG Member" },
    { key: "ALFC SPOC", text: "ALFC SPOC", value: "ALFC SPOC" },
    { key: "FTPUser", text: "FTPUser", value: "FTPUser" },
    { key: "ICS Admin", text: "ICS Admin", value: "ICS Admin" },
    { key: "ICS WG Member", text: "ICS WG Member", value: "ICS WG Member" },
    { key: "ICS SPOC", text: "ICS SPOC", value: "ICS SPOC" },
];

export const userReducer: Reducer<UserData> = (
    state: UserData = savedState || initialState,
    action: AuthentificationActionType
): UserData => {
    switch (action.type) {
        case SET_USER:
            return { ...action.user };
        case CLEAR_USER:
            return { ...initialState };
        case CLEAR_PASSWORD_RECOVERY_TOKEN:
            return { ...state, passwordRecoveryToken: undefined };
        default:
            return state;
    }
};

export default userReducer;
