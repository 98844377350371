import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import { IAccessRight } from "../../models/accessRight";
import { getAccessRight } from "../../services/accessRightsService";
import { EditAccessRight } from "./EditAccessRight";

export const AccessRightsDetails = () => {
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [accessRight, setAccessRight] = useState<IAccessRight>();

    const fetchAccessRight = useCallback(async (id) => {
        setLoading(true);
        const res = await getAccessRight(id);
        res.guid && setAccessRight(res);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchAccessRight(params.guid);
    }, [fetchAccessRight, params.guid]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="access-right-details-container">
            <div className="container-header">
                <h1>Access right details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/company/access-rights", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {accessRight ? (
                <EditAccessRight accessRight={accessRight} setAccessRight={setAccessRight} />
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Access right
                </h2>
            )}
        </div>
    );
};
