import { useState } from "react";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { invoiceCategoryInit } from "../../models/invoice";
import { createInvoiceCategory } from "../../services/invoicesService";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddInvoiceCategory: () => void;
}

export const AddInvoiceCategoryForm = ({ open, closeModal, handleAddInvoiceCategory }: IProps) => {
    const [newInvoiceCategory, setNewInvoiceCategory] = useState(invoiceCategoryInit);

    const handleAddNewInvoiceCategory = async () => {
        try {
            await createInvoiceCategory(newInvoiceCategory);
            handleAddInvoiceCategory();
            toast("Invoice category successfully added", true, 1500);
        } catch {
            toast("Error creating invoice category", false);
        } finally {
            closeModal();
        }
    };

    return (
        <Modal open={open} onClose={closeModal} dimmer="blurring" centered={false}>
            <Modal.Header>
                <h2>Add new invoice category</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form>
                    <div className="input-container">
                        <label>Name</label>
                        <Input
                            type="name"
                            value={newInvoiceCategory.name}
                            onChange={(e: any) =>
                                setNewInvoiceCategory({
                                    ...newInvoiceCategory,
                                    name: e.target.value,
                                })
                            }
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new invoice category"
                    disabled={newInvoiceCategory.name === ""}
                    secondary
                    fluid
                    onClick={handleAddNewInvoiceCategory}
                />
            </Modal.Actions>
        </Modal>
    );
};
