import { useCallback } from "react";
import { useEffect, useState } from "react";
import { Button, Icon, Loader, Pagination, Segment, Select, Table } from "semantic-ui-react";
import { IProjectNote } from "../../models/projectNote";
import { getProjectNotes } from "../../services/projectNotesService";
import { ProjectNotesRow } from "../ProjectNotes/ProjectNotesRow";
import { AddProjectNotesForm } from "../ProjectNotes/AddProjectNotesForm";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import "./ProjectNotes.css";

export const ProjectNotes = () => {
    const projects = useSelector((state: AppState) => state.projects);
    const loc = useLocation();
    const [projectNotes, setProjectNotes] = useState<IProjectNote[]>([]);
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [projectId, setProjectId] = useState("");
    const [date, setDate] = useState("");

    const fetchProjectNotes = useCallback(async (page, projectId, date) => {
        setLoading(true);
        const res = await getProjectNotes(page, projectId, date);
        if (res.rows) {
            setProjectNotes(res.rows as IProjectNote[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, []);

    const handleRemoveProjectNote = (r: IProjectNote) =>
        setProjectNotes([...projectNotes].filter(x => x !== r));

    useEffect(() => {
        fetchProjectNotes(page, projectId, date);
    }, [fetchProjectNotes, page, projectId, date]);

    const handleFetchProjectNotes = () => {
        setPage(1);
        fetchProjectNotes(page, projectId, date);
    };

    return (
        <div className="project-notes-container">
            <div className="container-header">
                <h1>Minutes of the meetings</h1>
                <Button
                    secondary
                    content="Add minutes of the meeting"
                    icon="file text"
                    onClick={() => setModalOpen(true)}
                />
            </div>
            <div className="project-notes-display">
                <div className="project-notes-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment className="project-notes-options">
                                <Select
                                    clearable
                                    id="project-filter"
                                    placeholder="Select project..."
                                    selectOnBlur={false}
                                    className="project-notes-select"
                                    options={projects}
                                    value={projectId}
                                    onChange={(e, data: any) => setProjectId(data.value)}
                                />
                                <CalendarInput
                                    date={date}
                                    placeholder="Select date..."
                                    handleDateChange={e => {
                                        e ? setDate(getISODateFormat(e)) : setDate("");
                                    }}
                                    clearable
                                />
                            </Segment>
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Date</Table.HeaderCell>
                                            <Table.HeaderCell>Title</Table.HeaderCell>
                                            <Table.HeaderCell>Project</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {projectNotes.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="4">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            projectNotes.map(x => (
                                                <ProjectNotesRow
                                                    key={x.guid}
                                                    projectNote={x}
                                                    handleRemoveProjectNote={
                                                        handleRemoveProjectNote
                                                    }
                                                    page={page + ""}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{ content: <Icon name="angle double left" /> }}
                                        lastItem={{ content: <Icon name="angle double right" /> }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                            <AddProjectNotesForm
                                open={modalOpen}
                                projects={projects}
                                closeModal={() => setModalOpen(false)}
                                handleAddProjectNotes={handleFetchProjectNotes}
                            />
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
