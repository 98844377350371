import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";

export const SuperAdminHeaders = () => {
    return (
        <Fragment>
            <Menu.Item>
                <Link className="link-header" to={{ pathname: "/companies" }}>
                    Companies
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link className="link-header" to={{ pathname: "/administration/users" }}>
                    Users
                </Link>
            </Menu.Item>
        </Fragment>
    );
};
