import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, Table } from "semantic-ui-react";
import { IAccessRight, IRecipient } from "../../models/accessRight";
import { deleteRecipient, getRecipients } from "../../services/accessRightsService";

interface IProps {
    recipient: IRecipient;
    accessRight: IAccessRight;
    setRecipients: Dispatch<SetStateAction<IRecipient[]>>;
}

export const RecipientRow = ({ recipient, accessRight, setRecipients }: IProps) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteRecipient = async (guid: string | undefined) => {
        deleteRecipient(accessRight.guid, guid).then(async () => {
            const rec = await getRecipients(accessRight.guid);
            Array.isArray(rec) && setRecipients(rec);
        });
        setDeleteModalOpen(false);
    };

    return (
        <Fragment>
            <Table.Row
                key={recipient.recipientGuid}
                onClick={() =>
                    history.push(`${accessRight.guid}/recipients/${recipient.recipientGuid}`)
                }
                className="clickable-row"
            >
                <Table.Cell>{recipient.firstName + " " + recipient.lastName}</Table.Cell>
                <Table.Cell>{recipient.userName}</Table.Cell>
                <Table.Cell>{recipient.isActive === true ? "Yes" : "No"}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    <Button
                        color="purple"
                        content="Archive"
                        onClick={() => setDeleteModalOpen(true)}
                        icon="delete"
                        disabled={recipient.isActive}
                    />
                    <Modal
                        size="small"
                        open={deleteModalOpen}
                        onClose={() => setDeleteModalOpen(false)}
                        dimmer="blurring"
                    >
                        <Modal.Header>
                            Are you sure you want to archive recipient of this access right?
                        </Modal.Header>
                        <Modal.Actions>
                            <Button
                                color="red"
                                onClick={() => setDeleteModalOpen(false)}
                                content="Cancel"
                            />
                            <Button
                                color="green"
                                onClick={() => {
                                    handleDeleteRecipient(recipient.recipientGuid);
                                }}
                                content="Confirm"
                            />
                        </Modal.Actions>
                    </Modal>
                </Table.Cell>
            </Table.Row>
        </Fragment>
    );
};
