import { Item, Modal } from "semantic-ui-react";
import { IBoardData } from "../../models/board";
import "./Board.css";

interface IProps {
    open: boolean;
    closeModal: () => void;
    article: IBoardData;
}

export const ViewBoardArticle = ({ open, closeModal, article }: IProps) => {
    return (
        <Modal open={open} onClose={closeModal} dimmer="blurring" className="view-modal" centered>
            <Modal.Content className="view">
                <h3 className="view-title">{article.title}</h3>
                <div className="view-body">
                    {article.imgGuid !== "00000000-0000-0000-0000-000000000000" && (
                        <Item.Image size="medium" src={article.imgSrc} key={article.imgGuid} />
                    )}
                    <p
                        className={
                            article.imgGuid !== "00000000-0000-0000-0000-000000000000"
                                ? "view-text"
                                : "view-text-no-img"
                        }
                    >
                        {article.description}
                    </p>
                </div>
            </Modal.Content>
        </Modal>
    );
};
