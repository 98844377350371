import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { toast } from "../..";
import { UserData } from "../../actions/authentificationActions";
import agent from "../../api/agent";
import { CurrentWorkAttendance, getWorkAttendanceStatus } from "../../models/workAttendance";
import { getImageSource, putImage } from "../../services/imageService";
import { DocumentType } from "../../models/document";
import { Button, Card, Icon, Label, Loader, Modal, Popup } from "semantic-ui-react";
import { ImageDropCrop } from "../../components/FileDropzone/ImageDropCrop";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { jobTitleTypes } from "../../models/appUser";
import { getCroppedImg } from "./getCroppedImage";
import { RefreshImage } from "../../actions/imageAction";
import { ImageDropzone } from "../../components/FileDropzone/ImageDropzone";
import { getHomepageStatusColor } from "../Home/HomeInfoLegend";

interface IProps {
    loggedUser: UserData;
    userDetails: UserData;
    setUserDetails: Dispatch<SetStateAction<UserData | undefined>>;
}

export const UserPrimaryData = ({ userDetails, setUserDetails }: IProps) => {
    const roles = useSelector((state: AppState) => state.roles);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [workAttendance, setWorkAttendance] = useState<CurrentWorkAttendance>();
    const [crop, setCrop] = useState(false);
    const [imageDropped, setImageDropped] = useState(false);
    const [userPhotoSrc, setUserPhotoSrc] = useState("");
    const [photoPreview, setPhotoPreview] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    const fetchWorkAttendance = useCallback(async () => {
        if (userDetails?.guid) {
            try {
                setLoading(true);
                const res = await agent.WorkAttendance.getByGuid(userDetails?.guid);
                console.log(res);
                setWorkAttendance(res);
                console.log(workAttendance);
            } catch {
                toast("Failed to fetch work attendance", false);
            } finally {
                setLoading(false);
            }
        }
    }, [userDetails?.guid]);

    useEffect(() => {
        fetchWorkAttendance();
    }, [fetchWorkAttendance]);

    const fetchUserPhoto = useCallback(async () => {
        if (userDetails && userDetails.guid) {
            const res = await getImageSource(userDetails.guid, DocumentType.UserPhoto);
            if (res.ok) setUserPhotoSrc((await res.text()).replace(/"/g, ""));
            setPhotoPreview("");
        } else setUserPhotoSrc("");
    }, [userDetails]);

    useEffect(() => {
        fetchUserPhoto();
    }, [fetchUserPhoto]);

    const handleSaveImage = async () => {
        if (imageDropped) {
            try {
                const img = await getCroppedImg(crop, userDetails!.guid!, 250, 250);
                await putImage(userDetails!.guid!, img, DocumentType.UserPhoto);
                dispatch(RefreshImage(userDetails?.guid));
                setModalOpen(false);
            } catch {
                toast("Failed to update user image, please try again", false);
            }
        }
    };

    return loading ? (
        <Loader />
    ) : (
        <>
            <Card className="user-card" onClick={() => setModalOpen(true)}>
                <ImageDropzone
                    documentType={DocumentType.UserPhoto}
                    guid={userDetails?.guid ?? ""}
                    onDrop={() => setImageDropped(true)}
                    crop={crop}
                    setCrop={setCrop}
                    disabled
                />
                <Card.Content>
                    <Card.Header>
                        {userDetails?.firstName} {userDetails?.lastName}
                    </Card.Header>
                    <Card.Content>
                        <DropdownInput
                            options={jobTitleTypes}
                            value={userDetails.jobTitle}
                            onChange={(e, data: any) =>
                                setUserDetails({
                                    ...userDetails,
                                    jobTitle: data.value,
                                })
                            }
                            disabled
                        />
                        <DropdownInput
                            options={roles}
                            value={userDetails?.role ?? ""}
                            onChange={(e, data: any) =>
                                setUserDetails({
                                    ...userDetails,
                                    role: data.value,
                                })
                            }
                            disabled
                        />
                    </Card.Content>
                </Card.Content>
                <Popup
                    trigger={
                        <Label
                            className="user-status-label"
                            circular
                            size="large"
                            color={getHomepageStatusColor(workAttendance?.attendance ?? "")}
                        />
                    }
                    content={getWorkAttendanceStatus(
                        workAttendance?.attendance,
                        userDetails?.role ?? ""
                    )}
                    position="top center"
                />
            </Card>
            <Modal
                className="user-image-container"
                dimmer="blurring"
                centered={false}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <Modal.Header>
                    <h2>Add profile picture</h2>
                    <Icon name="close" onClick={() => setModalOpen(false)} />
                </Modal.Header>
                <Modal.Content>
                    <ImageDropCrop
                        guid={userDetails?.guid ?? ""}
                        onDrop={() => setImageDropped(true)}
                        crop={crop}
                        setCrop={setCrop}
                        documentType={DocumentType.UserPhoto}
                        src={userPhotoSrc}
                        setSrc={setUserPhotoSrc}
                        preview={photoPreview}
                        setPreview={setPhotoPreview}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary content="Save image" icon="save" onClick={handleSaveImage} />
                </Modal.Actions>
            </Modal>
        </>
    );
};
