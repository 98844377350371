import { IAccessRight } from "./accessRight";

export interface IClient {
    guid: string;
    email: string;
    companyName: string;
    address: string;
    oib: string;
    vaTnumber: string;
    contactFirstName: string;
    contactLastName: string;
    phoneNumber: string;
    accessRights: IAccessRight[];
    contactPersonGuid: string;
    contractIsExpiring: boolean;
}

export const clientInit: Partial<IClient> = {
    email: "",
    companyName: "",
    address: "",
    vaTnumber: "",
    contactFirstName: "",
    contactLastName: "",
    phoneNumber: "",
    accessRights: [],
};
