import { IProject } from "../models/project";
import { getAuthorizationProperties } from "./authorizationHelper";
import { DropdownItemProps } from "semantic-ui-react";
import { IPaginationWrapper } from "../models/common";

export async function getProjects(page: number, query?: string): Promise<IPaginationWrapper> {
    const params = new URLSearchParams({ page: page + "" });
    query && params.append("query", query);
    return await fetch("/api/projects?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function getProject(guid: string): Promise<IProject> {
    return await fetch("/api/projects/" + guid, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function getProjectsAsProps(): Promise<DropdownItemProps[]> {
    return await fetch("/api/projects/props", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function editProject(project: IProject): Promise<Response> {
    return await fetch("/api/projects/" + project.guid, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(project),
    });
}

export async function deleteProject(guid: string): Promise<Response> {
    return await fetch("/api/projects/" + guid, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function createProject(projectDto: Partial<IProject>): Promise<Response> {
    return await fetch("/api/projects/", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(projectDto),
    });
}

export async function getGanttChart(text: string) {
    return await fetch("/api/projects/gantt", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "text/plain" }),
        body: text,
    });
}

export async function downloadCustomerReport(startDate: string, endDate: string, projectGuid: string, billableHours: number | undefined) {

    const params = new URLSearchParams({ startDate: startDate.toString(), endDate: endDate.toString(), projectGuid: projectGuid.toString() });
    billableHours && params.append("billableHours", billableHours.toString())
    const res = await fetch("/api/reports/downloadcustomerreport?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    })
    if (!res.ok) return false;
    const blob = await res.blob();
    const url = window.URL.createObjectURL(new Blob([blob], { type: "application/zip" }));
    const link = document.createElement("a");
    link.href = url;
    const fileName =
        "Customer_report_" +
        startDate.toString().padStart(2, "0") +
        "_" +
        endDate.toString().substring(2);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
    return true;
}
