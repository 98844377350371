import React, { useCallback, useEffect, useState } from "react";
import { DocumentType } from "../../models/document";
import { getImageSource } from "../../services/imageService";
import placeholder from "./placeholder.jpg";
import { Image as SemanticImage } from "semantic-ui-react";
import { ReactComponent as UserIcon } from "../../icons/User_icon.svg";
import { ReactComponent as ProjectIcon } from "../../icons/Project_icon.svg";

interface IProps {
    guid: string;
    type: DocumentType;
    avatar?: boolean;
    header?: boolean;
}

export const Image = ({ guid, type, avatar, header }: IProps) => {
    const [src, setSrc] = useState("");
    const handleCheckImageExists = useCallback(async () => {
            const res = await getImageSource(guid, type);
            if (res.ok) setSrc((await res.text()).replace(/"/g, ""));
            else setSrc("");
        }, [guid, type]);
    

    useEffect(() => {
        handleCheckImageExists();
    }, [handleCheckImageExists]);

    return avatar && src.length > 0 ? (
        <SemanticImage src={`${src}?${Math.random()}`} avatar />
    ) : src.length > 0 ? (
        <img src={`${src}?${Math.random()}`} alt="Avatar" />
    ) : header ? (
        <UserIcon title="" />
    ) : type === DocumentType.ProjectPhotos ? (
        <ProjectIcon title=""/>
    ) : (
        <img src={placeholder} alt="Avatar" />
    );
};
