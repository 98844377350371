import { useState } from "react";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { createComment } from "../../api/newsFeed";
import { INewsFeedFilters } from "../../models/newsFeedItem";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddComment: (filters: INewsFeedFilters) => void;
    filters: INewsFeedFilters;
    clearNewsFeed: () => void;
}

export const AddCommentForm = ({
    open,
    closeModal,
    handleAddComment,
    filters,
    clearNewsFeed,
}: IProps) => {
    const [newComment, setNewComment] = useState("");
    const [loading, setLoading] = useState(false);

    const handleAddNewComment = async () => {
        setLoading(true);
        try {
            await createComment(newComment, filters.projectGuid);
            toast("Comment successfully created", true, 1500);
            clearNewsFeed();
            handleAddComment(filters);
        } catch {
            toast("Error creating comment", false, 1500);
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    return (
        <Modal open={open} onClose={closeModal} dimmer="blurring" centered={false}>
            <Modal.Header>
                <h2>Add new comment</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form>
                    <div className="input-container">
                        <label>Comment</label>
                        <Input
                            type="comment"
                            value={newComment}
                            onChange={(e: any) => setNewComment(e.target.value)}
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new comment"
                    loading={loading}
                    disabled={newComment === ""}
                    secondary
                    fluid
                    onClick={handleAddNewComment}
                />
            </Modal.Actions>
        </Modal>
    );
};
