import { DropdownItemProps } from "semantic-ui-react";
import { IBankStatement } from "./bankStatement";
import { IInvoiceCategory } from "./invoice";

export interface IDocument {
    guid: string;
    name: string;
    path: string;
    date: string;
    comment: string;
    documentType: number;
    documentClassification: number;
    username: string;
    userOfDocument: string;
    externalGuid?: string;
    hasUploadedFile?: boolean;
    createdWithoutUpload?: boolean;
}

export interface IUploadDocument {
    documentType: number;
    userGuid?: string;
    entityGuid?: string;
    inventoryGuid?: string;
    companyGuid?: string;
}

export enum DocumentClassification {
    None = 0,
    Confidential = 1,
    Public = 2,
    Internal_only = 3,
    Restricted = 4,
}

export const documentClassificationProps: DropdownItemProps[] = [
    { key: 0, text: "None", value: 0 },
    { key: 1, text: "Confidential", value: 1 },
    { key: 2, text: "Public", value: 2 },
    { key: 3, text: "Internal-only", value: 3 },
    { key: 4, text: "Restricted", value: 4 },
];

export enum DocumentType {
    General = 0,
    Invoice = 1,
    Contract = 2,
    CurriculumVitae = 3,
    SickLeavePaper = 4,
    UserPhoto = 5,
    CompanyPhoto = 6,
    Minutes = 7,
    PaycheckWorkAttendanceReport = 8,
    PaycheckMultisport = 9,
    PaycheckManagementDecision = 10,
    GDPR = 11,
    NDA = 12,
    Certificate = 13,
    ProjectPhotos = 14,
    Images = 15,
    BoardDescription = 16,
    Custom = 17,
}

export const homepageDocumentType: any[] = [
    { key: 2, value: "Contract" },
    { key: 11, value: "GDPR" },
    { key: 12, value: "NDA" },
];

export const documentTypeProps: DropdownItemProps[] = [
    { key: 0, text: "General", value: 0 },
    { key: 1, text: "Invoice", value: 1 },
    { key: 2, text: "Contract", value: 2 },
    { key: 3, text: "Curriculum vitae", value: 3 },
    { key: 4, text: "Sick leave paper", value: 4 },
    { key: 5, text: "User photo", value: 5 },
    { key: 6, text: "Company photo", value: 6 },
    { key: 7, text: "Minutes of the meeting", value: 7 },
    { key: 8, text: "Paycheck work attendance report", value: 8 },
    { key: 9, text: "Paycheck Multisport", value: 9 },
    { key: 10, text: "Paycheck management decision", value: 10 },
    { key: 11, text: "GDPR", value: 11 },
    { key: 12, text: "NDA", value: 12 },
    { key: 13, text: "Certificate", value: 13 },
    { key: 17, text: "Custom", value: 17 }
];

export const userDocumentTypes = [0, 2, 3, 4, 11, 12];

export interface IInvoiceDocument {
    name: string;
    amount: string;
    ibaNs: string[];
    guid: string;
    currency: string;
    exchangeRate: string;
    bankStatementGuid: string;
    invoiceType: number;
    invoiceCategoryGuid: string;
    researchAndDevelopment: boolean;
}

export interface IInvoiceDocumentsWrapper {
    uploadedPDFs: IInvoiceDocument[];
    bankStatements: IBankStatement[];
    invoiceCategories: IInvoiceCategory[];
}

export interface ICloudStorageFile {
    guid: string;
    name: string;
    created: string;
    type: string;
    size: string;
}
