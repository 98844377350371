export const GET_UNREAD_BOARD_NEWS = "GET_UNREAD_BOARD_NEWS";
export const RESET_UNREAD = "RESET_UNREAD";

export const GetUnreadBoardNews = () => {
    return { type: GET_UNREAD_BOARD_NEWS };
};

export const ResetUnread = () => {
    return { type: RESET_UNREAD };
};
