import { Fragment, useCallback, useState } from "react";
import { Table, Button, Modal, Input, Label, Icon } from "semantic-ui-react";
import { toast } from "../..";
import { ILocation } from "../../models/location";
import { deleteLocation, setLocationToHQ, updateLocation } from "../../api/locations";

interface IProps {
    location: ILocation;
    fetchLocations: () => void;
}

export const LocationRow = ({ location, fetchLocations }: IProps) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [locationDTO, setLocationDTO] = useState(location);

    const handleDeleteLocation = async () => {
        try {
            await deleteLocation(location.guid);
            fetchLocations();
            toast("Successfully deleted location", true);
        } catch {
            toast("Failed to delete location", false);
        } finally {
            setDeleteModalOpen(false);
        }
    };

    const handleEditLocation = async () => {
        try {
            await updateLocation(locationDTO);
            fetchLocations();
            toast("Successfully updated location", true);
        } catch {
            toast("Failed to update location", false);
        } finally {
            setEditModalOpen(false);
        }
    };

    const handleSetHQ = async () => {
        try {
            await setLocationToHQ(location.guid);
            fetchLocations();
            toast("Successfully updated HQ location", true);
        } catch {
            toast("Failed to update HQ location", false);
        }
    };

    const handleEnterPress = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter" && locationDTO.name !== "") {
                e.preventDefault();
                handleEditLocation();
            }
        },
        [handleEditLocation]
    );

    const onRowClick = () => {
        setEditModalOpen(true);
    };

    return (
        <Fragment>
            <Table.Row onClick={onRowClick} className="clickable-row">
                <Table.Cell className="label-column" onClick={(e: any) => e.stopPropagation()}>
                    {location.isHQ && <Label className="HQ-label" content="HQ" color="yellow" />}
                </Table.Cell>
                <Table.Cell>{location.name}</Table.Cell>

                <Table.Cell>{location.users?.length}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    <Button
                        color="yellow"
                        content="Set HQ"
                        icon="home"
                        onClick={handleSetHQ}
                        disabled={location.isHQ}
                    />
                    <Button
                        color="red"
                        content="Delete"
                        icon="delete"
                        onClick={() => setDeleteModalOpen(true)}
                    />
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this location?</Modal.Header>
                <Modal.Actions>
                    <Button
                        color="red"
                        onClick={() => setDeleteModalOpen(false)}
                        content="Cancel"
                    />
                    <Button color="green" onClick={handleDeleteLocation} content="Confirm" />
                </Modal.Actions>
            </Modal>
            <Modal
                className="edit-location-modal"
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>
                    <h2>Edit location</h2>
                    <Icon name="close" onClick={() => setEditModalOpen(false)} />
                </Modal.Header>
                <Modal.Content>
                    <form>
                        <div className="input-container">
                            <label>Name</label>
                            <Input
                                name="name"
                                defaultValue={location.name}
                                onChange={(e, data: any) =>
                                    setLocationDTO({ ...locationDTO, name: data.value })
                                }
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleEnterPress(e)}
                            />
                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        disabled={locationDTO.name === ""}
                        color="green"
                        onClick={handleEditLocation}
                        content="Confirm"
                    />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
