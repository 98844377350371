import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import { getVendor } from "../../api/vendors";
import { IVendor } from "../../models/vendor";
import { VendorContacts } from "../VendorContacts/VendorContacts";
import { EditVendor } from "./EditVendor";

export const VendorDetails = () => {
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(true);
    const [vendor, setVendor] = useState<IVendor>();

    const fetchVendor = useCallback(async id => {
        setLoading(true);
        const res = await getVendor(id);
        res.guid && setVendor(res);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchVendor(params.guid);
    }, [fetchVendor, params.guid]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="client-details-container">
            <div className="container-header">
                <h1>Vendor details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/company/vendors", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {vendor ? (
                <div>
                    <EditVendor vendor={vendor} setVendor={setVendor} />
                    <VendorContacts vendorGuid={vendor.guid} />
                </div>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Vendor not found
                </h2>
            )}
        </div>
    );
};
