export function getCroppedImg(crop: any, fileName: string, width? : number, height? : number) {
    const image: any = document.querySelector("img.ReactCrop__image");
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = width ? width : crop.width;
    canvas.height = height ? height : crop.height;
    const ctx = canvas.getContext("2d")!;
    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        width ? width : crop.width,
        height ? height : crop.height
    );
    return new Promise((resolve) => {
        canvas.toBlob(
            (blob: any) => {
                blob.name = fileName;
                resolve(blob);
            },
            "image",
            1
        );
    });
}
