import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Button, Popup, Modal, Label } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { IClient } from "../../models/client";
import { deleteClient } from "../../services/clientsService";
import { fetchClients } from "../../store/clientsReducer";
import { AppState } from "../../store/configureStore";

interface IProps {
    client: IClient;
    handleRemoveClient: () => void;
    page: string;
}

export const ClientRow: React.FC<IProps> = ({ client, handleRemoveClient, page }) => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteClient = async () => {
        try {
            await deleteClient(client.guid);
            handleRemoveClient();
            fetchClients(dispatch);
            toast("Successfully deleted client", true);
        } catch {
            toast("Failed to delete client", false);
        }
    };
    return (
        <Fragment>
            <Table.Row
                key={client.guid}
                onClick={() =>
                    history.push({
                        pathname: `clients/${client.guid}`,
                        state: page,
                    })
                }
                className="clickable-row"
            >
                <Table.Cell>{client.companyName}</Table.Cell>
                <Table.Cell>{client.email}</Table.Cell>
                <Table.Cell>{client.phoneNumber}</Table.Cell>
                <Table.Cell>
                    {client.contractIsExpiring && (
                        <Label circular size="large" color="red" content="Contract expiring" />
                    )}
                </Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Popup
                            disabled={
                                client.accessRights !== undefined
                                    ? client.accessRights.length === 0
                                    : true
                            }
                            content="Cannot delete client that's connected to access right"
                            trigger={
                                <span>
                                    <Button
                                        color="red"
                                        content="Delete"
                                        icon="delete"
                                        onClick={() => setModalOpen(true)}
                                        disabled={
                                            client.accessRights !== undefined
                                                ? client.accessRights.length !== 0
                                                : false
                                        }
                                    />
                                </span>
                            }
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this client?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteClient} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
