import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon, Input, Modal, Select } from "semantic-ui-react";
import { toast } from "../..";
import { projectInit } from "../../models/project";
import { createProject } from "../../api/projects";
import { AppState } from "../../store/configureStore";
import { fetchProjects } from "../../store/projectsReducer";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddProject: () => void;
}

export const AddProjectForm = ({ open, closeModal, handleAddProject }: IProps) => {
    const dispatch = useDispatch();
    const clients = useSelector((state: AppState) => state.clients);
    const staff = useSelector((state: AppState) => state.staff);
    const [newProject, setNewProject] = useState(projectInit);

    const handleAddNewProject = async () => {
        try {
            await createProject(newProject);
            handleAddProject();
            fetchProjects(dispatch);
            toast("Project successfully added", true, 1500);
        } catch {
            toast("Error creating project", false);
        } finally {
            closeModal();
        }
    };

    return (
        <Modal
            className="add-project-modal"
            open={open}
            onClose={closeModal}
            dimmer="blurring"
            centered={false}
        >
            <Modal.Header>
                <h2>Add new project</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-project-form">
                    <div className="input-container">
                        <label>Name</label>
                        <Input
                            type="name"
                            value={newProject.name}
                            onChange={(e: any) =>
                                setNewProject({ ...newProject, name: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Client</label>
                        <Select
                            placeholder="Select project client..."
                            clearable
                            selectOnBlur={false}
                            value={newProject.clientGuid}
                            options={clients}
                            onChange={(e, data: any) =>
                                setNewProject({ ...newProject, clientGuid: data.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Project code</label>
                        <Input
                            value={newProject.projectCode}
                            onChange={(e: any) =>
                                setNewProject({ ...newProject, projectCode: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>URL</label>
                        <Input
                            value={newProject.url}
                            onChange={(e: any) =>
                                setNewProject({ ...newProject, url: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Gitlab URL</label>
                        <Input
                            value={newProject.gitlabUrl}
                            onChange={(e: any) =>
                                setNewProject({ ...newProject, gitlabUrl: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Google drive</label>
                        <Input
                            value={newProject.googleDrive}
                            onChange={(e: any) =>
                                setNewProject({ ...newProject, googleDrive: e.target.value })
                            }
                        />
                    </div>
                </form>
                <div className="input-container">
                    <label>Project members</label>
                    <Select
                        placeholder="Add project members..."
                        selectOnBlur={false}
                        fluid
                        multiple
                        search
                        value={newProject.projectUsers}
                        options={staff}
                        onChange={(e, data: any) =>
                            setNewProject({ ...newProject, projectUsers: data.value })
                        }
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new project"
                    disabled={newProject.name === ""}
                    secondary
                    fluid
                    onClick={handleAddNewProject}
                />
            </Modal.Actions>
        </Modal>
    );
};
