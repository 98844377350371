export interface IAccessRight {
    guid: string;
    claim: string;
    client: string;
    clientGuid: string;
    internalAssignee: string;
    assignee: string;
    assigneeGuid: string;
    recipients: IRecipient[];
    isInternal: boolean;
    isArchived: boolean;
}

export const accessRightInit: Partial<IAccessRight> = {
    claim: "",
    clientGuid: "",
    assigneeGuid: "",
    recipients: [],
    isInternal: false,
    isArchived: false
};

export interface IRecipient {
    recipientGuid: string;
    firstName: string;
    lastName: string;
    userName: string;
    isActive: boolean;
    isArchived: boolean;
    accessRightGuid: string;
    comment: string;
    recipientHistoryNotes: IHistoryNote[]
}

export const recipientInit: Partial<IRecipient> = {
    recipientGuid: "",
    firstName: "",
    lastName: "",
    userName: "",
    isActive: true,
    isArchived: false,
    accessRightGuid: "",
    comment: "",
    recipientHistoryNotes: []
}

export interface IHistoryNote {
    guid: string,
    timestamp: string,
    action: string,
    comment: string
}

export const historyNoteInit: Partial<IHistoryNote> = {
    guid: "",
    timestamp: "",
    action: "",
    comment: ""
}


