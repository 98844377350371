import { useCallback, useEffect, useState } from "react";
import { Modal, Icon, Select, Button, DropdownItemProps, Input } from "semantic-ui-react";
import { projectNoteInit } from "../../models/projectNote";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { workTimeOptions } from "../../models/date";
import { toast } from "../..";
import {
    createProjectNote,
    getProjectNoteAttendeesAndClientAttendees,
} from "../../services/projectNotesService";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import { IUploadDocument } from "../../models/document";
import { DocumentType } from "../../models/document";
import { createDocument } from "../../api/documents";
import "react-quill/dist/quill.snow.css";
import "./ProjectNotes.css";

interface IProps {
    open: boolean;
    projects: any;
    closeModal: () => void;
    handleAddProjectNotes: () => void;
}

export const AddProjectNotesForm = ({
    open,
    projects,
    closeModal,
    handleAddProjectNotes,
}: IProps) => {
    const [newProjectNote, setNewProjectNote] = useState(projectNoteInit);
    const [attendees, setAttendees] = useState<DropdownItemProps[]>([]);
    const [clientAttendees, setClientAttendees] = useState<DropdownItemProps[]>([]);
    const uploadDocument: IUploadDocument = { documentType: DocumentType.Minutes };
    const user = useSelector((state: AppState) => state.user);
    const [openDropzone, setOpenDropzone] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchAllAttendees = useCallback(async () => {
        const res = await getProjectNoteAttendeesAndClientAttendees(newProjectNote.projectGuid!);
        if (res.attendees) {
            setAttendees(res.attendees);
            setClientAttendees(res.clientAttendees);
        }
    }, [newProjectNote]);

    const handleUploadDocument = async (files: File[]) => {
        setLoading(true);
        const res = await createDocument(uploadDocument, files[0]);
        if (res.guid) {
            toast("Document successfully uploaded", true);
        } else toast("Failed to upload document, please try again", false);
        setLoading(false);
        setOpenDropzone(false);
    };

    useEffect(() => {
        open && fetchAllAttendees();
    }, [fetchAllAttendees, open]);

    useEffect(() => {
        setNewProjectNote(p => ({ ...p, attendees: [user.guid!], clientAttendees: [] }));
    }, [newProjectNote.projectGuid]);

    const handleAddNewProjectNote = async () => {
        const res = await createProjectNote(newProjectNote);
        if (!res.ok) toast("Error creating project note", false);
        else {
            handleAddProjectNotes();
            setNewProjectNote(projectNoteInit);
            toast("Project note successfully added", true, 1500);
        }
        closeModal();
    };

    return (
        <Modal open={open} onClose={closeModal} centered={false}>
            <Modal.Header>
                <h2>Add new minutes of the meeting</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-project-notes-form">
                    <section className="add-project-notes-row">
                        <div className="input-container">
                            <label>Meeting title</label>
                            <Input
                                value={newProjectNote.title}
                                onChange={(e: any) =>
                                    setNewProjectNote({
                                        ...newProjectNote,
                                        title: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Project</label>
                            <Select
                                fluid
                                placeholder="Select project..."
                                selectOnBlur={false}
                                options={projects}
                                value={newProjectNote.projectGuid}
                                onChange={(e, data: any) =>
                                    setNewProjectNote({
                                        ...newProjectNote,
                                        projectGuid: data.value,
                                    })
                                }
                            />
                        </div>
                    </section>
                    <section className="add-project-notes-row">
                        <div className="input-container">
                            <label>Date</label>
                            <CalendarInput
                                date={newProjectNote.date}
                                placeholder="Select date..."
                                handleDateChange={e =>
                                    setNewProjectNote({
                                        ...newProjectNote,
                                        date: getISODateFormat(e),
                                    })
                                }
                            />
                        </div>
                        <div id="time-selector" className="input-container">
                            <label>From</label>
                            <Select
                                id="time-selector"
                                placeholder="From"
                                search
                                selectOnBlur={false}
                                value={newProjectNote.from}
                                options={workTimeOptions}
                                onChange={(e, data: any) =>
                                    setNewProjectNote({
                                        ...newProjectNote,
                                        from: data.value,
                                    })
                                }
                            />
                        </div>
                        <div id="time-selector" className="input-container">
                            <label>To</label>
                            <Select
                                id="time-selector"
                                placeholder="To"
                                search
                                selectOnBlur={false}
                                value={newProjectNote.to}
                                options={workTimeOptions}
                                onChange={(e, data: any) =>
                                    setNewProjectNote({
                                        ...newProjectNote,
                                        to: data.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Location</label>
                            <Input
                                value={newProjectNote.location}
                                onChange={(e: any) =>
                                    setNewProjectNote({
                                        ...newProjectNote,
                                        location: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </section>
                    <div className="input-container" id="project-notes-container">
                        <label>Attendees</label>
                        <Select
                            placeholder="Add attendees..."
                            selectOnBlur={false}
                            fluid
                            multiple
                            search
                            value={newProjectNote.attendees}
                            options={attendees}
                            onChange={(e, data: any) =>
                                setNewProjectNote({
                                    ...newProjectNote,
                                    attendees: data.value,
                                })
                            }
                        />
                    </div>
                    <div className="input-container" id="project-notes-container">
                        <label>Client attendees</label>
                        <Select
                            placeholder="Add client attendees..."
                            selectOnBlur={false}
                            fluid
                            multiple
                            search
                            value={newProjectNote.clientAttendees}
                            options={clientAttendees}
                            onChange={(e, data: any) =>
                                setNewProjectNote({
                                    ...newProjectNote,
                                    clientAttendees: data.value,
                                })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Notes</label>
                        <ReactQuill
                            value={newProjectNote.content}
                            onChange={(e: string) =>
                                setNewProjectNote({
                                    ...newProjectNote,
                                    content: e,
                                })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Minutes of the meeting</label>
                        <ReactQuill
                            value={newProjectNote.minutesOfTheMeeting}
                            onChange={(e: string) =>
                                setNewProjectNote({
                                    ...newProjectNote,
                                    minutesOfTheMeeting: e,
                                })
                            }
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    className="minutes-file-upload"
                    secondary
                    fluid
                    content="Upload document"
                    icon="upload"
                    onClick={() => setOpenDropzone(true)}
                />
                <Button
                    content="Add new project note"
                    secondary
                    fluid
                    onClick={handleAddNewProjectNote}
                    disabled={
                        newProjectNote.title === "" ||
                        newProjectNote.content === "" ||
                        newProjectNote.location === "" ||
                        newProjectNote.projectGuid === "" ||
                        newProjectNote.from === "" ||
                        newProjectNote.to === "" ||
                        newProjectNote.attendees?.length === 0
                    }
                />
            </Modal.Actions>
            <Modal open={openDropzone} onClose={() => setOpenDropzone(false)} dimmer="blurring">
                <Modal.Header>Please upload your document</Modal.Header>
                <Modal.Content>
                    <FileDropzone
                        title="Upload document"
                        handleUploadFiles={handleUploadDocument}
                        loading={loading}
                    />
                </Modal.Content>
            </Modal>
        </Modal>
    );
};
