import { useState } from "react";
import { useSelector } from "react-redux";
import { Label, Popup, Segment } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import { daysOfWeek } from "../../models/common";
import { IHeatMapData } from "../../models/heatMapData";
import { AppState } from "../../store/configureStore";
import { getEmptyDates, getMonthLabel, ICalendarDate } from "./getAllDatesHelper";

interface IProps {
    month: number;
    dates: ICalendarDate[];
    selectedDates: string[];
    handleDates: (d: string) => void;
    previousSameType: string[];
    previousOtherTypes: string[];
    holidays: string[];
    enableEdit: boolean;
    selectableWeekends?: boolean;
    type?: string;
    preselectionDates: string[];
    handlePreselectionDates: (d: string) => void;
    heatMapData?: IHeatMapData[];
}

export const CalendarMonth = ({
    month,
    dates,
    selectedDates,
    handleDates,
    previousSameType,
    previousOtherTypes,
    holidays,
    enableEdit,
    selectableWeekends,
    type,
    preselectionDates,
    handlePreselectionDates,
    heatMapData,
}: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [employeesOnVacation, setEmployeesOnVacation] = useState<string[]>([]);
    const monthDates = getEmptyDates(dates[0].dayOfWeek).concat(dates);
    const heatMapCondition =
        type === "yearly-vacation-plan" && user.role === UserRole.Admin && heatMapData;

    const onHoverActions = (calendarDate: ICalendarDate, enter: boolean) => {
        if (enableEdit && enter) {
            handlePreselectionDates(calendarDate.value);
        }
        if (enableEdit && !enter) {
            handlePreselectionDates("");
        }
        if (heatMapCondition && enter) {
            setEmployeesOnVacation(
                heatMapData.filter(h => h.date === calendarDate.value)[0]?.employees
            );
        }
        if (heatMapCondition && !enter) {
            setEmployeesOnVacation([]);
        }
    };

    const getClassName = (x: ICalendarDate) => {
        let employeesCount = heatMapData?.find(h => h.date === x.value)?.employees.length;
        if (
            (!selectableWeekends && x.dayOfWeek > 5) ||
            previousSameType.includes(x.value) ||
            previousOtherTypes.includes(x.value) ||
            holidays.includes(x.value) ||
            (heatMapCondition && employeesCount === 0)
        ) {
            return "disabled";
        } else if (x.day < 1) {
            return "invisible";
        } else return "";
    };

    const getColor = (x: ICalendarDate) => {
        let employeesCount = heatMapData?.find(h => h.date === x.value)?.employees.length;
        if (previousOtherTypes.includes(x.value)) {
            return "grey";
        } else if (
            previousSameType.includes(x.value) ||
            (heatMapCondition && [3, 4, 5].includes(employeesCount!))
        ) {
            return "yellow";
        } else if (
            selectedDates.includes(x.value) ||
            (heatMapCondition && [1, 2].includes(employeesCount!))
        ) {
            return "green";
        } else if (
            preselectionDates.includes(x.value) ||
            (heatMapCondition && employeesCount! >= 6)
        ) {
            return "red";
        } else return "grey";
    };

    const labelComp = (x: ICalendarDate) => (
        <Label
            className={getClassName(x)}
            color={getColor(x)}
            key={x.day}
            onClick={enableEdit ? () => handleDates(x.value) : undefined}
            onMouseEnter={() => onHoverActions(x, true)}
            onMouseLeave={() => onHoverActions(x, false)}
            content={x.day}
        />
    );

    return (
        <Segment.Group className="calendar-month-container">
            <Segment className="calendar-month-header">
                <h4>{getMonthLabel(month)}</h4>
                <div className="calendar-month-week">
                    {daysOfWeek.map(x => (
                        <span key={x}>{x}</span>
                    ))}
                </div>
            </Segment>
            <Segment className="calendar-month-days">
                {monthDates.map(x =>
                    heatMapData && heatMapData.length !== 0 ? (
                        <Popup key={x.value} trigger={labelComp(x)}>
                            <Popup.Header>On vacation:</Popup.Header>
                            <Popup.Content className="popup-content-display">
                                {employeesOnVacation && employeesOnVacation.map(e => (
                                    <span key={e}>{e}</span>
                                ))}
                            </Popup.Content>
                        </Popup>
                    ) : (
                        labelComp(x)
                    )
                )}
            </Segment>
        </Segment.Group>
    );
};
