import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppState } from "../../store/configureStore";
import { Icon, Loader, Pagination, Segment, Table, Button } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import "./Services.css";
import { IService } from "../../models/service";
import { ServiceRow } from "./ServiceRow";
import { AddServiceForm } from "./AddServiceForm";
import { getServices } from "../../api/services";

export const Services = () => {
    const user = useSelector((state: AppState) => state.user);
    const [loading, setLoading] = useState(true);
    const loc = useLocation();
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [totalRows, setTotalRows] = useState(0);
    const [services, setServices] = useState<IService[]>([]);
    const [modalOpen, setModalOpen] = useState(false);

    const fetchServices = useCallback(async () => {
        setLoading(true);
        const res = await getServices(page);
        if (res.rows) {
            setServices(res.rows as IService[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page]);

    useEffect(() => {
        fetchServices();
    }, [fetchServices]);

    const handleRemoveService = (d: IService) => setServices([...services].filter(x => x !== d));

    const handleFetchServices = () => {
        setPage(1);
        fetchServices();
    };

    return (
        <div className="services-container">
            <div className="container-header">
                <h1>Services</h1>
                {user.role === UserRole.Admin && (
                    <Button
                        secondary
                        content="Add new service"
                        icon="plus"
                        onClick={() => setModalOpen(true)}
                    />
                )}
            </div>
            <div className="services-display">
                <div className="services-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment />
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Code</Table.HeaderCell>
                                            <Table.HeaderCell>Service description</Table.HeaderCell>
                                            <Table.HeaderCell>Unit measure</Table.HeaderCell>
                                            <Table.HeaderCell>Net price (HRK)</Table.HeaderCell>
                                            <Table.HeaderCell>Discount (%)</Table.HeaderCell>
                                            <Table.HeaderCell>Tax rate (%)</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {services.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="5">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            services.map(x => (
                                                <ServiceRow
                                                    key={x.guid}
                                                    service={x}
                                                    handleRemoveService={handleRemoveService}
                                                    page={page + ""}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                            <AddServiceForm
                                open={modalOpen}
                                closeModal={() => setModalOpen(false)}
                                handleAddService={handleFetchServices}
                            />
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
