import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Icon, Loader, Button } from "semantic-ui-react";
import { toast } from "../..";
import { CalendarSelector } from "../../components/CalendarSelector/CalendarSelector";
import {
    getUserPreviousWorkAttendanceDates,
    getYearlyVacationPlansHeatMapData,
} from "../../services/workAttendancesService";
import { getAttendanceTypeEnum } from "./CreateWorkAttendance";
import { IHeatMapData } from "../../models/heatMapData";

export const ViewWorkAttendance = () => {
    const history = useHistory();
    const params: { guid: string } = useParams();
    const type = history.location.pathname.split("/")[2];
    const [dates, setDates] = useState<string[]>([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(true);
    const [previousSameType, setPreviousSameType] = useState<string[]>([]);
    const [previousOtherTypes, setPreviousOtherTypes] = useState<string[]>([]);
    const [holidays, setHolidays] = useState<string[]>([]);
    const [heatMapData, setHeatMapData] = useState<IHeatMapData[]>([]);
    const loc = useLocation();

    const handleDates = (d: string) =>
        dates.includes(d) ? setDates(dates.filter(x => x !== d)) : setDates(dates.concat(d));

    const fetchPreviousWorkAttendanceDates = useCallback(async () => {
        setLoading(true);
        const res = await getUserPreviousWorkAttendanceDates(
            getAttendanceTypeEnum(type),
            params.guid
        );
        if (res.previousSameType) {
            setPreviousSameType(res.previousSameType);
            setPreviousOtherTypes(res.previousOtherTypes);
            setHolidays(res.holidays);
            setDates(res.currentDates);
        }
        setLoading(false);
    }, [type, params.guid]);

    const fetchHeatMapData = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getYearlyVacationPlansHeatMapData();
            if (res.rows) {
                setHeatMapData(res.rows);
            }
        } catch (error) {
            toast("Problem while fetching yearly vacation plans data", false);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        params.guid ? fetchPreviousWorkAttendanceDates() : fetchHeatMapData();
    }, [params.guid]);

    return (
        <div>
            <div className="create-plan-header">
                <Icon size="big" name="angle left" onClick={() => setYear(year - 1)} />
                <h1>{year}</h1>
                <Icon size="big" name="angle right" onClick={() => setYear(year + 1)} />
            </div>
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <Fragment>
                    <CalendarSelector
                        year={year}
                        selectedDates={dates}
                        handleDates={handleDates}
                        previousSameType={previousSameType}
                        previousOtherTypes={previousOtherTypes}
                        holidays={holidays}
                        enableEdit={false}
                        preselectionDates={[]}
                        handlePreselectionDates={() => {}}
                        heatMapData={heatMapData}
                        type={type}
                    />
                    <Button
                        as={Link}
                        to={
                            type === "yearly-vacation-plan"
                                ? "/employee-data/vacations"
                                : { pathname: "/employee-data/" + type, state: loc.state }
                        }
                        secondary
                        content="Back to list"
                        icon="arrow left"
                    />
                </Fragment>
            )}
        </div>
    );
};
