import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, Table } from "semantic-ui-react";
import { toast } from "../..";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { IProjectNote } from "../../models/projectNote";
import { deleteProjectNote } from "../../services/projectNotesService";

interface IProps {
    projectNote: IProjectNote;
    handleRemoveProjectNote: (r: IProjectNote) => void;
    page: string;
}

export const ProjectNotesRow: React.FC<IProps> = ({
    projectNote,
    handleRemoveProjectNote,
    page,
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteProjectNote = async () => {
        const res = await deleteProjectNote(projectNote.guid);
        if (!res.ok) toast("Failed to delete project note", false);
        else {
            handleRemoveProjectNote(projectNote);
            toast("Successfully deleted project note", true);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `minutes/${projectNote.guid}`,
            state: page
        });
    }

    return (
        <Fragment>
            <Table.Row onClick={onRowClick} className="clickable-row">
                <Table.Cell>{getDisplayDateFormat(projectNote.date)}</Table.Cell>
                <Table.Cell>{projectNote.title}</Table.Cell>
                <Table.Cell>{projectNote.project}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    <Button
                        color="red"
                        content="Delete"
                        icon="delete"
                        onClick={() => setModalOpen(true)}
                    />
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>
                    Are you sure you want to delete these minutes of the meeting?
                </Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteProjectNote} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
