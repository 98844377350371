import { useCallback, useEffect, useState } from "react";
import { Location } from "history";
import { useParams, useHistory, useLocation, Link, Prompt } from "react-router-dom";
import {
    Button,
    Icon,
    Input,
    Label,
    Loader,
    Modal,
    Segment,
    Select,
    Table,
} from "semantic-ui-react";
import { toast } from "../..";
import { ApproveStatus, getDayOfWeek, getStatusColor, getStatusLabel } from "../../models/common";
import { IDate, workAttendanceTypeOptions, workTimeOptions } from "../../models/date";
import { IWorkingPlan, WorkingPlanType } from "../../models/workingPlan";
import { getPlanHours, validatePlanHours } from "./getPlanHoursHelper";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import {
    deleteWorkingPlanRequest,
    getWorkingPlan,
    getWorkingPlanDates,
    updateWorkingPlan,
} from "../../api/workingPlans";

interface LocationState {
    fromCreate: {
        obj: boolean;
    };
}

export const WorkingPlanDetails = () => {
    const user = useSelector((state: AppState) => state.user);
    const params: { guid: string } = useParams();
    const history = useHistory();
    const loc = useLocation<LocationState>();
    const type = loc.pathname.split("/")[1]!;
    const [loading, setLoading] = useState(false);
    const [workingPlan, setWorkingPlan] = useState<IWorkingPlan>();
    const [dates, setDates] = useState<IDate[]>([]);
    const [disabled, setDisabled] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location<unknown>>(); //unknown je tip kojeg vraća Prompt komponenta
    const [modalOpen, setModalOpen] = useState(false);
    const [authorized, setAuthorized] = useState(false);

    const handleUpdateWorkingPlan = async () => {
        setSaving(true);
        setSaved(true);
        if (dates.length === 0) {
            try {
                await deleteWorkingPlanRequest(workingPlan!.guid);
                toast(
                    `${
                        WorkingPlanType[workingPlan!.workingPlanType]
                    } plan request successfully deleted`,
                    true
                );
                history.push(`/${type}`);
            } catch {
                toast("Error saving changes", false);
            } finally {
                setSaving(false);
            }
        } else {
            try {
                const previousStatus = workingPlan?.status;
                await updateWorkingPlan(workingPlan!.guid, dates);
                if (previousStatus === ApproveStatus.Approved)
                    toast(
                        `Your request to edit your ${WorkingPlanType[
                            workingPlan!.workingPlanType
                        ].toLowerCase()} plan has been sent`,
                        true
                    );
                else
                    toast(
                        `${
                            WorkingPlanType[workingPlan!.workingPlanType]
                        } plan successfully created`,
                        true
                    );
                if (workingPlan?.status == ApproveStatus.Draft)
                    sessionStorage.setItem("prevStudStatus", "2");
                else sessionStorage.setItem("prevStudStatus", "4");
                history.push(`/${type}`);
            } catch {
                toast("Error saving changes", false);
            } finally {
                setSaving(false);
            }
        }
    };

    const handleDeleteWorkingPlan = async () => {
        setSaving(true);
        try {
            if (lastLocation) {
                await deleteWorkingPlanRequest(workingPlan!.guid);
                toast(
                    `${
                        WorkingPlanType[workingPlan!.workingPlanType]
                    } plan request successfully deleted`,
                    true
                );
                history.push(lastLocation);
            }
        } catch {
            toast("Error saving changes", false);
        } finally {
            setSaving(false);
            setModalOpen(false);
        }
    };

    const fetchWorkingPlan = useCallback(async guid => {
        setLoading(true);
        const sp = await getWorkingPlan(guid);
        if (sp.guid) {
            setWorkingPlan(sp);
            console.log(sp.workingPlanType);
            setAuthorized(user.username === sp.userEmail);
            let res = await getWorkingPlanDates(guid);
            if (Array.isArray(res)) {
                setDates(res.map(r => (r.hours === 0 ? { ...r, hours: 8 } : r)));
            }
        }
        setLoading(false);
    }, []);

    const handleDateInputChange = (i: number, prop: string, e: any, data: any) => {
        let newDates = [...dates];
        if (prop !== "hours") {
            newDates[i][prop] = data.value;
            let hours: number | null = null;
            if (newDates[i].start && newDates[i].end) hours = getPlanHours(newDates[i]);
            if (hours) newDates[i].hours = hours;
        } else newDates[i].hours = e.target.value;
        setDates(newDates);
    };

    const handleDeleteDate = (i: number) => {
        let newDates = [...dates].filter((d, index) => index !== i);
        setDates(newDates);
    };

    useEffect(() => {
        fetchWorkingPlan(params.guid);
    }, [fetchWorkingPlan, params.guid]);

    useEffect(() => {
        const valid = validatePlanHours(dates);
        if (valid === dates.length) setDisabled(false);
        else setDisabled(true);
    }, [dates]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="working-plan-details-container">
            <div className="container-header">
                <h1>
                    {workingPlan?.workingPlanType
                        ? `${WorkingPlanType[workingPlan.workingPlanType]} plan details`
                        : "Plan details"}
                </h1>
                <Button
                    as={Link}
                    to={{ pathname: `/${type}`, state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {workingPlan ? (
                <div className="working-plan-details-content">
                    <Segment.Group>
                        <Segment>
                            <h2>Plan details</h2>
                        </Segment>
                        <Segment>
                            <Table basic="very" celled compact>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>User</Table.Cell>
                                        <Table.Cell>{workingPlan.userEmail}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Status</Table.Cell>
                                        <Table.Cell>
                                            <Label
                                                size="large"
                                                circular
                                                color={getStatusColor(workingPlan.status)}
                                                content={getStatusLabel(workingPlan.status)}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Submit date</Table.Cell>
                                        <Table.Cell>
                                            {getDisplayDateFormat(workingPlan.submitDate)}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment.Group>
                    <Segment.Group>
                        <Segment>
                            <h2>Plan dates</h2>
                        </Segment>
                        <Segment>
                            <Table basic="very">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        <Table.HeaderCell>Start</Table.HeaderCell>
                                        <Table.HeaderCell>End</Table.HeaderCell>
                                        <Table.HeaderCell>Hours</Table.HeaderCell>
                                        <Table.HeaderCell>Work status</Table.HeaderCell>
                                        {authorized && <Table.HeaderCell>Actions</Table.HeaderCell>}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {dates.length === 0 ? (
                                        <Table.Row>
                                            <Table.Cell textAlign="center" colSpan="5">
                                                No matching records found
                                            </Table.Cell>
                                        </Table.Row>
                                    ) : (
                                        dates.map(date => (
                                            <Table.Row key={date.guid}>
                                                <Table.Cell>
                                                    {getDisplayDateFormat(date.workDay)} (
                                                    {getDayOfWeek(date.workDay)})
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {authorized ? (
                                                        <Select
                                                            placeholder="Start"
                                                            selectOnBlur={false}
                                                            options={workTimeOptions}
                                                            value={date.start}
                                                            onChange={(e, data) =>
                                                                handleDateInputChange(
                                                                    dates.findIndex(
                                                                        x => x.guid === date.guid
                                                                    ),
                                                                    "start",
                                                                    e,
                                                                    data
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <span>{date.start.toString()}</span>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {authorized ? (
                                                        <Select
                                                            placeholder="End"
                                                            selectOnBlur={false}
                                                            options={workTimeOptions}
                                                            value={date.end}
                                                            onChange={(e, data) =>
                                                                handleDateInputChange(
                                                                    dates.findIndex(
                                                                        x => x.guid === date.guid
                                                                    ),
                                                                    "end",
                                                                    e,
                                                                    data
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <span>{date.end.toString()}</span>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {authorized ? (
                                                        <Input
                                                            value={date.hours ?? ""}
                                                            onChange={(e, data) =>
                                                                handleDateInputChange(
                                                                    dates.findIndex(
                                                                        x => x.guid === date.guid
                                                                    ),
                                                                    "hours",
                                                                    e,
                                                                    data
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <span>{date.hours.toString()}</span>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {authorized ? (
                                                        <Select
                                                            placeholder="Work status"
                                                            selectOnBlur={false}
                                                            options={workAttendanceTypeOptions}
                                                            value={date.workStatus}
                                                            onChange={(e, data) =>
                                                                handleDateInputChange(
                                                                    dates.findIndex(
                                                                        x => x.guid === date.guid
                                                                    ),
                                                                    "workStatus",
                                                                    e,
                                                                    data
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <span>
                                                            {
                                                                workAttendanceTypeOptions.find(
                                                                    x => x.value === date.workStatus
                                                                )?.text
                                                            }
                                                        </span>
                                                    )}
                                                </Table.Cell>
                                                {authorized && (
                                                    <Table.Cell>
                                                        <Button
                                                            className="delete-date-button"
                                                            icon="close"
                                                            color="red"
                                                            content="Delete"
                                                            onClick={() =>
                                                                handleDeleteDate(
                                                                    dates.findIndex(
                                                                        x => x.guid === date.guid
                                                                    )
                                                                )
                                                            }
                                                            disabled={!authorized}
                                                        />
                                                    </Table.Cell>
                                                )}
                                            </Table.Row>
                                        ))
                                    )}
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment.Group>
                    {authorized && (
                        <Button
                            secondary
                            content="Save changes"
                            icon="save"
                            fluid
                            disabled={disabled || saving}
                            loading={saving}
                            onClick={handleUpdateWorkingPlan}
                        />
                    )}
                </div>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Working plan not found
                </h2>
            )}
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>
                    Are you sure you want to leave? It will remove present working plan
                </Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteWorkingPlan} content="Confirm" />
                </Modal.Actions>
            </Modal>
            <Prompt
                when={loc.state?.fromCreate !== undefined && !saved && !modalOpen}
                message={(location, action) => {
                    if (location !== loc) {
                        setModalOpen(true);
                        setLastLocation(location);
                        return false;
                    }
                    return true;
                }}
            />
        </div>
    );
};
