import axios from "axios";
import { DropdownItemProps } from "semantic-ui-react";
import { IPaginationWrapper } from "../models/common";
import { IVendor } from "../models/vendor";

export const getVendors = async (page: number) => {
    const response = await axios.get(`/vendors?page=${page}`);
    return response.data as IPaginationWrapper;
};

export const getVendorContactsAsProps = async (vendorGuid: string | undefined) => {
    const response = await axios.get(`/vendors/contacts/props/${vendorGuid}`);
    return response.data as DropdownItemProps[];
};

export const getVendorsAsProps = async () => {
    const response = await axios.get(`/vendors/props`);
    return response.data as DropdownItemProps[];
};

export const getVendor = async (guid: string) => {
    const response = await axios.get(`/vendors/${guid}`);
    return response.data as IVendor;
};

export const createVendor = async (vendorDto: Partial<IVendor>) => {
    const response = await axios.post("/vendors", vendorDto);
    return response;
};

export const updateVendor = async (vendorDto: IVendor) => {
    const response = await axios.put(`/vendors/${vendorDto.guid}`, vendorDto);
    return response;
};

export const deleteVendor = async (guid: string) => {
    const response = await axios.delete(`/vendors/${guid}`);
    return response;
};
