import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Form, Input, Button } from "semantic-ui-react";
import { RedirectIfLoggedIn } from "../Login/RedirectIfLoggedIn";
import { ReactComponent as LogoIcon } from "../../icons/Aconto_logo_small.svg";
import { ReactComponent as Logo } from "../../icons/Aconto_logo_log_in.svg";
import { Link } from "react-router-dom";
import { checkPasswordToken, resetPassword } from "../../services/authenticationService";

export const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [repeatedPassword, setRepeatedPassword] = useState("");
    const [passwordDontMatch, setPasswordDontMatch] = useState(false);
    const [validToken, setValidToken] = useState(false);
    const [token, setToken] = useState("");
    const location: any = useLocation();
    const [success, setSuccess] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const disabled = password.length === 0 || repeatedPassword.length === 0 || sendingRequest;

    const handleResetPassword = async () => {
        setSendingRequest(true);
        const userDto = { token: token, password: password };
        const res = await resetPassword(userDto);
        if (res.ok) {
            setSuccess(true);
        }
        setSendingRequest(false);
    };

    useEffect(() => {
        const tokenString = location.search;
        const newToken = tokenString.substring(tokenString.indexOf("=") + 1);
        setToken(newToken);
        checkPasswordToken(newToken).then((res) => {
            if (res.ok) setValidToken(true);
        });
    }, [location.search]);

    useEffect(() => {
        if (password !== "" && repeatedPassword !== "" && password !== repeatedPassword) {
            setPasswordDontMatch(true);
        } else {
            setPasswordDontMatch(false);
        }
    }, [password, repeatedPassword]);

    return (
        <div className="login-container">
            <RedirectIfLoggedIn
                path={location.state !== undefined ? location.state.from.pathname : "/login"}
            />
            <div className="center-box">
                <React.Fragment>
                    <div className="login-form-container">
                        <LogoIcon title="" />
                        {success ? (
                            <div className="succesfully-reset-password">
                                <p id="text-center">Password sucessfully reset</p>
                                <Button
                                    fluid
                                    primary
                                    size="large"
                                    color="yellow"
                                    as={Link}
                                    to="/login"
                                    content="Back to log in"
                                />
                            </div>
                        ) : validToken ? (
                            <React.Fragment>
                                <h2 id="text-center">Reset password</h2>
                                <Form>
                                    <Input
                                        id="password"
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <Input
                                        id="repeated-password"
                                        type="password"
                                        placeholder="Repeated password"
                                        value={repeatedPassword}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setRepeatedPassword(e.target.value)
                                        }
                                    />
                                    {passwordDontMatch && (
                                        <p id="login-error-message">Passwords don't match.</p>
                                    )}
                                    <Button
                                        fluid
                                        size="large"
                                        color="yellow"
                                        type="submit"
                                        primary
                                        loading={sendingRequest}
                                        disabled={disabled || passwordDontMatch}
                                        content="Reset password"
                                        onClick={handleResetPassword}
                                    />
                                </Form>
                            </React.Fragment>
                        ) : (
                            <div className="succesfully-reset-password">
                                <p id="login-error-message">
                                    Password reset token has expired or is invalid. Please try
                                    resetting your password again.
                                </p>
                            </div>
                        )}
                    </div>
                    <div id="divider"></div>
                    <div className="aconto-logo-container">
                        <Logo title="" />
                        <h1>Business administration made easy</h1>
                    </div>
                </React.Fragment>
            </div>
        </div>
    );
};
