export const ADD_IMAGE_GUID = 'ADD_IMAGE_GUID';
export const REFRESH_IMAGE = 'REFRESH_IMAGE';

export function AddImageGuid(imageGuid) {
    return { type: ADD_IMAGE_GUID, imageGuid };
}

export function RefreshImage(imageGuid) {
    return { type: REFRESH_IMAGE, imageGuid };
}
