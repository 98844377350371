import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Button, Icon, Loader, Radio, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { getUser, updateUser } from "../../api/users";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { AppState } from "../../store/configureStore";

export const SuperAdminUserDetails = () => {
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const params: { guid: string } = useParams();
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState<UserData>();
    const [saving, setSaving] = useState(false);

    const fetchUser = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getUser(params.guid);
            setUserDetails(res);
        } catch (error) {
            toast("Failed to fetch user details.", false);
        } finally {
            setLoading(false);
        }
    }, [params.guid]);

    const handleUpdateUser = async () => {
        setSaving(true);
        try {
            await updateUser(userDetails!);
            toast("Successfully saved changes", true);
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    return !(user.role === UserRole.SuperAdmin) ? (
        <Redirect to="/unauthorized" />
    ) : loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="user-container">
            <div className="container-header">
                <h1>User details</h1>
                <div>
                    <Button
                        secondary
                        content="Save changes"
                        icon="save"
                        onClick={handleUpdateUser}
                        disabled={saving}
                        loading={saving}
                    />
                    {user.role === UserRole.SuperAdmin && (
                        <Button
                            secondary
                            content="Back to list"
                            icon="arrow left"
                            onClick={() => history.push("/administration/users")}
                        />
                    )}
                </div>
            </div>
            {userDetails && !loading ? (
                <Fragment>
                    <Segment.Group>
                        <Segment>
                            <Table basic="very" celled compact>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell width={3}>User name</Table.Cell>
                                        <Table.Cell>
                                            {userDetails.firstName} {userDetails.lastName}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={3}>User email</Table.Cell>
                                        <Table.Cell>{userDetails.username}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={3}>Company</Table.Cell>
                                        <Table.Cell>{userDetails.company}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={3}>Start date</Table.Cell>
                                        <Table.Cell>
                                            {getISODateFormat(
                                                new Date(userDetails.startDate ?? "00-00-0000")
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Is enabled</Table.Cell>
                                        <Table.Cell>
                                            <Radio
                                                toggle
                                                checked={userDetails.isActive}
                                                onChange={() =>
                                                    setUserDetails({
                                                        ...userDetails,
                                                        isActive: !userDetails.isActive,
                                                    })
                                                }
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell width={3}>Company</Table.Cell>
                                        <Table.Cell>
                                            <Button
                                                secondary
                                                content={userDetails.company}
                                                icon="arrow left"
                                                onClick={() => history.push("/companies")}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment.Group>
                </Fragment>
            ) : (
                <h2 className="user-not-found-h2">
                    <Icon name="warning circle" />
                    User not found
                </h2>
            )}
        </div>
    );
};
