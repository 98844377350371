import { useState } from "react";
import { Button, Icon, Input, Modal, TextArea } from "semantic-ui-react";
import { toast } from "../..";
import { createProjectRole } from "../../api/projects";
import { projectRoleInit } from "../../models/projectRole";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddProjectRole: () => void;
}

export const AddProjectRoleForm = ({ open, closeModal, handleAddProjectRole }: IProps) => {
    const [newProjectRole, setNewProjectRole] = useState(projectRoleInit);

    const handleAddNewProjectRole = async () => {
        try {
            await createProjectRole(newProjectRole);
            handleAddProjectRole();
            toast("Project role successfully added", true, 1500);
        } catch {
            toast("Error creating project role", false, 1500);
        } finally {
            closeModal();
        }
    };

    return (
        <Modal open={open} onClose={closeModal} dimmer="blurring" centered={false}>
            <Modal.Header>
                <h2>Add new project role</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form>
                    <div className="input-container">
                        <label>Name</label>
                        <Input
                            type="name"
                            value={newProjectRole.name}
                            onChange={(e: any) =>
                                setNewProjectRole({
                                    ...newProjectRole,
                                    name: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Description</label>
                        <TextArea
                            rows={2}
                            type="description"
                            value={newProjectRole.description}
                            onChange={(e: any) =>
                                setNewProjectRole({
                                    ...newProjectRole,
                                    description: e.target.value,
                                })
                            }
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new project role"
                    disabled={newProjectRole.name === ""}
                    secondary
                    fluid
                    onClick={handleAddNewProjectRole}
                />
            </Modal.Actions>
        </Modal>
    );
};
