import { UserRole } from "../actions/authentificationActions";
import { ApproveStatus } from "./common";
import { IDate } from "./date";

export interface IWorkAttendance {
    guid: string;
    dateOfRequest: string;
    startDate: string;
    endDate: string;
    status: ApproveStatus;
    attendanceType: WorkAttendance;
    user: string;
    userEmail: string;
    duration: number;
    companyGuid: string;
    userGuid: string;
    reason?: string;
    numberOfDocuments: number;
    dates: Array<IDate>;
}

export enum WorkAttendance {
    Unknown = 0,
    Vacation = 1,
    SickLeave = 2,
    RemoteWork = 3,
    Office = 4,
    PaidLeave = 5,
    ParentalLeave = 6,
    YearlyVacationPlan = 7,
    Present = 8,
    Remote = 9,
    Weekend = 10,
    Absent = 11
}

export interface CurrentWorkAttendance {
    attendance: WorkAttendance;
}

export const getWorkAttendanceStatus = (
    workAttendance: WorkAttendance | undefined,
    role: string
) => {
    if (workAttendance === WorkAttendance.Unknown) {
        return "Not working today";
    } else if (workAttendance === WorkAttendance.Office) {
        return "At office";
    } else if (
        (role === UserRole.Employee && workAttendance === WorkAttendance.RemoteWork) ||
        (role === UserRole.Student && workAttendance === WorkAttendance.Remote)
    ) {
        return "Remote";
    } else {
        return "Out of office";
    }
};