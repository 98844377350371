import { useCallback, useEffect, useState } from "react";
import { Button, Icon, Modal, TextArea, Checkbox } from "semantic-ui-react";
import { IBoardData, newBoardInit } from "../../models/board";
import { toast } from "../..";
import agent from "../../api/agent";
import { DocumentType } from "../../models/document";
import { ImageDropzone } from "../../components/FileDropzone/ImageDropzone";
import { getCroppedImg } from "../UsersDashboard/getCroppedImage";
import { putImage } from "../../services/imageService";
import { v4 as uuidv4 } from "uuid";
import { TextInput } from "../../components/TextInput/TextInput";

interface IProps {
    open: boolean;
    closeModal: () => void;
    refreshBoard: () => void;
    guid?: string;
}

export const EditBoardNews = ({ guid, open, closeModal, refreshBoard }: IProps) => {
    const [loading, setLoading] = useState(false);
    const [news, setNews] = useState<IBoardData>(newBoardInit);
    const [checked, setCheckbox] = useState<boolean>(false);
    const [crop, setCrop] = useState(false);
    const [imageDropped, setImageDropped] = useState(false);

    const fetchUser = useCallback(async () => {
        const res = await agent.Board.getByGuid(guid);
        setNews(res);
        setCheckbox(res.active);
    }, [guid]);

    const setActive = useCallback(() => {
        setNews(n => ({ ...n, active: checked }));
    }, [checked]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    useEffect(() => {
        setActive();
    }, [setActive]);

    const handleEditBoard = async () => {
        try {
            if (imageDropped) {
                let imgGuid = uuidv4();
                const img = await getCroppedImg(crop, imgGuid);
                await putImage(imgGuid, img, DocumentType.Images);
                news.imgGuid = imgGuid;
            }
            await agent.Board.edit(news);
            refreshBoard();
            toast("Changes saved", true);
            setImageDropped(false);
        } catch (e: any) {
            toast(e.message, false);
        } finally {
            closeModal();
            setLoading(false);
        }
    };
    return (
        <Modal
            className="add-board-modal"
            open={open}
            onClose={closeModal}
            dimmer="blurring"
            centered={false}
        >
            <Modal.Header>
                <h2>Add board news</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-board-form">
                    <div className="input-container">
                        <label>Title</label>
                        <TextInput
                            disabled={false}
                            name={"title"}
                            value={news.title}
                            handleInputChange={(e: any) =>
                                setNews({ ...news, title: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Description</label>
                        <TextArea
                            style={{ minHeight: 250 }}
                            value={news.description}
                            placeholder="tell us more"
                            onChange={(e: any) => setNews({ ...news, description: e.target.value })}
                        />
                    </div>
                    <div className="input-container">
                        <label>Active/deactivate news</label>
                        <Checkbox
                            checked={news.active}
                            onChange={() => setCheckbox(!checked)}
                            toggle
                        />
                    </div>
                    <div className="input-container">
                        <label>News image</label>
                        <ImageDropzone
                            guid={news?.imgGuid ?? ""}
                            onDrop={() => setImageDropped(true)}
                            crop={crop}
                            setCrop={setCrop}
                            documentType={DocumentType.Images}
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Save changes"
                    icon="save"
                    loading={loading}
                    secondary
                    fluid
                    onClick={handleEditBoard}
                />
            </Modal.Actions>
        </Modal>
    );
};
