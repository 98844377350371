import { DropdownItemProps } from "semantic-ui-react";

export interface IProject {
    guid: string;
    name: string;
    description: string;
    url: string;
    gitlabUrl: string;
    gitlabProjectId: number;
    gitlabWebhookToken: string;
    googleDrive: string;
    projectCode: string;
    client: string;
    clientGuid: string;
    visibility: number;
    projectUsers: string[];
    projectProjectRoles: string[];
    usersWithAccessRequest: string[];
}

export const projectInit: Partial<IProject> = {
    name: "",
    url: "",
    gitlabUrl: "",
    gitlabProjectId: undefined,
    gitlabWebhookToken: "",
    googleDrive: "",
    projectCode: "",
    client: "",
    clientGuid: "",
    projectUsers: [],
    projectProjectRoles: [],
};

export const projectVisibilityTypes: DropdownItemProps[] = [
    { key: 0, text: "Visible to everyone", value: 0 },
    { key: 1, text: "Visible to employees only", value: 1 },
    { key: 2, text: "Hidden", value: 2 },
];

export const projectOptions = [
    { key: 0, label: "All projects" },
    { key: 1, label: "My projects" },
];
