import { DocumentType } from "../models/document";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getImageSource(guid: string, type: DocumentType) {
    return await fetch(`/api/images/${guid}?type=${type}`, {
        method: "GET",
        ...getAuthorizationProperties(),
    });
}

export async function putImage(guid: string, data: any, type: DocumentType) {
    return await fetch(`/api/images/${guid}?type=${type}`, {
        method: "PUT",
        ...getAuthorizationProperties(),
        body: data,
    });
}
