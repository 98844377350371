import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { Button, DropdownItemProps, Icon, Loader } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { getBuyRequestsList } from "../../api/buyRequests";
import { getInventoryItemsList } from "../../api/inventoryItems";
import { getUsers } from "../../api/users";
import { DocumentsComponentTable } from "../../components/DocumentsComponentTable/DocumentsComponentTable";
import { DocumentType } from "../../models/document";
import { IInvoice } from "../../models/invoice";
import { getInvoice, getInvoiceCategoriesList } from "../../services/invoicesService";
import { getVehiclesAsProps } from "../../services/vehiclesService";
import { AppState } from "../../store/configureStore";
import { BankStatementsTable } from "./BankStatementsTable";
import { EditInvoice } from "./EditInvoice";
import { InvoiceLiquidation } from "./InvoiceLiquidation";

export const InvoiceDetails = () => {
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles = ["admin", "accountant"];
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [invoice, setInvoice] = useState<IInvoice>();
    const [users, setUsers] = useState<DropdownItemProps[]>([]);
    const [employees, setEmployees] = useState<DropdownItemProps[]>([]);
    const [vehicles, setVehicles] = useState<DropdownItemProps[]>([]);
    const [invoiceCategories, setInvoiceCategories] = useState<DropdownItemProps[]>([]);
    const [buyRequests, setBuyRequests] = useState<DropdownItemProps[]>([]);
    const [inventoryItems, setInventoryItems] = useState<DropdownItemProps[]>([]);

    const handleIsProcessed = () => setInvoice({ ...invoice!, isProcessed: !invoice?.isProcessed });

    const fetchInvoice = useCallback(async guid => {
        setLoading(true);
        const res = await getInvoice(guid);
        const vehicles = await getVehiclesAsProps();
        const inventoryItemsList = await getInventoryItemsList();
        const invoiceCategoriesList = await getInvoiceCategoriesList();
        const buyRequestsList = await getBuyRequestsList();
        res.guid && setInvoice(res);
        vehicles && setVehicles(vehicles);
        inventoryItemsList && setInventoryItems(inventoryItemsList);
        invoiceCategoriesList && setInvoiceCategories(invoiceCategoriesList);
        buyRequestsList && setBuyRequests(buyRequestsList);
        setLoading(false);
    }, []);

    useEffect(() => {
        return () => {
            if (history.action === "POP") {
                history.push({
                    pathname:
                        history.location.pathname.substring(0, 8) === "/invoice"
                            ? "/invoice-processing/invoices"
                            : "/company/inventories",
                    state: loc.state,
                });
            }
        };
    });

    useEffect(() => {
        fetchInvoice(params.guid);
    }, [fetchInvoice, params.guid]);

    const fetchUsers = useCallback(async () => {
        try {
            const res = await getUsers();
            const userProps: DropdownItemProps[] = [];
            const employeesProps: DropdownItemProps[] = [];
            res.forEach(x =>
                userProps.push({
                    key: x.guid,
                    text: `${x.firstName ?? ""} ${x.lastName ?? ""} (${x.username ?? ""})`,
                    value: x.guid,
                })
            );
            res.forEach(x => {
                if (x.role === UserRole.Employee) {
                    employeesProps.push({
                        key: x.guid,
                        text: `${x.firstName ?? ""} ${x.lastName ?? ""} (${x.username ?? ""})`,
                        value: x.guid,
                    });
                }
            });
            setUsers(userProps);
            setEmployees(employeesProps);
        } catch {
            toast("Failed to load users", false);
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return !allowedRoles.includes(user.role!) ? (
        <Redirect to="/unauthorized" />
    ) : loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="invoice-container">
            <div className="container-header">
                <h1>Invoice details</h1>
                <Button
                    onClick={() => history.goBack()}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {invoice ? (
                <Fragment>
                    <EditInvoice
                        invoice={invoice}
                        setInvoice={setInvoice}
                        users={users}
                        vehicles={vehicles}
                        invoiceCategories={invoiceCategories}
                        buyRequests={buyRequests}
                        inventoryItems={inventoryItems}
                    />
                    {invoice.bankStatementName && invoice.bankStatementName.length > 0 ? (
                        <BankStatementsTable invoice={invoice} />
                    ) : null}
                    <InvoiceLiquidation
                        invoice={invoice}
                        setInvoice={setInvoice}
                        employees={employees}
                    />
                    <DocumentsComponentTable
                        entityGuid={invoice.guid}
                        documentType={DocumentType.Invoice}
                        handleIsProcessed={handleIsProcessed}
                    />
                </Fragment>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Invoice not found
                </h2>
            )}
        </div>
    );
};
