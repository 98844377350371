import { Dispatch, SetStateAction } from "react";
import { Icon } from "semantic-ui-react";
import { IProject } from "../../models/project";
import { EditProject } from "./EditProject";
import { ProjectRolesTable } from "./ProjectRolesTable/ProjectRolesTable";

interface IProps {
    project: IProject | undefined;
    setProject: Dispatch<SetStateAction<IProject | undefined>>;
    viewEnabled: boolean | undefined;
}

export const ProjectDetails = ({ project, setProject, viewEnabled }: IProps) => {
    return (
        <div className="project-details-container">
            {project ? (
                <>
                    <EditProject project={project} setProject={setProject} viewEnabled={viewEnabled} />
                    {viewEnabled && <ProjectRolesTable project={project} />}
                </>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Project not found
                </h2>
            )}
        </div>
    );
};
