import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import { getService } from "../../api/services";
import { IService } from "../../models/service";
import { EditService } from "./EditService";

export const ServiceDetails = () => {
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(true);
    const [service, setService] = useState<IService>();

    const fetchService = useCallback(async (guid: string) => {
        setLoading(true);
        const res = await getService(guid);
        res.guid && setService(res);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchService(params.guid);
    }, [fetchService, params.guid]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="services-container">
            <div className="container-header">
                <h1>Service details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/administration/settings", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {service ? (
                <EditService service={service} setService={setService} />
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Service not found
                </h2>
            )}
        </div>
    );
};
