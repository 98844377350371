import { IClientContact } from "../models/clientContact";
import { IPaginationWrapper } from "../models/common";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getClientContacts(
    page: number,
    clientGuid: string
): Promise<IPaginationWrapper> {
    const params = new URLSearchParams({ page: page + "" });
    clientGuid !== "" && params.append("clientGuid", clientGuid);
    return await fetch("/api/clientContacts?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function getClientContact(guid: string): Promise<IClientContact> {
    return await fetch("/api/clientContacts/" + guid, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function editClientContact(clientContact: IClientContact): Promise<Response> {
    return await fetch("/api/clientcontacts/" + clientContact.guid, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(clientContact),
    });
}

export async function createClientContact(
    clientContactDto: Partial<IClientContact>
): Promise<Response> {
    return await fetch("/api/clientContacts/", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(clientContactDto),
    });
}

export async function deleteClientContact(guid: string): Promise<Response> {
    return await fetch("/api/clientContacts/" + guid, {
        method: "DELETE",
        ...getAuthorizationProperties(),
    });
}
