import { DropdownItemProps } from "semantic-ui-react";
import { IVehicle } from "./vehicle";

export interface IInvoice {
    guid: string;
    name: string;
    amount: string;
    bankStatementAmount: string;
    isProcessed: boolean;
    created: string;
    bankStatementDate: string;
    bankStatementName: string;
    documentGuid: string;
    paymentType: number;
    currency: number;
    isTravelExpense: boolean;
    isPaid: boolean;
    paidByGuid: string;
    invoiceType: number;
    invoiceOrigin: number;
    invoiceSource: number;
    isInVatSystem: boolean;
    serviceType: number;
    invoiceCountry: number;
    invoiceCountryString: string;
    invoiceCountryFullName: string;
    info: string;
    tags: string[];
    ibaNs: string[];
    vehicleGuid: string;
    vehicle?: IVehicle;
    projectGuid?: string;
    inventoryItemsGuid?: string[];
    comment?: string;
    invoiceCategoryGuid: string;
    invoiceCategoryName: string;
    responsible: string;
    purpose: string;
    liquidationDate: string;
    recognitionDate: string;
    buyRequestGuid?: string;
    researchAndDevelopment?: boolean;
}
export interface IUploadedFiles {
    FileName: string;
    FileGuid: string;
}
export interface IInvoiceCategory {
    guid: string;
    name: string;
    count: number;
}

export const invoiceCategoryInit: Partial<IInvoiceCategory> = {
    name: "",
};

export const getPaymentType = (val: number) => {
    switch (val) {
        case 0:
            return "Incoming";
        case 1:
            return "Outgoing";
        default:
            return "";
    }
};

export const invoiceTypes: DropdownItemProps[] = [
    { key: 1, text: "Default", value: 1 },
    { key: 2, text: "Payment error", value: 2 },
    { key: 3, text: "Rent", value: 3 },
    { key: 4, text: "Transaction fee", value: 4 },
    { key: 5, text: "Cash withdrawal", value: 5 },
    { key: 6, text: "Paid in cash", value: 6 },
    { key: 7, text: "Public fee payment", value: 7 },
];

export const currencyOptions: DropdownItemProps[] = [
    { key: 0, text: "Unknown", value: 0 },
    { key: 1, text: "HRK", value: 1 },
    { key: 2, text: "EUR", value: 2 },
    { key: 3, text: "USD", value: 3 },
    { key: 4, text: "GBP", value: 4 },
    { key: 5, text: "CHF", value: 5 },
    { key: 6, text: "AUD", value: 6 },
    { key: 7, text: "CAD", value: 7 },
];

export const invoiceOrigins: DropdownItemProps[] = [
    { key: 1, text: "HR", value: 1 },
    { key: 2, text: "Foreign", value: 2 },
];

export const invoiceSources: DropdownItemProps[] = [
    { key: 0, text: "Unknown", value: 0 },
    { key: 1, text: "Bank statement", value: 1 },
    { key: 2, text: "Document", value: 2 },
    { key: 3, text: "Manual", value: 3 },
];

export const serviceTypeOptions: DropdownItemProps[] = [
    { key: 0, text: "None", value: 0 },
    { key: 1, text: "102 - Usluge popravka i održavanja pokretne imovine", value: 1 },
    { key: 2, text: "103 - Usluge dorade robe u tuđem vlasništvu", value: 2 },
    { key: 3, text: "205 - Usluge prijevoza", value: 3 },
    {
        key: 4,
        text: "205a - Usluge prijevoza koje se ne odnose na prijevoz robe prilikom uvoza",
        value: 4,
    },
    { key: 5, text: "249 - Građevinske usluge", value: 5 },
    { key: 6, text: "253 - Usluge osiguranja i mirovinskih fondova", value: 6 },
    { key: 7, text: "247 - Telekomunikacijske usluge", value: 7 },
    { key: 8, text: "958 - Poštanske usluge", value: 8 },
    { key: 9, text: "959 - Kurirske usluge", value: 9 },
    {
        key: 10,
        text: "260 - Financijske usluge, osim usluga osiguravajućih društava i mirovinskih fondova",
        value: 10,
    },
    { key: 11, text: "263 - Računalne usluge", value: 11 },
    { key: 12, text: "889 - Informacijske usluge; usluge novinskih agencija", value: 12 },
    { key: 13, text: "890 - Informacijske usluge; ostalo", value: 13 },
    {
        key: 14,
        text: "891 - Naknade za prava na korištenje intelektualnog vlasništva - franšize i slična prava",
        value: 14,
    },
    {
        key: 15,
        text: "892 - Naknade za prava na korištenje intelektualnog vlasništva - patenti, licencije i ostalo",
        value: 15,
    },
    {
        key: 16,
        text: "270 - Usluge povezane s trgovinom; preprodaja robe (merchanting)",
        value: 16,
    },
    { key: 17, text: "271 - Usluge povezane s trgovinom; ostalo", value: 17 },
    { key: 18, text: "272 - Usluge operativnog leasinga", value: 18 },
    { key: 19, text: "275 - Pravne usluge", value: 19 },
    { key: 20, text: "276 - Računovodstvene usluge", value: 20 },
    { key: 21, text: "277 - Usluge savjetovanja, odnosi s javnošću", value: 21 },
    { key: 22, text: "278 - Usluge oglašavanja, istraživanje tržišta", value: 22 },
    { key: 23, text: "279 - Usluge istraživanja i razvoja", value: 23 },
    { key: 24, text: "280 - Arhitektonske, inženjerske i ostale tehničke usluge", value: 24 },
    {
        key: 25,
        text: "282 - Poljoprivredne i rudarske usluge i ostale usluge prerade na terenu; zbrinjavanje otpada i uklanjanje onečišćenja",
        value: 25,
    },
    {
        key: 26,
        text: "283 - Poljoprivredne i rudarske usluge i ostale usluge prerade na terenu; ostalo",
        value: 26,
    },
    { key: 27, text: "284 - Ostale poslovne usluge", value: 27 },
    { key: 28, text: "288 - Audio - vizualne usluge", value: 28 },
    { key: 29, text: "895 - Ostale osobne usluge; usluge obrazovanja", value: 29 },
    { key: 30, text: "896 - Ostale osobne usluge; zdravstvene usluge", value: 30 },
    { key: 31, text: "897 - Ostale osobne usluge; kultura i razonoda, ostalo", value: 31 },
];

export const countryOptions: DropdownItemProps[] = [
    {
        key: 0,
        text: "Afghanistan",
        value: 0,
    },
    {
        key: 165,
        text: "Åland Islands",
        value: 165,
    },
    {
        key: 1,
        text: "Albania",
        value: 1,
    },
    {
        key: 2,
        text: "Algeria",
        value: 2,
    },
    {
        key: 3,
        text: "American Samoa",
        value: 3,
    },
    {
        key: 5,
        text: "Andorra",
        value: 5,
    },
    {
        key: 6,
        text: "Angola",
        value: 6,
    },
    {
        key: 7,
        text: "Anguilla",
        value: 7,
    },
    {
        key: 8,
        text: "Antarctica",
        value: 8,
    },
    {
        key: 9,
        text: "Antigua and Barbuda",
        value: 9,
    },
    {
        key: 10,
        text: "Argentina",
        value: 10,
    },
    {
        key: 11,
        text: "Armenia",
        value: 11,
    },
    {
        key: 12,
        text: "Aruba ",
        value: 12,
    },
    {
        key: 13,
        text: "Australia",
        value: 13,
    },
    {
        key: 14,
        text: "Austria",
        value: 14,
    },
    {
        key: 15,
        text: "Azerbaijan",
        value: 15,
    },
    {
        key: 16,
        text: "Bahamas",
        value: 16,
    },
    {
        key: 17,
        text: "Bahrain",
        value: 17,
    },
    {
        key: 18,
        text: "Bangladesh",
        value: 18,
    },
    {
        key: 19,
        text: "Barbados",
        value: 19,
    },
    {
        key: 20,
        text: "Belarus",
        value: 20,
    },
    {
        key: 21,
        text: "Belgium",
        value: 21,
    },
    {
        key: 22,
        text: "Belize",
        value: 22,
    },
    {
        key: 23,
        text: "Benin",
        value: 23,
    },
    {
        key: 24,
        text: "Bermuda",
        value: 24,
    },
    {
        key: 25,
        text: "Bhutan",
        value: 25,
    },
    {
        key: 26,
        text: "Bolivia",
        value: 26,
    },
    {
        key: 27,
        text: "Bosnia and Herzegovina",
        value: 27,
    },
    {
        key: 28,
        text: "Botswana",
        value: 28,
    },
    {
        key: 167,
        text: "Bouvet Island",
        value: 167,
    },
    {
        key: 29,
        text: "Brazil",
        value: 29,
    },
    {
        key: 35,
        text: "British Indian Ocean Territory",
        value: 35,
    },
    {
        key: 34,
        text: "British Virgin Islands",
        value: 34,
    },
    {
        key: 30,
        text: "Brunei",
        value: 30,
    },
    {
        key: 31,
        text: "Bulgaria",
        value: 31,
    },
    {
        key: 32,
        text: "Burkina Faso",
        value: 32,
    },
    {
        key: 33,
        text: "Burundi",
        value: 33,
    },
    {
        key: 38,
        text: "Cambodia",
        value: 38,
    },
    {
        key: 39,
        text: "Cameroon",
        value: 39,
    },
    {
        key: 40,
        text: "Canada",
        value: 40,
    },
    {
        key: 37,
        text: "Cape Verde",
        value: 37,
    },
    {
        key: 108,
        text: "Cayman Islands",
        value: 108,
    },
    {
        key: 41,
        text: "Central African Republic",
        value: 41,
    },
    {
        key: 42,
        text: "Chad",
        value: 42,
    },
    {
        key: 43,
        text: "Chile",
        value: 43,
    },
    {
        key: 44,
        text: "China",
        value: 44,
    },
    {
        key: 36,
        text: "Christmas Island",
        value: 36,
    },
    {
        key: 113,
        text: "Cocos Islands ",
        value: 113,
    },
    {
        key: 45,
        text: "Colombia",
        value: 45,
    },
    {
        key: 46,
        text: "Comoros",
        value: 46,
    },
    {
        key: 47,
        text: "Congo",
        value: 47,
    },
    {
        key: 117,
        text: "Cook Islands",
        value: 117,
    },
    {
        key: 49,
        text: "Costa Rica",
        value: 49,
    },
    {
        key: 50,
        text: "Côte d'Ivoire",
        value: 50,
    },
    {
        key: 51,
        text: "Croatia",
        value: 51,
    },
    {
        key: 52,
        text: "Cuba",
        value: 52,
    },
    {
        key: 53,
        text: "Cyprus",
        value: 53,
    },
    {
        key: 54,
        text: "Czech Republic",
        value: 54,
    },
    {
        key: 48,
        text: "Democratic Republic of the Congo",
        value: 48,
    },
    {
        key: 55,
        text: "Denmark",
        value: 55,
    },
    {
        key: 56,
        text: "Djibouti",
        value: 56,
    },
    {
        key: 57,
        text: "Dominica",
        value: 57,
    },
    {
        key: 58,
        text: "Dominican Republic",
        value: 58,
    },
    {
        key: 59,
        text: "Ecuador",
        value: 59,
    },
    {
        key: 60,
        text: "Egypt",
        value: 60,
    },
    {
        key: 62,
        text: "Equatorial Guinea",
        value: 62,
    },
    {
        key: 63,
        text: "Eritrea",
        value: 63,
    },
    {
        key: 64,
        text: "Estonia",
        value: 64,
    },
    {
        key: 207,
        text: "Eswatini",
        value: 207,
    },
    {
        key: 65,
        text: "Ethiopia",
        value: 65,
    },
    {
        key: 66,
        text: "Falkland Islands",
        value: 66,
    },
    {
        key: 67,
        text: "Faroe Islands",
        value: 67,
    },
    {
        key: 68,
        text: "Fiji",
        value: 68,
    },
    {
        key: 69,
        text: "Finland",
        value: 69,
    },
    {
        key: 70,
        text: "France",
        value: 70,
    },
    {
        key: 71,
        text: "French Guiana",
        value: 71,
    },
    {
        key: 72,
        text: "French Polynesia",
        value: 72,
    },
    {
        key: 73,
        text: "French Southern Lands",
        value: 73,
    },
    {
        key: 74,
        text: "Gabon",
        value: 74,
    },
    {
        key: 75,
        text: "Gambia",
        value: 75,
    },
    {
        key: 76,
        text: "Georgia",
        value: 76,
    },
    {
        key: 77,
        text: "Germany",
        value: 77,
    },
    {
        key: 78,
        text: "Ghana",
        value: 78,
    },
    {
        key: 79,
        text: "Gibraltar",
        value: 79,
    },
    {
        key: 83,
        text: "Greece",
        value: 83,
    },
    {
        key: 84,
        text: "Grenada",
        value: 84,
    },
    {
        key: 85,
        text: "Grenland",
        value: 85,
    },
    {
        key: 80,
        text: "Guadeloupe",
        value: 80,
    },
    {
        key: 81,
        text: "Guam",
        value: 81,
    },
    {
        key: 86,
        text: "Guatemala",
        value: 86,
    },
    {
        key: 82,
        text: "Guernsey",
        value: 82,
    },
    {
        key: 87,
        text: "Guinea",
        value: 87,
    },
    {
        key: 88,
        text: "Guinea-Bissau",
        value: 88,
    },
    {
        key: 89,
        text: "Guyana",
        value: 89,
    },
    {
        key: 90,
        text: "Haiti",
        value: 90,
    },
    {
        key: 168,
        text: "Heard Island and McDonald Islands",
        value: 168,
    },
    {
        key: 92,
        text: "Honduras",
        value: 92,
    },
    {
        key: 93,
        text: "Hong Kong",
        value: 93,
    },
    {
        key: 94,
        text: "Hungaria",
        value: 94,
    },
    {
        key: 95,
        text: "Iceland",
        value: 95,
    },
    {
        key: 96,
        text: "India",
        value: 96,
    },
    {
        key: 97,
        text: "Indonesia",
        value: 97,
    },
    {
        key: 98,
        text: "Iran",
        value: 98,
    },
    {
        key: 99,
        text: "Iraq",
        value: 99,
    },
    {
        key: 100,
        text: "Ireland",
        value: 100,
    },
    {
        key: 169,
        text: "Isle of Man",
        value: 169,
    },
    {
        key: 101,
        text: "Israel",
        value: 101,
    },
    {
        key: 102,
        text: "Italy",
        value: 102,
    },
    {
        key: 103,
        text: "Jamaica",
        value: 103,
    },
    {
        key: 104,
        text: "Japan",
        value: 104,
    },
    {
        key: 106,
        text: "Jersey",
        value: 106,
    },
    {
        key: 105,
        text: "Jordan",
        value: 105,
    },
    {
        key: 110,
        text: "Kazakhstan",
        value: 110,
    },
    {
        key: 111,
        text: "Kenya",
        value: 111,
    },
    {
        key: 112,
        text: "Kiribati",
        value: 112,
    },
    {
        key: 116,
        text: "Kosovo",
        value: 116,
    },
    {
        key: 118,
        text: "Kuwait",
        value: 118,
    },
    {
        key: 119,
        text: "Kyrgyzstan",
        value: 119,
    },
    {
        key: 120,
        text: "Laos",
        value: 120,
    },
    {
        key: 121,
        text: "Latvia",
        value: 121,
    },
    {
        key: 122,
        text: "Lebanon",
        value: 122,
    },
    {
        key: 123,
        text: "Lesotho",
        value: 123,
    },
    {
        key: 124,
        text: "Liberia",
        value: 124,
    },
    {
        key: 125,
        text: "Libya",
        value: 125,
    },
    {
        key: 126,
        text: "Liechtenstein",
        value: 126,
    },
    {
        key: 127,
        text: "Lithuania",
        value: 127,
    },
    {
        key: 128,
        text: "Luxembourg",
        value: 128,
    },
    {
        key: 131,
        text: "Macao",
        value: 131,
    },
    {
        key: 129,
        text: "Madagascar",
        value: 129,
    },
    {
        key: 132,
        text: "Malawi",
        value: 132,
    },
    {
        key: 133,
        text: "Malaysia",
        value: 133,
    },
    {
        key: 134,
        text: "Maldives",
        value: 134,
    },
    {
        key: 135,
        text: "Mali",
        value: 135,
    },
    {
        key: 136,
        text: "Malta",
        value: 136,
    },
    {
        key: 137,
        text: "Marshall Islands",
        value: 137,
    },
    {
        key: 138,
        text: "Martinique",
        value: 138,
    },
    {
        key: 139,
        text: "Mauritania",
        value: 139,
    },
    {
        key: 141,
        text: "Mauritius",
        value: 141,
    },
    {
        key: 140,
        text: "Mayotte",
        value: 140,
    },
    {
        key: 142,
        text: "Mexico",
        value: 142,
    },
    {
        key: 143,
        text: "Micronesia",
        value: 143,
    },
    {
        key: 235,
        text: "Micronesia",
        value: 235,
    },
    {
        key: 144,
        text: "Moldova",
        value: 144,
    },
    {
        key: 145,
        text: "Monaco",
        value: 145,
    },
    {
        key: 147,
        text: "Mongolia",
        value: 147,
    },
    {
        key: 148,
        text: "Montenegro",
        value: 148,
    },
    {
        key: 146,
        text: "Montserrat",
        value: 146,
    },
    {
        key: 149,
        text: "Morocco",
        value: 149,
    },
    {
        key: 150,
        text: "Mozambique",
        value: 150,
    },
    {
        key: 151,
        text: "Myanmar",
        value: 151,
    },
    {
        key: 152,
        text: "Namibia",
        value: 152,
    },
    {
        key: 153,
        text: "Nauru",
        value: 153,
    },
    {
        key: 154,
        text: "Nepal",
        value: 154,
    },
    {
        key: 155,
        text: "Netherlands",
        value: 155,
    },
    {
        key: 159,
        text: "Netherlands Antilles",
        value: 159,
    },
    {
        key: 163,
        text: "New Caledonia",
        value: 163,
    },
    {
        key: 156,
        text: "New Zealand",
        value: 156,
    },
    {
        key: 157,
        text: "Nicaragua",
        value: 157,
    },
    {
        key: 160,
        text: "Niger",
        value: 160,
    },
    {
        key: 161,
        text: "Nigeria",
        value: 161,
    },
    {
        key: 158,
        text: "Niue",
        value: 158,
    },
    {
        key: 170,
        text: "Norfolk Island",
        value: 170,
    },
    {
        key: 114,
        text: "North Korea",
        value: 114,
    },
    {
        key: 130,
        text: "North Macedonia",
        value: 130,
    },
    {
        key: 189,
        text: "Northern Mariana Islands",
        value: 189,
    },
    {
        key: 162,
        text: "Norway",
        value: 162,
    },
    {
        key: 164,
        text: "Oman",
        value: 164,
    },
    {
        key: 171,
        text: "Pakistan",
        value: 171,
    },
    {
        key: 172,
        text: "Palaua",
        value: 172,
    },
    {
        key: 173,
        text: "Palestine",
        value: 173,
    },
    {
        key: 174,
        text: "Panamana",
        value: 174,
    },
    {
        key: 175,
        text: "Papua New Guinea",
        value: 175,
    },
    {
        key: 176,
        text: "Paraguay",
        value: 176,
    },
    {
        key: 177,
        text: "Peru",
        value: 177,
    },
    {
        key: 178,
        text: "Philippines",
        value: 178,
    },
    {
        key: 180,
        text: "Pitcairn Islands",
        value: 180,
    },
    {
        key: 179,
        text: "Poland",
        value: 179,
    },
    {
        key: 182,
        text: "Portugal",
        value: 182,
    },
    {
        key: 181,
        text: "Puerto Rico",
        value: 181,
    },
    {
        key: 109,
        text: "Quatar",
        value: 109,
    },
    {
        key: 183,
        text: "Réunion",
        value: 183,
    },
    {
        key: 184,
        text: "Romania",
        value: 184,
    },
    {
        key: 185,
        text: "Russia",
        value: 185,
    },
    {
        key: 186,
        text: "Rwanda",
        value: 186,
    },
    {
        key: 208,
        text: "Saint Helena",
        value: 208,
    },
    {
        key: 210,
        text: "Saint Kitts and Nevis",
        value: 210,
    },
    {
        key: 209,
        text: "Saint Lucia",
        value: 209,
    },
    {
        key: 211,
        text: "Saint Pierre and Miquelon ",
        value: 211,
    },
    {
        key: 213,
        text: "Saint Vincent and the Grenadines",
        value: 213,
    },
    {
        key: 61,
        text: "Salvador",
        value: 61,
    },
    {
        key: 187,
        text: "Samoa",
        value: 187,
    },
    {
        key: 188,
        text: "San Marino",
        value: 188,
    },
    {
        key: 212,
        text: "São Tomé and Príncipe",
        value: 212,
    },
    {
        key: 190,
        text: "Saudi Arabia",
        value: 190,
    },
    {
        key: 191,
        text: "Senegal",
        value: 191,
    },
    {
        key: 192,
        text: "Serbia",
        value: 192,
    },
    {
        key: 193,
        text: "Seychelles",
        value: 193,
    },
    {
        key: 194,
        text: "Sierra Leone",
        value: 194,
    },
    {
        key: 195,
        text: "Singapore",
        value: 195,
    },
    {
        key: 196,
        text: "Slovakia",
        value: 196,
    },
    {
        key: 197,
        text: "Slovenia",
        value: 197,
    },
    {
        key: 198,
        text: "Solomon Islands",
        value: 198,
    },
    {
        key: 199,
        text: "Somali",
        value: 199,
    },
    {
        key: 200,
        text: "South Africa",
        value: 200,
    },
    {
        key: 107,
        text: "South Georgia and the South Sandwich Islands",
        value: 107,
    },
    {
        key: 115,
        text: "South Korea",
        value: 115,
    },
    {
        key: 201,
        text: "South Sudanese",
        value: 201,
    },
    {
        key: 202,
        text: "Spain",
        value: 202,
    },
    {
        key: 203,
        text: "Sri Lanka",
        value: 203,
    },
    {
        key: 204,
        text: "Sudan",
        value: 204,
    },
    {
        key: 205,
        text: "Suriname",
        value: 205,
    },
    {
        key: 206,
        text: "Svalbard and Jan Mayen",
        value: 206,
    },
    {
        key: 214,
        text: "Sweden",
        value: 214,
    },
    {
        key: 215,
        text: "Switzerland",
        value: 215,
    },
    {
        key: 216,
        text: "Syria",
        value: 216,
    },
    {
        key: 220,
        text: "Taiwan",
        value: 220,
    },
    {
        key: 217,
        text: "Tajikistan",
        value: 217,
    },
    {
        key: 218,
        text: "Tanzania",
        value: 218,
    },
    {
        key: 219,
        text: "Thailand",
        value: 219,
    },
    {
        key: 221,
        text: "Timor-Leste",
        value: 221,
    },
    {
        key: 222,
        text: "Togo",
        value: 222,
    },
    {
        key: 223,
        text: "Tokelau",
        value: 223,
    },
    {
        key: 224,
        text: "Tonga",
        value: 224,
    },
    {
        key: 225,
        text: "Trinidad and Tobago",
        value: 225,
    },
    {
        key: 226,
        text: "Tunisia",
        value: 226,
    },
    {
        key: 227,
        text: "Turkey",
        value: 227,
    },
    {
        key: 228,
        text: "Turkmenistan",
        value: 228,
    },
    {
        key: 166,
        text: "Turks and Caicos Islands",
        value: 166,
    },
    {
        key: 229,
        text: "Tuvalu",
        value: 229,
    },
    {
        key: 230,
        text: "Uganda",
        value: 230,
    },
    {
        key: 231,
        text: "Ukraine",
        value: 231,
    },
    {
        key: 232,
        text: "United Arab Emirates",
        value: 232,
    },
    {
        key: 233,
        text: "United Kingdom",
        value: 233,
    },
    {
        key: 234,
        text: "United States of America",
        value: 234,
    },
    {
        key: 4,
        text: "United States Virgin Islands",
        value: 4,
    },
    {
        key: 236,
        text: "Uruguay",
        value: 236,
    },
    {
        key: 237,
        text: "Uzbekistan",
        value: 237,
    },
    {
        key: 238,
        text: "Vanuatu",
        value: 238,
    },
    {
        key: 91,
        text: "Vatican",
        value: 91,
    },
    {
        key: 239,
        text: "Venezuela",
        value: 239,
    },
    {
        key: 240,
        text: "Vietnam",
        value: 240,
    },
    {
        key: 242,
        text: "Wallis and Futuna",
        value: 242,
    },
    {
        key: 243,
        text: "Western Sahara",
        value: 243,
    },
    {
        key: 241,
        text: "Yemen",
        value: 241,
    },
    {
        key: 244,
        text: "Zambia",
        value: 244,
    },
    {
        key: 245,
        text: "Zimbabwe",
        value: 245,
    },
];
