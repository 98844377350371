import { Message } from "semantic-ui-react";

export const Unauthorized = () => {
    return (
        <Message
            className="unauthorized"
            header="You do not have the permission to view the contents of this page"
            icon="ban"
        />
    );
};
