import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Button, Loader, Message, Segment, Tab } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { getUser, updateUser } from "../../api/users";
import { AppState } from "../../store/configureStore";
import { ContractType } from "../../models/common";
import { UserGeneral } from "./UserGeneral";
import { UserCompany } from "./UserCompany";
import { UserPersonalDetails } from "./UserPersonalDetails";
import { UserAccess } from "./UserAccess";
import { UserPrimaryData } from "./UserPrimaryData";
import { UserDocumentsTable } from "./UserDocumentsTable";
import { UserCertificatesTable } from "./UserCertificatesTable";
import { VacationsTable } from "./VacationsTable";
import "./UserDetails.css";

export const UserDetails = () => {
    const user = useSelector((state: AppState) => state.user);
    const [saving, setSaving] = useState(false);
    const params: { guid: string } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState<UserData>();
    const authorized = user.role === UserRole.Admin || user.guid === params.guid;
    const disabled = userDetails?.firstName === "" || userDetails?.lastName === "";

    const fetchUser = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getUser(params.guid);
            setUserDetails(res);
        } catch {
            toast("Failed to fetch user", false);
        } finally {
            setLoading(false);
        }
    }, [params.guid]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const handleInputChange = (e: any) =>
        setUserDetails({ ...userDetails!, [e.target.name]: e.target.value });

    const handleUpdateUser = async () => {
        setSaving(true);
        if (
            userDetails &&
            userDetails.contractType === ContractType.Temporary &&
            userDetails.endDate === "0001-01-01"
        ) {
            toast("Please set end date for temporary contract", false);
            setSaving(false);
            return;
        }
        try {
            await updateUser(userDetails!);
            toast("Successfully saved changes", true);
            if (
                (user.role === UserRole.Admin && user.guid === params.guid) ||
                user.guid === params.guid
            ) {
                history.push("/administration/users/" + params.guid);
            } else if (user.role === UserRole.Admin) {
                history.goBack();
            }
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    const SaveButton = () =>
        authorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateUser}
                disabled={saving || disabled}
                loading={saving}
            />
        ) : null;

    return !authorized ? (
        <Redirect to="/unauthorized" />
    ) : loading ? (
        <Loader active inline="centered" size="huge" />
    ) : !userDetails ? (
        <Message icon="info circle" header="User not found" floating size="small" />
    ) : (
        <div className="user-container">
            <Segment.Group>
                <Segment>
                    <h1>User details</h1>
                    <div>
                        <SaveButton />
                        {user.role === UserRole.Admin && (
                            <Button
                                secondary
                                content="Back to list"
                                icon="arrow left"
                                onClick={() => history.push("/administration/users")}
                            />
                        )}
                    </div>
                </Segment>
                <Segment>
                    <div className="user-details-container">
                        <div className="user-tabs-container">
                            <Tab
                                className="user-tabs"
                                menu={{ secondary: true }}
                                panes={[
                                    {
                                        menuItem: "General",
                                        render: () => (
                                            <Tab.Pane attached={false}>
                                                <UserGeneral
                                                    loggedUser={user}
                                                    userDetails={userDetails}
                                                    setUserDetails={setUserDetails}
                                                    handleInputChange={handleInputChange}
                                                />
                                            </Tab.Pane>
                                        ),
                                    },
                                    {
                                        menuItem: "Company",
                                        render: () => (
                                            <Tab.Pane attached={false}>
                                                <UserCompany
                                                    loggedUser={user}
                                                    userDetails={userDetails}
                                                    setUserDetails={setUserDetails}
                                                    handleInputChange={handleInputChange}
                                                />
                                            </Tab.Pane>
                                        ),
                                    },
                                    {
                                        menuItem: "Personal details",
                                        render: () => (
                                            <Tab.Pane attached={false}>
                                                <UserPersonalDetails
                                                    loggedUser={user}
                                                    userDetails={userDetails}
                                                    setUserDetails={setUserDetails}
                                                    handleInputChange={handleInputChange}
                                                />
                                            </Tab.Pane>
                                        ),
                                    },
                                    {
                                        menuItem: "Access",
                                        render: () => (
                                            <Tab.Pane attached={false}>
                                                <UserAccess
                                                    loggedUser={user}
                                                    userDetails={userDetails}
                                                    setUserDetails={setUserDetails}
                                                    handleInputChange={handleInputChange}
                                                />
                                            </Tab.Pane>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                        <div className="primary-data-container">
                            <UserPrimaryData
                                loggedUser={user}
                                userDetails={userDetails}
                                setUserDetails={setUserDetails}
                            />
                        </div>
                    </div>
                </Segment>
                <Segment>
                    <div>
                        <SaveButton />
                        {user.role === UserRole.Admin && (
                            <Button
                                secondary
                                content="Back to list"
                                icon="arrow left"
                                onClick={() => history.push("/administration/users")}
                            />
                        )}
                    </div>
                </Segment>
            </Segment.Group>
            {authorized && <UserDocumentsTable userGuid={userDetails.guid!} />}
            <UserCertificatesTable userGuid={userDetails.guid!} />
            {authorized && userDetails.role !== UserRole.Student && (
                <VacationsTable
                    title="Vacations"
                    startYear={
                        userDetails.startDate && !userDetails.startDate.startsWith("0")
                            ? new Date(userDetails.startDate).getFullYear()
                            : 2018
                    }
                    userDetails={userDetails}
                    history={history}
                    params={params}
                />
            )}
        </div>
    );
};
