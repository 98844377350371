import { useCallback, useEffect, useState } from "react";
import { Icon, Loader, Pagination, Segment, Table, Button } from "semantic-ui-react";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { ClientContractRow } from "./ClientContractRow";
import { AddClientContractsForm } from "./AddClientContractForm";
import { useLocation } from "react-router-dom";
import { UserRole } from "../../actions/authentificationActions";
import "../ClientContracts/ClientContracts.css";
import { IClientContract } from "../../models/clientContract";
import { getClientContracts } from "../../api/clientContract";

interface IProps {
    clientGuid: string | undefined;
}

export const ClientContracts = ({ clientGuid }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [loading, setLoading] = useState(true);
    const [clientContracts, setClientContracts] = useState<IClientContract[]>([]);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const fetchClientContracts = useCallback(async () => {
        setLoading(true);
        const res = await getClientContracts(page, clientGuid ?? "");
        setTotalRows(res.totalRows);
        setClientContracts(res.rows as IClientContract[]);
        setLoading(false);
    }, [page, clientGuid]);

    useEffect(() => {
        fetchClientContracts();
    }, [fetchClientContracts]);

    const handleFetchClientContracts = () => {
        setPage(1);
        fetchClientContracts();
    };

    return (
        <div className="client-contracts-container">
            <div className="container-header">
                <h1>Client contracts</h1>
                {user.role === UserRole.Admin && (
                    <Button
                        secondary
                        content="Add new client contract"
                        icon="plus"
                        onClick={() => setModalOpen(true)}
                    />
                )}
            </div>
            <div className="client-contracts-display">
                <div className="client-contracts-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment />
                            <Segment className="table-container">
                                <Table basic="very" columns={5}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Start date</Table.HeaderCell>
                                            <Table.HeaderCell>End date</Table.HeaderCell>
                                            <Table.HeaderCell>Value (HRK)</Table.HeaderCell>
                                            <Table.HeaderCell>Contract status</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {clientContracts.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="5">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            clientContracts.map(x => (
                                                <ClientContractRow
                                                    key={x.guid}
                                                    clientContract={x}
                                                    handleFetchClientContracts={
                                                        handleFetchClientContracts
                                                    }
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 0 && (
                                    <div className="client-contracts-pagination">
                                        {totalRows > 10 && (
                                            <Pagination
                                                size="small"
                                                activePage={page}
                                                onPageChange={(e, data: any) =>
                                                    setPage(+data.activePage)
                                                }
                                                ellipsisItem={{
                                                    content: <Icon name="ellipsis horizontal" />,
                                                }}
                                                firstItem={{
                                                    content: <Icon name="angle double left" />,
                                                }}
                                                lastItem={{
                                                    content: <Icon name="angle double right" />,
                                                }}
                                                prevItem={{ content: <Icon name="angle left" /> }}
                                                nextItem={{ content: <Icon name="angle right" /> }}
                                                totalPages={Math.ceil(totalRows / 10)}
                                            />
                                        )}
                                    </div>
                                )}
                            </Segment>
                            <AddClientContractsForm
                                clientGuid={clientGuid ?? ""}
                                open={modalOpen}
                                closeModal={() => setModalOpen(false)}
                                handleAddClientContracts={handleFetchClientContracts}
                            />
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
