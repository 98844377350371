import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Loader, Segment, Table, Pagination, Icon, Search } from "semantic-ui-react";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { getContacts } from "../../api/users";
import { ContactsRow } from "./ContactsRow";
import "./Contacts.css";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";

export const Contacts = () => {
    const location = useLocation();
    const user = useSelector((state: AppState) => state.user);
    const [listOfUsers, setContacts] = useState<UserData[]>([]);
    const [page, setPage] = useState(location.state ? (location.state as number) : 1);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    const fetchContacts = useCallback(async () => {
        setLoading(true);
        const res = await getContacts(page, search);
        if (res.rows) {
            setContacts(res.rows as UserData[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page, search]);

    const handleRemoveContact = (contact: UserData) =>
        setContacts([...listOfUsers].filter(x => x !== contact));

    useEffect(() => {
        fetchContacts();
    }, [fetchContacts]);

    return (
        <div className="contacts-container">
            <div className="container-header">
                <h1>Contacts</h1>
                <Search
                    onSearchChange={(e: any) => {
                        e.preventDefault();
                        setSearch(e.target.value);
                    }}
                    placeholder="Enter first or last name"
                    open={false}
                />
            </div>
            <div className="contacts-display">
                <div className="contacts-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment />
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Full name</Table.HeaderCell>
                                            <Table.HeaderCell>Username</Table.HeaderCell>
                                            <Table.HeaderCell>Role</Table.HeaderCell>
                                            <Table.HeaderCell>Job title</Table.HeaderCell>
                                            {user.role === UserRole.Admin && (
                                                <Table.HeaderCell>Actions</Table.HeaderCell>
                                            )}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {listOfUsers.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="4">
                                                    No matches found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            listOfUsers.map(u => (
                                                <ContactsRow
                                                    key={u.guid}
                                                    user={u}
                                                    handleRemoveContact={handleRemoveContact}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
