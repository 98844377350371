import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Icon, Loader } from "semantic-ui-react";
import { toast } from "../..";
import { verifyDocument } from "../../api/documents";
import { ReactComponent as Logo } from "../../icons/Aconto_logo_log_in.svg";
import "./DocumentVerification.css";

export const DocumentVerification = () => {
    const params: { guid: string } = useParams();
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(true);

    const verify = useCallback(async () => {
        if (!loading) setLoading(true);
        try {
            const docGuid = params.guid;
            const res = await verifyDocument(docGuid!);
            setVerified(res);
        } catch {
            toast("Verification error", false);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        verify();
    }, [verify]);

    return (
        <div className="verification-container">
            <div className="center-box">
                <div className="aconto-logo-container">
                    <Logo title="" />
                    {loading ? (
                        <Loader />
                    ) : (
                        <h1>{verified ? "Document verified" : "Document not found"}</h1>
                    )}
                </div>
            </div>
        </div>
    );
};
