import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Feed } from "semantic-ui-react";
import { toast } from "../..";
import { getProjectAsNewsFeedItems } from "../../api/newsFeed";
import { INewsFeedItem } from "../../models/newsFeedItem";
import { NewsFeedItem } from "../NewsFeed/NewsFeedItem";

export const ProjectFeed = () => {
    const [newsFeedItems, setNewsFeedItems] = useState<INewsFeedItem[]>([]);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const params: { guid: string } = useParams();

    const fetchProjectAsNewsFeedItems = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getProjectAsNewsFeedItems(page, params.guid);
            if (res.rows) {
                setNewsFeedItems(nf => nf.concat(res.rows as INewsFeedItem[]));
                setTotalRows(res.totalRows);
            }
        } catch {
            toast("Failed to fetch news feed data for project", false);
        } finally {
            setLoading(false);
        }
    }, [page, params.guid]);

    useEffect(() => {
        fetchProjectAsNewsFeedItems();
    }, [fetchProjectAsNewsFeedItems]);

    return (
        <div className="news-feed-display">
            <Feed>
                {newsFeedItems.map(nfi => (
                    <Fragment key={nfi.guid}>
                        <NewsFeedItem item={nfi} />
                        <div className="news-feed-separator"></div>
                    </Fragment>
                ))}
            </Feed>
            {totalRows > page * 10 && (
                <Button
                    secondary
                    fluid
                    content="Load more"
                    icon="spinner"
                    onClick={() => setPage(page + 1)}
                    disabled={loading}
                    loading={loading}
                />
            )}
        </div>
    );
};
