import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DropdownItemProps, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { getInventoryItemsList } from "../../api/inventoryItems";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import { TextInput } from "../../components/TextInput/TextInput";

interface IProps {
    loggedUser: UserData;
    userDetails: UserData;
    setUserDetails: Dispatch<SetStateAction<UserData | undefined>>;
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
}

export const UserAccess = ({
    loggedUser,
    userDetails,
    setUserDetails,
    handleInputChange,
}: IProps) => {
    const [inventoryItems, setInventoryItems] = useState<DropdownItemProps[]>([]);

    const fetchInventoryItems = useCallback(async () => {
        try {
            const res = await getInventoryItemsList();
            setInventoryItems(res);
        } catch {
            toast("Failed to load inventory items", false);
        }
    }, []);

    useEffect(() => {
        fetchInventoryItems();
    }, [fetchInventoryItems]);

    const normalizeCardId = (id: any) => {
        let nId = (1000000 + id).toString();
        return nId.slice(-6);
    };

    return (
        <Table basic="very" celled collapsing>
            <Table.Row>
                <Table.Cell>Inventory items</Table.Cell>
                <Table.Cell>
                    <DropdownInput
                        multiple
                        options={inventoryItems}
                        placeholder="No inventory item(s) assigned"
                        value={userDetails.inventoryItemsGuid}
                        onChange={(e, data: any) =>
                            setUserDetails({
                                ...userDetails,
                                inventoryItemsGuid: data.value,
                            })
                        }
                        search
                        clearable
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Access rights</Table.Cell>
                <Table.Cell>
                    {userDetails.accessRights
                        ?.filter(ar => !ar.isArchived && !ar.recipients[0].isArchived)
                        .map((accessRight, index, arr) =>
                            loggedUser.role === UserRole.Admin ? (
                                <Link
                                    className={`${
                                        accessRight.recipients[0].isActive ? "active" : "inactive"
                                    }`}
                                    key={accessRight.guid}
                                    to={`/company/access-rights/${accessRight.guid}`}
                                >
                                    {index !== arr.length - 1
                                        ? `${accessRight.claim}, `
                                        : accessRight.claim}
                                </Link>
                            ) : (
                                <p
                                    className={`${
                                        accessRight.recipients[0].isActive ? "active" : "inactive"
                                    }`}
                                    key={accessRight.guid}
                                >
                                    {index !== arr.length - 1
                                        ? `${accessRight.claim}, `
                                        : accessRight.claim}
                                </p>
                            )
                        )}
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Gitlab username</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="gitlabUsername"
                        value={userDetails.gitlabUsername ?? ""}
                        handleInputChange={handleInputChange}
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Active directory username</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="activeDirectoryUsername"
                        value={userDetails.activeDirectoryUsername ?? ""}
                        handleInputChange={handleInputChange}
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Card number</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="cardNumber"
                        value={
                            userDetails.cardNumber ? normalizeCardId(userDetails.cardNumber) : ""
                        }
                        handleInputChange={handleInputChange}
                        disabled={loggedUser.role !== UserRole.Admin}
                        isNumber
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Guid</Table.Cell>
                <Table.Cell>{userDetails.guid}</Table.Cell>
            </Table.Row>
            {/* <Table.Row>
                <Table.Cell>Clickup username</Table.Cell>
            </Table.Row> */}
            {/* vratiti kad se doda clickup */}
        </Table>
    );
};
