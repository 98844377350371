import React, { Fragment, useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import { Icon, Loader, Button, Radio, Input, TextArea, Segment } from "semantic-ui-react";
import { toast } from "../..";
import { UserData } from "../../actions/authentificationActions";
import { CalendarSelector } from "../../components/CalendarSelector/CalendarSelector";
import { getUser, getUserVacation } from "../../api/users";
import {
    getUserPreviousWorkAttendanceDates,
    editWorkAttendance,
} from "../../services/workAttendancesService";
import {
    findTenSuccessiveDays,
    getDaysInBetween,
    getPreselectedDates,
    getSelectedDates,
} from "../../components/CalendarSelector/getAllDatesHelper";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { WorkAttendance } from "../../models/workAttendance";

const getAttendanceTypeEnum = (type: string) => {
    switch (type) {
        case "vacations":
            return 1;
        case "sick-leaves":
            return 2;
        case "remote-work":
            return 3;
        case "paid-leaves":
            return 5;
        case "parental-leaves":
            return 6;
        case "yearly-vacation-plan":
            return 7;
        default:
            return 0;
    }
};

interface LocationState {
    employeeGuid: string;
}

type IndexProps = RouteComponentProps<{}, {}, LocationState>;

export const EditWorkAttendance: React.FC<IndexProps> = prop => {
    const history = useHistory();
    const employeeGuid = prop.location.state ? prop.location.state.employeeGuid : "";
    const params: { guid: string } = useParams();
    const workAttendanceType = history.location.pathname.split("/")[2];
    const [dates, setDates] = useState<string[]>([]);
    const [datesWithWeekendsAndHolidays, setDatesWithWeekendsAndHolidays] = useState<string[]>([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(true);
    const [previousSameType, setPreviousameType] = useState<string[]>([]);
    const [previousOtherTypes, setPreviousOtherTypes] = useState<string[]>([]);
    const [holidays, setHolidays] = useState<string[]>([]);
    const [accountable, setAccountable] = useState(false);
    const [employee, setEmployee] = useState<UserData>();
    const [reasonText, setReasonText] = useState<string>();
    const [firstPickDate, setFirstPickDate] = useState<string>("");
    const [preselectionDates, setPreselectionDates] = useState<string[]>([]);
    const [vacationPlanApproved, setVacationPlanApproved] = useState(false);
    const user = useSelector((state: AppState) => state.user);
    const [vacationDaysLeft, setVacationDaysLeft] = useState<number>(0);
    const [tenDaysInRow, setTenDaysInRow] = useState(true);

    const handleDates = (d: string) => {
        const { pickedDates, pickedDatesWithWeekendsAndHolidays } = getSelectedDates(
            firstPickDate,
            d,
            holidays,
            previousSameType,
            previousOtherTypes,
            setFirstPickDate
        );
        getAttendanceTypeEnum(workAttendanceType) === WorkAttendance.YearlyVacationPlan
            ? firstPickDate &&
              pickedDates.map(x =>
                  dates.includes(x)
                      ? setDates(dates.filter(d => !pickedDates.includes(d)))
                      : setDates(dates.concat(pickedDates))
              )
            : setDates(pickedDates);
        getAttendanceTypeEnum(workAttendanceType) === WorkAttendance.YearlyVacationPlan &&
            firstPickDate &&
            pickedDatesWithWeekendsAndHolidays.map(x =>
                datesWithWeekendsAndHolidays.includes(x)
                    ? setDatesWithWeekendsAndHolidays(
                          datesWithWeekendsAndHolidays.filter(
                              d => !pickedDatesWithWeekendsAndHolidays.includes(d)
                          )
                      )
                    : setDatesWithWeekendsAndHolidays(
                          datesWithWeekendsAndHolidays.concat(pickedDatesWithWeekendsAndHolidays)
                      )
            );
    };

    const handlePreselectionDates = (d: string) => {
        const preselectedDates = getPreselectedDates(firstPickDate, d, holidays);
        setPreselectionDates(preselectedDates);
    };

    const fetchPreviousWorkAttendance = useCallback(async () => {
        setLoading(true);
        let res;
        if (workAttendanceType === "parental-leaves") {
            res = await getUserPreviousWorkAttendanceDates(
                getAttendanceTypeEnum(workAttendanceType),
                params.guid,
                employeeGuid
            );
        } else {
            res = await getUserPreviousWorkAttendanceDates(
                getAttendanceTypeEnum(workAttendanceType),
                params.guid
            );
        }
        if (res.previousSameType) {
            setPreviousameType(res.previousSameType);
            setPreviousOtherTypes(res.previousOtherTypes);
            setHolidays(res.holidays);
            setDates(res.currentDates);
            setAccountable(res.accountable);
            setVacationPlanApproved(res.yearlyVacationPlanApproved);
            setDatesWithWeekendsAndHolidays(res.currentDates);
        }
        const res2 = await getUser(employeeGuid);
        setEmployee(res2);
        setLoading(false);
    }, [workAttendanceType, employeeGuid, params.guid]);

    const handleEditWorkAttendance = async () => {
        setLoading(true);
        const res = await editWorkAttendance(
            params.guid,
            getAttendanceTypeEnum(workAttendanceType),
            dates,
            accountable,
            reasonText ?? ""
        );
        if (!res.ok) toast("Error updating work attendance", false);
        else {
            toast(
                workAttendanceType === "yearly-vacation-plan"
                    ? "Successfully updated yearly vacation plan"
                    : "Successfully updated work attendance",
                true
            );
            history.push(
                workAttendanceType === "yearly-vacation-plan"
                    ? "/employee-data/vacations"
                    : "/employee-data/" + workAttendanceType
            );
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPreviousWorkAttendance();
    }, [fetchPreviousWorkAttendance]);

    useEffect(() => {
        findTenSuccessiveDays(
            datesWithWeekendsAndHolidays,
            holidays,
            tenDaysInRow,
            setTenDaysInRow
        );
    }, [datesWithWeekendsAndHolidays]);

    useEffect(() => {
        return () => {};
    }, []);

    const fetchVacationDaysLeft = useCallback(async () => {
        try {
            if (
                workAttendanceType === "vacations" ||
                workAttendanceType === "yearly-vacation-plan"
            ) {
                setLoading(true);
                const res = await getUserVacation(user.guid!);
                setVacationDaysLeft(res.daysLeft);
            }
        } catch {
            toast("Get user vacation failed.", false);
        } finally {
            setLoading(false);
        }
    }, [workAttendanceType, user.guid]);

    useEffect(() => {
        fetchVacationDaysLeft();
    }, [fetchVacationDaysLeft]);

    return (
        <div>
            {workAttendanceType === "parental-leaves" && employee && (
                <div className="work-attendance-parental-leave-container">
                    <label className="work-attendance-parental-leave-label">
                        <b>Employee:</b>
                    </label>
                    <Input value={`${employee?.firstName} ${employee?.lastName}`} disabled={true} />
                </div>
            )}
            <div className="create-plan-header">
                <Icon size="big" name="angle left" onClick={() => setYear(year - 1)} />
                <h1>{year}</h1>
                <Icon size="big" name="angle right" onClick={() => setYear(year + 1)} />
            </div>
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <Fragment>
                    <CalendarSelector
                        year={year}
                        selectedDates={dates}
                        handleDates={handleDates}
                        previousSameType={previousSameType}
                        previousOtherTypes={previousOtherTypes}
                        holidays={holidays}
                        enableEdit={!vacationPlanApproved}
                        type={workAttendanceType}
                        preselectionDates={preselectionDates}
                        handlePreselectionDates={handlePreselectionDates}
                    />
                    {workAttendanceType === "paid-leaves" && (
                        <Radio
                            className="accountable-toggle"
                            toggle
                            label="Is accountable"
                            checked={accountable}
                            onClick={() => setAccountable(!accountable)}
                        />
                    )}
                    {workAttendanceType === "remote-work" && (
                        <div className="input-container" style={{ paddingBottom: "15px" }}>
                            <label>Reason</label>
                            <TextArea
                                rows={5}
                                name="text"
                                onChange={(e, data: any) => setReasonText(data.value)}
                            />
                        </div>
                    )}
                    {(workAttendanceType === "vacations" ||
                        workAttendanceType === "yearly-vacation-plan") && (
                        <Segment>
                            <h4>Number of vacation days selected: {dates.length}</h4>
                            <h4>Number of vacation days left: {vacationDaysLeft}</h4>
                        </Segment>
                    )}
                    <Button
                        fluid
                        secondary
                        content={
                            workAttendanceType === "yearly-vacation-plan"
                                ? "Update yearly vacation plan"
                                : "Update work attendance"
                        }
                        icon="calendar plus"
                        onClick={handleEditWorkAttendance}
                        loading={loading}
                        disabled={!tenDaysInRow || dates.length === 0 || loading}
                    />
                </Fragment>
            )}
        </div>
    );
};
