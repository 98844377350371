export interface ICompany {
    guid: string;
    name: string;
    vat: string;
    hqAddress: string;
    city: string;
    zipCode: string;
    email: string;
    country: string;
    photoPath: string;
    invoiceComment: string;
    invoiceIBAN: string;
    invoiceTaxRate: number;
    invoiceLiquidatorGuid: string;
    gamificationAllowed: boolean;
    anonymousIdeasAllowed: boolean;
}

export const newCompanyInit: ICompany = {
    guid: "",
    name: "",
    vat: "",
    hqAddress: "",
    city: "",
    zipCode: "",
    email: "",
    country: "",
    photoPath: "",
    invoiceComment: "",
    invoiceIBAN: "",
    invoiceTaxRate: 0,
    invoiceLiquidatorGuid: "",
    gamificationAllowed: false,
    anonymousIdeasAllowed: false,
};
