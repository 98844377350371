import { Fragment, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Checkbox, Icon, Label, Modal, Popup, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import { getStatusColor, getStatusLabel, ApproveStatus } from "../../models/common";
import { IWorkAttendance, WorkAttendance } from "../../models/workAttendance";
import { downloadSickLeaveDocument } from "../../services/documentsService";
import {
    addWorkAttendanceDocuments,
    deleteWorkAttendance,
    submitWorkAttendanceRequest,
} from "../../services/workAttendancesService";

interface IProps {
    workAttendance: IWorkAttendance;
    selected: boolean;
    handleSelect: (sp: IWorkAttendance) => void;
    user: UserData;
    handleProcessRequest: (plans: IWorkAttendance[], choice: boolean) => void;
    fetchWorkAttendances: () => void;
    yearlyVacationPlan: IWorkAttendance[];
    status: number;
    statusAll: boolean;
}

export const WorkAttendanceRow = ({
    workAttendance,
    selected,
    handleSelect,
    user,
    handleProcessRequest,
    fetchWorkAttendances,
    yearlyVacationPlan,
    status,
    statusAll,
}: IProps) => {
    const loc = useLocation();
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const requestCondition =
        workAttendance.attendanceType === WorkAttendance.Vacation ||
        workAttendance.attendanceType === WorkAttendance.RemoteWork ||
        workAttendance.attendanceType === WorkAttendance.SickLeave ||
        workAttendance.attendanceType === WorkAttendance.PaidLeave;

    const onRowClick = () => {
        sessionStorage.setItem("prevStatus", status.toString());
        let path = "";
        switch (user.role) {
            case UserRole.Admin:
                path =
                    workAttendance.attendanceType === WorkAttendance.YearlyVacationPlan
                        ? `/employee-data/yearly-vacation-plan/view/${workAttendance.guid}`
                        : `${loc.pathname}/view/${workAttendance.guid}`;
                break;
            case UserRole.Employee:
                path =
                    workAttendance.attendanceType === WorkAttendance.YearlyVacationPlan
                        ? `/employee-data/yearly-vacation-plan/edit/${workAttendance.guid}`
                        : `${loc.pathname}/edit/${workAttendance.guid}`;
                break;
            default:
                path = "#";
                break;
        }
        history.push(path);
    };

    const getWorkAttendanceType = () => {
        switch (workAttendance.attendanceType) {
            case WorkAttendance.Vacation:
                return "Vacation";
            case WorkAttendance.SickLeave:
                return "Sick leave";
            case WorkAttendance.RemoteWork:
                return "Remote work";
            case WorkAttendance.PaidLeave:
                return "Paid leave";
            case WorkAttendance.YearlyVacationPlan:
                return "Yearly Vacation Plan";
            default:
                return "";
        }
    };

    const handleSendRequest = async () => {
        try {
            setLoading(true);
            const res = await submitWorkAttendanceRequest(workAttendance.guid, false);
            if (!res.ok) toast("Unable to submit employee plan request", false);
            else {
                toast(getWorkAttendanceType() + " request successfully submitted", true);
                fetchWorkAttendances();
            }
        } catch {
            toast("Unable to submit employee plan request", false);
        } finally {
            setLoading(false);
        }
    };

    const handleApprovedWorkAttendanceDeletionRequest = async () => {
        try {
            setLoading(true);
            const res = await submitWorkAttendanceRequest(workAttendance.guid, true);
            if (!res.ok) toast("Unable to submit employee request", false);
            else {
                toast(getWorkAttendanceType() + " deletion request successfully submitted", true);
                fetchWorkAttendances();
            }
        } catch {
            toast("Unable to submit employee request", false);
        } finally {
            setLoading(false);
        }
    };

    const handleUploadFile = async (files: File[]) => {
        try {
            setLoading(true);
            const res = await addWorkAttendanceDocuments(workAttendance.guid, files);
            if (!res.ok) {
                toast(`Unable to upload ${getWorkAttendanceType()} document`, false);
            } else {
                toast(`${getWorkAttendanceType()} document successfully uploaded`, true);
                fetchWorkAttendances();
            }
        } catch {
            toast(`Unable to upload ${getWorkAttendanceType()} document`, false);
        } finally {
            setLoading(false);
            setModalOpen(false);
        }
    };

    const handleDeleteRequest = async () => {
        const res = await deleteWorkAttendance(workAttendance.guid);
        if (!res.ok) toast("Unable to delete employee plan request", false);
        else {
            toast(getWorkAttendanceType() + " request successfully deleted", true);
            fetchWorkAttendances();
        }
    };

    const checkVacationMatch = (): boolean => {
        let value: boolean = true;
        let yearlyPlanDates: string[] = [];
        yearlyVacationPlan.map(plan => plan.dates.map(date => yearlyPlanDates.push(date.workDay)));
        workAttendance.dates.map(date => {
            if (!yearlyPlanDates.includes(date.workDay)) {
                value = false;
            }
        });
        return value;
    };

    return (
        <Fragment>
            <Table.Row onClick={onRowClick} className="clickable-row">
                {user.role === UserRole.Admin && (
                    <Table.Cell
                        onClick={(e: any) => e.stopPropagation()}
                        textAlign="center"
                        collapsing
                    >
                        <Checkbox
                            toggle
                            checked={selected}
                            onClick={() => handleSelect(workAttendance)}
                        />
                    </Table.Cell>
                )}
                <Table.Cell>
                    <Label
                        className="work-attendance-label"
                        size="large"
                        circular
                        color={getStatusColor(workAttendance.status)}
                        content={getStatusLabel(workAttendance.status)}
                    />
                    {workAttendance.reason && (
                        <Popup
                            header={"Reason"}
                            content={workAttendance.reason}
                            trigger={<Icon size="large" name="info circle" />}
                        />
                    )}
                    {workAttendance.attendanceType === WorkAttendance.Vacation &&
                        !checkVacationMatch() && (
                            <Popup
                                header={"Alert"}
                                content={"Picked vacation dates differ from the yearly plan!"}
                                trigger={<Icon size="large" name="warning circle" color="red" />}
                            />
                        )}
                </Table.Cell>
                <Table.Cell>{workAttendance.user}</Table.Cell>
                <Table.Cell>{getDisplayDateFormat(workAttendance.startDate)}</Table.Cell>
                <Table.Cell>{getDisplayDateFormat(workAttendance.endDate)}</Table.Cell>
                <Table.Cell>{workAttendance.duration}</Table.Cell>
                <Table.Cell>{getDisplayDateFormat(workAttendance.dateOfRequest, true)}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {workAttendance.status === ApproveStatus.Requested &&
                    user.role === UserRole.Admin ? (
                        <Button.Group>
                            <Button
                                color="green"
                                content="Approve"
                                icon="calendar check"
                                onClick={() => handleProcessRequest([workAttendance], true)}
                            />
                            <Button.Or />
                            <Button
                                color="red"
                                content="Reject"
                                icon="calendar times"
                                onClick={() => handleProcessRequest([workAttendance], false)}
                            />
                        </Button.Group>
                    ) : workAttendance.status === ApproveStatus.Draft &&
                      user.role === UserRole.Employee ? (
                        <Button
                            color="green"
                            content="Send request"
                            disabled={loading}
                            loading={loading}
                            icon="send"
                            onClick={() => handleSendRequest()}
                        />
                    ) : workAttendance.status === ApproveStatus.Approved &&
                      user.role === UserRole.Admin ? (
                        statusAll ? (
                            <Button.Group>
                                <Button
                                    color="green"
                                    content="Approve"
                                    icon="calendar check"
                                    onClick={() => handleProcessRequest([workAttendance], true)}
                                    disabled={true}
                                />
                                <Button.Or />
                                <Button
                                    color="red"
                                    content="Reject"
                                    icon="calendar times"
                                    onClick={() => handleProcessRequest([workAttendance], false)}
                                />
                            </Button.Group>
                        ) : (
                            <Button
                                color="red"
                                content="Reject"
                                icon="calendar times"
                                onClick={() => handleProcessRequest([workAttendance], false)}
                            />
                        )
                    ) : workAttendance.status === ApproveStatus.Rejected &&
                      user.role === UserRole.Admin ? (
                        statusAll ? (
                            <Button.Group>
                                <Button
                                    color="green"
                                    content="Approve"
                                    icon="calendar check"
                                    onClick={() => handleProcessRequest([workAttendance], true)}
                                />
                                <Button.Or />
                                <Button
                                    color="red"
                                    content="Reject"
                                    icon="calendar times"
                                    onClick={() => handleProcessRequest([workAttendance], false)}
                                    disabled
                                />
                            </Button.Group>
                        ) : (
                            <Button
                                color="green"
                                content="Approve"
                                icon="calendar check"
                                onClick={() => handleProcessRequest([workAttendance], true)}
                            />
                        )
                    ) : null}
                    {workAttendance.attendanceType === WorkAttendance.SickLeave &&
                    workAttendance.status !== ApproveStatus.Draft ? (
                        <Popup
                            content="No file uploaded"
                            disabled={workAttendance.numberOfDocuments > 0}
                            trigger={
                                <span>
                                    <Button
                                        disabled={workAttendance.numberOfDocuments === 0}
                                        color="purple"
                                        content="Download"
                                        icon="download"
                                        onClick={() =>
                                            downloadSickLeaveDocument(workAttendance.guid)
                                        }
                                    />
                                </span>
                            }
                        />
                    ) : null}
                    {workAttendance.attendanceType === WorkAttendance.SickLeave && (
                        <Button
                            color="olive"
                            content="Upload"
                            icon="upload"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="close"
                            onClick={handleDeleteRequest}
                        />
                    )}
                    {user.role === UserRole.Employee &&
                        requestCondition &&
                        workAttendance.status === ApproveStatus.Approved && (
                            <Button
                                color="red"
                                content="Request to delete"
                                icon="close"
                                onClick={handleApprovedWorkAttendanceDeletionRequest}
                            />
                        )}
                </Table.Cell>
            </Table.Row>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} dimmer="blurring">
                <Modal.Header>Please upload your sick leave paper</Modal.Header>
                <Modal.Content>
                    <FileDropzone title="" handleUploadFiles={handleUploadFile} loading={loading} />
                </Modal.Content>
            </Modal>
        </Fragment>
    );
};
