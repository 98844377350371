import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, DropdownItemProps, Form, Icon, Input, Modal, Select } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, newUserInit, UserRole } from "../../actions/authentificationActions";
import { getCompaniesList } from "../../api/companies";
import { createUser } from "../../api/users";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { AppState } from "../../store/configureStore";
import { AddCompanyModal } from "../Company/AddCompanyModal";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddUser: (user: UserData) => void;
}

export const AddUserForm = ({ open, closeModal, handleAddUser }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const roles = useSelector((state: AppState) => state.roles);
    const [newUser, setNewUser] = useState(newUserInit);
    const [loading, setLoading] = useState(false);
    const disabled = Object.values(newUser).some(x => x === "");
    const [companiesList, setCompaniesList] = useState<DropdownItemProps[]>([]);
    const [createCompanyModalOpen, setCreateCompanyModalOpen] = useState(false);
    const [companyOpen, setCompanyOpen] = useState(false);

    const handleAddNewUser = async () => {
        setLoading(true);
        try {
            const res = await createUser(newUser);
            handleAddUser(res.data as UserData);
            toast("User successfully added", true);
            setNewUser(newUserInit);
        } catch {
            toast("Error adding user", false);
        } finally {
            closeModal();
            setLoading(false);
        }
    };

    const fetchCompaniesList = useCallback(async () => {
        const createCompanyDropdownItem: DropdownItemProps = {
            key: "new-company-key",
            text: "New company",
            icon: "add circle",
            onClick: (e: any, a: any) => {
                setCreateCompanyModalOpen(true);
                setCompanyOpen(false);
            },
        };
        const companies = await getCompaniesList();
        Array.isArray(companies) && setCompaniesList([createCompanyDropdownItem].concat(companies));
    }, []);

    useEffect(() => {
        fetchCompaniesList();
    }, [fetchCompaniesList]);

    useEffect(() => {
        if (user.role === UserRole.SuperAdmin) {
            setNewUser({
                ...newUser,
                role: UserRole.Admin,
                startDate: getISODateFormat(new Date()),
            });
        }
    }, []);

    return (
        <>
            <Modal
                className="add-user-modal"
                open={open}
                onClose={closeModal}
                dimmer="blurring"
                centered={false}
            >
                <Modal.Header>
                    <h2>Add new user</h2>
                    <Icon name="close" onClick={closeModal} />
                </Modal.Header>
                <Modal.Content>
                    <Form className="add-user-form">
                        <div className="input-container">
                            <label>Email</label>
                            <Input
                                value={newUser.username}
                                onChange={(e: any) =>
                                    setNewUser({ ...newUser, username: e.target.value })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Password</label>
                            <Input
                                type="password"
                                value={newUser.password}
                                onChange={(e: any) =>
                                    setNewUser({ ...newUser, password: e.target.value })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>First name</label>
                            <Input
                                value={newUser.firstName}
                                onChange={(e: any) =>
                                    setNewUser({ ...newUser, firstName: e.target.value })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Last name</label>
                            <Input
                                value={newUser.lastName}
                                onChange={(e: any) =>
                                    setNewUser({ ...newUser, lastName: e.target.value })
                                }
                            />
                        </div>
                        {user.role === UserRole.Admin && (
                            <div className="input-container">
                                <label>User role</label>
                                <Select
                                    search
                                    selectOnBlur={false}
                                    options={roles.filter(x => x.text !== "Super admin")}
                                    value={newUser.role}
                                    onChange={(e, data: any) =>
                                        setNewUser({ ...newUser, role: data.value })
                                    }
                                />
                            </div>
                        )}
                        <div className="input-container">
                            <label>Start date</label>
                            <CalendarInput
                                date={newUser.startDate}
                                placeholder="Select date..."
                                handleDateChange={e =>
                                    setNewUser({ ...newUser, startDate: getISODateFormat(e) })
                                }
                                disabled={user.role === UserRole.SuperAdmin}
                            />
                        </div>
                        {user.role === UserRole.SuperAdmin && (
                            <Form.Field>
                                <div className="input-container">
                                    <label>Company</label>
                                    <Select
                                        open={companyOpen}
                                        search
                                        selectOnBlur={false}
                                        closeOnChange={true}
                                        options={companiesList}
                                        value={newUser.companyGuid}
                                        onBlur={() => setCompanyOpen(false)}
                                        onChange={(e, data: any) =>
                                            setNewUser({ ...newUser, companyGuid: data.value })
                                        }
                                        onClick={() => setCompanyOpen(!companyOpen)}
                                    />
                                </div>
                            </Form.Field>
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Add new user"
                        icon="add user"
                        disabled={disabled || loading}
                        loading={loading}
                        secondary
                        fluid
                        onClick={handleAddNewUser}
                    />
                </Modal.Actions>
            </Modal>
            <AddCompanyModal
                open={createCompanyModalOpen}
                closeModal={() => setCreateCompanyModalOpen(false)}
                fetchCompanies={fetchCompaniesList}
            />
        </>
    );
};
