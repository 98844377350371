import { Dispatch, Fragment, SetStateAction } from "react";
import { Button, Input, Table } from "semantic-ui-react";
import { UserData } from "../../actions/authentificationActions";

interface IProps {
    user: UserData;
    users: UserData[];
    refreshUsers: Dispatch<SetStateAction<UserData[]>>;
}

export const UserPointRow = ({ user, refreshUsers, users }: IProps) => {
    const handleChangePoints = async (pointsType: boolean) => {
        if (pointsType) {
            const index = users.findIndex(u => u.guid === user.guid);
            users[index] = { ...user, points: 1 + +(user.points ?? 0)};
            const newUsers = [...users];
            refreshUsers(newUsers);
        } else {
            const index = users.findIndex(u => u.guid === user.guid);
            users[index] = { ...user, negativePoints: 1 + +(user.negativePoints ?? 0)};
            const newUsers = [...users];
            refreshUsers(newUsers);
        }
    };

    const handleInputChange = (e: any) => {
        const index = users.findIndex(u => u.guid === user.guid);
        const newUser = { ...user, [e.target.name]: e.target.value };
        const newUsers = [...users.slice(0, index), newUser, ...users.slice(index + 1)];
        refreshUsers(newUsers);
    };

    return (
        <Fragment>
            <Table.Row key={user.guid}>
                <Table.Cell>
                    {user.firstName} {user.lastName}{" "}
                    {!user.isActive && <span className="user-inactive">(inactive)</span>}
                </Table.Cell>
                <Table.Cell>
                    <Input
                        className="points-input"
                        name="points"
                        value={user.points}
                        onChange={handleInputChange}
                    />
                    <Button color="green" icon="plus" onClick={() => handleChangePoints(true)} />
                </Table.Cell>
                <Table.Cell>
                    <Input
                        className="points-input"
                        name="negativePoints"
                        value={user.negativePoints}
                        onChange={handleInputChange}
                    />
                    <Button color="red" icon="minus" onClick={() => handleChangePoints(false)} />
                </Table.Cell>
            </Table.Row>
        </Fragment>
    );
};
