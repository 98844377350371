import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Segment, Select, Table } from "semantic-ui-react";
import { toast } from "../..";
import { IAccessRight, IRecipient, recipientInit } from "../../models/accessRight";
import { addRecipientToAccessRight, getRecipients } from "../../services/accessRightsService";
import { AppState } from "../../store/configureStore";
import { AddRecipientForm } from "../Recipients/AddRecipientForm";
import { RecipientRow } from "./RecipientRow";

interface IProps {
    accessRight: IAccessRight;
    setAccessRight: Dispatch<SetStateAction<IAccessRight | undefined>>;
}

export const Recipients = ({ accessRight, setAccessRight }: IProps) => {
    const staff = useSelector((state: AppState) => state.staff);
    const [recipients, setRecipients] = useState<IRecipient[]>([]);
    const [newRecipient, setNewRecipient] = useState(recipientInit);
    const [modalOpen, setModalOpen] = useState(false);

    const handleAddRecipient = async () => {
        try {
            await addRecipientToAccessRight(accessRight.guid, newRecipient);
            toast("New recipient successfully added", true);
            fetchRecipients();
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setModalOpen(false);
        }
    };

    const fetchRecipients = useCallback(async () => {
        const rec = await getRecipients(accessRight.guid);
        Array.isArray(rec) && setRecipients(rec);
    }, [accessRight.recipients]);

    useEffect(() => {
        fetchRecipients();
    }, [fetchRecipients]);

    return (
        <React.Fragment>
            <Segment.Group>
                <Segment className="edit-header">
                    <h2>Recipients</h2>
                    <Select
                        className="access-right-selector"
                        placeholder="Add recepient"
                        selectOnBlur={false}
                        fluid
                        search
                        options={staff.filter(x => {
                            let rec = recipients.map(r => r.recipientGuid);
                            return !rec.includes(x.key);
                        })}
                        onChange={(e, data: any) => {
                            setNewRecipient({ ...newRecipient, recipientGuid: data.value });
                            setModalOpen(true);
                        }}
                    />
                </Segment>
                <Segment className="table-container">
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Username</Table.HeaderCell>
                                <Table.HeaderCell>Is active</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {recipients.map(rec => (
                                <RecipientRow
                                    key={rec.recipientGuid}
                                    recipient={rec}
                                    setRecipients={setRecipients}
                                    accessRight={accessRight}
                                />
                            ))}
                            <Table.Row />
                        </Table.Body>
                    </Table>
                </Segment>
                <AddRecipientForm
                    open={modalOpen}
                    closeModal={() => setModalOpen(false)}
                    handleAddRecipient={handleAddRecipient}
                    newRecipient={newRecipient}
                    setNewRecipient={setNewRecipient}
                />
            </Segment.Group>
        </React.Fragment>
    );
};
