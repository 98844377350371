import { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { Segment, Input, TextArea, Button, Icon, Radio, Select } from "semantic-ui-react";
import { UserData } from "../../actions/authentificationActions";
import { IReport, reportInit, reportTypeOptions } from "../../models/report";
import { AppState } from "../../store/configureStore";
import { saveUserReports } from "../../api/reports";
import { useHistory } from "react-router-dom";
import { toast } from "../..";

interface IProps {
    projectGuid: string;
    reports: IReport[];
    setReports: Dispatch<SetStateAction<IReport[]>>;
    user: UserData;
    date: string;
}

export const UserReportsForDateContainer = ({
    projectGuid,
    reports,
    setReports,
    user,
    date,
}: IProps) => {
    const projects = useSelector((state: AppState) => state.projects);
    const projectReports = reports.filter(x => x.projectGuid === projectGuid);
    const [saving, setSaving] = useState(false);
    const history = useHistory();

    const handleInputChange = (e: any, i: number) => {
        let newState = [...reports];
        newState[i][e.target.name] = e.target.value;
        setReports(newState);
    };

    const handleTypeChange = (data: any, i: number) => {
        let newState = [...reports];
        newState[i].type = +data.value;
        setReports(newState);
    };

    const handleBoolChange = (data: any, i: number) => {
        let newState = [...reports];
        newState[i][data.propertyname] = !newState[i][data.propertyname];
        setReports(newState);
    };

    const handleAddNewReport = () => {
        const newReport = { ...reportInit };
        newReport.projectGuid = projectGuid;
        newReport.userGuid = user.guid ?? "";
        const d = new Date(date);
        newReport.date = d.toISOString();
        setReports(reports.concat(newReport));
    };

    const handleRemoveReport = (r: IReport) => setReports(reports.filter(x => x !== r));

    const handleSaveChanges = async () => {
        setSaving(true);
        try {
            await saveUserReports(reports.filter(x => x.projectGuid === projectGuid));
            toast("Successfully saved changes", true);
            history.push("/company/reports");
        } catch {
            toast("Problem saving changes", false);
        } finally {
            setSaving(false);
        }
    };

    return (
        <Segment.Group>
            <Segment className="reports-header">
                <h2>
                    Reports for{" "}
                    {projects.find(p => p.value === projectGuid)?.text ?? "Other project"}
                </h2>
                <Button secondary content="Create new" icon="plus" onClick={handleAddNewReport} />
            </Segment>
            <Segment>
                {projectReports.map((x, i) => (
                    <section key={i} className="user-report-item">
                        <div className="user-report-container">
                            <section>
                                <div className="input-container">
                                    <label>Hours</label>
                                    <Input
                                        error={x.hours === -1 || x.hours.toString() === ""}
                                        name="hours"
                                        value={x.hours === -1 ? "" : x.hours}
                                        onChange={(e: any) =>
                                            handleInputChange(
                                                e,
                                                reports.findIndex(u => u === x)
                                            )
                                        }
                                    />
                                </div>
                                <div className="input-container">
                                    <label>Type</label>
                                    <Select
                                        disabled={x.hours === -1 || x.hours.toString() === ""}
                                        value={x.type}
                                        options={reportTypeOptions}
                                        onChange={(e, data: any) =>
                                            handleTypeChange(
                                                data,
                                                reports.findIndex(u => u === x)
                                            )
                                        }
                                    />
                                </div>
                            </section>
                            <div className="input-container">
                                <label>Description</label>
                                <TextArea
                                    rows={5}
                                    name="text"
                                    value={x.text ?? ""}
                                    onChange={(e: any) =>
                                        handleInputChange(
                                            e,
                                            reports.findIndex(u => u === x)
                                        )
                                    }
                                />
                            </div>
                            <div className="input-container">
                                <label>Ticket URL</label>
                                <Input
                                    name="ticketUrl"
                                    value={x.ticketUrl ?? ""}
                                    onChange={(e: any) =>
                                        handleInputChange(
                                            e,
                                            reports.findIndex(u => u === x)
                                        )
                                    }
                                />
                            </div>
                            <div className="input-container">
                                <label>Commit URL</label>
                                <Input
                                    name="commitUrl"
                                    value={x.commitUrl ?? ""}
                                    onChange={(e: any) =>
                                        handleInputChange(
                                            e,
                                            reports.findIndex(u => u === x)
                                        )
                                    }
                                />
                            </div>
                            <div className="input-container">
                                <label>Billable Hours</label>
                                <Radio
                                    toggle
                                    name={"billableHours" + i.toString() + "_" + x.projectGuid}
                                    propertyname="billableHours"
                                    checked={x.billableHours}
                                    onChange={(e, data: any) =>
                                        handleBoolChange(
                                            data,
                                            reports.findIndex(u => u === x)
                                        )
                                    }
                                    disabled={false}
                                />
                            </div>
                            <div className="input-container">
                                <label>Different Customer Description</label>
                                <Radio
                                    toggle
                                    name={`differentCustomerDescription${i.toString}_${projectGuid}`}
                                    propertyname="differentCustomerDescription"
                                    checked={x.differentCustomerDescription}
                                    onChange={(e, data: any) =>
                                        handleBoolChange(
                                            data,
                                            reports.findIndex(u => u === x)
                                        )
                                    }
                                    disabled={false}
                                />
                            </div>
                            <div
                                className="input-container"
                                hidden={!x.differentCustomerDescription}
                            >
                                <label hidden={!x.differentCustomerDescription}>
                                    Customer Description
                                </label>
                                <TextArea
                                    rows={5}
                                    name="customerDescription"
                                    hidden={!x.differentCustomerDescription}
                                    value={x.customerDescription ?? ""}
                                    onChange={(e: any) =>
                                        handleInputChange(
                                            e,
                                            reports.findIndex(u => u === x)
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <Icon
                            link
                            name="close"
                            size="big"
                            disabled={x.guid !== ""}
                            onClick={() => handleRemoveReport(x)}
                        />
                    </section>
                ))}
                {projectReports.length > 0 && (
                    <Button
                        className="report-save-button"
                        fluid
                        secondary
                        content="Save changes"
                        icon="save"
                        onClick={handleSaveChanges}
                        loading={saving}
                        disabled={
                            projectReports.some(
                                x => x.hours && parseInt(x.hours + "") !== 0 && x.text.length === 0
                            ) || projectReports.some(x => x.hours.toString() === "")
                        }
                    />
                )}
            </Segment>
        </Segment.Group>
    );
};
