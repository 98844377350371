import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form, Input } from "semantic-ui-react";
import { forgotPassword } from "../../services/authenticationService";
import { RedirectIfLoggedIn } from "../Login/RedirectIfLoggedIn";
import { ReactComponent as LogoIcon } from "../../icons/Aconto_logo_small.svg";
import { ReactComponent as Logo } from "../../icons/Aconto_logo_log_in.svg";

export const ForgotPassword = () => {
    const [username, setUsername] = useState("");
    const [success, setSuccess] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const disabled = username.length === 0 || sendingRequest;
    const location: any = useLocation();

    const handleSendForgotPassword = async () => {
        setSendingRequest(true);
        const response = await forgotPassword(username);
        if (response.ok) {
            setSuccess(true);
        }
        setSendingRequest(false);
    };

    return (
        <div className="login-container">
            <RedirectIfLoggedIn
                path={location.state !== undefined ? location.state.from.pathname : "/login"}
            />
            <div className="center-box">
                <React.Fragment>
                    <div className="login-form-container">
                        <LogoIcon title="" />
                        <h2 id="text-center">Forgot password</h2>
                        {success ? (
                            <p>Email has been sent. Please check your inbox.</p>
                        ) : (
                            <React.Fragment>
                                <p id="text-center">Please type in your email address.</p>
                                <Form>
                                    <Input
                                        id="username"
                                        value={username}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setUsername(e.target.value)
                                        }
                                    />
                                    <Button
                                        fluid
                                        size="large"
                                        color="yellow"
                                        type="submit"
                                        primary
                                        loading={sendingRequest}
                                        disabled={disabled}
                                        content="Send password reset email"
                                        onClick={handleSendForgotPassword}
                                    />
                                </Form>
                            </React.Fragment>
                        )}
                    </div>
                    <div id="divider"></div>
                    <div className="aconto-logo-container">
                        <Logo title="" />
                        <h1>Business administration made easy</h1>
                    </div>
                </React.Fragment>
            </div>
        </div>
    );
};
