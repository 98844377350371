import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Table, Button, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { IProject } from "../../models/project";
import { deleteProject, submitProjectAccessRequest } from "../../api/projects";
import { AppState } from "../../store/configureStore";
import { fetchProjects } from "../../store/projectsReducer";
import { Image } from "../../components/Image/Image";
import { DocumentType } from "../../models/document";

interface IProps {
    project: IProject;
    handleRemoveProject: () => void;
    page: string;
    status: number;
}

export const ProjectRow = ({ project, handleRemoveProject, page, status }: IProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const handleDeleteProject = async () => {
        try {
            await deleteProject(project.guid);
            handleRemoveProject();
            fetchProjects(dispatch);
            toast("Successfully deleted project", true);
        } catch {
            toast("Failed to delete project", false);
        }
    };

    const handleSendRequest = async () => {
        setLoading(true);
        try {
            await submitProjectAccessRequest(project.guid);
            toast("Project access request successfully submitted", true);
            handleRemoveProject();
        } catch {
            toast("Unable to submit project access request", false);
        } finally {
            setLoading(false);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `projects/${project.guid}`,
            state: page,
        });
    };

    return (
        <Fragment>
            <Table.Row key={project.guid} onClick={onRowClick} className="clickable-row">
                <Table.Cell className="photo">
                    <Image
                        guid={project.guid ?? ""}
                        type={DocumentType.ProjectPhotos}
                        avatar={true}
                    />
                </Table.Cell>
                <Table.Cell data-label="Name">{project.name}</Table.Cell>
                <Table.Cell data-label="Project client">
                    {project.clientGuid ? project.client : <em>Internal project</em>}
                </Table.Cell>
                <Table.Cell data-label="Num of project members">{project.projectUsers.length}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role !== UserRole.Admin && status === 0 && (
                        <Button
                            color="green"
                            content="Request access"
                            loading={loading}
                            disabled={
                                project.projectUsers.includes(user.guid!) ||
                                (project.usersWithAccessRequest &&
                                    project.usersWithAccessRequest.includes(user.guid!))
                            }
                            icon="send"
                            onClick={handleSendRequest}
                        />
                    )}
                    {user.role === UserRole.Admin && (
                        <React.Fragment>
                            <Button
                                as={Link}
                                to={{ pathname: "projects/customerreport/" + project.guid }}
                                color="green"
                                content="Customer report"
                                icon="file excel outline"
                            />
                            <Button
                                color="red"
                                content="Delete"
                                icon="delete"
                                onClick={() => setModalOpen(true)}
                            />
                        </React.Fragment>
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this project?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteProject} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
