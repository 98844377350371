import { IAccessRight, IRecipient } from "../models/accessRight";
import { IPaginationWrapper } from "../models/common";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getAccessRights(page: number): Promise<IPaginationWrapper> {
    const params = new URLSearchParams({ page: page + "" });
    return await fetch("/api/accessRights?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function createAccessRight(accessRightDto: Partial<IAccessRight>): Promise<Response> {
    return await fetch("/api/accessRights", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(accessRightDto),
    });
}

export async function deleteAccessRight(guid: string): Promise<Response> {
    return await fetch("/api/accessRights/" + guid, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function getAccessRight(guid: string): Promise<IAccessRight> {
    return await fetch("/api/accessRights/" + guid, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function addRecipientToAccessRight(
    guid: string,
    recipient: Partial<IRecipient>
): Promise<Response> {
    return await fetch(`/api/accessRights/${guid}/recipients`, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(recipient),
    });
}

export async function getRecipients(guid: string): Promise<IRecipient[]> {
    return await fetch(`/api/accessRights/${guid}/recipients`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function editAccessRight(accessRight: IAccessRight): Promise<Response> {
    return await fetch("/api/accessRights/" + accessRight.guid, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(accessRight),
    });
}

export async function deleteRecipient(
    guid: string,
    recipient: string | undefined
): Promise<Response> {
    return await fetch(`/api/accessRights/${guid}/recipients`, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(recipient),
    });
}

export async function getRecipient(
    accessRightGuid: string,
    recipientGuid: string | undefined
): Promise<IRecipient> {
    return await fetch(`/api/accessRights/${accessRightGuid}/recipients/${recipientGuid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });;
}

export async function editRecipient(accessRightGuid: string, recipient: IRecipient): Promise<Response> {
    return await fetch("/api/accessRights/" + accessRightGuid + "/recipients/" + recipient.recipientGuid, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(recipient),
    });
}
