import React, { useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { TextInput } from "../../components/TextInput/TextInput";
import { createCompanyServiceType } from "../../api/companyServicesType";
import { companyServiceTypeInit } from "../../models/companyServiceType";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddCompanyServiceType: () => void;
}

export const AddCompanyServiceTypeForm = ({
    open,
    closeModal,
    handleAddCompanyServiceType,
}: IProps) => {
    const [newCompanyServiceType, setNewCompanyServiceType] = useState(companyServiceTypeInit);

    const handleAddNewCompanyServiceType = async () => {
        try {
            await createCompanyServiceType(newCompanyServiceType);
            handleAddCompanyServiceType();
            toast("Company service type successfully added", true, 1500);
        } catch {
            toast("Error creating company service type", false);
        } finally {
            closeModal();
        }
    };

    return (
        <Modal open={open} onClose={closeModal} dimmer="blurring" centered={false} size="tiny">
            <Modal.Header>
                <h2>Add new company service type</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-company-service-type-form">
                    <div className="input-container">
                        <label>Code</label>
                        <TextInput
                            name="number"
                            value={newCompanyServiceType.number}
                            handleInputChange={(e: any) =>
                                setNewCompanyServiceType({
                                    ...newCompanyServiceType,
                                    number: e.target.value,
                                })
                            }
                            disabled={false}
                        />
                    </div>
                    <div className="input-container">
                        <label>Service type</label>
                        <TextInput
                            name="serviceType"
                            isTextArea={true}
                            value={newCompanyServiceType.type}
                            handleInputChange={(e: any) =>
                                setNewCompanyServiceType({
                                    ...newCompanyServiceType,
                                    type: e.target.value,
                                })
                            }
                            disabled={false}
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new company service type"
                    disabled={newCompanyServiceType.type === ""}
                    secondary
                    fluid
                    onClick={handleAddNewCompanyServiceType}
                />
            </Modal.Actions>
        </Modal>
    );
};
