import { Fragment, useCallback, useEffect, useState } from "react";
import { IHomepageInfo, IDayRange, EmployeeDto } from "../../models/homepageInfo";
import { getHomepageInfo, getWorkHomepageInfo } from "../../services/homepageService";
import { Icon, Loader, Segment } from "semantic-ui-react";
import { HomepageUsersTable } from "./HomepageUsersTable";
import { HomepageNotifications } from "./HomepageNotifications";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { BirthdaysTable } from "../UsersDashboard/BirthdaysTable";
import { UserRole } from "../../actions/authentificationActions";
import { isMobile } from "react-device-detect";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import "./Home.css";

export const HomeDashboard = () => {
    const user = useSelector((state: AppState) => state.user);
    const [homepageInfo, setHomepageInfo] = useState<IHomepageInfo>();
    const [homepageWork, setHomepageWork] = useState<EmployeeDto>();
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const loc = useLocation();
    //number of day pairs that will be fetched from backend at once (per page)
    const numberOfDayPairsPerPage = 7;
    const [pageCount, setPageCount] = useState(loc.state ? (loc.state as number) : 0);
    const [dayPairCount, setDayPairCount] = useState(numberOfDayPairsPerPage * pageCount);
    //on mobile only one day pair is shown on the screen
    const [dayRangeToDisplay, setDayRangeToDisplay] = useState<IDayRange>({
        startDayIndex: isMobile ? mod(dayPairCount, numberOfDayPairsPerPage) * 2 : 0,
        endDayIndex: isMobile
            ? mod(dayPairCount, numberOfDayPairsPerPage) * 2 + 1
            : numberOfDayPairsPerPage * 2 - 1,
    });
    const fetchWorkHomepageinfo = useCallback(async () => {
        if (homepageInfo?.employeeDto) {
            const _pageNumber = isMobile
                ? Math.floor(dayPairCount / numberOfDayPairsPerPage)
                : pageCount;
            let dto = { ...homepageInfo.employeeDto };
            dto.pageNumber = _pageNumber;
            const res = await getWorkHomepageInfo(dto);
            setHomepageWork(res);
            setTableLoading(false);
            setLoading(false);
        }
    }, [homepageInfo, dayPairCount, pageCount]);

    const fetchHomepageInfo = useCallback(async () => {
        const res = await getHomepageInfo();
        setHomepageInfo(res);
    }, []);
    function handlePlus() {
        setTableLoading(true);
        setPageCount(pageCount + 1);
        setDayPairCount(dayPairCount + 1);
    }
    function handleMinus() {
        setTableLoading(true);
        setPageCount(pageCount - 1);
        setDayPairCount(dayPairCount - 1);
    }
    function calculateDayRangeToDisplay() {
        setDayRangeToDisplay({
            startDayIndex: isMobile ? mod(dayPairCount, numberOfDayPairsPerPage) * 2 : 0,
            endDayIndex: isMobile
                ? mod(dayPairCount, numberOfDayPairsPerPage) * 2 + 1
                : numberOfDayPairsPerPage * 2 - 1,
        });
    }
    function mod(n: number, m: number) {
        return ((n % m) + m) % m;
    }
    useEffect(() => {
        fetchHomepageInfo();
    }, [fetchHomepageInfo]);

    useEffect(() => {
        fetchWorkHomepageinfo();
    }, [fetchWorkHomepageinfo]);

    useEffect(() => {
        calculateDayRangeToDisplay();
    }, [dayPairCount]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : homepageInfo ? (
        <div className="homepage-container">
            {user.role === UserRole.Admin || user.role === UserRole.Accountant ? (
                <Fragment>
                    <HomepageNotifications homepageInfo={homepageInfo} />
                    <Segment
                        as={Link}
                        to={"/invoice-processing/bank-statements"}
                        className="last-upload"
                    >
                        <label>
                            Latest bank statement upload:&nbsp;
                            <span>
                                {getDisplayDateFormat(homepageInfo.lastBankStatementUpload, true)}
                            </span>
                        </label>
                    </Segment>
                </Fragment>
            ) : null}
            {homepageInfo.upcomingBirthdays.length > 0 && (
                <BirthdaysTable
                    title="Upcoming birthdays"
                    birthdays={homepageInfo.upcomingBirthdays}
                />
            )}
            <div className="btnWrapper" style={{ textAlign: "right" }}>
                <Icon link name="angle left" size="big" onClick={handleMinus} />

                <Icon link name="angle right" size="big" onClick={handlePlus} />
            </div>
            {tableLoading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <>
                    {(user.role === UserRole.Employee ||
                        user.role === UserRole.Student ||
                        user.role === UserRole.External) && (
                        <HomepageUsersTable
                            title={"My plan"}
                            dateRange={homepageWork?.dateRange}
                            users={homepageWork!.myPlan}
                            dayRangeToDisplay={dayRangeToDisplay}
                            loggedInUser={user}
                        />
                    )}
                    {(user.role === UserRole.Admin ||
                        user.role === UserRole.Employee ||
                        user.role === UserRole.External ||
                        user.role === UserRole.Student) && (
                        <>
                            <HomepageUsersTable
                                title={"Employees at work"}
                                dateRange={homepageWork?.dateRange}
                                users={homepageWork!.employees}
                                dayRangeToDisplay={dayRangeToDisplay}
                                loggedInUser={user}
                            />
                            <HomepageUsersTable
                                title={"Students at work"}
                                dateRange={homepageWork?.dateRange}
                                users={homepageWork!.students}
                                dayRangeToDisplay={dayRangeToDisplay}
                                loggedInUser={user}
                            />
                            <HomepageUsersTable
                                title={"Externals at work"}
                                dateRange={homepageWork?.dateRange}
                                users={homepageWork!.externals}
                                dayRangeToDisplay={dayRangeToDisplay}
                                loggedInUser={user}
                            />
                        </>
                    )}
                </>
            )}
            {user.role === UserRole.Admin && (
                <section className="homepage-cards">
                    <Segment.Group>
                        <Segment>
                            <h2>Number of employees</h2>
                        </Segment>
                        <Segment>
                            <h1 className="green">{homepageInfo.employeeDto.employees.length}</h1>
                        </Segment>
                    </Segment.Group>
                    <Segment.Group>
                        <Segment>
                            <h2>Number of students</h2>
                        </Segment>
                        <Segment>
                            <h1 className="yellow">{homepageInfo.employeeDto.students.length}</h1>
                        </Segment>
                    </Segment.Group>
                    <Segment.Group>
                        <Segment>
                            <h2>Number of externals</h2>
                        </Segment>
                        <Segment>
                            <h1 className="red">{homepageInfo.employeeDto.externals.length}</h1>
                        </Segment>
                    </Segment.Group>
                </section>
            )}
        </div>
    ) : null;
};
