import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, DropdownItemProps, Icon, Loader } from "semantic-ui-react";
import { IClient } from "../../models/client";
import { getClient, getClientContactsAsProps } from "../../services/clientsService";
import { ClientContacts } from "../ClientContacts/ClientContacts";
import { EditClient } from "./EditClient";
import { ContractsComponentTable } from "./ContractsComponentTable";
import { UserRole } from "../../actions/authentificationActions";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { ClientContracts } from "../ClientContracts/ClientContracts";

export const ClientDetails = () => {
    const user = useSelector((state: AppState) => state.user);
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState<IClient>();
    const [clientsArray, setClientsArray] = useState<DropdownItemProps[]>([]);

    const fetchClient = useCallback(async id => {
        setLoading(true);
        const res = await getClient(id);
        res.guid && setClient(res);
        const clients = await getClientContactsAsProps(res.guid);
        Array.isArray(clients) && setClientsArray(clients);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchClient(params.guid);
    }, [fetchClient, params.guid]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="client-details-container">
            <div className="container-header">
                <h1>Client details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/company/clients", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {client ? (
                <div>
                    <EditClient
                        client={client}
                        setClient={setClient}
                        clientContacts={clientsArray}
                    />
                    <ClientContacts clientGuid={client.guid} />
                    {user.role === UserRole.Admin && <ClientContracts clientGuid={client.guid} />}
                </div>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Client not found
                </h2>
            )}
        </div>
    );
};
