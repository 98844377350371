import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, DropdownItemProps, Input, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import { TextInput } from "../../components/TextInput/TextInput";
import { IClientContact } from "../../models/clientContact";
import { editClientContact } from "../../services/clientContactService";
import { AppState } from "../../store/configureStore";

interface IProps {
    clientContact: IClientContact;
    setClientContact: Dispatch<SetStateAction<IClientContact | undefined>>;
    clients: DropdownItemProps[];
}

export const EditClientContact = ({ clientContact, setClientContact, clients }: IProps) => {
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [saving, setSaving] = useState(false);
    const unauthorized = user.role !== UserRole.Admin;

    const handleInputChange = (e: any) =>
        setClientContact({ ...clientContact!, [e.target.name]: e.target.value });

    const handleUpdateClientContact = async () => {
        try {
            setSaving(true);
            await editClientContact(clientContact);
            toast("Successfully saved changes", true);
            history.push("/company/client-contacts");
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateClientContact}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    return (
        <Segment.Group>
            <Segment className="edit-header">
                <h2>Edit client contact</h2>
                <SaveButton />
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={3}>First name</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="firstName"
                                    value={clientContact.firstName}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Last name</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="lastName"
                                    value={clientContact.lastName}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Email</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="email"
                                    value={clientContact.email}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Phone number</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="phoneNumber"
                                    value={clientContact.phoneNumber}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Employer</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    placeholder="Choose client..."
                                    search
                                    fluid
                                    value={clientContact.clientGuid}
                                    options={clients}
                                    onChange={(e, data: any) =>
                                        setClientContact({
                                            ...clientContact,
                                            clientGuid: data.value,
                                        })
                                    }
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        {user.role === UserRole.Admin && (
                            <Table.Row>
                                <Table.Cell>Access rights</Table.Cell>
                                <Table.Cell className="access-rights-cell-content">
                                    <Input
                                        name="accessRights"
                                        value={clientContact.accessRights.join(", ").trim()}
                                        onChange={handleInputChange}
                                        disabled={true}
                                    />
                                    <Button
                                        secondary
                                        onClick={() => history.push("/company/access-rights")}
                                    >
                                        Edit access rights
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Segment>
            <Segment className="edit-footer">
                <SaveButton />
            </Segment>
        </Segment.Group>
    );
};
