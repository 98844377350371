import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Button, DropdownItemProps, Radio, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import {
    currencyOptions,
    IInvoice,
    invoiceOrigins,
    invoiceSources,
    invoiceTypes,
    serviceTypeOptions,
    countryOptions,
} from "../../models/invoice";
import { editInvoice } from "../../services/invoicesService";
import { AppState } from "../../store/configureStore";
import { UserRole } from "../../actions/authentificationActions";
import { Link } from "react-router-dom";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { TextInput } from "../../components/TextInput/TextInput";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";

interface IProps {
    invoice: IInvoice;
    setInvoice: Dispatch<SetStateAction<IInvoice | undefined>>;
    users: DropdownItemProps[];
    vehicles: DropdownItemProps[];
    invoiceCategories: DropdownItemProps[];
    buyRequests: DropdownItemProps[];
    inventoryItems: DropdownItemProps[];
}

export const EditInvoice = ({
    invoice,
    setInvoice,
    users,
    vehicles,
    inventoryItems,
    invoiceCategories,
    buyRequests,
}: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const loc = useLocation();

    const [saving, setSaving] = useState(false);
    const disabled = user.role !== UserRole.Admin;

    const handleInputChange = (e: any) =>
        setInvoice({ ...invoice!, [e.target.name]: e.target.value });

    const handleUpdateInvoice = async () => {
        setSaving(true);
        try {
            await editInvoice(invoice);
            toast("Successfully saved changes", true);
            history.push({
                pathname: "/invoice-processing/invoices",

                state: loc.state,
            });
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    const SaveButton = () =>
        !disabled ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateInvoice}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    return (
        <Segment.Group>
            <Segment>
                <h2>Edit invoice details</h2>
                <SaveButton />
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Name</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="name"
                                    value={invoice.name}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Amount (HRK)</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="amount"
                                    value={invoice.amount.replace(/[,.]/g, m =>
                                        m === "," ? "." : ","
                                    )}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Bank statement amount</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="bankStatementAmount"
                                    value={invoice.bankStatementAmount.replace(/[,.]/g, m =>
                                        m === "," ? "." : ","
                                    )}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Currency</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={invoice.currency}
                                    options={currencyOptions}
                                    onChange={(e, data: any) =>
                                        setInvoice({ ...invoice, currency: +data.value })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Inventory items</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    multiple
                                    placeholder="Not an inventory item invoice"
                                    value={invoice.inventoryItemsGuid}
                                    options={inventoryItems}
                                    onChange={(e, data: any) =>
                                        setInvoice({ ...invoice, inventoryItemsGuid: data.value })
                                    }
                                    disabled={disabled}
                                    clearable
                                />
                                <Button
                                    id="new_inventory_item"
                                    as={Link}
                                    to={{
                                        pathname: "/company/inventories/create",
                                        state: invoice.guid,
                                    }}
                                    secondary
                                    content="Create new"
                                    icon="pencil"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Buy requests</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    placeholder="No related buy request"
                                    value={invoice.buyRequestGuid}
                                    options={buyRequests}
                                    onChange={(e, data: any) =>
                                        setInvoice({ ...invoice, buyRequestGuid: data.value })
                                    }
                                    disabled={disabled}
                                    clearable
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Is processed</Table.Cell>
                            <Table.Cell>
                                <Radio
                                    toggle
                                    name="isProcessed"
                                    checked={invoice.isProcessed}
                                    onChange={e =>
                                        setInvoice({
                                            ...invoice,
                                            isProcessed: !invoice.isProcessed,
                                        })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Created</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="created"
                                    value={getDisplayDateFormat(invoice.created, true)}
                                    handleInputChange={handleInputChange}
                                    disabled
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Tags</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="tags"
                                    value={invoice.tags ? invoice.tags.join(", ") : ""}
                                    handleInputChange={handleInputChange}
                                    disabled
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Is travel expense</Table.Cell>
                            <Table.Cell>
                                <Radio
                                    toggle
                                    name="isTravelExpense"
                                    checked={invoice.isTravelExpense}
                                    onChange={e =>
                                        setInvoice({
                                            ...invoice,
                                            isTravelExpense: !invoice.isTravelExpense,
                                        })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Is paid</Table.Cell>
                            <Table.Cell>
                                <Radio
                                    toggle
                                    name="isPaid"
                                    checked={invoice.isPaid}
                                    onChange={e =>
                                        setInvoice({
                                            ...invoice,
                                            isPaid: !invoice.isPaid,
                                        })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Paid by</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={invoice.paidByGuid}
                                    options={users}
                                    onChange={(e, data: any) =>
                                        setInvoice({ ...invoice, paidByGuid: data.value })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Vehicle</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={invoice.vehicleGuid}
                                    options={vehicles}
                                    onChange={(e, data: any) => {
                                        setInvoice({ ...invoice, vehicleGuid: data.value });
                                    }}
                                    disabled={disabled}
                                    clearable
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Type</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={invoice.invoiceType}
                                    options={invoiceTypes}
                                    onChange={(e, data: any) =>
                                        setInvoice({ ...invoice, invoiceType: +data.value })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Category</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={invoice.invoiceCategoryGuid}
                                    options={invoiceCategories}
                                    onChange={(e, data: any) =>
                                        setInvoice({ ...invoice, invoiceCategoryGuid: data.value })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Origin</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={invoice.invoiceOrigin}
                                    options={invoiceOrigins}
                                    onChange={(e, data: any) => {
                                        if (data.value === 2)
                                            setInvoice({
                                                ...invoice,
                                                invoiceOrigin: +data.value,
                                                serviceType: +13,
                                            });
                                        else setInvoice({ ...invoice, invoiceOrigin: +data.value });
                                    }}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Source</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={invoice.invoiceSource}
                                    options={invoiceSources}
                                    onChange={(e, data: any) =>
                                        setInvoice({ ...invoice, invoiceSource: +data.value })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Info</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="info"
                                    value={invoice.info}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                    isTextArea
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Is in VAT system</Table.Cell>
                            <Table.Cell>
                                <Radio
                                    toggle
                                    name="isInVatSystem"
                                    checked={invoice.isInVatSystem}
                                    onChange={e =>
                                        setInvoice({
                                            ...invoice,
                                            isInVatSystem: !invoice.isInVatSystem,
                                        })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>R&D</Table.Cell>
                            <Table.Cell>
                                <Radio
                                    toggle
                                    name="researchAndDevelopment"
                                    checked={invoice.researchAndDevelopment}
                                    onChange={e =>
                                        setInvoice({
                                            ...invoice,
                                            researchAndDevelopment: !invoice.researchAndDevelopment,
                                        })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Service type</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={invoice.serviceType}
                                    options={serviceTypeOptions}
                                    onChange={(e, data: any) =>
                                        setInvoice({ ...invoice, serviceType: +data.value })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Country</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={invoice.invoiceCountry}
                                    options={countryOptions}
                                    onChange={(e, data: any) =>
                                        setInvoice({ ...invoice, invoiceCountry: +data.value })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Comment</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="comment"
                                    value={invoice.comment ?? ""}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                <SaveButton />
            </Segment>
        </Segment.Group>
    );
};