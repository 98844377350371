import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Button, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { IDepartment } from "../../models/department";
import { AppState } from "../../store/configureStore";
import { deleteDepartment } from "../../services/departmentsService";
import { UserRole } from "../../actions/authentificationActions";

interface IProps {
    department: IDepartment;
    handleRemoveDepartment: (d: IDepartment) => void;
    page: string;
}

export const DepartmentRow: React.FC<IProps> = ({ department, handleRemoveDepartment, page }) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteDepartment = async () => {
        const res = await deleteDepartment(department.guid);
        if (!res.ok) toast("Failed to delete report", false);
        else {
            handleRemoveDepartment(department);
            toast("Successfully deleted report", true);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `departments/${department.guid}`,
            state: page,
        });
    };

    return (
        <Fragment>
            <Table.Row onClick={onRowClick} className="clickable-row">
                <Table.Cell>{department.name}</Table.Cell>
                <Table.Cell>{department.users.length}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this department?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteDepartment} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
