import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Checkbox, Modal, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { addEducationCertificate, deleteEducation } from "../../api/educations";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import { IEducation } from "../../models/educationsModel";
import { downloadDocument } from "../../services/documentsService";

interface IProps {
    education: IEducation;
    selected: boolean;
    handleSelect: (sp: IEducation) => void;
    user: UserData;
    fetchEducations: (userGuid: string) => void;
    userGuid: string;
}

export const EducationRow = ({
    education,
    selected,
    handleSelect,
    user,
    fetchEducations,
    userGuid,
}: IProps) => {
    const history = useHistory();
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDeleteEducation = async () => {
        try {
            await deleteEducation(education.guid);
            toast("Education successfully deleted", true);
            fetchEducations(userGuid);
        } catch {
            toast("Error deleting education", false);
        }
    };

    const onRowClick = () => {
        let path = `/employee-data/educations/edit/${education.guid}`;
        history.push(path);
    };

    const handleUploadFile = async (files: File[]) => {
        setLoading(true);
        try {
            await addEducationCertificate(education.guid, files[0]);
            toast("Certificate successfully uploaded", true);
            fetchEducations(userGuid);
        } catch {
            toast("Unable to upload certificate", false);
        } finally {
            setLoading(false);
            setModalOpen(false);
        }
    };

    return (
        <>
            <Table.Row onClick={onRowClick} className="clickable-row">
                {user.role === UserRole.Admin && (
                    <Table.Cell
                        onClick={(e: any) => e.stopPropagation()}
                        textAlign="center"
                        collapsing
                    >
                        <Checkbox
                            toggle
                            checked={selected}
                            onClick={() => handleSelect(education)}
                        />
                    </Table.Cell>
                )}
                <Table.Cell>{education.user}</Table.Cell>
                <Table.Cell>{education.title}</Table.Cell>
                <Table.Cell>{getDisplayDateFormat(education.date)}</Table.Cell>
                <Table.Cell>
                    {education.totalHours} hour{education.totalHours > 1 ? "s" : ""}
                </Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    <Button
                        color="olive"
                        content="Upload"
                        icon="upload"
                        onClick={() => setModalOpen(true)}
                    />
                    <Button
                        color="purple"
                        content="Download"
                        icon="download"
                        onClick={() => downloadDocument(education.certificates[0].guid)}
                        disabled={education.certificates.length === 0}
                    />
                    <Button
                        color="red"
                        content="Delete"
                        icon="close"
                        onClick={() => handleDeleteEducation()}
                    />
                </Table.Cell>
            </Table.Row>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} dimmer="blurring">
                <Modal.Header>Please upload your certificate</Modal.Header>
                <Modal.Content>
                    <FileDropzone title="" handleUploadFiles={handleUploadFile} loading={loading} />
                </Modal.Content>
            </Modal>
        </>
    );
};
