import { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Loader, List } from "semantic-ui-react";
import { toast } from "../..";
import { getMyCompany, updateCompany } from "../../api/companies";
import { ICompany, newCompanyInit } from "../../models/company";
import { AccessRights } from "../AccessRights/AccessRights";
import "./Settings.css";

export const EmployeeSettings = () => {
    const [company, setCompany] = useState<ICompany>(newCompanyInit);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const fetchCompany = useCallback(async () => {
        try {
            setLoading(true);
            setCompany(await getMyCompany());
        } catch {
            toast("Unable to load company info", false);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleSave = async () => {
        setSaving(true);
        try {
            await updateCompany(company);
            toast("Changes saved successfully", true);
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    return (
        <>
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <div>
                    <div className="container-header">
                        <h1>Employee settings</h1>
                        <Button
                            secondary
                            content="Save settings"
                            icon="save"
                            onClick={handleSave}
                        />
                    </div>
                    <List verticalAlign="middle">
                        <List.Item>
                            <List.Content className="settings-item">
                                <label className="settings-label">Turn on gamification</label>
                                <Checkbox
                                    toggle
                                    name="gamificationAllowed"
                                    checked={company.gamificationAllowed}
                                    onClick={() =>
                                        setCompany({
                                            ...company,
                                            gamificationAllowed: !company.gamificationAllowed,
                                        })
                                    }
                                />
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content className="settings-item">
                                <label className="settings-label">Allow anonymous ideas</label>
                                <Checkbox
                                    toggle
                                    name="anonymousIdeasAllowed"
                                    checked={company.anonymousIdeasAllowed}
                                    onClick={() =>
                                        setCompany({
                                            ...company,
                                            anonymousIdeasAllowed: !company.anonymousIdeasAllowed,
                                        })
                                    }
                                />
                            </List.Content>
                        </List.Item>
                    </List>
                    <AccessRights />
                </div>
            )}
        </>
    );
};
