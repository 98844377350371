import { Dispatch, SetStateAction } from "react";
import { Button, Icon, Input, Modal, Radio } from "semantic-ui-react";
import { IRecipient } from "../../models/accessRight";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddRecipient: () => void;
    newRecipient: Partial<IRecipient>;
    setNewRecipient: Dispatch<SetStateAction<Partial<IRecipient>>>;
}

export const AddRecipientForm = ({
    open,
    closeModal,
    handleAddRecipient,
    newRecipient,
    setNewRecipient,
}: IProps) => {
    const disabled = newRecipient.userName === "";

    return (
        <Modal
            className="add-recipient-modal"
            open={open}
            onClose={closeModal}
            dimmer="blurring"
            centered={false}
        >
            <Modal.Header>
                <h2>Add new access right recipient</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-recipient-form">
                    <div className="add-recipient-container">
                        <div className="input-container">
                            <label>Username</label>
                            <Input
                                type="username"
                                placeholder="Enter username"
                                value={newRecipient.userName}
                                onChange={(e: any) =>
                                    setNewRecipient({ ...newRecipient, userName: e.target.value })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Is active</label>
                            <Radio
                                toggle
                                checked={newRecipient.isActive}
                                onClick={() =>
                                    setNewRecipient({
                                        ...newRecipient,
                                        isActive: !newRecipient.isActive,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Comment</label>
                            <Input
                                type="comment"
                                placeholder="Enter comment"
                                value={newRecipient.comment}
                                onChange={(e: any) =>
                                    setNewRecipient({ ...newRecipient, comment: e.target.value })
                                }
                            />
                        </div>
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new recipient"
                    disabled={disabled}
                    secondary
                    fluid
                    onClick={handleAddRecipient}
                />
            </Modal.Actions>
        </Modal>
    );
};
