import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Icon, Loader, Pagination, Segment, Table, Transition } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { ApproveStatus, commonStatusOptions } from "../../models/common";
import { IWorkAttendance } from "../../models/workAttendance";
import {
    getWorkAttendances,
    processWorkAttendanceRequests,
    getYearlyVacationPlan,
    getYearlyVacationPlanExist,
} from "../../services/workAttendancesService";
import { AppState } from "../../store/configureStore";
import { WorkAttendanceRow } from "./WorkAttendanceRow";
import "./WorkAttendances.css";

type RouteParams = {
    attendanceType: string;
    status: string;
};

export const WorkAttendances = () => {
    const user = useSelector((state: AppState) => state.user);
    const loc = useLocation();
    const history = useHistory();
    let params = useParams<RouteParams>();
    const allowedRoles = ["admin", "employee"];
    const [workAttendances, setWorkAttendances] = useState<IWorkAttendance[]>([]);
    const [yearlyVacationPlan, setYearlyVacationPlan] = useState<IWorkAttendance[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState<IWorkAttendance[]>([]);
    const [yearlyVacationPlanExist, setYearlyVacationPlanExist] = useState(false);
    const [status, setStatus] = useState(
        sessionStorage.getItem("prevStatus")
            ? Number.parseInt(sessionStorage.getItem("prevStatus")!)
            : user.role === UserRole.Admin
            ? ApproveStatus.Requested
            : ApproveStatus.Draft
    );
    const statusOptions =
        user.role === UserRole.Admin
            ? commonStatusOptions.concat({ key: -1, label: "All" })
            : user.role === UserRole.Employee
            ? [{ key: 2, label: "Draft" }].concat(commonStatusOptions)
            : commonStatusOptions;

    const getHeader = () => {
        switch (params.attendanceType) {
            case "vacations":
                return "Vacations";
            case "sick-leaves":
                return "Sick leaves";
            case "remote-work":
                return "Remote work";
            case "paid-leaves":
                return "Paid leaves";
            default:
                return "";
        }
    };

    const getWorkAttendanceType = () => {
        switch (params.attendanceType) {
            case "vacations":
                return "Vacation";
            case "sick-leaves":
                return "Sick leave";
            case "remote-work":
                return "Remote work";
            case "paid-leaves":
                return "Paid leave";
            case "yearly-vacation-plan":
                return "Yearly Vacation Plan";
            default:
                return "";
        }
    };

    const handleSelect = (sp: IWorkAttendance) => {
        if (selected.includes(sp)) setSelected(selected.filter(x => x !== sp));
        else setSelected(selected.concat(sp));
    };

    const handleToggleAll = () =>
        selected.length === workAttendances.length ? setSelected([]) : setSelected(workAttendances);

    const handleProcessRequest = async (plans: IWorkAttendance[], choice: boolean) => {
        setLoading(true);
        const ids = plans.map(x => x.guid);
        const res = await processWorkAttendanceRequests(ids, choice);
        if (!res.ok)
            toast(
                "Unable to process " + getWorkAttendanceType()?.toLocaleLowerCase() + " requests",
                false
            );
        else {
            toast(getWorkAttendanceType() + " requests successfully processed", true);
            fetchWorkAttendances();
            fetchYearlyVacationPlan();
            setSelected([]);
        }
        setLoading(false);
    };

    const fetchWorkAttendances = useCallback(async () => {
        setLoading(true);
        setWorkAttendances([]);
        const res = await getWorkAttendances(page, params.attendanceType, status);

        if (res.rows) {
            setWorkAttendances(res.rows as IWorkAttendance[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page, params.attendanceType, status]);

    const fetchYearlyVacationPlan = useCallback(async () => {
        const res = await getYearlyVacationPlan(page, params.attendanceType, status);
        if (res.rows) {
            setYearlyVacationPlan(res.rows as IWorkAttendance[]);
        }
    }, [page, params.attendanceType, status]);

    const fetchYearlyVacationPlanExist = useCallback(async () => {
        const res = await getYearlyVacationPlanExist();
        setYearlyVacationPlanExist(res);
    }, [yearlyVacationPlan.length]);

    useEffect(() => {
        fetchWorkAttendances();
    }, [fetchWorkAttendances]);

    useEffect(() => {
        fetchYearlyVacationPlanExist();
    }, [fetchYearlyVacationPlanExist]);

    useEffect(() => {
        fetchYearlyVacationPlan();
    }, [fetchYearlyVacationPlan]);

    useEffect(() => {
        setPage(1);
    }, [params.attendanceType]);

    useEffect(() => {
        if (
            params.status &&
            params.status === "approved" &&
            sessionStorage.getItem("prevStatus") === null
        ) {
            setStatus(ApproveStatus.Approved);
        } else {
            let prevStat = sessionStorage.getItem("prevStatus");
            if (prevStat && status === Number.parseInt(prevStat)) {
                sessionStorage.removeItem("prevStatus");
            }
        }
    }, [status]);

    return !allowedRoles.includes(user.role!) ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="work-attendance-container">
            <div className="container-header">
                <h1>{getHeader()}</h1>
                <div>
                    {params.attendanceType === "vacations" && user.role !== UserRole.Admin && (
                        <Button
                            as={Link}
                            to={`/employee-data/yearly-vacation-plan/create`}
                            secondary
                            content="Create yearly vacation plan"
                            icon="plus"
                            disabled={yearlyVacationPlanExist}
                        />
                    )}
                    {params.attendanceType === "vacations" && user.role === UserRole.Admin && (
                        <Button
                            as={Link}
                            to={`/employee-data/yearly-vacation-plan/view`}
                            secondary
                            content="View yearly vacation plans"
                        />
                    )}
                    <Button
                        onClick={() =>
                            history.push(`/employee-data/${params.attendanceType}/create`)
                        }
                        secondary
                        content={`Create new`}
                        icon="plus"
                    />
                </div>
            </div>
            <div className="work-attendance-display">
                <div className="work-attendance-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment className="work-attendance-options">
                                {user.role === UserRole.Admin && (
                                    <Button
                                        secondary
                                        size="small"
                                        content="Select all"
                                        icon={
                                            workAttendances.length > 0 &&
                                            selected.length === workAttendances.length
                                                ? "toggle off"
                                                : "toggle on"
                                        }
                                        onClick={handleToggleAll}
                                    />
                                )}
                                {statusOptions.map(x => (
                                    <span
                                        className={status === x.key ? "selected" : ""}
                                        key={x.key}
                                        onClick={() => setStatus(x.key)}
                                    >
                                        {x.label}
                                    </span>
                                ))}
                                <Transition
                                    visible={selected.length > 0}
                                    animation="scale"
                                    duration={300}
                                >
                                    <Button.Group size="small">
                                        <Button
                                            color="green"
                                            content="Approve selected"
                                            icon="calendar check"
                                            onClick={() => handleProcessRequest(selected, true)}
                                        />
                                        <Button.Or />
                                        <Button
                                            color="red"
                                            content="Reject selected"
                                            icon="calendar times"
                                            onClick={() => handleProcessRequest(selected, false)}
                                        />
                                    </Button.Group>
                                </Transition>
                            </Segment>
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            {user.role === UserRole.Admin && (
                                                <Table.HeaderCell width="1">
                                                    Select
                                                </Table.HeaderCell>
                                            )}
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                            <Table.HeaderCell>User</Table.HeaderCell>
                                            <Table.HeaderCell>Start date</Table.HeaderCell>
                                            <Table.HeaderCell>End date</Table.HeaderCell>
                                            <Table.HeaderCell>Total days</Table.HeaderCell>
                                            <Table.HeaderCell>Date of request</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {workAttendances.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="8">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            workAttendances.map(x => (
                                                <WorkAttendanceRow
                                                    key={x.guid}
                                                    workAttendance={x}
                                                    selected={selected.includes(x)}
                                                    handleSelect={handleSelect}
                                                    user={user}
                                                    handleProcessRequest={handleProcessRequest}
                                                    fetchWorkAttendances={fetchWorkAttendances}
                                                    yearlyVacationPlan={yearlyVacationPlan}
                                                    status={status}
                                                    statusAll={status === -1}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                    {getWorkAttendanceType() === "Vacation" &&
                                        yearlyVacationPlan.length > 0 && (
                                            <Table.Footer>
                                                <Table.Row>
                                                    <Table.Cell textAlign="center" colSpan="8">
                                                        <h4>Yearly Vacation Plan</h4>
                                                    </Table.Cell>
                                                </Table.Row>
                                                {yearlyVacationPlan.map(x => (
                                                    <WorkAttendanceRow
                                                        key={x.guid}
                                                        workAttendance={x}
                                                        selected={selected.includes(x)}
                                                        handleSelect={handleSelect}
                                                        user={user}
                                                        handleProcessRequest={handleProcessRequest}
                                                        fetchWorkAttendances={
                                                            fetchYearlyVacationPlan
                                                        }
                                                        yearlyVacationPlan={yearlyVacationPlan}
                                                        status={status}
                                                        statusAll={status === -1}
                                                    />
                                                ))}
                                                <Table.Row />
                                            </Table.Footer>
                                        )}
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
