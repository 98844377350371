import { DropdownItemProps } from "semantic-ui-react";

export interface IDate extends Record<string, string | number> {
    guid: string;
    workDay: string;
    hours: number;
    start: string;
    end: string;
    yearPlan: number;
    studentPlan: number;
    workStatus: number;
}

const workTimes = [
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
];

export const workTimeOptions: DropdownItemProps[] = workTimes.map(x => {
    return { key: x, text: x, value: x };
});

export const workAttendanceTypeOptions: DropdownItemProps[] = [
    { key: 0, text: "Office", value: 0 },
    { key: 1, text: "Remote", value: 1 },
];

export interface IUnselectableDates {
    previousSameType: string[];
    previousOtherTypes: string[];
    holidays: string[];
    currentDates: string[];
    accountable: false;
    yearlyVacationPlanApproved: boolean;
}
