import { type } from "os";
import { IPaginationWrapper } from "../models/common";
import { DocumentType, IDocument, IUploadDocument } from "../models/document";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getDocuments(
    page: number,
    documentType: DocumentType,
    entityGuid?: string,
    userGuid?: string,
    query?: string,
    typeQuery?: number,
): Promise<IPaginationWrapper> {
    const params = new URLSearchParams({ page: page.toString(), documentType: documentType.toString() });
    entityGuid && params.append("entityGuid", entityGuid);
    userGuid && params.append("userGuid", userGuid);
    query && params.append("query", query);
    typeQuery && params.append("typeQuery", typeQuery.toString());
    return await fetch("/api/documents?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getDocument(guid: string): Promise<IDocument> {
    return await fetch("/api/documents/" + guid, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function createDocument(doc: IUploadDocument, file: File): Promise<IDocument> {
    const formData = new FormData();
    formData.append("document", JSON.stringify(doc));
    formData.append("file", file, file.name);
    return await fetch("/api/documents", {
        method: "POST",
        ...getAuthorizationProperties(),
        body: formData,
    }).then(res => {
        return res.json();
    });
}

export async function createDocumentWithoutUpload(doc: IDocument): Promise<IDocument> {
    const formData = new FormData();
    formData.append("document", JSON.stringify(doc));
    return await fetch("/api/documents/noUpload", {
        method: "POST",
        ...getAuthorizationProperties(),
        body: formData,
    }).then(res => {
        return res.json();
    });
}

export async function editDocument(doc: IDocument): Promise<Response> {
    return await fetch("/api/documents/" + doc.guid, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(doc),
    });
}

export async function downloadDocument(guid: string) {
    const url = `/api/documents/${guid}/download`;
    const a = document.createElement("a");
    a.href = url;
    a.type = "document";
    a.click();
}

export async function downloadSickLeaveDocument(guid: string) {
    const url = `/api/documents/${guid}/downloadSickLeave`;
    const a = document.createElement("a");
    a.href = url;
    a.type = "document";
    a.click();
}

export async function cleanUnuploadedDocuments(): Promise<Response> {
    return await fetch("/api/documents/clean", {
        method: "DELETE",
        ...getAuthorizationProperties(),
    });
}

export async function deleteDocument(guid: string): Promise<Response> {
    return await fetch("/api/documents/" + guid, {
        method: "DELETE",
        ...getAuthorizationProperties(),
    });
}
