import { useState } from "react";
import { Button, Modal, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { IEducation } from "../../models/educationsModel";
import { deleteDocument, downloadDocument } from "../../services/documentsService";

interface IProps {
    education: IEducation;
    setEducation: React.Dispatch<React.SetStateAction<IEducation | undefined>>;
}

export const CertificateTable = ({ education, setEducation }: IProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState("");

    const handleOpenModal = (guid: string) => {
        setModalOpen(true);
        setSelectedDocument(guid);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedDocument("");
    };

    const handleDeleteDocument = async () => {
        try {
            await deleteDocument(selectedDocument);
            setEducation({
                ...education,
                certificates: education.certificates.filter(c => c.guid !== selectedDocument),
            });
            toast("Document successfully deleted", true);
        } catch {
            toast("Failed to delete document", false);
        } finally {
            handleCloseModal();
        }
    };

    return (
        <div>
            <Segment.Group>
                <Segment>
                    <h2>Certificates</h2>
                </Segment>
                <Segment>
                    {education.certificates.length ? (
                        <Table basic="very" columns="3">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {education.certificates.map(doc => (
                                    <Table.Row key={doc.guid}>
                                        <Table.Cell>{doc.name}</Table.Cell>
                                        <Table.Cell>
                                            {getDisplayDateFormat(doc.date, true)}
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Button
                                                color="purple"
                                                content="Download"
                                                icon="download"
                                                onClick={() => downloadDocument(doc.guid)}
                                            />
                                            <Button
                                                color="red"
                                                content="Delete"
                                                icon="delete"
                                                onClick={() => handleOpenModal(doc.guid)}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    ) : (
                        <Table basic="very" columns="3">
                            <Table.Row>
                                <Table.Cell textAlign="center" colSpan="5">
                                    No matching records found
                                </Table.Cell>
                            </Table.Row>
                        </Table>
                    )}
                </Segment>
            </Segment.Group>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => handleCloseModal()}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this document?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => handleCloseModal()} content="Cancel" />
                    <Button
                        color="green"
                        onClick={() => handleDeleteDocument()}
                        content="Confirm"
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
};
