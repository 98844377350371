import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Loader, Message } from "semantic-ui-react";
import { IReport } from "../../models/report";
import { getUserReportsForDate, saveUserReports } from "../../api/reports";
import { AppState } from "../../store/configureStore";
import { UserReportsForDateContainer } from "./UserReportsForDateContainer";
import { toast } from "../..";
import "./Reports.css";

export const UserReportsForDate = () => {
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const params: { date: string } = useParams();
    const [loading, setLoading] = useState(false);
    const [userReports, setUserReports] = useState<IReport[]>([]);
    const [projectGuids, setProjectGuids] = useState<string[]>([]);
    const [saving, setSaving] = useState(false);
    const dayOfWeek = new Date(params.date).getDay();
    const dateString = new Date(params.date).toLocaleDateString("default", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    const fetchUserReportsForDate = useCallback(async () => {
        setLoading(true);
        const res = await getUserReportsForDate(params.date);
        if (res.userReports) {
            setUserReports(res.userReports);
            setProjectGuids(res.projectGuids);
        }
        setLoading(false);
    }, [params.date]);

    const handleSaveChanges = async () => {
        setSaving(true);
        try {
            await saveUserReports(userReports);
            toast("Successfully saved changes", true);
            history.push("/company/reports");
        } catch {
            toast("Problem saving changes", false);
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchUserReportsForDate();
    }, [fetchUserReportsForDate]);

    return (
        <div className="user-reports-container">
            <div className="container-header">
                <h1>Your reports for {dateString}</h1>
                <Button
                    secondary
                    content="Back to list"
                    icon="arrow left"
                    onClick={() => history.push("/company/reports")}
                />
            </div>
            {dayOfWeek === 6 || dayOfWeek === 0 ? (
                <Message
                    icon="smile"
                    header="It's the weekend, no reports necessary today. Have fun and relax!"
                    floating
                />
            ) : loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <section>
                    {projectGuids.length === 0 ? (
                        <Message
                            icon="warning circle"
                            header="You are not included in any projects at the moment"
                            floating
                        />
                    ) : (
                        <div className="user-reports-list">
                            {projectGuids.map(x => (
                                <UserReportsForDateContainer
                                    key={x}
                                    projectGuid={x}
                                    reports={userReports}
                                    setReports={setUserReports}
                                    user={user}
                                    date={params.date}
                                />
                            ))}
                        </div>
                    )}
                </section>
            )}
            {userReports.length > 0 && (
                        <Button className="report-save-button"
                            fluid
                            secondary
                            content="Save changes"
                            icon="save"
                            onClick={handleSaveChanges}
                            loading={saving}
                            disabled={
                                userReports.some(
                                    x =>
                                        x.hours &&
                                        parseInt(x.hours + "") !== 0 &&
                                        x.text.length === 0
                                ) || userReports.some(x => x.hours.toString() === "")
                            }
                        />
            )}
        </div>
    );
};
