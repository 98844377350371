import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Input, Loader, Modal, Segment, TextArea } from "semantic-ui-react";
import { toast } from "../..";
import { createEducation } from "../../api/educations";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { IUploadDocument } from "../../models/document";
import { educationInit, IEducation } from "../../models/educationsModel";
import { DocumentType } from "../../models/document";
import { createDocument } from "../../services/documentsService";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import "./Education.css";

export const CreateEducation = () => {
    const uploadDocument: IUploadDocument = { documentType: DocumentType.Certificate };
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [education, setEducation] = useState<IEducation>(educationInit);
    const [openDropzone, setOpenDropzone] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [documnetUploaded, setDocumentUploaded] = useState(false);

    const handleCreateEducation = async () => {
        setLoading(true);
        try {
            await createEducation(education);
            toast("Education successfully added", true);
            history.push("/employee-data/educations");
        } catch {
            toast("Error adding education", false);
        } finally {
            setLoading(false);
        }
    };

    const handleUploadDocument = async (files: File[]) => {
        setUploadLoading(true);
        try {
            await createDocument(uploadDocument, files[0]);
            toast("Document successfully uploaded", true);
            setDocumentUploaded(true);
        } catch {
            toast("Failed to upload documnet, please try again", false);
        } finally {
            setUploadLoading(false);
            setOpenDropzone(false);
        }
    };

    const handleOnChange = (event: any) => {
        setEducation({ ...education, [event.target.name]: event.target.value });
    };

    return (
        <div>
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <Segment.Group>
                    <Segment>
                        <h2>Create education</h2>
                    </Segment>
                    <Segment>
                        <form className="add-education-form">
                            <section className="input-container">
                                <label>Title</label>
                                <Input
                                    name="title"
                                    placeholder="Enter title"
                                    onChange={(event: any) => handleOnChange(event)}
                                />
                            </section>
                            <div className="input-container">
                                <label>Duration</label>
                                <Input
                                    name="totalHours"
                                    placeholder="Enter duration in hours"
                                    onChange={(event: any) => handleOnChange(event)}
                                />
                            </div>
                            <div className="input-container">
                                <label>Date of completion</label>
                                <CalendarInput
                                    date={education.date}
                                    placeholder="Select date..."
                                    handleDateChange={e =>
                                        setEducation({
                                            ...education,
                                            date: getISODateFormat(e),
                                        })
                                    }
                                />
                            </div>
                            <div className="input-container">
                                <label>Certificate Id</label>
                                <Input
                                    name="certificateId"
                                    placeholder="Enter certificate id"
                                    onChange={(event: any) => handleOnChange(event)}
                                />
                            </div>
                            <div className="input-container">
                                <label>Issuing institution</label>
                                <Input
                                    name="issuingInstitution"
                                    placeholder="Enter issuing institution"
                                    onChange={(event: any) => handleOnChange(event)}
                                />
                            </div>
                            <div className="input-container">
                                <label>Description</label>
                                <TextArea
                                    rows={5}
                                    name="description"
                                    onChange={event => handleOnChange(event)}
                                />
                            </div>
                            <div className="input-container">
                                {documnetUploaded && <label>Certificate uploaded</label>}
                            </div>
                        </form>
                        <Button
                            className="certificate-upload-button"
                            fluid
                            secondary
                            content={
                                documnetUploaded
                                    ? "Change uploaded certificate"
                                    : "Upload certificate"
                            }
                            icon="upload"
                            onClick={() => setOpenDropzone(true)}
                        />
                    </Segment>
                    <Segment>
                        <Button
                            fluid
                            secondary
                            content="Create education"
                            icon="book"
                            onClick={() => {
                                handleCreateEducation();
                            }}
                            loading={loading}
                            disabled={education.title === ""}
                        />
                    </Segment>
                </Segment.Group>
            )}
            <Modal
                className="certificate-upload-modal"
                open={openDropzone}
                onClose={() => setOpenDropzone(false)}
                dimmer="blurring"
            >
                <Modal.Header>Upload your certificate</Modal.Header>
                <Modal.Content>
                    <FileDropzone
                        title=""
                        handleUploadFiles={handleUploadDocument}
                        loading={uploadLoading}
                    />
                </Modal.Content>
            </Modal>
        </div>
    );
};
