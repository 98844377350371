import { useState, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { updateService } from "../../api/services";
import { TextInput } from "../../components/TextInput/TextInput";
import { IService } from "../../models/service";
import { AppState } from "../../store/configureStore";

interface IProps {
    service: IService;
    setService: Dispatch<SetStateAction<IService | undefined>>;
}

export const EditService = ({ service, setService }: IProps) => {
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [saving, setSaving] = useState(false);
    const unauthorized = user.role !== UserRole.Admin;

    const handleServiceUpdate = async () => {
        setSaving(true);
        try {
            await updateService(service);
            toast("Successfully saved changes", true);
            history.push("/administration/settings");
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    const handleInputChange = (e: any) => {
        setService({ ...service!, [e.target.name]: e.target.value });
    };

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleServiceUpdate}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    return (
        <Segment.Group>
            <Segment>
                <h2>Edit service details</h2>
                <SaveButton />
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Code</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="code"
                                    value={service.code}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Service description</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="serviceDescription"
                                    value={service.serviceDescription}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Unit measure</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="unitMeasure"
                                    value={service.unitMeasure}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Quantity</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="quantity"
                                    value={service.quantity}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Net price (HRK)</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="netPrice"
                                    value={service.netPrice}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Discount (%)</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="discount"
                                    value={service.discount}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Tax rate (%)</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="taxRate"
                                    value={service.taxRate}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                <SaveButton />
            </Segment>
        </Segment.Group>
    );
};
