import { Dispatch, SetStateAction } from "react";

export interface ICalendarDate {
    value: string;
    day: number;
    month: number;
    dayOfWeek: number;
}
export interface IDateSpan {
    pickedDates: string[];
    pickedDatesWithWeekendsAndHolidays: string[];
}

const getDayOfWeek = (d: number) => {
    switch (d) {
        case 0:
            return 7;
        default:
            return d;
    }
};

export const getISODateFormat = (date: Date) => {
    const yyyy: string = date.getFullYear().toString();
    const mm: string = (date.getMonth() + 1).toString().padStart(2, "0");
    const dd: string = date.getDate().toString().padStart(2, "0");

    // Date part of ISO standard (yyyy-MM-dd)
    return `${yyyy}-${mm}-${dd}`;
};

// This method should only be used for displaying dates.
// Do not use as date transport format when sending data to backend.
export const getDisplayDateFormat = (
    dateString?: string | Date,
    includeTime: boolean = false,
    localeFormat: string = "hr-HR"
): string => {
    if (!dateString) {
        return "";
    }

    const parsedDate = new Date(dateString);

    return includeTime
        ? parsedDate.toLocaleString(localeFormat)
        : parsedDate.toLocaleDateString(localeFormat);
};

export const getAllDates = (year: number) => {
    const startDate = new Date(year - 1, 11, 31);
    const endDate = new Date(year + 1, 0, 1);
    let begin = new Date(startDate);
    begin.setDate(startDate.getDate() + 1);
    const dates: ICalendarDate[] = [];
    while (begin < endDate) {
        const d = new Date(begin);
        const newDate: ICalendarDate = {
            value: getISODateFormat(d),
            day: d.getDate(),
            dayOfWeek: getDayOfWeek(d.getDay()),
            month: d.getMonth() + 1,
        };
        dates.push(newDate);
        begin.setDate(begin.getDate() + 1);
    }
    return dates;
};

export const getMonthLabel = (m: number) => {
    switch (m) {
        case 1:
            return "January";
        case 2:
            return "February";
        case 3:
            return "March";
        case 4:
            return "April";
        case 5:
            return "May";
        case 6:
            return "June";
        case 7:
            return "July";
        case 8:
            return "August";
        case 9:
            return "September";
        case 10:
            return "October";
        case 11:
            return "November";
        case 12:
            return "December";
        default:
            return "";
    }
};

export const getEmptyDates = (first: number) => {
    const emptyDates: ICalendarDate[] = [];
    const limit = -(first - 1);
    for (let i = 0; i > limit; i--)
        emptyDates.unshift({ value: "", day: i, month: i, dayOfWeek: i });
    return emptyDates;
};

export const getDaysInBetween = (start: Date | string | number, end: Date | string | number) => {
    let startDate = new Date(start);
    let endDate = new Date(end);
    if (startDate > endDate) {
        [startDate, endDate] = [endDate, startDate];
    }
    for (var arr = [], dt = startDate; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
        arr.push(getISODateFormat(new Date(dt)));
    }
    return arr;
};

export const getSelectedDates = (
    firstPickDate: string,
    d: string,
    holidays: string[],
    previousSameType: string[],
    previousOtherTypes: string[],
    setFirstPickDate: React.Dispatch<React.SetStateAction<any>>
): IDateSpan => {
    let dateSpan: string[] = [];
    if (firstPickDate.length !== 0) {
        const first = new Date(firstPickDate);
        const second = new Date(d);
        setFirstPickDate("");
        dateSpan = getDaysInBetween(first, second);
    } else {
        setFirstPickDate(d);
        dateSpan = [d];
    }

    const pickedDatesWithWeekendsAndHolidays = [...dateSpan];

    // exclude previous
    dateSpan = dateSpan.filter(x => !previousSameType.includes(x));
    dateSpan = dateSpan.filter(x => !previousOtherTypes.includes(x));

    // exclude weekends
    dateSpan = dateSpan.filter(x => new Date(x).getDay() !== 6 && new Date(x).getDay() !== 0);
    // exclude holidays
    dateSpan = dateSpan.filter(x => !holidays.includes(x));
    const pickedDates = [...dateSpan];
    const dateSpans: IDateSpan = { pickedDates, pickedDatesWithWeekendsAndHolidays };
    return { pickedDates, pickedDatesWithWeekendsAndHolidays };
};

export const getPreselectedDates = (firstPickDate: string, d: string, holidays: string[]) => {
    if (d === "") {
        return [] as string[];
    } else if (firstPickDate.length !== 0) {
        let dates = getDaysInBetween(new Date(d), new Date(firstPickDate));

        // exclude weekends
        dates = dates.filter(x => new Date(x).getDay() !== 6 && new Date(x).getDay() !== 0);
        // exclude holidays
        dates = dates.filter(x => !holidays.includes(x));
        return dates;
    }
    return [] as string[];
};

export const findTenSuccessiveDays = (
    datesWithWeekendsAndHolidays: string[],
    holidays: string[],
    tenDaysInRow: boolean,
    setTenDaysInRow: Dispatch<SetStateAction<boolean>>
) => {
    let continousDates: string[] = [];
    datesWithWeekendsAndHolidays = datesWithWeekendsAndHolidays.sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    for (let index = 0; index < datesWithWeekendsAndHolidays.length; index++) {
        const d1 = datesWithWeekendsAndHolidays[index];
        const d2 = datesWithWeekendsAndHolidays[index + 1];
        !continousDates.includes(d1) && continousDates.push(d1);
        //check if days are continous
        if (Math.abs(dateDiff(new Date(d2), new Date(d1))) == 1) {
            continousDates.push(d2);
        } else {
            let daysBetweenUnsliced = getDaysInBetween(d1, d2);
            let daysBetween = daysBetweenUnsliced.splice(1, daysBetweenUnsliced.length - 2);
            let isWeekendOrHoliday = false;
            for (let day of daysBetween) {
                if (
                    new Date(day).getDay() === 6 ||
                    new Date(day).getDay() === 0 ||
                    holidays.includes(day)
                ) {
                    isWeekendOrHoliday = true;
                } else {
                    isWeekendOrHoliday = false;
                    break;
                }
            }
            if (isWeekendOrHoliday) {
                continousDates.push(d2);
                continue;
            }
            checkForTenSuccessiveDay(continousDates, holidays, setTenDaysInRow);
            continousDates = [];
            if (tenDaysInRow) break;
        }
    }
};

function dateDiff(d0: any, d1: any) {
    return Math.round((d1 - d0) / 8.64e7);
}

const checkForTenSuccessiveDay = (
    continousDates: string[],
    holidays: string[],
    setTenDaysInRow: Dispatch<SetStateAction<boolean>>
) => {
    // exclude weekends
    continousDates = continousDates.filter(
        x => new Date(x).getDay() !== 6 && new Date(x).getDay() !== 0
    );
    // exclude holidays
    continousDates = continousDates.filter(x => !holidays.includes(x));

    continousDates.length > 9 ? setTenDaysInRow(true) : setTenDaysInRow(false);
};
