import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import { CalendarSelector } from "../../components/CalendarSelector/CalendarSelector";
import {
    getPreselectedDates,
    getSelectedDates,
} from "../../components/CalendarSelector/getAllDatesHelper";
import { IProject } from "../../models/project";
import { downloadCustomerReport } from "../../services/projectsService";
import { getProject } from "../../api/projects";
import { toast } from "../..";

export const CustomerProjectReport = () => {
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState<IProject>();
    const [year, setYear] = useState(new Date().getFullYear());
    const [dates, setDates] = useState<string[]>([]);
    const [firstPickDate, setFirstPickDate] = useState<string>("");
    const [preselectionDates, setPreselectionDates] = useState<string[]>([]);
    const [billableHours, setBillableHours] = useState<number>();

    const handleDates = (d: string) => {
        const { pickedDates } = getSelectedDates(firstPickDate, d, [], [], [], setFirstPickDate);
        setDates(pickedDates);
    };

    const handlePreselectionDates = (d: string) => {
        const preselectedDates = getPreselectedDates(firstPickDate, d, []);
        setPreselectionDates(preselectedDates);
    };

    const exportClientReports = async () => {
        try {
            setLoading(true);
            const res = await downloadCustomerReport(
                dates[0],
                dates[dates.length - 1],
                project ? project.guid : "",
                billableHours
            );

            if (!res) toast("Unable to generate monthly report", false);
        } catch {
            toast("Unable to generate monthly report", false);
        } finally {
            setLoading(false);
        }
    };

    const fetchProject = useCallback(async id => {
        setLoading(true);
        const res = await getProject(id);
        res.guid && setProject(res);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchProject(params.guid);
    }, [fetchProject, params.guid]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div>
            {project ? (
                <div>
                    <div className="container-header">
                        <h1>Customer report for {project?.name}</h1>
                        <Button
                            as={Link}
                            to={{ pathname: "/company/projects", state: loc.state }}
                            secondary
                            content="Back to list"
                            icon="arrow left"
                        />
                    </div>
                    <div className="create-plan-header">
                        <Icon size="big" name="angle left" onClick={() => setYear(year - 1)} />
                        <h1>{year}</h1>
                        <Icon size="big" name="angle right" onClick={() => setYear(year + 1)} />
                    </div>
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Fragment>
                            <CalendarSelector
                                year={year}
                                selectedDates={dates}
                                handleDates={handleDates}
                                previousSameType={[]}
                                previousOtherTypes={[]}
                                holidays={[]}
                                enableEdit={true}
                                showLegend={false}
                                selectableWeekends={true}
                                preselectionDates={preselectionDates}
                                handlePreselectionDates={handlePreselectionDates}
                                billableHours={billableHours}
                                setBillableHours={setBillableHours}
                            />
                            <Button
                                fluid
                                secondary
                                content="Create report"
                                icon="file excel outline"
                                onClick={exportClientReports}
                                loading={loading}
                                disabled={dates.length === 0 || loading}
                            />
                        </Fragment>
                    )}
                </div>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Project not found
                </h2>
            )}
        </div>
    );
};
