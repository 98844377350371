import { Dispatch, Reducer } from "redux";
import { DropdownItemProps } from "semantic-ui-react";
import { GET_CLIENTS } from "../actions/clientsActions";
import { getClientsAsProps } from "../services/clientsService";

export interface ClientsState extends DropdownItemProps {}

const initialState: ClientsState[] = [];

export const clientsReducer: Reducer<ClientsState[]> = (
    state: ClientsState[] = initialState,
    action: any
): ClientsState[] => {
    switch (action.type) {
        case GET_CLIENTS:
            return action.payload;
        default:
            return state;
    }
};

export async function fetchClients(dispatch: Dispatch) {
    const res = await getClientsAsProps();
    dispatch({ type: "GET_CLIENTS", payload: res });
}
