import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Button, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { deleteVendorContact } from "../../api/vendorsContacts";
import { IVendorContact } from "../../models/vendorContact";
import { AppState } from "../../store/configureStore";

interface IProps {
    vendorContact: IVendorContact;
    handleRemoveVendorContact: () => void;
    page: string;
}

export const VendorContactRow = ({ vendorContact, handleRemoveVendorContact, page }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteVendorContact = async () => {
        try {
            await deleteVendorContact(vendorContact.guid);
            handleRemoveVendorContact();
            toast("Successfully deleted vendor contact", true);
        } catch {
            toast("Failed to delete vendor contact", false);
        }
    };

    return (
        <Fragment>
            <Table.Row
                key={vendorContact.guid}
                onClick={() =>
                    history.push({
                        pathname: `vendor-contacts/${vendorContact.guid}`,
                        state: page,
                    })
                }
                className="clickable-row"
            >
                <Table.Cell>{vendorContact.firstName + " " + vendorContact.lastName}</Table.Cell>
                <Table.Cell>{vendorContact.vendor}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this vendor contact?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteVendorContact} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
