import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import "./DropdownInput.css";

interface IProps {
    options: DropdownItemProps[];
    value: string | string[] | number | number[] | boolean | undefined;
    onChange: (e: any, data: any) => void;
    placeholder?: string;
    multiple?: boolean;
    search?: boolean;
    disabled?: boolean;
    fluid?: boolean;
    clearable?: boolean;
    selectOnBlur?: boolean;
}

export const DropdownInput = ({
    placeholder,
    options,
    value,
    onChange,
    multiple,
    search,
    disabled,
    fluid,
    clearable,
    selectOnBlur,
}: IProps) => {
    const values: Array<string | number> = multiple ? (value as string[] | number[]) : [];

    return (
        <>
            {disabled ? (
                <p className="dropdown-container">
                    {multiple
                        ? options
                              .filter(x => values.includes(x.value as string | number))
                              .map(y => y.text as string)
                              .join(", ")
                        : options.find(x => x.value === value)?.text}
                </p>
            ) : (
                <Dropdown
                    selection
                    search={search}
                    multiple={multiple}
                    value={value}
                    placeholder={placeholder}
                    fluid={fluid}
                    clearable={clearable}
                    selectOnBlur={selectOnBlur}
                    options={options}
                    onChange={onChange}
                />
            )}
        </>
    );
};
