import axios from "axios";
import { DropdownItemProps } from "semantic-ui-react";
import { ICompany } from "../models/company";

export const getCompanies = async () => {
    const response = await axios.get("/api/companies");
    return response.data as ICompany[];
};

export const getMyCompany = async () => {
    const response = await axios.get("/api/companies/my-company");
    return response.data as ICompany;
};

export const updateCompany = async (companyDto: ICompany) => {
    const response = await axios.patch("/api/companies", companyDto);
    return response;
};

export const deleteCompany = async (guid: string) => {
    const response = await axios.delete(`/api/companies/${guid}`);
    return response;
};
export const createCompany = async (companyDto: ICompany) => {
    const response = await axios.post("/api/companies", companyDto);
    return response;
};

export const getCompaniesList = async () => {
    const response = await axios.get("/api/companies/list/");
    return response.data as DropdownItemProps[];
};
