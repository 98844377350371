import { CalendarMonth } from "./CalendarMonth";
import { getAllDates } from "./getAllDatesHelper";
import "./CalendarSelector.css";
import { months } from "../../models/common";
import { Label, Select } from "semantic-ui-react";
import { IHeatMapData } from "../../models/heatMapData";
import { Dispatch, SetStateAction } from "react";
import { billableHoursOptions } from "../../models/report";

const monthValues = months.map(x => Number.parseInt(x.value + ""));

interface IProps {
    year: number;
    selectedDates: string[];
    handleDates: (d: string) => void;
    previousSameType: string[];
    previousOtherTypes: string[];
    holidays: string[];
    enableEdit: boolean;
    showLegend?: boolean;
    selectableWeekends?: boolean;
    type?: string;
    preselectionDates: string[];
    handlePreselectionDates: (d: string) => void;
    heatMapData?: IHeatMapData[];
    billableHours?: number;
    setBillableHours?: Dispatch<SetStateAction<number | undefined>>;
}

export const CalendarSelector = ({
    year,
    selectedDates,
    handleDates,
    previousSameType,
    previousOtherTypes,
    holidays,
    enableEdit,
    showLegend,
    selectableWeekends,
    type,
    preselectionDates,
    handlePreselectionDates,
    heatMapData,
    billableHours,
    setBillableHours,
}: IProps) => {
    const allDates = getAllDates(year);

    return (
        <div className="calendar-selector-container">
            {heatMapData && heatMapData.length !== 0 ? (
                <div className="calendar-selector-legend">
                    <div>
                        <span>Number of employees on vacation:</span>
                    </div>
                    <div>
                        <span>None</span>
                        <Label size="large" circular color="grey" />
                    </div>
                    <div>
                        <span>1-2</span>
                        <Label size="large" circular color="green" />
                    </div>
                    <div>
                        <span>3-5</span>
                        <Label size="large" circular color="yellow" />
                    </div>
                    <div>
                        <span>More than 5</span>
                        <Label size="large" circular color="red" />
                    </div>
                </div>
            ) : !showLegend ? (
                <div className="calendar-selector-legend">
                    {setBillableHours && (
                        <div id="billable-hours">
                            <Select
                                selectOnBlur={false}
                                placeholder="Select hour type"
                                value={billableHours}
                                options={billableHoursOptions}
                                onChange={(e, data: any) => setBillableHours(data.value)}
                            />
                        </div>
                    )}
                    <div>
                        <span>Workday</span>
                        <Label size="large" circular color="grey" />
                    </div>
                    <div>
                        <span>Previously selected</span>
                        <Label size="large" circular color="yellow" />
                    </div>
                    <div>
                        <span>Currently selected</span>
                        <Label size="large" circular color="green" />
                    </div>
                </div>
            ) : null}
            <div className="calendar-selector-months-container">
                {monthValues.map(m => (
                    <CalendarMonth
                        key={m}
                        month={m}
                        dates={allDates.filter(x => x.month === m)}
                        selectedDates={selectedDates}
                        handleDates={handleDates}
                        previousSameType={previousSameType}
                        previousOtherTypes={previousOtherTypes}
                        holidays={holidays}
                        enableEdit={enableEdit}
                        selectableWeekends={selectableWeekends}
                        type={type}
                        preselectionDates={preselectionDates}
                        handlePreselectionDates={handlePreselectionDates}
                        heatMapData={heatMapData}
                    />
                ))}
            </div>
        </div>
    );
};
