import { useParams } from "react-router-dom";
import { Loader, Tab } from "semantic-ui-react";
import { ProjectFeed } from "./ProjectFeed";
import { ProjectDetails } from "./ProjectDetails";
import { useCallback, useEffect, useState } from "react";
import { UserRole } from "../../actions/authentificationActions";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { IProject } from "../../models/project";
import { getProject } from "../../api/projects";

export const Project = () => {
    const params: { guid: string } = useParams();
    const user = useSelector((state: AppState) => state.user);
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState<IProject>();

    const viewEnabled =
        user.role === UserRole.Admin || (project && project.projectUsers.includes(user.guid!));

    const fetchProject = useCallback(async id => {
        setLoading(true);
        const res = await getProject(id);
        res.guid && setProject(res);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchProject(params.guid);
    }, [fetchProject, params.guid]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <>
            {viewEnabled ? (
                <Tab
                    className="project-container"
                    menu={{ secondary: true }}
                    panes={[
                        {
                            menuItem: "Project feed",
                            render: () => (
                                <Tab.Pane attached={false}>
                                    <ProjectFeed />
                                </Tab.Pane>
                            ),
                        },
                        {
                            menuItem: "Project details",
                            render: () => (
                                <Tab.Pane attached={false}>
                                    <ProjectDetails
                                        project={project}
                                        setProject={setProject}
                                        viewEnabled={viewEnabled}
                                    />
                                </Tab.Pane>
                            ),
                        },
                    ]}
                />
            ) : (
                <ProjectDetails
                    project={project}
                    setProject={setProject}
                    viewEnabled={viewEnabled}
                />
            )}
        </>
    );
};
