import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Button, Icon, Loader, Pagination, Segment, Table } from "semantic-ui-react";
import { ApproveStatus, commonStatusOptions } from "../../models/common";
import { IBuyRequest } from "../../models/buyRequests";
import { AppState } from "../../store/configureStore";
import { BuyRequestRow } from "./BuyRequestRow";
import { getBuyRequests } from "../../api/buyRequests";
import { UserRole } from "../../actions/authentificationActions";
import "./BuyRequests.css";

export const BuyRequests = () => {
    const user = useSelector((state: AppState) => state.user);
    const loc = useLocation();
    const [buyRequests, setBuyRequests] = useState<IBuyRequest[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState(
        loc.state
            ? ApproveStatus.Approved
            : user.role === UserRole.Admin
            ? ApproveStatus.Requested
            : ApproveStatus.Draft
    );
    const statusOptions =
        user.role === UserRole.Admin
            ? commonStatusOptions.concat(
                  { key: ApproveStatus.Completed, label: "Completed" },
                  { key: ApproveStatus.All, label: "All" }
              )
            : user.role === UserRole.Employee ||
              user.role === UserRole.Student ||
              user.role === UserRole.External
            ? [{ key: ApproveStatus.Draft, label: "Draft" }].concat(commonStatusOptions, {
                  key: ApproveStatus.Completed,
                  label: "Completed",
              })
            : commonStatusOptions;

    const fetchBuyRequests = useCallback(async () => {
        setLoading(true);
        const res = await getBuyRequests(page, status);
        if (res.rows) {
            setBuyRequests(res.rows as IBuyRequest[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page, status]);

    useEffect(() => {
        fetchBuyRequests();
    }, [fetchBuyRequests]);

    useEffect(() => {
        setPage(1);
    }, [status]);

    return (
        <div className="buy-request-container">
            <div className="container-header">
                <h1>Buy requests</h1>
                <div>
                    <Button
                        as={Link}
                        to={`/requests/buy-requests/create`}
                        secondary
                        content="Create new"
                        icon="plus"
                    />
                </div>
            </div>
            <div className="buy-request-display">
                <div className="buy-request-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment className="buy-request-options">
                                {statusOptions.map(x => (
                                    <span
                                        className={status === x.key ? "selected" : ""}
                                        key={x.key}
                                        onClick={() => setStatus(x.key)}
                                    >
                                        {x.label}
                                    </span>
                                ))}
                            </Segment>
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                            <Table.HeaderCell>User</Table.HeaderCell>
                                            <Table.HeaderCell>Title</Table.HeaderCell>
                                            <Table.HeaderCell>Description</Table.HeaderCell>
                                            <Table.HeaderCell>Date of request</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {buyRequests.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="8">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            buyRequests.map(x => (
                                                <BuyRequestRow
                                                    key={x.guid}
                                                    buyRequest={x}
                                                    user={user}
                                                    fetchBuyRequests={fetchBuyRequests}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{ content: <Icon name="angle double left" /> }}
                                        lastItem={{ content: <Icon name="angle double right" /> }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
