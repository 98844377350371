import { useState } from "react";
import { Icon, Input } from "semantic-ui-react";
import Calendar from "react-calendar";
import { getDisplayDateFormat } from "../CalendarSelector/getAllDatesHelper";
import { CSSTransition } from "react-transition-group";
import { months } from "../../models/common";
import "./CalendarInput.css";
import "react-calendar/dist/Calendar.css";

const parseDate = (date: string | undefined) => {
    if (date) {
        return new Date(date);
    }

    return new Date();
};

interface IProps {
    date: string | undefined;
    handleDateChange: (e: any) => void;
    placeholder?: string;
    disabled?: boolean;
    detail?: string;
    clearable?: boolean;
    displayTime?: boolean;
}

export const CalendarInput = ({
    date,
    handleDateChange,
    placeholder,
    disabled,
    detail,
    clearable,
    displayTime,
}: IProps) => {
    const [showCalendar, setShowCalendar] = useState(false);

    return (
        <>
            {disabled ? (
                <p className="calendar-container">
                    {detail === "year"
                        ? (months[parseDate(date).getMonth()].text as string)
                        : !date || date === "01.01.0001."
                        ? ""
                        : getDisplayDateFormat(date, displayTime)}
                </p>
            ) : (
                <Input
                    disabled={disabled}
                    tabIndex={0}
                    className="calendar-input"
                    onBlur={(e: any) => {
                        if (e.relatedTarget === null) setShowCalendar(false);
                    }}
                >
                    <input
                        placeholder={placeholder}
                        readOnly
                        value={
                            detail === "year"
                                ? (months[parseDate(date).getMonth()].text as string)
                                : !date || date === "01.01.0001."
                                ? ""
                                : getDisplayDateFormat(date, displayTime)
                        }
                        className={`date-input ${showCalendar ? "calendar-open" : ""}`}
                        onClick={() => setShowCalendar(!showCalendar)}
                    />
                    {clearable && (
                        <Icon
                            link
                            name={date ? "delete" : "angle down"}
                            onClick={() => handleDateChange(undefined)}
                        />
                    )}
                    <CSSTransition
                        in={showCalendar}
                        timeout={300}
                        classNames="show-calendar"
                        unmountOnExit
                        onEnter={() => setShowCalendar(true)}
                        onExited={() => setShowCalendar(false)}
                    >
                        <Calendar
                            className="portal-calendar"
                            onChange={(e: any) => {
                                handleDateChange(e);
                                setShowCalendar(false);
                            }}
                            minDetail={detail as any}
                            maxDetail={detail as any}
                            nextLabel={<Icon name="angle right" />}
                            prevLabel={<Icon name="angle left" />}
                            next2Label={<Icon name="angle double right" />}
                            prev2Label={<Icon name="angle double left" />}
                        />
                    </CSSTransition>
                </Input>
            )}
        </>
    );
};
