import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Button, Modal, Input } from "semantic-ui-react";
import { toast } from "../..";
import { AppState } from "../../store/configureStore";
import { UserRole } from "../../actions/authentificationActions";
import { IInvoiceCategory } from "../../models/invoice";
import { deleteInvoiceCategory, editInvoiceCategory } from "../../services/invoicesService";

interface IProps {
    invoiceCategory: IInvoiceCategory;
    fetchInvoiceCategories: () => void;
}

export const InvoiceCategoryRow = ({ invoiceCategory, fetchInvoiceCategories }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [invoiceCategoryDTO, setInvoiceCategoryDTO] = useState(invoiceCategory);

    const handleDeleteInvoiceCategory = async () => {
        const res = await deleteInvoiceCategory(invoiceCategory.guid);
        if (!res.ok) {
            toast("Failed to delete invoice category", false);
        } else {
            fetchInvoiceCategories();
            toast("Successfully deleted invoice category", true);
        }
        setDeleteModalOpen(false);
    };

    const handleEditInvoiceCategory = async () => {
        const res = await editInvoiceCategory(invoiceCategoryDTO);
        if (!res.ok) {
            toast("Failed to update invoice category", false);
        } else {
            fetchInvoiceCategories();
            toast("Successfully updated invoice category", true);
        }
        setEditModalOpen(false);
    };

    const onRowClick = () => {
        setEditModalOpen(true);
    };

    return (
        <Fragment>
            <Table.Row onClick={onRowClick} className="clickable-row">
                <Table.Cell>{invoiceCategory.name}</Table.Cell>
                <Table.Cell>{invoiceCategory.count}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setDeleteModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this invoice category?</Modal.Header>
                <Modal.Actions>
                    <Button
                        color="red"
                        onClick={() => setDeleteModalOpen(false)}
                        content="Cancel"
                    />
                    <Button color="green" onClick={handleDeleteInvoiceCategory} content="Confirm" />
                </Modal.Actions>
            </Modal>
            <Modal
                size="mini"
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Edit invoice category...</Modal.Header>
                <Modal.Content>
                    <h3>Name</h3>
                    <Input
                        name="name"
                        defaultValue={invoiceCategory.name}
                        onChange={(e, data: any) =>
                            setInvoiceCategoryDTO({ ...invoiceCategoryDTO, name: data.value })
                        }
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button color="green" onClick={handleEditInvoiceCategory} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
