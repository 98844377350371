import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Radio, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { IRecipient } from "../../models/accessRight";
import { AppState } from "../../store/configureStore";
import { editRecipient } from "../../services/accessRightsService";
import { UserRole } from "../../actions/authentificationActions";
import { TextInput } from "../../components/TextInput/TextInput";

interface IProps {
    accessRightsGuid: string;
    recipient: IRecipient;
    setRecipient: Dispatch<SetStateAction<IRecipient | undefined>>;
}

export const EditRecipient = ({ accessRightsGuid, recipient, setRecipient }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const unauthorized = user.role !== UserRole.Admin;
    const [saving, setSaving] = useState(false);
    const [changed, setChanged] = useState(false);

    const handleUpdateRecipient = async () => {
        try {
            setSaving(true);
            await editRecipient(accessRightsGuid, recipient);
            toast("Successfully saved changes", true);
            history.push(`/company/access-rights/${accessRightsGuid}`);
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateRecipient}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    const handleInputChange = (e: any) =>
        setRecipient({ ...recipient!, [e.target.name]: e.target.value });

    useEffect(() => {
        return () => {};
    }, []);

    return (
        <React.Fragment>
            <Segment.Group>
                <Segment className="edit-header">
                    <h2>Edit recipient</h2>
                </Segment>
                <Segment>
                    <Table basic="very" celled compact>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Username</Table.Cell>
                                <Table.Cell>
                                    <TextInput
                                        name="userName"
                                        value={recipient.userName}
                                        handleInputChange={handleInputChange}
                                        disabled={unauthorized}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Is active</Table.Cell>
                                <Table.Cell>
                                    <Radio
                                        toggle
                                        checked={recipient.isActive}
                                        onClick={() => {
                                            setRecipient({
                                                ...recipient,
                                                isActive: !recipient.isActive,
                                            });
                                            setChanged(true);
                                        }}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Comment</Table.Cell>
                                <Table.Cell>
                                    <TextInput
                                        name="comment"
                                        value={recipient.comment}
                                        handleInputChange={handleInputChange}
                                        disabled={unauthorized || !changed}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Segment>
                <Segment className="edit-footer">
                    <SaveButton />
                </Segment>
            </Segment.Group>
        </React.Fragment>
    );
};
