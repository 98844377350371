import axios from "axios";
import { DropdownItemProps } from "semantic-ui-react";
import { IPaginationWrapper } from "../models/common";
import { INewsFeedFilters } from "../models/newsFeedItem";

const qs = require("qs");

export const getNewsFeedItems = async (filters: INewsFeedFilters) => {
    const params = new URLSearchParams({ page: filters.page.toString() });
    filters.projectGuid && params.append("projectGuid", filters.projectGuid);
    filters.userGuid && params.append("userGuid", filters.userGuid);
    filters.types?.length !== 0 && filters.types?.map(x => params.append("type", x.toString()));
    const response = await axios.get(`/api/newsFeed?${params.toString()}`, {
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: "repeat" });
        },
    });
    return response.data as IPaginationWrapper;
};

export const getProjectAsNewsFeedItems = async (page: number, projectGuid: string) => {
    const params = new URLSearchParams({ page: page.toString(), projectGuid: projectGuid });
    const response = await axios.get(`/api/newsFeed`, { params: params });
    return response.data as IPaginationWrapper;
};

export const getActionsList = async () => {
    const response = await axios.get("/api/newsFeed/list/");
    return response.data as DropdownItemProps[];
};

export const createComment = async (comment: string, projectGuid: string) => {
    const params = new URLSearchParams({ comment: comment.toString() });
    projectGuid && params.append("projectGuid", projectGuid);
    const response = await axios.post(`/api/newsFeed/comment?${params}`);
    return response;
};

export const getUserActivityForDate = async (userGuid: string, date: string, page: number) => {
    const response = await axios.get(`/api/newsFeed/${userGuid}`, {
        params: { date: date, page: page },
    });
    return response.data as IPaginationWrapper;
};
