import { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Loader, Select } from "semantic-ui-react";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { months } from "../../models/common";
import { IStaffMonthlySummary } from "../../models/staffMonthlySummary";
import {
    downloadMonthlyReport,
    getStaffMonthlySummary,
} from "../../services/workAttendancesService";
import { EmployeeMonthlyReportTable } from "./EmployeeMonthlyReportTable";
import { StudentsAndExternalsMonthlySummary } from "./StudentsAndExternalsMonthlySummary";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { getUser } from "../../api/users";
import "./StaffMonthlySummary.css";

const dateInit = new Date(new Date().setDate(1));
const employeeSummaryAuthRoles = ["company_admin", "admin", "invoice_input_operator", "employee"];
const studentandExternalSummaryAuthRoles = [
    "company_admin",
    "admin",
    "invoice_input_operator",
    "student",
    "external",
];

export const StaffMonthlySummary = () => {
    const user = useSelector((state: AppState) => state.user);
    const staff = useSelector((state: AppState) => state.staff);
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState<IStaffMonthlySummary>();
    const [date, setDate] = useState(dateInit);
    const displayDate = months[date.getMonth()].text + " " + date.getFullYear();
    const [userGuid, setUserGuid] = useState("");
    const [generating, setGenerating] = useState(false);
    const [userRole, setUserRole] = useState<UserData>();

    const fetchStaffMonthlySummary = useCallback(async () => {
        setLoading(true);
        const res = await getStaffMonthlySummary(date.getFullYear(), date.getMonth() + 1, userGuid);
        res.employeesSummary && setSummary(res);
        setLoading(false);
    }, [date, userGuid]);

    const getUserByGuid = useCallback(async () => {
        setLoading(true);
        const res = await getUser(userGuid);
        res && setUserRole(res);
        setLoading(false);
    }, [userGuid]);

    const downloadStaffMonthlyReport = async () => {
        try {
            setGenerating(true);
            const res = await downloadMonthlyReport(
                date.getFullYear(),
                date.getMonth() + 1,
                userGuid
            );
            if (!res) toast("Unable to generate monthly report", false);
        } catch {
            toast("Unable to generate monthly report", false);
        } finally {
            setGenerating(false);
        }
    };

    useEffect(() => {
        getUserByGuid();
    }, [userGuid]);

    useEffect(() => {
        fetchStaffMonthlySummary();
    }, [fetchStaffMonthlySummary]);

    return (
        <div className="monthly-report-container">
            <div className="container-header">
                <h1>Work attendances monthly report for {displayDate}</h1>
                <CalendarInput
                    date={getISODateFormat(date)}
                    handleDateChange={e => setDate(e)}
                    detail="year"
                />
                {user.role === UserRole.Admin && (
                    <Select
                        clearable
                        placeholder="Select user..."
                        selectOnBlur={false}
                        options={staff}
                        value={userGuid}
                        onChange={(e, data: any) => setUserGuid(data.value)}
                    />
                )}
                {user.role === UserRole.Admin && (
                    <Button
                        secondary
                        content="Generate monthly report"
                        icon="file excel outline"
                        onClick={downloadStaffMonthlyReport}
                        disabled={generating}
                        loading={generating}
                    />
                )}
            </div>
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : summary ? (
                <Fragment>
                    {employeeSummaryAuthRoles.includes(user.role!) && (
                        <Fragment>
                            <EmployeeMonthlyReportTable
                                title="Vacations"
                                rows={summary.employeesSummary.vacations}
                            />
                            <EmployeeMonthlyReportTable
                                title="Paid leaves"
                                rows={summary.employeesSummary.paidLeaves}
                            />
                            <EmployeeMonthlyReportTable
                                title="Sick leaves"
                                rows={summary.employeesSummary.sickLeaves}
                            />
                            <EmployeeMonthlyReportTable
                                title="Remote work"
                                rows={summary.employeesSummary.remoteWork}
                            />
                        </Fragment>
                    )}
                    {(user.role === UserRole.Student || user.role === UserRole.External) &&
                        studentandExternalSummaryAuthRoles.includes(user.role!) && (
                            <StudentsAndExternalsMonthlySummary
                                title={
                                    user.role === UserRole.Student
                                        ? "Student plans"
                                        : "External plans"
                                }
                                rows={summary.studentsSummary}
                            />
                        )}
                </Fragment>
            ) : null}
        </div>
    );
};
