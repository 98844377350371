import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import { ApproveStatus } from "../../models/common";
import { homepageDocumentType } from "../../models/document";
import { IHomepageInfo } from "../../models/homepageInfo";
import { AppState } from "../../store/configureStore";

interface IProps {
    homepageInfo: IHomepageInfo;
}

export const HomepageNotifications = ({ homepageInfo }: IProps) => {
    const {
        employeeDto,
        vacationRequests,
        sickLeaveRequests,
        remoteWorkRequests,
        studentPlanRequests,
        externalPlanRequests,
        paidLeaveRequests,
        unprocessedInvoices,
        yearlyVacationPlans,
        buyRequests,
        approvedBuyRequests,
        ideas,
        projectAccessRequests,
        clientCount,
        clientContractStatus,
        userContractsEnding,
    } = homepageInfo;
    const hidden =
        vacationRequests === 0 &&
        sickLeaveRequests === 0 &&
        remoteWorkRequests === 0 &&
        studentPlanRequests === 0 &&
        externalPlanRequests === 0 &&
        paidLeaveRequests === 0 &&
        unprocessedInvoices === 0 &&
        yearlyVacationPlans === 0 &&
        buyRequests === 0 &&
        approvedBuyRequests === 0 &&
        ideas === 0 &&
        projectAccessRequests === 0 &&
        clientCount === 0 &&
        userContractsEnding === 0;

    const user = useSelector((state: AppState) => state.user);
    const employeesList = employeeDto.employees.filter(x => !x.hasRequiredDocuments);

    const getUserContractsLink = (numberOfContracts: number) => {
        switch (numberOfContracts) {
            case 1:
                return `/administration/users/${homepageInfo.usersWhoseContractsAreEnding[0]?.userGuid}`;
            default:
                return "/administration/users";
        }
    };

    return hidden ? null : (
        <div className="homepage-requests">
            {vacationRequests > 0 && (
                <Segment as={Link} to="/employee-data/vacations">
                    <label>
                        <span className="green">
                            {vacationRequests} vacation request{vacationRequests > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {paidLeaveRequests > 0 && (
                <Segment as={Link} to="/employee-data/paid-leaves">
                    <label>
                        <span className="yellow">
                            {paidLeaveRequests} paid leave request{paidLeaveRequests > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {sickLeaveRequests > 0 && (
                <Segment as={Link} to="/employee-data/sick-leaves">
                    <label>
                        <span className="red">
                            {sickLeaveRequests} sick leave request{sickLeaveRequests > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {remoteWorkRequests > 0 && (
                <Segment as={Link} to="/employee-data/remote-work">
                    <label>
                        <span className="purple">
                            {remoteWorkRequests} remote work request{remoteWorkRequests > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {studentPlanRequests > 0 && (
                <Segment as={Link} to="/student-plans">
                    <label>
                        <span className="yellow">
                            {studentPlanRequests} student plan request
                            {studentPlanRequests > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {externalPlanRequests > 0 && (
                <Segment as={Link} to="/external-plans">
                    <label>
                        <span className="yellow">
                            {externalPlanRequests} external plan request
                            {externalPlanRequests > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {unprocessedInvoices > 0 && (
                <Segment
                    as={Link}
                    to={{
                        pathname: "/invoice-processing/invoices",
                        state: { fromDashboard: true, page: 1 },
                    }}
                >
                    <label>
                        <span className="yellow">
                            {unprocessedInvoices} unprocessed invoice
                            {unprocessedInvoices > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {yearlyVacationPlans > 0 && (
                <Segment as={Link} to="/employee-data/vacations">
                    <label>
                        <span className="green">
                            {yearlyVacationPlans} yearly vacation plan request
                            {yearlyVacationPlans > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {buyRequests > 0 && (
                <Segment as={Link} to="/requests/buy-requests">
                    <label>
                        <span className="green">
                            {buyRequests} buy request{buyRequests > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {approvedBuyRequests > 0 && (
                <Segment
                    as={Link}
                    to={{ pathname: "/requests/buy-requests", state: ApproveStatus.Approved }}
                >
                    <label>
                        <span className="red">
                            {approvedBuyRequests} approved buy request
                            {approvedBuyRequests > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {ideas > 0 && (
                <Segment as={Link} to="/requests/ideas">
                    <label>
                        <span className="green">
                            {ideas} idea{ideas > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {projectAccessRequests > 0 && (
                <Segment as={Link} to="/company/projects/access-requests">
                    <label>
                        <span className="green">
                            {projectAccessRequests} project access request
                            {projectAccessRequests > 1 && "s"}
                        </span>{" "}
                        pending
                    </label>
                </Segment>
            )}
            {user.role === UserRole.Admin && clientCount > 2 ? (
                <Segment as={Link} to={`/company/clients`}>
                    <label>
                        <span> Client contracts expiring. </span>{" "}
                        <span className="green">Click here to see more.</span>{" "}
                    </label>
                </Segment>
            ) : (
                clientContractStatus.map(x => (
                    <Segment as={Link} to={`/company/clients/${x.clientGuid}`}>
                        <label>
                            <span> {x.clientName} contract expiring. </span>{" "}
                            <span className="green">Click here to see more.</span>{" "}
                        </label>
                    </Segment>
                ))
            )}
            {employeesList.length > 2 ? (
                <Segment className="user-documents" as={Link} to={`/administration/users`}>
                    <label>
                        <span> User documents missing.</span>{" "}
                        <span className="green">Click here to see more</span>{" "}
                    </label>
                </Segment>
            ) : (
                employeesList.map(x => (
                    <Segment
                        className="user-documents"
                        as={Link}
                        to={`/administration/users/${x.userGuid}`}
                    >
                        <label>
                            <span className="green">
                                {x.userName} is missing {x.userDocuments.length === 2 && "a"}{" "}
                                required
                            </span>{" "}
                            document{x.userDocuments.length < 2 && "s "} <br /> ({" "}
                            {homepageDocumentType
                                .filter(d => !x.userDocuments.find(t => t.documentType === d.key))
                                .map((d, i) =>
                                    i !==
                                    homepageDocumentType.filter(
                                        d => !x.userDocuments.find(t => t.documentType === d.key)
                                    ).length -
                                        1 ? (
                                        <span>{d.value}, </span>
                                    ) : (
                                        <span>{d.value} </span>
                                    )
                                )}
                            )
                        </label>
                    </Segment>
                ))
            )}
            {userContractsEnding > 0 && (
                <Segment as={Link} to={getUserContractsLink(userContractsEnding)}>
                    <label>
                        <span className="red">
                            {userContractsEnding} user contract{userContractsEnding > 1 && "s"}
                        </span>{" "}
                        ending
                    </label>
                </Segment>
            )}
        </div>
    );
};
