import { useHistory } from "react-router-dom";
import { Button, Checkbox, Label, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { ApproveStatus } from "../../models/common";
import { getStatusColor, getStatusLabel } from "../../models/common";
import { IWorkingPlan } from "../../models/workingPlan";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { deleteWorkingPlanRequest, submitWorkingPlanRequest } from "../../api/workingPlans";

interface IProps {
    workingPlan: IWorkingPlan;
    selected: boolean;
    handleSelect: (sp: IWorkingPlan) => void;
    user: UserData;
    handleProcessRequest: (plans: IWorkingPlan[], choice: boolean) => void;
    handleRejectRemoteWork: (plans: IWorkingPlan[]) => void;
    fetchWorkingPlans: () => void;
    page: string;
    status: number;
    type: string;
}

export const WorkingPlanRow = ({
    workingPlan,
    selected,
    handleSelect,
    user,
    handleProcessRequest,
    handleRejectRemoteWork,
    fetchWorkingPlans,
    page,
    status,
    type,
}: IProps) => {
    const history = useHistory();

    const handleSendRequest = async () => {
        try {
            await submitWorkingPlanRequest(workingPlan.guid);
            toast("Working plan request successfully submitted", true);
            fetchWorkingPlans();
        } catch {
            toast("Unable to submit working plan request", false);
        }
    };

    const handleDeleteRequest = async () => {
        try {
            await deleteWorkingPlanRequest(workingPlan.guid);
            toast("Working plan request successfully deleted", true);
            fetchWorkingPlans();
        } catch {
            toast("Unable to delete working plan request", false);
        }
    };

    const onRowClick = () => {
        sessionStorage.setItem("prevStatus", status.toString());
        history.push({
            pathname: `/${type}/${workingPlan.guid}`,
            state: page,
        });
    };

    return (
        <Table.Row onClick={onRowClick} className="clickable-row">
            {user.role === UserRole.Admin && (
                <Table.Cell onClick={(e: any) => e.stopPropagation()} textAlign="center" collapsing>
                    <Checkbox toggle checked={selected} onClick={() => handleSelect(workingPlan)} />
                </Table.Cell>
            )}
            <Table.Cell>
                <Label
                    className="work-attendance-label"
                    size="large"
                    circular
                    color={getStatusColor(workingPlan.status)}
                    content={getStatusLabel(workingPlan.status)}
                />
            </Table.Cell>
            <Table.Cell>{workingPlan.user}</Table.Cell>
            <Table.Cell>{workingPlan.period}</Table.Cell>
            <Table.Cell>{workingPlan.totalHours}</Table.Cell>
            <Table.Cell>{getDisplayDateFormat(workingPlan.submitDate)}</Table.Cell>
            <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                {user.role === UserRole.Admin && status === -1 && (
                    <>
                        <Button.Group>
                            <Button
                                color="green"
                                content="Approve"
                                icon="calendar check"
                                onClick={() => handleProcessRequest([workingPlan], true)}
                                disabled={workingPlan.status === ApproveStatus.Approved}
                            />
                            <Button.Or />
                            <Button
                                color="red"
                                content="Reject"
                                icon="calendar times"
                                onClick={() => handleProcessRequest([workingPlan], false)}
                                disabled={workingPlan.status === ApproveStatus.Rejected}
                            />
                        </Button.Group>
                        <Button
                            color="yellow"
                            content="Reject remote work"
                            icon="calendar minus"
                            disabled={!workingPlan.hasRemoteDays}
                            onClick={() => handleRejectRemoteWork([workingPlan])}
                        />
                    </>
                )}
                {workingPlan.status === ApproveStatus.Requested &&
                user.role === UserRole.Admin &&
                status !== -1 ? (
                    <>
                        <Button.Group>
                            <Button
                                color="green"
                                content="Approve"
                                icon="calendar check"
                                onClick={() => handleProcessRequest([workingPlan], true)}
                            />
                            <Button.Or />
                            <Button
                                color="red"
                                content="Reject"
                                icon="calendar times"
                                onClick={() => handleProcessRequest([workingPlan], false)}
                            />
                        </Button.Group>
                        <Button
                            color="yellow"
                            content="Reject remote work"
                            icon="calendar minus"
                            disabled={!workingPlan.hasRemoteDays}
                            onClick={() => handleRejectRemoteWork([workingPlan])}
                        />
                    </>
                ) : workingPlan.status === ApproveStatus.Draft &&
                  (user.role === UserRole.Student || user.role === UserRole.External) ? (
                    <Button
                        color="green"
                        content="Send request"
                        icon="send"
                        onClick={handleSendRequest}
                    />
                ) : workingPlan.status === ApproveStatus.Approved &&
                  user.role === UserRole.Admin &&
                  status !== -1 ? (
                    <Button
                        color="red"
                        content="Reject"
                        icon="calendar times"
                        onClick={() => handleProcessRequest([workingPlan], false)}
                    />
                ) : workingPlan.status === ApproveStatus.Rejected &&
                  user.role === UserRole.Admin &&
                  status !== -1 ? (
                    <Button
                        color="green"
                        content="Approve"
                        icon="calendar check"
                        onClick={() => handleProcessRequest([workingPlan], true)}
                    />
                ) : null}
                <Button
                    color="red"
                    content="Delete"
                    disabled={
                        (workingPlan.status === ApproveStatus.Approved ||
                            workingPlan.status === ApproveStatus.Rejected) &&
                        (user.role === UserRole.Student || user.role === UserRole.External)
                    }
                    icon="close"
                    onClick={() => handleDeleteRequest()}
                />
            </Table.Cell>
        </Table.Row>
    );
};
