import React, { useState } from "react";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { serviceInit } from "../../models/service";
import { createService } from "../../api/services";
import { TextInput } from "../../components/TextInput/TextInput";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddService: () => void;
}

export const AddServiceForm = ({ open, closeModal, handleAddService }: IProps) => {
    const [newService, setNewService] = useState(serviceInit);

    const handleAddNewService = async () => {
        try {
            await createService(newService);
            handleAddService();
            toast("Service successfully added", true, 1500);
        } catch {
            toast("Error creating service", false);
        } finally {
            closeModal();
        }
    };

    return (
        <Modal open={open} onClose={closeModal} dimmer="blurring">
            <Modal.Header>
                <h2>Add new service</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-service-form">
                    <div className="input-container">
                        <label>Code</label>
                        <TextInput
                            name="code"
                            value={newService.code}
                            handleInputChange={(e: any) =>
                                setNewService({ ...newService, code: e.target.value })
                            }
                            disabled={false}
                        />
                    </div>
                </form>
                <form className="add-service-form">
                    <div className="input-container" id="add-service-textarea">
                        <label>Service description</label>
                        <TextInput
                            name="serviceDescription"
                            isTextArea={true}
                            value={newService.serviceDescription}
                            handleInputChange={(e: any) =>
                                setNewService({
                                    ...newService,
                                    serviceDescription: e.target.value,
                                })
                            }
                            disabled={false}
                        />
                    </div>
                </form>
                <form className="add-service-form">
                    <div className="input-container" id="add-service-unit">
                        <label>Unit measure</label>
                        <TextInput
                            name="unitMeasure"
                            value={newService.unitMeasure}
                            handleInputChange={(e: any) =>
                                setNewService({ ...newService, unitMeasure: e.target.value })
                            }
                            disabled={false}
                        />
                    </div>
                    <div className="input-container" id="add-service-unit">
                        <label>Quantity</label>
                        <TextInput
                            name="quantity"
                            value={newService.quantity}
                            handleInputChange={(e: any) =>
                                setNewService({ ...newService, quantity: e.target.value })
                            }
                            disabled={false}
                        />
                    </div>
                    <div className="input-container" id="add-service-unit">
                        <label>Net price</label>
                        <TextInput
                            name="netPrice"
                            value={newService.netPrice}
                            handleInputChange={(e: any) =>
                                setNewService({ ...newService, netPrice: e.target.value })
                            }
                            disabled={false}
                        />
                    </div>
                    <div className="input-container" id="add-service-unit">
                        <label>Discount</label>
                        <TextInput
                            name="discount"
                            value={newService.discount}
                            handleInputChange={(e: any) =>
                                setNewService({ ...newService, discount: e.target.value })
                            }
                            disabled={false}
                        />
                    </div>
                    <div className="input-container" id="add-service-unit">
                        <label>Tax rate</label>
                        <TextInput
                            name="taxRate"
                            value={newService.taxRate}
                            handleInputChange={(e: any) =>
                                setNewService({ ...newService, taxRate: e.target.value })
                            }
                            disabled={false}
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new service"
                    disabled={newService.serviceDescription === ""}
                    secondary
                    fluid
                    onClick={handleAddNewService}
                />
            </Modal.Actions>
        </Modal>
    );
};
