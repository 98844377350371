import ReactDOM from "react-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./containers/Login/PrivateRoute";
import { Login } from "./containers/Login/Login";
import { ForgotPassword } from "./containers/ForgotPassword/ForgotPassword";
import "semantic-ui-css/semantic.min.css";
import { SetToastMessage, ShowToastMessage, HideToastMessage } from "./actions/toastMessageActions";
import { ResetPassword } from "./containers/ResetPassword/ResetPassword";
import { ToastMessage } from "./components/ToastMessage/ToastMessage";
import { DocumentVerification } from "./containers/DocumentVerification/DocumentVerification";

const store = configureStore();

export const toast = (text: string, success: boolean, timeout: number = 5000) => {
    const dispatch = store.dispatch;
    dispatch(SetToastMessage(text, success));
    dispatch(ShowToastMessage());
    setTimeout(() => dispatch(HideToastMessage()), timeout);
};

ReactDOM.render(
    <Provider store={store}>
        <ToastMessage />
        <Router>
            <Switch>
                <Route exact={true} path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/document-verification/:guid" component={DocumentVerification} />
                <PrivateRoute exact={true} path="/" component={App} />
                <PrivateRoute component={App} />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
