import { useCallback, useState } from "react";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { createLocation } from "../../api/locations";
import { locationInit } from "../../models/location";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddLocation: () => void;
}

export const AddLocationForm = ({ open, closeModal, handleAddLocation }: IProps) => {
    const [newLocation, setNewLocation] = useState(locationInit);

    const handleAddNewLocation = async () => {
        try {
            await createLocation(newLocation);
            handleAddLocation();
            toast("Location successfully added", true, 1500);
        } catch {
            toast("Error creating location", false);
        } finally {
            closeModal();
        }
    };

    const handleEnterPress = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter" && newLocation.name !== "") {
                e.preventDefault();
                handleAddNewLocation();
            }
        },
        [handleAddNewLocation]
    );

    return (
        <Modal
            className="edit-location-modal"
            open={open}
            onClose={closeModal}
            dimmer="blurring"
            centered={false}
        >
            <Modal.Header>
                <h2>Add new location</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form>
                    <div className="input-container">
                        <label>Name</label>
                        <Input
                            className="location-name-input"
                            type="name"
                            value={newLocation.name}
                            onChange={(e: any) =>
                                setNewLocation({
                                    ...newLocation,
                                    name: e.target.value,
                                })
                            }
                            onKeyDown = {(e: React.KeyboardEvent<HTMLInputElement>) => handleEnterPress(e)}
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new location"
                    disabled={newLocation.name === ""}
                    secondary
                    fluid
                    onClick={handleAddNewLocation}
                />
            </Modal.Actions>
        </Modal>
    );
};
