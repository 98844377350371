import { useCallback, useEffect, useState } from "react";
import { Modal, Icon, Select, Button, DropdownItemProps, Input } from "semantic-ui-react";
import { toast } from "../..";
import { vendorContactInit } from "../../models/vendorContact";
import { getVendorsAsProps } from "../../api/vendors";
import { createVendorContact } from "../../api/vendorsContacts";

interface IProps {
    vendorGuid: string;
    open: boolean;
    closeModal: () => void;
    handleAddVendorContacts: () => void;
}

export const AddVendorContactsForm = ({
    vendorGuid,
    open,
    closeModal,
    handleAddVendorContacts,
}: IProps) => {
    const [newVendorContact, setNewVendorContact] = useState(vendorContactInit);
    const [vendors, setVendors] = useState<DropdownItemProps[]>([]);

    const fetchVendors = useCallback(async () => {
        const vendors = await getVendorsAsProps();
        Array.isArray(vendors) && setVendors(vendors);
    }, []);

    useEffect(() => {
        open && fetchVendors();
    }, [fetchVendors, open]);

    useEffect(() => {
        vendorGuid.length > 0 && setNewVendorContact(c => ({ ...c, vendorGuid: vendorGuid }));
    }, [vendorGuid]);

    const handleAddNewVendorContact = async () => {
        try {
            await createVendorContact(newVendorContact);
            handleAddVendorContacts();
            setNewVendorContact(vendorContactInit);
            toast("Vendor contact successfully added", true, 1500);
        } catch {
            toast("Error creating vendor contact", false, 1500);
        }
        closeModal();
    };

    return (
        <Modal open={open} onClose={closeModal} dimmer="blurring" centered={false}>
            <Modal.Header>
                <h2>Add new vendor contact</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-client-contact-form">
                    <div className="datetime-container" id="client-contact-container">
                        <div className="input-container">
                            <label>First name</label>
                            <Input
                                value={newVendorContact.firstName}
                                onChange={(e: any) =>
                                    setNewVendorContact({
                                        ...newVendorContact,
                                        firstName: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Last name</label>
                            <Input
                                value={newVendorContact.lastName}
                                onChange={(e: any) =>
                                    setNewVendorContact({
                                        ...newVendorContact,
                                        lastName: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Employer</label>
                            <Select
                                fluid
                                placeholder="Select employer..."
                                selectOnBlur={false}
                                options={vendors}
                                value={
                                    vendorGuid.length > 0
                                        ? vendorGuid
                                        : newVendorContact.vendorGuid!
                                }
                                disabled={vendorGuid.length > 0}
                                onChange={(e, data: any) =>
                                    setNewVendorContact({
                                        ...newVendorContact,
                                        vendorGuid: data.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Email</label>
                            <Input
                                value={newVendorContact.email}
                                onChange={(e: any) =>
                                    setNewVendorContact({
                                        ...newVendorContact,
                                        email: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Phone number</label>
                            <Input
                                value={newVendorContact.phoneNumber}
                                onChange={(e: any) =>
                                    setNewVendorContact({
                                        ...newVendorContact,
                                        phoneNumber: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new vendor contact"
                    secondary
                    fluid
                    onClick={handleAddNewVendorContact}
                    disabled={
                        newVendorContact.firstName === "" ||
                        newVendorContact.lastName === "" ||
                        (newVendorContact.vendorGuid === "" && vendorGuid === "")
                    }
                />
            </Modal.Actions>
        </Modal>
    );
};
