import { UserData } from "../actions/authentificationActions";

export interface IEducation {
    guid: string;
    user?: UserData;
    totalHours: number;
    userGuid?: string | undefined;
    title: string;
    description: string;
    date: string;
    certificateId?: string;
    certificates: any[],
    issuingInstitution: string;
};


export const educationInit: IEducation = {
    guid: "",
    totalHours: 0,
    title: "",
    description: "",
    date: "",
    certificates: [],
    issuingInstitution: ""
}