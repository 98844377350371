import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IBoardData, IBoardDataWrapper } from "../models/board";
import { CurrentWorkAttendance } from "../models/workAttendance";
import { IInvoiceDocument, IInvoiceDocumentsWrapper } from "../models/document";
import { IUploadedFiles } from "../models/invoice";
import { ISearchbarData } from "../models/searchbarData";
import { IPaginationWrapper } from "../models/common";

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string, config?: AxiosRequestConfig<any> | undefined) =>
        axios.get<T>(url, config).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
    patch: <T>(url: string) => axios.patch<T>(url).then(responseBody),
};

const SearchBar = {
    list: () => requests.get<ISearchbarData[]>("api/home/searchbar"),
};

const Board = {
    list: (isActive: boolean) =>
        requests.get<IBoardDataWrapper>(`api/board/GetAllNews/?isActive=${isActive}`),
    create: (board: IBoardData) => requests.post<void>("api/board/CreateBoardData", board),
    delete: (guid?: string) => requests.del<void>(`api/board/${guid}`),
    edit: (board: IBoardData) => requests.put<void>(`api/board`, board),
    getByGuid: (guid?: string) => requests.get<IBoardData>(`api/board/${guid}`),
    getUnreadBoardNews: async () => requests.get<void>("/api/board/unreadBoardNews"),
    markAsRead: async (boardGuid: string) => requests.put<void>(`/api/board/${boardGuid}`, {}),
    pinBoardNews: async (boardGuid: string) => requests.patch<void>(`/api/board/${boardGuid}`),
};

const Invoices = {
    upload: (files: FormData) =>
        requests.post<IInvoiceDocumentsWrapper>(`/api/invoices/upload`, files),
    create: (invoiceDocuments: IInvoiceDocument[], projectId: number) =>
        requests.post<IUploadedFiles[]>(`/api/invoices?projectId=${projectId}`, invoiceDocuments),
};
const WorkAttendance = {
    getByGuid: (guid: string) =>
        requests.get<CurrentWorkAttendance>(`/api/workAttendances/current/${guid}`),
};
const Documents = {
    UploadMultiDoc: (formData: FormData) =>
        requests.post<void>(`/api/documents/multiDoc`, formData),
};

const agent = {
    SearchBar,
    Board,
    Invoices,
    WorkAttendance,
    Documents,
};

export default agent;
