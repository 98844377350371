import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RefreshImage } from "../../actions/imageAction";
import { Button, Icon, Input, Loader, Popup, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { getMyCompany, updateCompany } from "../../api/companies";
import { putImage } from "../../services/imageService";
import { ICompany, newCompanyInit } from "../../models/company";
import { DocumentType } from "../../models/document";
import { getCroppedImg } from "../UsersDashboard/getCroppedImage";
import { ImageDropzone } from "../../components/FileDropzone/ImageDropzone";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import { AppState } from "../../store/configureStore";

export const GeneralSettings = () => {
    const dispatch = useDispatch();
    const staff = useSelector((state: AppState) => state.staff);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState<ICompany>(newCompanyInit);
    const [imageDropped, setImageDropped] = useState(false);
    const [imageCrop, setImageCrop] = useState(false);

    const fetchCompany = useCallback(async () => {
        try {
            setLoading(true);
            setCompany(await getMyCompany());
        } catch {
            toast("Unable to load company info", false);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompany({ ...company, [event.target.name]: event.target.value });
    };

    const handleSave = async () => {
        setSaving(true);
        if (imageDropped) {
            const img = await getCroppedImg(imageCrop, company.guid);
            const res = await putImage(company.guid, img, DocumentType.CompanyPhoto);
            if (res.ok) {
                dispatch(RefreshImage(company.guid));
            } else toast("Failed to update company image, please try again", false);
        }

        try {
            await updateCompany(company);
            toast("Changes saved successfully", true);
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    const SaveButton = () => {
        return (
            <Button
                secondary
                content="Save changes"
                icon="save"
                disabled={saving}
                loading={saving}
                onClick={handleSave}
            />
        );
    };

    return (
        <>
            <div className="container-header">
                <h1>General settings</h1>
                {!loading && <SaveButton />}
            </div>
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <Segment.Group>
                    <Segment />
                    <Segment id="general-settings">
                        <Table basic="very" celled compact>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>Company name</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="name"
                                            value={company.name ?? ""}
                                            onChange={handleTextChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>VAT number</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="vat"
                                            value={company.vat ?? ""}
                                            onChange={handleTextChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>HQ address</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="hqAddress"
                                            value={company.hqAddress ?? ""}
                                            onChange={handleTextChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Country</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="country"
                                            value={company.country ?? ""}
                                            onChange={handleTextChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>City</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="city"
                                            value={company.city ?? ""}
                                            onChange={handleTextChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Zip code</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="zipCode"
                                            value={company.zipCode ?? ""}
                                            onChange={handleTextChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Email</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="email"
                                            value={company.email ?? ""}
                                            onChange={handleTextChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>IBAN</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="invoiceIBAN"
                                            value={company.invoiceIBAN ?? ""}
                                            onChange={handleTextChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Tax rate (%)</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="invoiceTaxRate"
                                            value={company.invoiceTaxRate ?? ""}
                                            onChange={handleTextChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Invoice comment{" "}
                                        <Popup
                                            content={"These appear at the bottom of every invoice"}
                                            trigger={
                                                <Icon
                                                    size="large"
                                                    name="info circle"
                                                    color="grey"
                                                />
                                            }
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="invoiceComment"
                                            value={company.invoiceComment ?? ""}
                                            onChange={handleTextChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Main liquidator</Table.Cell>
                                    <Table.Cell>
                                        <DropdownInput
                                            placeholder="Select main liquidator"
                                            value={company.invoiceLiquidatorGuid}
                                            options={staff}
                                            onChange={(e, data: any) =>
                                                setCompany({
                                                    ...company,
                                                    invoiceLiquidatorGuid: data.value,
                                                })
                                            }
                                            clearable
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Company logo</Table.Cell>
                                    <Table.Cell>
                                        <ImageDropzone
                                            guid={company.guid}
                                            onDrop={() => setImageDropped(true)}
                                            crop={imageCrop}
                                            setCrop={setImageCrop}
                                            documentType={DocumentType.CompanyPhoto}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Segment>
                </Segment.Group>
            )}
        </>
    );
};
