import { DropdownItemProps } from "semantic-ui-react";

export interface IPaginationWrapper {
    totalRows: number;
    rows: any[];
}

export const years: DropdownItemProps[] = [2019, 2020, 2021].map(x => {
    return { key: x, text: x, value: x };
});

export const daysOfWeek = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

export const months: DropdownItemProps[] = [
    { key: 1, text: "January", value: 1 },
    { key: 2, text: "February", value: 2 },
    { key: 3, text: "March", value: 3 },
    { key: 4, text: "April", value: 4 },
    { key: 5, text: "May", value: 5 },
    { key: 6, text: "June", value: 6 },
    { key: 7, text: "July", value: 7 },
    { key: 8, text: "August", value: 8 },
    { key: 9, text: "September", value: 9 },
    { key: 10, text: "October", value: 10 },
    { key: 11, text: "November", value: 11 },
    { key: 12, text: "December", value: 12 },
];

export const getDayOfWeek = (dateString: string) => {
    return new Date(dateString).toLocaleString("en-us", {
        weekday: "long",
    });
};

export enum ApproveStatus {
    All = -1,
    Rejected,
    Approved,
    Draft,
    UserCancelled,
    Requested,
    InReview,
    Postponed,
    Completed,
}

export const commonStatusOptions = [
    { key: 4, label: "Requested" },
    { key: 1, label: "Approved" },
    { key: 0, label: "Rejected" },
];

export const getStatusLabel = (status: ApproveStatus) => {
    switch (status) {
        case ApproveStatus.Draft:
            return "Draft";
        case ApproveStatus.UserCancelled:
            return "User cancelled";
        case ApproveStatus.InReview:
            return "In review";
        case ApproveStatus.Approved:
            return "Approved";
        case ApproveStatus.Requested:
            return "Requested";
        case ApproveStatus.Rejected:
            return "Rejected";
        case ApproveStatus.Completed:
            return "Completed";
        default:
            return status;
    }
};

export const getStatusColor = (status: ApproveStatus) => {
    switch (status) {
        case ApproveStatus.Approved:
            return "green";
        case ApproveStatus.Requested:
            return "yellow";
        case ApproveStatus.Rejected:
            return "red";
        case ApproveStatus.Draft:
            return "purple";
        case ApproveStatus.UserCancelled:
            return "grey";
        case ApproveStatus.Completed:
            return "olive";
        default:
            return "grey";
    }
};

export const formatDisplayDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("default", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};

export enum ContractType {
    OpenEnded = 0,
    Temporary = 1,
}
