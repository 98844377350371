import { useCallback, useEffect, useState } from "react";
import { Icon, Loader, Pagination, Segment, Table, Button } from "semantic-ui-react";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { IClientContact } from "../../models/clientContact";
import { getClientContacts } from "../../services/clientContactService";
import { ClientContactRow } from "./ClientContactRow";
import { AddClientContactsForm } from "./AddClientContactForm";
import { useLocation } from "react-router-dom";
import { UserRole } from "../../actions/authentificationActions";
import "./ClientContacts.css";

interface IProps {
    clientGuid: string | undefined;
}

export const ClientContacts = ({ clientGuid }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const loc = useLocation();
    const [loading, setLoading] = useState(true);
    const [clientContacts, setClientContacts] = useState<IClientContact[]>([]);
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [totalRows, setTotalRows] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const fetchClientContacts = useCallback(async () => {
        setLoading(true);
        setClientContacts([]);
        const res = await getClientContacts(page, clientGuid ? clientGuid : "");
        setTotalRows(res.totalRows);
        setClientContacts(res.rows as IClientContact[]);
        setLoading(false);
    }, [page, clientGuid]);

    useEffect(() => {
        fetchClientContacts();
    }, [fetchClientContacts]);

    const handleFetchClientContacts = () => {
        setPage(1);
        fetchClientContacts();
    };

    return (
        <div className="client-contacts-container">
            <div className="container-header">
                <h1>Client contacts</h1>
                {user.role === UserRole.Admin && (
                    <Button
                        secondary
                        content="Add new client contact"
                        icon="plus"
                        onClick={() => setModalOpen(true)}
                    />
                )}
            </div>
            <div className="client-contacts-display">
                <div className="client-contacts-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment />
                            <Segment className="table-container">
                                <Table basic="very" columns={3}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Employer</Table.HeaderCell>
                                            {user.role === UserRole.Admin && (
                                                <Table.HeaderCell>Actions</Table.HeaderCell>
                                            )}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {clientContacts.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="5">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            clientContacts.map(x => (
                                                <ClientContactRow
                                                    key={x.guid}
                                                    clientContact={x}
                                                    handleRemoveClientContact={
                                                        handleFetchClientContacts
                                                    }
                                                    page={page.toString()}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 0 && (
                                    <div className="client-contacts-pagination">
                                        {totalRows > 10 && (
                                            <Pagination
                                                size="small"
                                                activePage={page}
                                                onPageChange={(e, data: any) =>
                                                    setPage(+data.activePage)
                                                }
                                                ellipsisItem={{
                                                    content: <Icon name="ellipsis horizontal" />,
                                                }}
                                                firstItem={{
                                                    content: <Icon name="angle double left" />,
                                                }}
                                                lastItem={{
                                                    content: <Icon name="angle double right" />,
                                                }}
                                                prevItem={{ content: <Icon name="angle left" /> }}
                                                nextItem={{ content: <Icon name="angle right" /> }}
                                                totalPages={Math.ceil(totalRows / 10)}
                                            />
                                        )}
                                    </div>
                                )}
                            </Segment>
                            <AddClientContactsForm
                                clientGuid={clientGuid ? clientGuid : ""}
                                open={modalOpen}
                                closeModal={() => setModalOpen(false)}
                                handleAddClientContacts={handleFetchClientContacts}
                            />
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
