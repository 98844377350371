import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
    Button,
    DropdownItemProps,
    Icon,
    Label,
    Loader,
    Radio,
    Segment,
    Select,
    TextArea,
} from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { CalendarSelector } from "../../components/CalendarSelector/CalendarSelector";
import { getUserVacation } from "../../api/users";
import { getUsers } from "../../api/users";
import {
    createWorkAttendance,
    getUserPreviousWorkAttendanceDates,
} from "../../services/workAttendancesService";
import { AppState } from "../../store/configureStore";
import {
    findTenSuccessiveDays,
    getDaysInBetween,
    getPreselectedDates,
    getSelectedDates,
} from "../../components/CalendarSelector/getAllDatesHelper";
import { WorkAttendance } from "../../models/workAttendance";

export const getAttendanceTypeEnum = (type: string) => {
    switch (type) {
        case "vacations":
            return 1;
        case "sick-leaves":
            return 2;
        case "remote-work":
            return 3;
        case "paid-leaves":
            return 5;
        case "parental-leaves":
            return 6;
        case "yearly-vacation-plan":
            return 7;
        default:
            return 0;
    }
};

export const CreateWorkAttendance = () => {
    const loc = useLocation<string>();
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const type = history.location.pathname.split("/")[2];
    const [dates, setDates] = useState<string[]>([]);
    const [datesWithWeekendsAndHolidays, setDatesWithWeekendsAndHolidays] = useState<string[]>([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(false);
    const [previousSameType, setPreviousSameType] = useState<string[]>([]);
    const [previousOtherTypes, setPreviousOtherTypes] = useState<string[]>([]);
    const [holidays, setHolidays] = useState<string[]>([]);
    const [accountable, setAccountable] = useState(false);
    const [employeeGuid, setEmployeeGuid] = useState<string>(loc.state ?? "");
    const [allEmployeesGuids, setAllEmployeesGuids] = useState<string[]>([]);
    const [employees, setEmployees] = useState<DropdownItemProps[]>([]);
    const [reasonText, setReasonText] = useState<string>();
    const [vacationDaysLeft, setVacationDaysLeft] = useState<number>(0);
    const [firstPickDate, setFirstPickDate] = useState<string>("");
    const [preselectionDates, setPreselectionDates] = useState<string[]>([]);
    const [tenDaysInRow, setTenDaysInRow] = useState(false);

    const handleDates = (d: string) => {
        const { pickedDates, pickedDatesWithWeekendsAndHolidays } = getSelectedDates(
            firstPickDate,
            d,
            holidays,
            previousSameType,
            previousOtherTypes,
            setFirstPickDate
        );

        getAttendanceTypeEnum(type) === WorkAttendance.YearlyVacationPlan
            ? firstPickDate &&
              pickedDates.map(x =>
                  dates.includes(x)
                      ? setDates(dates.filter(d => !pickedDates.includes(d)))
                      : setDates(dates.concat(pickedDates))
              )
            : setDates(pickedDates);
        getAttendanceTypeEnum(type) === WorkAttendance.YearlyVacationPlan &&
            firstPickDate &&
            pickedDatesWithWeekendsAndHolidays.map(x =>
                datesWithWeekendsAndHolidays.includes(x)
                    ? setDatesWithWeekendsAndHolidays(
                          datesWithWeekendsAndHolidays.filter(
                              d => !pickedDatesWithWeekendsAndHolidays.includes(d)
                          )
                      )
                    : setDatesWithWeekendsAndHolidays(
                          datesWithWeekendsAndHolidays.concat(pickedDatesWithWeekendsAndHolidays)
                      )
            );
    };

    const handlePreselectionDates = (d: string) => {
        const preselectedDates = getPreselectedDates(firstPickDate, d, holidays);
        setPreselectionDates(preselectedDates.concat(preselectedDates));
    };

    const fetchPreviousWorkAttendanceDates = useCallback(async () => {
        setLoading(true);
        let res;
        if (type === "parental-leaves" || (type === "vacations" && user.role === UserRole.Admin)) {
            res = await getUserPreviousWorkAttendanceDates(
                getAttendanceTypeEnum(type),
                "",
                employeeGuid
            );
        } else {
            res = await getUserPreviousWorkAttendanceDates(getAttendanceTypeEnum(type));
        }
        if (res.previousSameType) {
            setPreviousSameType(res.previousSameType);
            setPreviousOtherTypes(res.previousOtherTypes);
            setHolidays(res.holidays);
            setDates(res.currentDates);
        }
        setLoading(false);
    }, [type, employeeGuid]);

    const handleCreateWorkAttendance = async () => {
        setLoading(true);
        const res = await createWorkAttendance(
            getAttendanceTypeEnum(type),
            dates,
            accountable,
            employeeGuid,
            reasonText ?? "",
            allEmployeesGuids
        );
        if (!res.ok) toast("Error creating new work attendance", false);
        else {
            toast(
                type === "yearly-vacation-plan"
                    ? "Successfully created yearly vaction plan"
                    : "Successfully created new work attendance",
                true
            );
            type === "yearly-vacation-plan"
                ? history.push("/employee-data/vacations")
                : history.push("/employee-data/" + type);
        }
        setLoading(false);
    };

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        const res = await getUsers();
        const e: DropdownItemProps[] = [];
        res.forEach(u => {
            if (u.role === UserRole.Employee)
                e.push({ key: u.guid, text: u.firstName + " " + u.lastName, value: u.guid });
        });
        setEmployees(e);
        setLoading(false);
    }, []);

    const fetchVacationDaysLeft = useCallback(async () => {
        setLoading(true);
        const res = await getUserVacation(employeeGuid !== "" ? employeeGuid : user.guid!);
        setVacationDaysLeft(res.daysLeft);
        setLoading(false);
    }, [user.guid, employeeGuid]);

    useEffect(() => {
        fetchUsers();
        fetchPreviousWorkAttendanceDates();
    }, [fetchPreviousWorkAttendanceDates, fetchUsers]);

    useEffect(() => {
        getAttendanceTypeEnum(type) === WorkAttendance.YearlyVacationPlan &&
            findTenSuccessiveDays(
                datesWithWeekendsAndHolidays,
                holidays,
                tenDaysInRow,
                setTenDaysInRow
            );
    }, [datesWithWeekendsAndHolidays]);

    useEffect(() => {
        if (type === "vacations" || type === "yearly-vacation-plan") {
            fetchVacationDaysLeft();
        }
    }, [type, employeeGuid, fetchVacationDaysLeft]);

    return (
        <div>
            {(type === "parental-leaves" || type === "vacations") && user.role === UserRole.Admin && (
                <div className="work-attendance-parental-leave-container">
                    <label className="work-attendance-parental-leave-label">
                        <b>Employee:</b>
                    </label>
                    <Select
                        placeholder="Choose employee"
                        selectOnBlur={false}
                        clearable
                        value={employeeGuid}
                        options={employees}
                        onChange={(e, data: any) => {
                            setAllEmployeesGuids([]);
                            setEmployeeGuid(data.value);
                        }}
                    />
                    {getAttendanceTypeEnum(type) === WorkAttendance.Vacation && (
                        <Button
                            className="create-work-attendance-button"
                            secondary
                            content={
                                allEmployeesGuids.length === 0
                                    ? "Select all employees"
                                    : "All employees selected"
                            }
                            icon={allEmployeesGuids.length !== 0 && "check"}
                            onClick={(e, data) => {
                                setEmployeeGuid("");
                                allEmployeesGuids.length === employees.length
                                    ? setAllEmployeesGuids([])
                                    : setAllEmployeesGuids(employees.map(x => x.value as string));
                            }}
                        />
                    )}
                </div>
            )}
            <div>
                {getAttendanceTypeEnum(type) === WorkAttendance.YearlyVacationPlan &&
                    user.role === UserRole.Employee && (
                        <Label className="yearly-vacation-plan-label" size="large" color="red">
                            Your yearly vacation plan must contain 10 continuous days of vacation.
                            The vacation you take may differ from the plan created here.
                        </Label>
                    )}
            </div>
            <div className="create-plan-header">
                <Icon size="big" name="angle left" onClick={() => setYear(year - 1)} />
                <h1>{year}</h1>
                <Icon size="big" name="angle right" onClick={() => setYear(year + 1)} />
            </div>
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <Fragment>
                    <CalendarSelector
                        year={year}
                        selectedDates={dates}
                        handleDates={handleDates}
                        previousSameType={previousSameType}
                        previousOtherTypes={previousOtherTypes}
                        holidays={holidays}
                        enableEdit={true}
                        type={type}
                        preselectionDates={preselectionDates}
                        handlePreselectionDates={handlePreselectionDates}
                    />
                    {type === "paid-leaves" && (
                        <Radio
                            className="accountable-toggle"
                            toggle
                            label="Is accountable"
                            checked={accountable}
                            onClick={() => setAccountable(!accountable)}
                        />
                    )}
                    {type === "remote-work" && (
                        <div className="input-container">
                            <Label id="big-red-label" color="red">
                                (Align with your manager at least one business day in advance before
                                requesting remote work)
                            </Label>
                            <label>Reason*</label>
                            <TextArea
                                rows={5}
                                name="text"
                                onChange={(e, data: any) => {
                                    setReasonText(data.value);
                                }}
                            />
                        </div>
                    )}
                    {(type === "vacations" || type === "yearly-vacation-plan") && (
                        <Segment>
                            <h4>Number of vacation days selected: {dates.length}</h4>
                            <h4>Number of vacation days left: {vacationDaysLeft}</h4>
                        </Segment>
                    )}
                    <Button
                        fluid
                        secondary
                        content={
                            type === "yearly-vacation-plan"
                                ? "Create yearly vacation plan"
                                : "Create work attendance"
                        }
                        icon="calendar plus"
                        onClick={() => {
                            handleCreateWorkAttendance();
                        }}
                        loading={loading}
                        disabled={
                            (getAttendanceTypeEnum(type) === WorkAttendance.YearlyVacationPlan &&
                                !tenDaysInRow) ||
                            (dates.length === 0 && user.role !== UserRole.Admin) ||
                            (type === "remote-work" &&
                                (reasonText?.length === 0 || reasonText === undefined)) ||
                            loading ||
                            (type === "parental-leaves" && employeeGuid === "") ||
                            ((type === "vacations" || type === "yearly-vacation-plan") &&
                                dates.length > vacationDaysLeft &&
                                user.role !== UserRole.Admin)
                        }
                    />
                </Fragment>
            )}
        </div>
    );
};
