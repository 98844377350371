import axios from "axios";
import { DropdownItemProps } from "semantic-ui-react";
import {
    INewUser,
    IUserPoints,
    UserData,
    UserVacationDetails,
    UserVacations,
} from "../actions/authentificationActions";
import { IPaginationWrapper } from "../models/common";

export const getUsers = async () => {
    const response = await axios.get("/users");
    return response.data as UserData[];
};

export const getUser = async (guid: string) => {
    const response = await axios.get(`/users/${guid}`);
    return response.data as UserData;
};

export const createUser = async (userDto: INewUser) => {
    const response = await axios.post("/users", userDto);
    return response;
};

export const updateUser = async (userDto: UserData) => {
    const response = await axios.put(`/users/${userDto.guid}`, userDto);
    return response;
};

export const getUserVacation = async (guid: string) => {
    const response = await axios.get(`/users/vacations/${guid}`);
    return response.data as UserVacationDetails;
};

export const updateUserVacations = async (guid: string, vacations: UserVacations[]) => {
    const response = await axios.put(`/users/vacations/update/${guid}`, vacations);
    return response;
};

export const getStaff = async () => {
    const response = await axios.get("/users/staff");
    return response.data as DropdownItemProps[];
};

export const getContacts = async (page: number, search: string) => {
    const params = new URLSearchParams({ page: page + "" });
    search && params.append("search", search);
    const response = await axios.get(`/users/contacts?${params}`);
    return response.data as IPaginationWrapper;
};

export const deleteUser = async (guid: string = "") => {
    const response = await axios.delete(`/users/delete/${guid}`);
    return response;
};

export const changePoints = async (guid: string, pointsType: boolean) => {
    const response = await axios.put(`/users/points/${guid}`, null, {
        params: { pointsType: pointsType },
    });
    return response;
};

export const updatePoints = async (users: IUserPoints[]) => {
    const response = await axios.put(`/users/updatePoints`, users);
    return response;
};


