import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Loader } from "semantic-ui-react";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { getUsers } from "../../api/users";
import { AppState } from "../../store/configureStore";
import { AddUserForm } from "./AddUserForm";
import { UsersTable } from "./UsersTable";
import { SuperAdminUsersTable } from "./SuperAdminUsersTable";
import "./UsersDashboard.css";

export const UsersDashboard = () => {
    const user = useSelector((state: AppState) => state.user);
    const [employees, setEmployees] = useState<UserData[]>([]);
    const [students, setStudents] = useState<UserData[]>([]);
    const [others, setOthers] = useState<UserData[]>([]);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const fetchUsers = useCallback(async () => {
        setEmployees([]);
        setStudents([]);
        setOthers([]);
        setLoading(true);
        const res = await getUsers();
        const e: UserData[] = [];
        const s: UserData[] = [];
        const o: UserData[] = [];
        res.forEach(u => {
            if (u.role === UserRole.Employee) e.push(u);
            else if (u.role === UserRole.Student) s.push(u);
            else o.push(u);
        });
        setEmployees(e);
        setStudents(s);
        setOthers(o);
        setLoading(false);
    }, []);

    const StartHandleDeleteUser = () => {
        fetchUsers();
    };
    const handleAddUser = (newUser: UserData) => {
        switch (newUser.role) {
            case UserRole.Employee:
                setEmployees(employees.concat(newUser));
                break;
            case UserRole.Student:
                setStudents(students.concat(newUser));
                break;
            default:
                setOthers(others.concat(newUser));
                break;
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return user.role !== UserRole.Admin && user.role !== UserRole.SuperAdmin ? (
        <Redirect to="/unauthorized" />
    ) : loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="users-dashboard-container">
            <div className="container-header">
                <h1>List of users</h1>
                <Button
                    secondary
                    content="Add new user"
                    icon="add user"
                    onClick={() => setModalOpen(true)}
                />
            </div>
            {user.role === UserRole.Admin ? (
                <UsersTable
                    title="Employees"
                    users={employees}
                    handleDeleteUser={StartHandleDeleteUser}
                />
            ) : (
                <SuperAdminUsersTable
                    title="Employees"
                    users={employees}
                    fetchUsers={StartHandleDeleteUser}
                />
            )}
            {user.role === UserRole.Admin ? (
                <UsersTable
                    title="Students"
                    users={students}
                    handleDeleteUser={StartHandleDeleteUser}
                />
            ) : (
                <SuperAdminUsersTable
                    title="Students"
                    users={students}
                    fetchUsers={StartHandleDeleteUser}
                />
            )}
            {user.role === UserRole.Admin ? (
                <UsersTable
                    title="Others"
                    users={others}
                    handleDeleteUser={StartHandleDeleteUser}
                />
            ) : (
                <SuperAdminUsersTable
                    title="Others"
                    users={others}
                    fetchUsers={StartHandleDeleteUser}
                />
            )}
            <AddUserForm
                open={modalOpen}
                closeModal={() => setModalOpen(false)}
                handleAddUser={handleAddUser}
            />
        </div>
    );
};
