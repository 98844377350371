import { useCallback, useEffect, useState } from "react";
import { Icon, Loader, Pagination, Segment, Table, Button, Input } from "semantic-ui-react";
import { IProject, projectOptions } from "../../models/project";
import { getProjects } from "../../api/projects";
import { ProjectRow } from "./ProjectRow";
import { AddProjectForm } from "./AddProjectForm";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { UserRole } from "../../actions/authentificationActions";
import "./Projects.css";

export const ProjectsTable = () => {
    const user = useSelector((state: AppState) => state.user);
    const loc = useLocation();
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState<IProject[]>([]);
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [totalRows, setTotalRows] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [status, setStatus] = useState(user.role === UserRole.Admin ? 0 : 1);

    const fetchProjects = useCallback(async () => {
        const res = await getProjects(page, status, query);
        if (res.rows) {
            setProjects(res.rows as IProject[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page, query, status]);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    const handleFetchProjects = () => {
        setPage(1);
        fetchProjects();
    };

    return (
        <div className="projects-container">
            <div className="container-header">
                <h1>Projects</h1>
                {user.role === UserRole.Admin && (
                    <Button
                        secondary
                        content="Add new project"
                        icon="plus"
                        onClick={() => setModalOpen(true)}
                    />
                )}
            </div>
            <div className="projects-display">
                <div className="projects-table">
                    <Segment.Group>
                        <Segment className="projects-options-container">
                            <Input
                                placeholder="Search..."
                                value={query}
                                onChange={e => setQuery(e.target.value)}
                            />
                            <div className="project-options">
                                {user.role !== UserRole.Admin &&
                                    projectOptions.map(x => (
                                        <span
                                            className={status === x.key ? "selected" : ""}
                                            key={x.key}
                                            onClick={() => setStatus(x.key)}
                                        >
                                            {x.label}
                                        </span>
                                    ))}
                            </div>
                        </Segment>
                        <Segment className="table-container">
                            {loading ? (
                                <Loader active inline="centered" size="huge" />
                            ) : (
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Photo</Table.HeaderCell>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Project client</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                No. of project members
                                            </Table.HeaderCell>
                                            {(status !== 1 || user.role === UserRole.Admin) && (
                                                <Table.HeaderCell>Actions</Table.HeaderCell>
                                            )}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {projects.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="5">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            projects.map(x => (
                                                <ProjectRow
                                                    key={x.guid}
                                                    project={x}
                                                    handleRemoveProject={handleFetchProjects}
                                                    page={page + ""}
                                                    status={status}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            )}
                        </Segment>
                        <Segment>
                            {totalRows > 10 && (
                                <Pagination
                                    size="small"
                                    activePage={page}
                                    onPageChange={(e, data: any) => setPage(+data.activePage)}
                                    ellipsisItem={{
                                        content: <Icon name="ellipsis horizontal" />,
                                    }}
                                    firstItem={{
                                        content: <Icon name="angle double left" />,
                                    }}
                                    lastItem={{
                                        content: <Icon name="angle double right" />,
                                    }}
                                    prevItem={{ content: <Icon name="angle left" /> }}
                                    nextItem={{ content: <Icon name="angle right" /> }}
                                    totalPages={Math.ceil(totalRows / 10)}
                                />
                            )}
                        </Segment>
                        <AddProjectForm
                            open={modalOpen}
                            closeModal={() => setModalOpen(false)}
                            handleAddProject={handleFetchProjects}
                        />
                    </Segment.Group>
                </div>
            </div>
        </div>
    );
};
