import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, DropdownItemProps, Loader, Segment, Table } from "semantic-ui-react";
import { toast } from "../../..";
import { UserRole } from "../../../actions/authentificationActions";
import { IProject } from "../../../models/project";
import {
    getProjectRolesByProject,
    getProjectUsersAsProps,
    getProjetRolesAsProps,
    updateRoleAssignees,
} from "../../../api/projects";
import { AppState } from "../../../store/configureStore";
import { IProjectProjectRole } from "../../../models/projectRole";
import { ProjectRolesTableRow } from "./ProjectRolesTableRow";
import { AddProjectRoleForm } from "./AddProjectRoleForm";

interface IProps {
    project: IProject;
}

export const ProjectRolesTable = ({ project }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [loading, setLoading] = useState(false);
    const [projectRoles, setProjectRoles] = useState<IProjectProjectRole[]>([]);
    const [newProjectRoleModalOpen, setNewProjectRoleModalOpen] = useState(false);
    const [projectRolesOptions, setProjectRolesOptions] = useState<DropdownItemProps[]>([]);
    const [assigneesOptions, setAssigneesOptions] = useState<DropdownItemProps[]>([]);

    const fetchProjectRoles = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getProjectRolesByProject(project.guid);
            setProjectRoles(res);
        } catch {
            toast("Could not fetch project roles", false);
        } finally {
            setLoading(false);
        }
    }, [project.guid]);

    const fetchProjectRolesOptions = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getProjetRolesAsProps();
            setProjectRolesOptions(res);
        } catch {
            toast("Could not fetch project roles options", false);
        } finally {
            setLoading(false);
        }
    }, [project.guid]);

    const fetchAssigneesOptions = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getProjectUsersAsProps(project.guid);
            setAssigneesOptions(res);
        } catch {
            toast("Could not fetch assignee options", false);
        } finally {
            setLoading(false);
        }
    }, [project.guid]);

    const saveChanges = async () => {
        setLoading(true);
        try {
            await updateRoleAssignees(projectRoles);
            toast("Assignee changes successfully saved", true);
            fetchProjectRoles();
        } catch {
            toast("Could not save assignee changes", false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProjectRoles();
    }, [fetchProjectRoles]);

    useEffect(() => {
        fetchProjectRolesOptions();
    }, [fetchProjectRolesOptions]);

    useEffect(() => {
        fetchAssigneesOptions();
    }, [fetchAssigneesOptions]);

    return (
        <>
            <Segment.Group>
                <Segment>
                    <h2>{user.role === UserRole.Admin ? "Edit project roles" : "Project roles"}</h2>
                    {user.role === UserRole.Admin && (
                        <div>
                            <Button
                                secondary
                                content="Save changes"
                                icon="save"
                                onClick={saveChanges}
                            />
                            <Button
                                secondary
                                content="Add role"
                                icon="add"
                                onClick={() => setNewProjectRoleModalOpen(true)}
                            />
                        </div>
                    )}
                </Segment>
                <Segment>
                    <div className="project-role-details-container">
                        {projectRoles.length > 0 ? (
                            <>
                                {loading ? (
                                    <Loader active inline="centered" size="huge" />
                                ) : (
                                    <Table basic="very">
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Name</Table.HeaderCell>
                                                <Table.HeaderCell>Assignees</Table.HeaderCell>
                                                <Table.HeaderCell>Description</Table.HeaderCell>
                                                {user.role === UserRole.Admin && (
                                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                                )}
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {projectRoles.map(role => (
                                                <ProjectRolesTableRow
                                                    key={role.guid}
                                                    projectRole={role}
                                                    projectRoles={projectRoles}
                                                    setProjectRoles={setProjectRoles}
                                                    fetchProjectRoles={fetchProjectRoles}
                                                    assigneesOptions={assigneesOptions}
                                                />
                                            ))}
                                        </Table.Body>
                                    </Table>
                                )}
                            </>
                        ) : (
                            <h3>This project has no roles assigned</h3>
                        )}
                    </div>
                </Segment>
                <Segment>
                    {user.role === UserRole.Admin && (
                        <>
                            <Button
                                secondary
                                content="Save changes"
                                icon="save"
                                onClick={saveChanges}
                            />
                            <Button
                                secondary
                                content="Add role"
                                icon="add"
                                onClick={() => setNewProjectRoleModalOpen(true)}
                            />
                        </>
                    )}
                </Segment>
            </Segment.Group>
            <AddProjectRoleForm
                projectGuid={project.guid}
                open={newProjectRoleModalOpen}
                closeModal={() => setNewProjectRoleModalOpen(false)}
                projectRoles={projectRoles}
                fetchProjectRoles={fetchProjectRoles}
                setNewProjectRoleModalOpen={setNewProjectRoleModalOpen}
                projectRolesOptions={projectRolesOptions}
                assigneesOptions={assigneesOptions}
            />
        </>
    );
};
