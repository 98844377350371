import React, { useEffect, useState } from "react";
import { Button, Icon, Input, Modal, TextArea } from "semantic-ui-react";
import { toast } from "../..";
import { signUpInit } from "../../models/signUp";
import { sendSignUpData } from "../../api/signUp";

interface IProps {
    open: boolean;
    closeModal: () => void;
}

export const SignUpForm = ({ open, closeModal }: IProps) => {
    const [signUpData, setSignUpData] = useState(signUpInit);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await sendSignUpData(signUpData);
            toast("Your registration was successful. Check your inbox for details", true, 2500);
            setSignUpData(signUpInit);
        } catch {
            toast("Error submitting signup data", false, 2500);
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    return (
        <Modal size="mini" open={open} onClose={closeModal} dimmer="blurring">
            <Modal.Header>
                <h2>Sign up</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form>
                    <div className="input-container">
                        <label>First name</label>
                        <Input
                            type="firstName"
                            value={signUpData.firstName}
                            onChange={(e: any) =>
                                setSignUpData({ ...signUpData, firstName: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Last name</label>
                        <Input
                            type="lastName"
                            value={signUpData.lastName}
                            onChange={(e: any) =>
                                setSignUpData({ ...signUpData, lastName: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Email</label>
                        <Input
                            type="email"
                            value={signUpData.email}
                            onChange={(e: any) =>
                                setSignUpData({ ...signUpData, email: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Company name</label>
                        <Input
                            type="companyName"
                            value={signUpData.companyName}
                            onChange={(e: any) =>
                                setSignUpData({ ...signUpData, companyName: e.target.value })
                            }
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Submit"
                    loading={loading}
                    disabled={
                        signUpData.firstName === "" ||
                        signUpData.lastName === "" ||
                        signUpData.email === "" ||
                        signUpData.companyName === ""
                    }
                    secondary
                    fluid
                    onClick={handleSubmit}
                />
            </Modal.Actions>
        </Modal>
    );
};
