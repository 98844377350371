import { DropdownItemProps } from "semantic-ui-react";
import { IInvoice } from "./invoice";

export interface IBankStatement {
    guid: string;
    name: string;
    date: string;
    created: string;
    latestDate: string;
    amount: string;
    currency: string;
    iban: string;
    alreadyExists?: boolean;
    isProcessed: boolean;
    additionalRemittanceInformation: string;
    address: string;
    invoiceGuid?: string;
    invoice?: IInvoice;
    paymentOrigin: string;
    researchAndDevelopment: boolean;
}

export interface IUploadedBankStatements {
    invoiceIBANs: string[];
    invoices: IInvoice[];
    statements: IBankStatement[];
}
export const UploadedBankStatementsInit: IUploadedBankStatements = {
    invoiceIBANs: [],
    invoices: [],
    statements: [],
};

export interface IInvoiceFilters {
    status?: boolean;
    startDate?: string;
    endDate?: string;
    query: string;
    categoryGuid: string;
}

export const invoiceFiltersInit: IInvoiceFilters = {
    status: undefined,
    startDate: undefined,
    endDate: undefined,
    query: "",
    categoryGuid: "",
};

export const statusOptions: DropdownItemProps[] = [
    { key: "all", text: "All", value: undefined },
    { key: "processed", text: "Processed", value: true },
    { key: "not-processed", text: "Not processed", value: false },
];
