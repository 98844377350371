import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { AppState } from "../../store/configureStore";

interface IProps {
    path: string;
}

export const RedirectIfLoggedIn: React.FC<IProps> = ({ path }) => {
    const user = useSelector((state: AppState) => state.user);
    const login = useSelector((state: AppState) => state.login);

    if (user.token && login.loggedIn)
        return <Redirect to={{ pathname: path === "/login" ? "/" : path }} />;
    else return null;
};
