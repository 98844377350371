import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Table, Button, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { AppState } from "../../store/configureStore";
import { IInventoryItem } from "../../models/inventoryItem";
import { deleteInventoryItem } from "../../api/inventoryItems";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";

interface IProps {
    inventoryItem: IInventoryItem;
    handleRemoveInventoryItem: (inventoryItem: IInventoryItem) => void;
    page: string;
}

export const InventoryItemRow = ({ inventoryItem, handleRemoveInventoryItem, page }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteInventoryItem = async () => {
        try {
            await deleteInventoryItem(inventoryItem.guid);
            handleRemoveInventoryItem(inventoryItem);
            toast("Successfully deleted inventory item", true);
        } catch {
            toast("Failed to delete inventory item", false);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `/company/inventories/${inventoryItem.guid}`,
            state: page,
        });
    };

    return (
        <Fragment>
            <Table.Row key={inventoryItem.guid} onClick={onRowClick} className="clickable-row">
                <Table.Cell>{inventoryItem.name}</Table.Cell>
                <Table.Cell>{getDisplayDateFormat(inventoryItem.date)}</Table.Cell>
                <Table.Cell>
                    {inventoryItem.assignedStaff
                        ? `${inventoryItem.assignedStaff.firstName} ${inventoryItem.assignedStaff.lastName}`
                        : "Not assigned"}
                </Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing textAlign="right">
                    {inventoryItem.invoiceGuid !== "" && (
                        <Button
                            as={Link}
                            to={{
                                pathname:
                                    "/invoice-processing/invoices/" + inventoryItem.invoiceGuid,
                                state: page,
                            }}
                            color="yellow"
                            content="Invoice"
                            icon="edit"
                        />
                    )}
                    {user.role === "admin" && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this inventory item?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteInventoryItem} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
