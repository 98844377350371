import "./Footer.css";
import pkg from "../../../package.json";
import { Icon } from "semantic-ui-react";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";

export const Footer = () => {
    const { stage, timestamp } = useSelector((state: AppState) => state.appStage);

    return (
        <footer>
            <span id="footer-span">
                Copyright <Icon fitted name="copyright outline" /> {new Date().getFullYear()}
            </span>
            <span id="footer-divider">|</span>
            <span id="footer-span">
                Aconto v{pkg.version} - {timestamp}
            </span>
            <span id="footer-divider">|</span>
            <span id="footer-span">All rights reserved</span>
        </footer>
    );
};
