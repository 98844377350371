import { DropdownItemProps } from "semantic-ui-react";

export interface IReport extends Record<string, string | number | boolean | undefined> {
    guid: string;
    text: string;
    customerDescription?: string;
    billableHours: boolean;
    differentCustomerDescription: boolean;
    created: string;
    date: string;
    hours: number;
    projectGuid: string;
    project: string;
    type: number;
    ticketUrl: string;
    commitUrl: string;
    userGuid: string;
    user: string;
}

export interface IReportFilter extends Record<string, string | number | boolean | undefined> {
    page: number;
    projectGuid: string;
    userGuid: string;
    date: string;
}

export const reportFiltersInit: IReportFilter = {
    page: 0,
    projectGuid: "",
    userGuid: "",
    date: "",
};

export interface IDailyReportsWrapper {
    userReports: IReport[];
    projectGuids: string[];
}

export const reportTypeOptions: DropdownItemProps[] = [
    { key: 0, text: "Programming", value: 0 },
    { key: 1, text: "Research", value: 1 },
    { key: 2, text: "Documentation", value: 2 },
    { key: 3, text: "Telco", value: 3 },
    { key: 4, text: "Testing", value: 4 },
    { key: 5, text: "Security scanning", value: 5 },
    { key: 6, text: "Support", value: 6 },
    { key: 7, text: "JIRA", value: 7 },
    { key: 8, text: "Design", value: 8 },
    { key: 9, text: "Internal coordination", value: 9 },
];

export const reportInit: IReport = {
    guid: "",
    text: "",
    created: "",
    date: "",
    hours: 0,
    projectGuid: "",
    project: "",
    type: -1,
    ticketUrl: "",
    commitUrl: "",
    userGuid: "",
    user: "",
    customerDescription: "",
    billableHours: false,
    differentCustomerDescription: false,
};

export const billableHoursOptions: DropdownItemProps[] = [
    { key: 0, text: "Show all hours", value: 0 },
    { key: 1, text: "Show billable hours", value: 1 },
    { key: 2, text: "Show non-billable hours", value: 2 },
];
