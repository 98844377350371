import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { HeaderDropdown } from "../HeaderDropdown";
import {
    employeeDataAsAdminItems,
    companyItems,
    projectsItems,
    invoiceProcessingItems,
    adminItems,
    clientsAndVendorsItems,
} from "../navbarItems";

export const AdminHeaders = () => {
    return (
        <>
            <Menu.Item>
                <HeaderDropdown title="Administration" items={adminItems} />
            </Menu.Item>
            <Menu.Item>
                <HeaderDropdown title="Employee Data" items={employeeDataAsAdminItems} />
            </Menu.Item>
            <Menu.Item>
                <HeaderDropdown title="Company" items={companyItems} />
            </Menu.Item>
            <Menu.Item>
                <HeaderDropdown
                    title="Projects"
                    items={projectsItems.concat(
                        {
                            slug: "/projects/project-roles",
                            title: "Project roles",
                        },
                        {
                            slug: "/company/projects/access-requests",
                            title: "Access requests",
                        },
                        {
                            slug: "/company/projects/capacity-planning",
                            title: "Capacity planning",
                        },
                        {
                            slug: "/company/projects/employee-utilization",
                            title: "Employee utilization",
                        },
                        {
                            slug: "/company/projects/projects-allocation",
                            title: "Projects allocation",
                        },
                        {
                            slug: "/company/gantt",
                            title: "Generate Gantt chart",
                        }
                    )}
                />
            </Menu.Item>
            <Menu.Item>
                <HeaderDropdown title="Invoice Processing" items={invoiceProcessingItems} />
            </Menu.Item>
            <Menu.Item>
                <HeaderDropdown title="Clients and Vendors" items={clientsAndVendorsItems} />
            </Menu.Item>
            <Menu.Item>
                <Link
                    className="link-header"
                    to={{
                        pathname: "/contacts",
                    }}
                >
                    Contacts
                </Link>
            </Menu.Item>
        </>
    );
};
