import { useEffect, useState, useCallback } from "react";
import { Button, Icon, Item, Label, Loader, Modal, Segment } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import agent from "../../api/agent";
import { IBoardData } from "../../models/board";
import { EditBoardNews } from "./EditBoardNews";
import { ViewBoardArticle } from "./ViewBoardArticle";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import "./Board.css";

interface Props {
    boardData: IBoardData;
    refreshBoard: () => void;
    user: UserData;
    setPinned: React.Dispatch<React.SetStateAction<IBoardData | undefined>>;
    pinnedArticlesCount?: Number;
    boardDataRead: (boardItemGuid: string) => void;
}

export default function BoardListItem({
    boardData,
    refreshBoard,
    user,
    setPinned,
    pinnedArticlesCount,
    boardDataRead,
}: Props) {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);

    const handleOpenModal = () => {
        setViewModalOpen(true);
    };

    const handleCloseModal = () => {
        setViewModalOpen(false);
        boardDataRead(boardData.guid!);
    };

    const DeleteSelectedNews = async (guid?: string) => {
        try {
            setLoading(true);
            await agent.Board.delete(guid);
            refreshBoard();
            toast("News successfully deleted", true);
        } catch (e: any) {
            toast(e.message, false);
        } finally {
            setLoading(false);
        }
    };

    const handleReadNews = useCallback(async () => {
        if (!boardData.isRead && user.role !== UserRole.Admin) {
            agent.Board.markAsRead(boardData.guid!);
        }
    }, [boardData.guid, boardData.isRead, user.role]);

    useEffect(() => {
        handleReadNews();
    }, [handleReadNews]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <Segment.Group>
            <Segment className="article-segment">
                <Item.Group divided>
                    <Item
                        onClick={handleOpenModal}
                        className={boardData.pinned ? "pinned-article" : "regular-article"}
                    >
                        {boardData.imgGuid !== "00000000-0000-0000-0000-000000000000" && (
                            <Item.Image
                                size="small"
                                src={boardData.imgSrc}
                                key={boardData.imgGuid}
                            />
                        )}
                        <Item.Content>
                            <Item.Header className="title">
                                <span>{boardData.title}</span>{" "}
                                <div className="unread-pinned-container">
                                    {!boardData.isRead && user.role !== UserRole.Admin && (
                                        <Label circular size="large" color="red" />
                                    )}
                                    {boardData.pinned && <Icon name="pin" />}{" "}
                                </div>
                            </Item.Header>
                            <Item.Description
                                className={boardData.pinned ? "pinned-description" : ""}
                            >
                                <p>{boardData.description}</p>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                    <Item.Extra className="title">
                        {getDisplayDateFormat(boardData.boardCreateDate!, true).split(" ")}
                    </Item.Extra>
                    {user.role === UserRole.Admin && (
                        <div className="buttons">
                            <Button
                                color="red"
                                size="small"
                                content="Delete"
                                icon="delete"
                                onClick={() => setDeleteModalOpen(true)}
                            />
                            <Button
                                color="green"
                                size="small"
                                content="Edit"
                                icon="edit"
                                onClick={() => setModalOpen(true)}
                            />
                            <Button
                                size="small"
                                secondary
                                content={boardData.pinned ? "Unpin" : "Pin"}
                                icon="pin"
                                onClick={() => setPinned(boardData)}
                                disabled={pinnedArticlesCount && pinnedArticlesCount >= 8}
                            />
                        </div>
                    )}
                </Item.Group>

                <EditBoardNews
                    open={modalOpen}
                    closeModal={() => setModalOpen(false)}
                    refreshBoard={refreshBoard}
                    guid={boardData.guid}
                />
                <ViewBoardArticle
                    open={viewModalOpen}
                    closeModal={handleCloseModal}
                    article={boardData}
                />
                <Modal
                    size="mini"
                    open={deleteModalOpen}
                    onClose={() => {
                        setDeleteModalOpen(false);
                    }}
                    dimmer="blurring"
                >
                    <Modal.Header>Are you sure you want to delete this news?</Modal.Header>
                    <Modal.Actions>
                        <Button
                            color="red"
                            onClick={() => setDeleteModalOpen(false)}
                            content="Cancel"
                        />
                        <Button
                            color="green"
                            onClick={() => DeleteSelectedNews(boardData.guid)}
                            content="Confirm"
                        />
                    </Modal.Actions>
                </Modal>
            </Segment>
        </Segment.Group>
    );
}
