import { useState, Dispatch, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Input, Segment, Table, Radio } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { TextInput } from "../../components/TextInput/TextInput";
import { IVehicle } from "../../models/vehicle";
import { editVehicle } from "../../services/vehiclesService";
import { AppState } from "../../store/configureStore";
import { fetchStaff } from "../../store/staffReducer";

interface IProps {
    vehicle: IVehicle;
    setVehicle: Dispatch<SetStateAction<IVehicle | undefined>>;
}

export const VehicleEdit = ({ vehicle, setVehicle }: IProps) => {
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [saving, setSaving] = useState(false);
    const unauthorized = user.role !== UserRole.Admin;
    const dispatch = useDispatch();

    const handleVehicleUpdate = async () => {
        try {
            setSaving(true);
            await editVehicle(vehicle);
            toast("Successfully saved changes", true);
            fetchStaff(dispatch);
            history.push("/company/vehicles");
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    const handleInputChange = (e: any) =>
        setVehicle({ ...vehicle!, [e.target.name]: e.target.value });

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleVehicleUpdate}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    return (
        <Segment.Group>
            <Segment>
                <h2>Edit vehicle details</h2>
                <SaveButton />
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Manufacturer</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="manufacturer"
                                    value={vehicle.manufacturer}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Model</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="model"
                                    value={vehicle.model}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Registration Plate</Table.Cell>
                            <Table.Cell>
                                <Input
                                    name="registrationPlate"
                                    value={vehicle.registrationPlate ?? ""}
                                    onChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Vehicle Identification Number</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="vin"
                                    value={vehicle.vin}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Color</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="color"
                                    value={vehicle.color}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Active</Table.Cell>
                            <Table.Cell>
                                <Radio
                                    toggle
                                    name="active"
                                    checked={vehicle.active}
                                    onChange={(e, data: any) =>
                                        setVehicle({
                                            ...vehicle,
                                            active: !vehicle.active,
                                        })
                                    }
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                <SaveButton />
            </Segment>
        </Segment.Group>
    );
};
