import { useMemo, useState, Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import ReactCrop from "react-image-crop";
import { Image } from "../Image/Image";
import "react-image-crop/dist/ReactCrop.css";
import "./ImageDropCrop.css";
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from "./dropzoneStyles";
import { DocumentType } from "../../models/document";
import { Image as SemanticImage } from "semantic-ui-react";

interface IProps {
    guid: string;
    onDrop: () => void;
    crop: any;
    setCrop: Dispatch<SetStateAction<any>>;
    documentType: DocumentType;
    src: string;
    setSrc: Dispatch<SetStateAction<any>>;
    preview: string;
    setPreview: Dispatch<SetStateAction<any>>;
}

export const ImageDropCrop: React.FC<IProps> = ({
    guid,
    onDrop,
    crop,
    setCrop,
    documentType,
    src,
    setSrc,
    preview,
    setPreview,
}) => {
    const [file, setFile] = useState<File>();
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        maxSize: 5242880,
        maxFiles: 1,
        multiple: false,
        accept: "image/*",
        onDrop: (acceptedFiles: File[]) => {
            setPreview((window.URL ? URL : webkitURL).createObjectURL(acceptedFiles[0]));
            setFile(acceptedFiles[0]);
            onDrop();
        },
    });

    const style = useMemo(
        () =>
            ({
                ...baseStyle,
                ...(isDragActive ? activeStyle : {}),
                ...(isDragAccept ? acceptStyle : {}),
                ...(isDragReject ? rejectStyle : {}),
            } as any),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleChangeCrop = (newCrop: any) => setCrop(newCrop);

    const onImageLoaded = (image: any) => {
        let cropDim = 350;
        if (image.width < 350) cropDim = image.width;
        if (image.height < 350) cropDim = image.height;
        setCrop({ width: cropDim, height: cropDim, aspect: 1, x: 0, y: 0 });
        return false;
    };

    return (
        <div className="image-dropcrop-container">
            <section className="image-dc-container">
                {!preview && !src ? (
                    <Image guid={guid} type={documentType} />
                ) : !preview && src ? (
                    <SemanticImage
                        id="uploaded-picture"
                        src={`${src}?${Math.random()}`}
                        alt="Avatar"
                    />
                ) : (
                    <ReactCrop
                        className="react-crop"
                        src={preview}
                        crop={crop}
                        onChange={handleChangeCrop.bind(this)}
                        onImageLoaded={onImageLoaded.bind(this)}
                    />
                )}
                <div className="image-dropcrop" {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Drag and drop the image here or click to select image</p>
                </div>
            </section>
        </div>
    );
};
