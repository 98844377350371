import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, Table } from "semantic-ui-react";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { toast } from "../..";
import { useSelector } from "react-redux";
import { deleteUser } from "../../api/users";
interface IProps {
    user: UserData;
    fetchUsers: () => void;
}

export const SuperAdminUsersRow = ({ user, fetchUsers }: IProps) => {
    const currentUser = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();
    const handleDeleteUser = async () => {
        try {
            await deleteUser(user.guid);
            fetchUsers();
            toast("User successfully deleted", true, 1500);
        } catch {
            setModalOpen(false);
            toast("Failed to delete user", false);
        }
    };

    const onRowClick = () => {
        history.push(`/administration/users/${user.guid}/super-admin`);
    };

    return (
        <Fragment>
            <Table.Row wid={5} key={user.guid} onClick={onRowClick} className="clickable-row">
                <Table.Cell>
                    {user.firstName} {user.lastName}
                </Table.Cell>
                <Table.Cell>{user.username}</Table.Cell>
                <Table.Cell>{user.company}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    <Button
                        color="red"
                        content="Delete"
                        icon="delete"
                        onClick={() => setModalOpen(true)}
                        disabled={currentUser.role !== UserRole.SuperAdmin}
                    />
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this user?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteUser} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
