import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Button, Modal, Input, TextArea, Icon } from "semantic-ui-react";
import { toast } from "../..";
import { AppState } from "../../store/configureStore";
import { UserRole } from "../../actions/authentificationActions";
import { IProjectRole } from "../../models/projectRole";
import { deleteProjectRole, updateProjectRole } from "../../api/projects";

interface IProps {
    projectRole: IProjectRole;
    fetchProjectRoles: () => void;
}

export const ProjectRolesRow = ({ projectRole, fetchProjectRoles }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [projectRoleDto, setProjectRoleDto] = useState(projectRole);

    const handleDeleteProjectRole = async () => {
        try {
            await deleteProjectRole(projectRole.guid);
            fetchProjectRoles();
            toast("Successfully deleted project role", true);
        } catch {
            toast("Failed to delete project role", false);
        } finally {
            setDeleteModalOpen(false);
        }
    };

    const handleEditProjectRole = async () => {
        try {
            await updateProjectRole(projectRoleDto);
            fetchProjectRoles();
            toast("Successfully updated project role", true);
        } catch {
            toast("Failed to update project role", false);
        } finally {
            setEditModalOpen(false);
        }
    };

    const onRowClick = () => {
        setEditModalOpen(true);
    };

    return (
        <Fragment>
            <Table.Row onClick={onRowClick} className="clickable-row">
                <Table.Cell>{projectRole.name}</Table.Cell>
                <Table.Cell>{projectRole.description}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setDeleteModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this project role?</Modal.Header>
                <Modal.Actions>
                    <Button
                        color="red"
                        onClick={() => setDeleteModalOpen(false)}
                        content="Cancel"
                    />
                    <Button color="green" onClick={handleDeleteProjectRole} content="Confirm" />
                </Modal.Actions>
            </Modal>
            <Modal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                dimmer="blurring"
                centered={false}
            >
                <Modal.Header>
                    <h2>Edit project role</h2>
                    <Icon name="close" onClick={() => setEditModalOpen(false)} />
                </Modal.Header>
                <Modal.Content>
                    <form>
                        <div className="input-container">
                            <label>Name</label>
                            <Input
                                name="name"
                                defaultValue={projectRole.name}
                                onChange={(e, data: any) =>
                                    setProjectRoleDto({ ...projectRole, name: data.value })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Description</label>
                            <TextArea
                                rows={6}
                                name="description"
                                defaultValue={projectRole.description}
                                onChange={(e, data: any) =>
                                    setProjectRoleDto({ ...projectRole, description: data.value })
                                }
                            />
                        </div>
                    </form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color="green"
                        onClick={handleEditProjectRole}
                        content="Confirm"
                        disabled={projectRoleDto.name === ""}
                    />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
