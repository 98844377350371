import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import { Button, Icon, Input, Loader, Pagination, Segment, Select, Table } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import { documentTypeProps, IDocument } from "../../models/document";
import { getDocuments } from "../../services/documentsService";
import { AppState } from "../../store/configureStore";
import { DocumentRow } from "./DocumentRow";
import "./Documents.css";

interface IQuery {
    stringQuery: string;
    documentType?: number;
}

export const Documents = () => {
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles = ["admin", "accountant", "employee", "student", "external"];
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [query, setQuery] = useState<IQuery>({ stringQuery: "" });
    const [appliedQuery, setAppliedQuery] = useState<IQuery>();

    const fetchDocuments = useCallback(async () => {
        setLoading(true);
        const userGuid = user.role !== UserRole.Admin ? user.guid : undefined;
        const res = await getDocuments(
            page,
            -1,
            undefined,
            userGuid,
            appliedQuery?.stringQuery,
            appliedQuery?.documentType
        );
        if (res.rows) {
            setDocuments(res.rows as IDocument[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page, appliedQuery]);

    const handleRemoveDocument = (doc: IDocument) =>
        setDocuments([...documents].filter(x => x !== doc));

    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments]);

    return !allowedRoles.includes(user.role!) ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="documents-container">
            <div className="container-header">
                <h1>Documents</h1>
                <div>
                    <Button
                        secondary
                        content="Upload"
                        icon="upload"
                        as={Link}
                        to="/administration/documents/upload"
                    />
                    <Button
                        secondary
                        content="Create"
                        icon="add"
                        as={Link}
                        to="/administration/documents/create"
                    />
                </div>
            </div>
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <Segment.Group>
                    <Segment className="documents-options">
                        <Input
                            placeholder="Search..."
                            value={query.stringQuery}
                            onChange={e => setQuery({ ...query, stringQuery: e.target.value })}
                        />
                        <Select
                            placeholder="Document type..."
                            clearable
                            defaultValue={query.documentType}
                            options={documentTypeProps}
                            onChange={(e, data: any) =>
                                setQuery({ ...query, documentType: data.value })
                            }
                        />
                        <Button
                            secondary
                            content="Apply filters"
                            icon="filter"
                            onClick={() => setAppliedQuery(query)}
                        />
                        <Button
                            className="clear-filters-button"
                            secondary
                            content="Clear filters"
                            icon="undo"
                            onClick={() => {
                                setQuery({ stringQuery: "" });
                                setAppliedQuery({ stringQuery: "" });
                            }}
                        />
                    </Segment>
                    <Segment className="table-container">
                        <Table basic="very">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>GUID</Table.HeaderCell>
                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                    <Table.HeaderCell>Classification</Table.HeaderCell>
                                    <Table.HeaderCell>Created</Table.HeaderCell>
                                    <Table.HeaderCell>Created by</Table.HeaderCell>
                                    <Table.HeaderCell>Used by</Table.HeaderCell>
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {documents.map(x => (
                                    <DocumentRow
                                        key={x.guid}
                                        doc={x}
                                        handleRemoveDocument={handleRemoveDocument}
                                        page={page.toString()}
                                    />
                                ))}
                                <Table.Row />
                            </Table.Body>
                        </Table>
                    </Segment>
                    <Segment>
                        {totalRows > 10 && (
                            <Pagination
                                size="small"
                                activePage={page}
                                onPageChange={(e, data: any) => setPage(+data.activePage)}
                                ellipsisItem={{
                                    content: <Icon name="ellipsis horizontal" />,
                                }}
                                firstItem={{ content: <Icon name="angle double left" /> }}
                                lastItem={{ content: <Icon name="angle double right" /> }}
                                prevItem={{ content: <Icon name="angle left" /> }}
                                nextItem={{ content: <Icon name="angle right" /> }}
                                totalPages={Math.ceil(totalRows / 10)}
                            />
                        )}
                    </Segment>
                </Segment.Group>
            )}
        </div>
    );
};
