import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { Button, Icon, Loader, Pagination, Segment, Table } from "semantic-ui-react";
import { AppState } from "../../store/configureStore";
import { AddProjectRoleForm } from "./AddProjectRoleForm";
import { ProjectRolesRow } from "./ProjectRolesRow";
import { IProjectRole } from "../../models/projectRole";
import { getProjectRoles } from "../../api/projects";
import "./Projects.css";

export const ProjectRoles = () => {
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles = ["admin", "employee"];
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [projectRoles, setProjectRoles] = useState<IProjectRole[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [modalOpen, setModalOpen] = useState(false);

    const fetchProjectRoles = useCallback(async () => {
        setLoading(true);
        const res = await getProjectRoles(page);
        if (res.rows) {
            setProjectRoles(res.rows as IProjectRole[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page]);

    useEffect(() => {
        fetchProjectRoles();
    }, [fetchProjectRoles]);

    const handleFetchProjectRoles = () => {
        setPage(1);
        fetchProjectRoles();
    };

    return !allowedRoles.includes(user.role!) ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="project-roels-container">
            <div className="container-header">
                <h1>Project roles</h1>
                <Button
                    secondary
                    content="Add new project role"
                    icon="plus"
                    onClick={() => setModalOpen(true)}
                />
            </div>
            <div className="project-roles-display">
                <div className="project-roles-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment />
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Description</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {projectRoles.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="5">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            projectRoles.map(x => (
                                                <ProjectRolesRow
                                                    key={x.guid}
                                                    projectRole={x}
                                                    fetchProjectRoles={fetchProjectRoles}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                            <AddProjectRoleForm
                                open={modalOpen}
                                closeModal={() => setModalOpen(false)}
                                handleAddProjectRole={handleFetchProjectRoles}
                            />
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
