import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Loader, Message, Select, Input } from "semantic-ui-react";
import { toast } from "../..";
import {
    createOrUpdateCapacityPlanning,
    getUserWeeklyCapacityPlannings,
} from "../../api/capacityPlanning";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import {
    getMondayDate,
    ICapacityPlanning,
    IUserWeeklyCapacityPlanning,
} from "../../models/capacityPlanning";
import { AppState } from "../../store/configureStore";
import { UtilizationBar } from "./UtilizationBar";

type Props = {
    open: boolean;
    closeModal: () => void;
    userGuid: string;
    date: string;
    refresh: () => Promise<void>;
};

export const AddTasksToStaffModal = ({ open, closeModal, userGuid, date, refresh }: Props) => {
    const projects = useSelector((state: AppState) => state.projects);
    const [selectedDate, setSelectedDate] = useState(date);
    const [userWeeklyCapacityPlannings, setUserWeeklyCapacityPlannings] =
        useState<IUserWeeklyCapacityPlanning>();
    const [projectGuid, setProjectGuid] = useState("");
    const [hours, setHours] = useState("0");
    const [loading, setLoading] = useState(false);

    const fetchUserWeeklyCapacityPlannings = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getUserWeeklyCapacityPlannings(userGuid, selectedDate);
            setUserWeeklyCapacityPlannings(res);
        } catch {
            toast("Unable to fetch user's weekly capacity plannings", false);
        } finally {
            setLoading(false);
        }
    }, [selectedDate, userGuid]);

    const handleCreateOrUpdateCapacityPlannings = async () => {
        try {
            const capacityPlanning: ICapacityPlanning = {
                guid: "",
                allocatedHours: +hours,
                date: selectedDate,
                userGuid: userGuid,
                userName: "",
                projectGuid: projectGuid,
                projectName: "",
            };
            await createOrUpdateCapacityPlanning(capacityPlanning);
            toast("Successfully created or updated weekly capacity planning for user", true);
            await refresh();
        } catch {
            toast("Unable to create or update weekly capacity planning for user", false);
        } finally {
            closeModal();
        }
    };

    useEffect(() => {
        fetchUserWeeklyCapacityPlannings();
    }, [fetchUserWeeklyCapacityPlannings]);

    useEffect(() => {
        const targetCapacityPlanning = userWeeklyCapacityPlannings?.capacityPlannings.find(
            x => x.projectGuid === projectGuid
        );
        if (targetCapacityPlanning) setHours(targetCapacityPlanning.allocatedHours.toString());
        else setHours("0");
    }, [projectGuid, userWeeklyCapacityPlannings?.capacityPlannings]);

    return (
        <Modal open={open} onClose={closeModal} dimmer="blurring" closeOnDimmerClick={!loading}>
            <Modal.Header>
                Add more tasks for {userWeeklyCapacityPlannings?.userName ?? "User"}
            </Modal.Header>
            <Modal.Content>
                {loading ? (
                    <Loader active inline="centered" size="huge" />
                ) : userWeeklyCapacityPlannings ? (
                    <div className="add-capacity-planning-form">
                        <section>
                            <span>
                                <strong>Available hours</strong>
                                {userWeeklyCapacityPlannings.userAvailableHours}
                            </span>
                            <span>
                                <strong>Unallocated hours</strong>
                                {userWeeklyCapacityPlannings.userAvailableHours -
                                    userWeeklyCapacityPlannings.allocatedWeeklyHours}
                            </span>
                            <CalendarInput
                                date={selectedDate}
                                handleDateChange={(e: any) =>
                                    setSelectedDate(getISODateFormat(getMondayDate(e)))
                                }
                            />
                        </section>
                        <div className="utilization-charts centered">
                            {userWeeklyCapacityPlannings.capacityPlannings.map(cp => (
                                <UtilizationBar
                                    key={cp.projectGuid}
                                    allocated={cp.allocatedHours}
                                    available={userWeeklyCapacityPlannings.userAvailableHours}
                                    label={cp.projectName}
                                />
                            ))}
                            <UtilizationBar
                                allocated={
                                    userWeeklyCapacityPlannings.userAvailableHours -
                                    userWeeklyCapacityPlannings.allocatedWeeklyHours
                                }
                                available={userWeeklyCapacityPlannings.userAvailableHours}
                                label="Unallocated"
                                unallocated
                            />
                        </div>
                        <div className="inputs">
                            <div className="input-container">
                                <label>Select project</label>
                                <Select
                                    placeholder="Select project..."
                                    selectOnBlur={false}
                                    value={projectGuid}
                                    options={projects}
                                    onChange={(e, data) => setProjectGuid(data.value as string)}
                                />
                            </div>
                            <div className="input-container">
                                <label>Allocate hours for project</label>
                                <Input
                                    value={hours}
                                    onChange={e => setHours(e.target.value)}
                                    error={!hours || (!!hours && isNaN(parseFloat(hours)))}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Message
                        icon="warning circle"
                        header="User's capacity plannings not found"
                        floating
                    />
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button color="red" onClick={closeModal} content="Cancel" />
                <Button
                    color="green"
                    onClick={handleCreateOrUpdateCapacityPlannings}
                    content="Confirm"
                    disabled={!projectGuid || !hours || hours === "0"}
                />
            </Modal.Actions>
        </Modal>
    );
};
