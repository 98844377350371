import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Icon, Menu, Modal, TransitionablePortal } from "semantic-ui-react";
import { logout, UserRole } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { ReactComponent as LogoIcon } from "../../icons/Aconto_logo_small.svg";
import { ReactComponent as LogoutIcon } from "../../icons/Log_out_icon.svg";
import { AdminHeaders } from "./RoleHeaders/AdminHeaders";
import { EmployeeHeaders } from "./RoleHeaders/EmployeeHeaders";
import { StudentAndExternalHeaders } from "./RoleHeaders/StudentAndExternalHeaders";
import { AccountantHeaders } from "./RoleHeaders/AccountantHeaders";
import { SuperAdminHeaders } from "./RoleHeaders/SuperAdminHeaders";
import { Image } from "../../components/Image/Image";
import { DocumentType } from "../../models/document";
import "./Header.css";

export const Header = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);

    const handleLogout = () => {
        history.push("/");
        dispatch(logout());
    };

    return (
        <header id="navbar-header">
            <Menu secondary className="navbar-menu">
                <Menu.Item as={Link} to="/" header>
                    <LogoIcon title="" />
                </Menu.Item>
                <div className="navbar-links">
                    {(() => {
                        switch (user.role) {
                            case UserRole.SuperAdmin:
                                return <SuperAdminHeaders />;
                            case UserRole.Admin:
                                return <AdminHeaders />;
                            case UserRole.Employee:
                                return <EmployeeHeaders />;
                            case UserRole.Student:
                            case UserRole.External:
                                return <StudentAndExternalHeaders />;
                            case UserRole.Accountant:
                                return <AccountantHeaders />;
                            default:
                                break;
                        }
                    })()}
                </div>
                <Menu.Item position="right" className="navbar-links">
                    <div
                        id="user_company"
                        onClick={() => history.push(`/administration/users/${user.guid}`)}
                    >
                        <Image
                            guid={user.guid ?? ""}
                            type={DocumentType.UserPhoto}
                            avatar={true}
                            header={true}
                        />
                        <b>
                            {user.firstName} {user.lastName} <br /> {user.company}
                        </b>
                    </div>
                    <div onClick={handleLogout}>
                        <LogoutIcon title="" />
                        <span>Logout</span>
                    </div>
                </Menu.Item>
                <Menu.Item
                    position="right"
                    className="hamburger-icon"
                    onClick={() => setModalOpen(true)}
                    icon="bars"
                />
            </Menu>
            <TransitionablePortal
                open={modalOpen}
                onOpen={() => setTimeout(() => document.body.classList.add("modal-fade-in"), 0)}
                transition={{ animation: "scale", duration: 300 }}
                id="hamburger-modal"
            >
                <Modal
                    centered={false}
                    basic
                    onClose={() => setModalOpen(false)}
                    open
                    size="fullscreen"
                    className="hamburger-menu-modal"
                    id="hamburger-modal"
                >
                    <Menu vertical fluid secondary>
                        <Menu.Item className="hamburger-menu-header" id="hamburger-menu-header">
                            <LogoIcon
                                id="hamburger-logo"
                                title=""
                                onClick={() => history.push("/")}
                            />
                            <Icon name="close" size="big" onClick={() => setModalOpen(false)} />
                        </Menu.Item>
                        {(() => {
                            switch (user.role) {
                                case UserRole.SuperAdmin:
                                    return <SuperAdminHeaders />;
                                case UserRole.Admin:
                                    return <AdminHeaders />;
                                case UserRole.Employee:
                                    return <EmployeeHeaders />;
                                case UserRole.Student:
                                case UserRole.External:
                                    return <StudentAndExternalHeaders />;
                                case UserRole.Accountant:
                                    return <AccountantHeaders />;
                                default:
                                    break;
                            }
                        })()}
                    </Menu>
                </Modal>
            </TransitionablePortal>
        </header>
    );
};
