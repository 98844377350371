import { Dispatch, Reducer } from "redux";
import { DropdownItemProps } from "semantic-ui-react";
import { GET_ROLES } from "../actions/rolesActions";
import { getRoles } from "../services/homepageService";

export interface RolesState extends DropdownItemProps {}

const initialState: RolesState[] = [];

export const rolesReducer: Reducer<RolesState[]> = (
    state: RolesState[] = initialState,
    action: any
): RolesState[] => {
    switch (action.type) {
        case GET_ROLES:
            return action.payload;
        default:
            return state;
    }
};

export async function fetchRoles(dispatch: Dispatch) {
    const res = await getRoles();
    dispatch({ type: "GET_ROLES", payload: res });
}

export const getRoleName = (role: string | undefined) => {
    if (role) {
        const clean = role.replace(/_/g, " ");
        return clean.charAt(0).toUpperCase() + clean.slice(1);
    } else return "";
};
