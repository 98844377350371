import { useState } from "react";
import { Button, Segment, TextArea } from "semantic-ui-react";
import { getGanttChart } from "../../services/projectsService";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { UserRole } from "../../actions/authentificationActions";

export const GanttChart = () => {
    const user = useSelector((state: AppState) => state.user);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState("");

    const handleGenerateGanttChart = async () => {
        setLoading(true);
        await getGanttChart(text);
        setLoading(false);
    };

    return user.role !== UserRole.Admin ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="gantt-chart-container">
            <div className="container-header">
                <h1>Generate Gantt chart</h1>
            </div>
            <Segment.Group>
                <Segment></Segment>
                <Segment>
                    <div className="input-container">
                        <label>Additional text</label>
                        <TextArea rows={5} onChange={(e: any) => setText(e.target.value)} />
                    </div>
                </Segment>
                <Segment>
                    <Button
                        secondary
                        fluid
                        icon="chart bar"
                        content="Generate chart"
                        disabled={text.length === 0 || loading}
                        loading={loading}
                        onClick={handleGenerateGanttChart}
                    />
                </Segment>
            </Segment.Group>
        </div>
    );
};
