import { Link } from 'react-router-dom';
import { Menu } from "semantic-ui-react";
import { HeaderDropdown } from "../HeaderDropdown";
import { invoiceProcessingItems } from "../navbarItems";

export const AccountantHeaders = () => {
    return (
        <Menu.Item>
            <HeaderDropdown title="Invoice Processing" items={invoiceProcessingItems} />
            <Menu.Item>
                <Link
                    className="link-header"
                    to={{
                        pathname: "/contacts",
                    }}
                >
                    Contacts
                </Link>
            </Menu.Item>
        </Menu.Item>
    );
};
