import { Segment, Table } from "semantic-ui-react";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { IInvoice } from "../../models/invoice";

interface IProps {
    invoice: IInvoice;
}

export const BankStatementsTable = ({ invoice }: IProps) => {
    console.log(invoice);
    return (
        <Segment.Group>
            <Segment>
                <h2>Bank statements</h2>
            </Segment>
            <Segment>
                <Table basic="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.HeaderCell>IBAN</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{invoice.bankStatementName}</Table.Cell>
                            <Table.Cell>
                                {getDisplayDateFormat(invoice.bankStatementDate)}
                            </Table.Cell>
                            <Table.Cell>
                                {invoice.bankStatementAmount.replace(/[,.]/g, m =>
                                    m === "," ? "." : ","
                                )}
                            </Table.Cell>
                            <Table.Cell>{invoice.ibaNs.join(", ")}</Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};
