import { Segment, Table } from "semantic-ui-react";
import { IStudentMonthlySummary } from "../../models/staffMonthlySummary";

interface IProps {
    title: string;
    rows: IStudentMonthlySummary[];
}

export const StudentsAndExternalsMonthlySummary = ({ title, rows }: IProps) => {
    return (
        <Segment.Group>
            <Segment className="monthly-summary-options">
                <h2>{title}</h2>
            </Segment>
            <Segment>
                <Table basic="very" fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>User</Table.HeaderCell>
                            <Table.HeaderCell>Days</Table.HeaderCell>
                            <Table.HeaderCell>Hours</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {rows.length === 0 ? (
                            <Table.Row>
                                <Table.Cell textAlign="center" colSpan="3">
                                    No records available for selected parameters
                                </Table.Cell>
                            </Table.Row>
                        ) : (
                            rows.map(x => (
                                <Table.Row key={x.user}>
                                    <Table.Cell>{x.user}</Table.Cell>
                                    <Table.Cell>{x.days}</Table.Cell>
                                    <Table.Cell>{x.hours}</Table.Cell>
                                </Table.Row>
                            ))
                        )}
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};
