import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { INavbarItem } from "./navbarItems";

interface IProps {
    title: string;
    items: INavbarItem[];
}

export const HeaderDropdown: React.FC<IProps> = ({ title, items }) => {
    return (
        <Dropdown text={title} icon="chevron down">
            <Dropdown.Menu>
                {items.map((x, i) => (
                    <Fragment key={x.slug}>
                        <Dropdown.Item key={x.slug} as={Link} to={x.slug} content={x.title} />
                        {i !== items.length - 1 && <hr />}
                    </Fragment>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
