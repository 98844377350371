import axios from "axios";
import { IBuyRequest } from "../models/buyRequests";
import { IPaginationWrapper } from "../models/common";

export const getBuyRequests = async (page: number, status: number) => {
    const params = new URLSearchParams({ page: page.toString(), status: status.toString() });
    const response = await axios.get(`/api/buyRequests`, { params: params });
    return response.data as IPaginationWrapper;
};

export const getBuyRequest = async (guid: string) => {
    const response = await axios.get(`/api/buyRequests/${guid}`);
    return response.data as IBuyRequest;
};

export const getBuyRequestsList = async (invoiceGuid?: string) => {
    const params = new URLSearchParams();
    invoiceGuid && params.append("invoiceGuid", invoiceGuid);
    const response = await axios.get(`/api/buyRequests/list`, { params: params });
    return response.data as IBuyRequest[];
};

export const createBuyRequest = async (buyRequestDto: IBuyRequest) => {
    const response = await axios.post("/api/buyRequests", buyRequestDto);
    return response;
};

export const updateBuyRequest = async (buyRequestDto: IBuyRequest) => {
    const response = await axios.put(`/api/buyRequests/${buyRequestDto.guid}`, buyRequestDto);
    return response;
};

export const deleteBuyRequest = async (guid: string) => {
    const response = await axios.delete(`/api/buyRequests/${guid}`);
    return response;
};

export const submitBuyRequest = async (guid: string) => {
    const response = await axios.post(`/api/buyRequests/${guid}`);
    return response;
};

export const processBuyRequest = async (guid: string, choice: boolean) => {
    const params = new URLSearchParams({ guid: guid, choice: choice.toString() });
    const response = await axios.post(`/api/buyRequests/sent-requests?${params.toString()}`);
    return response;
};

export const completeBuyRequest = async (guid: string) => {
    const response = await axios.post(`/api/buyRequests/complete/${guid}`);
    return response;
};
