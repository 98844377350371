import { Dispatch, SetStateAction, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, DropdownItemProps, Segment, Select, Table } from "semantic-ui-react";
import { toast } from "../..";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { workTimeOptions } from "../../models/date";
import { IProjectNote } from "../../models/projectNote";
import { editProjectNote } from "../../services/projectNotesService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./ProjectNotes.css";
import { TextInput } from "../../components/TextInput/TextInput";

interface IProps {
    projectNote: IProjectNote;
    setProjectNote: Dispatch<SetStateAction<IProjectNote | undefined>>;
    attendees: DropdownItemProps[];
    clientAttendees: DropdownItemProps[];
}

export const EditProjectNote = ({
    projectNote,
    setProjectNote,
    attendees,
    clientAttendees,
}: IProps) => {
    const history = useHistory();
    const [saving, setSaving] = useState(false);

    const handleInputChange = (e: any) =>
        setProjectNote({ ...projectNote!, [e.target.name]: e.target.value });

    const handleUpdateProjectNote = async () => {
        setSaving(true);
        const res = await editProjectNote(projectNote);
        if (!res.ok) toast("Failed to save changes, please try again", false);
        else {
            toast("Successfully saved changes", true);
            history.push("/company/minutes");
        }
        setSaving(false);
    };

    const SaveButton = () => (
        <Button
            secondary
            content="Save changes"
            icon="save"
            onClick={handleUpdateProjectNote}
            disabled={saving}
            loading={saving}
        />
    );

    return (
        <Segment.Group>
            <Segment className="edit-header">
                <h2>Edit minutes of the meeting</h2>
                <SaveButton />
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Title</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="title"
                                    value={projectNote.title}
                                    handleInputChange={handleInputChange}
                                    disabled
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Project</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="project"
                                    value={projectNote.project}
                                    handleInputChange={handleInputChange}
                                    disabled
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Date</Table.Cell>
                            <Table.Cell>
                                <CalendarInput
                                    date={projectNote.date}
                                    handleDateChange={e =>
                                        setProjectNote({
                                            ...projectNote,
                                            date: getISODateFormat(e),
                                        })
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>From/To</Table.Cell>
                            <Table.Cell>
                                <div className="time-selector">
                                    <Select
                                        search
                                        selectOnBlur={false}
                                        value={projectNote.from}
                                        options={workTimeOptions}
                                        onChange={(e, data: any) =>
                                            setProjectNote({
                                                ...projectNote,
                                                from: data.value,
                                            })
                                        }
                                    />
                                    <Select
                                        search
                                        selectOnBlur={false}
                                        value={projectNote.to}
                                        options={workTimeOptions}
                                        onChange={(e, data: any) =>
                                            setProjectNote({
                                                ...projectNote,
                                                to: data.value,
                                            })
                                        }
                                    />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Location</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="location"
                                    value={projectNote.location}
                                    handleInputChange={handleInputChange}
                                    disabled
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Attendees</Table.Cell>
                            <Table.Cell>
                                <Select
                                    placeholder="Add attendees..."
                                    selectOnBlur={false}
                                    fluid
                                    multiple
                                    search
                                    value={projectNote.attendees}
                                    options={attendees}
                                    onChange={(e, data: any) =>
                                        setProjectNote({
                                            ...projectNote,
                                            attendees: data.value,
                                        })
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Client attendees</Table.Cell>
                            <Table.Cell>
                                <Select
                                    placeholder="Add client attendees..."
                                    selectOnBlur={false}
                                    fluid
                                    multiple
                                    search
                                    value={projectNote.clientAttendees}
                                    options={clientAttendees}
                                    onChange={(e, data: any) =>
                                        setProjectNote({
                                            ...projectNote,
                                            clientAttendees: data.value,
                                        })
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Notes</Table.Cell>
                            <Table.Cell>
                                <ReactQuill
                                    id="quill-mom"
                                    value={projectNote.content}
                                    onChange={(e: string) =>
                                        setProjectNote({
                                            ...projectNote,
                                            content: e,
                                        })
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Minutes of the meeting</Table.Cell>
                            <Table.Cell>
                                <ReactQuill
                                    id="quill-mom"
                                    value={projectNote.minutesOfTheMeeting}
                                    onChange={(e: string) =>
                                        setProjectNote({
                                            ...projectNote,
                                            minutesOfTheMeeting: e,
                                        })
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Author</Table.Cell>
                            <Table.Cell>{projectNote.authorName}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Segment className="edit-footer">
                <SaveButton />
            </Segment>
        </Segment.Group>
    );
};
