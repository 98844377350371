import { IVehicle } from "../models/vehicle";
import { getAuthorizationProperties } from "./authorizationHelper";
import { DropdownItemProps } from "semantic-ui-react";
import { IPaginationWrapper } from "../models/common";

export async function getVehicles(page: number, query?: string): Promise<IPaginationWrapper> {
    const params = new URLSearchParams({ page: page + "" });
    query && params.append("query", query);
    return await fetch("/api/vehicles?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function getVehicle(guid: string): Promise<IVehicle> {
    return await fetch("/api/vehicles/" + guid, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function getVehiclesAsProps(): Promise<DropdownItemProps[]> {
    return await fetch("/api/vehicles/props", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function editVehicle(vehicle: IVehicle): Promise<Response> {
    return await fetch("/api/vehicles/" + vehicle.guid, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(vehicle),
    });
}

export async function deleteVehicle(guid: string): Promise<Response> {
    return await fetch("/api/vehicles/" + guid, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function createVehicle(vehicleDto: Partial<IVehicle>): Promise<Response> {
    return await fetch("/api/vehicles/", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(vehicleDto),
    });
}
