import React, { useCallback, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import { BankStatementDetails } from "./containers/BankStatements/BankStatementDetails";
import { BankStatements } from "./containers/BankStatements/BankStatements";
import { Home } from "./containers/Home/Home";
import { Layout } from "./containers/Layout/Layout";
import { UploadBankStatements } from "./containers/UploadBankStatements/UploadBankStatements";
import { UsersDashboard } from "./containers/UsersDashboard/UsersDashboard";
import { UserDetails } from "./containers/UsersDashboard/UserDetails";
import { useDispatch } from "react-redux";
import { fetchRoles } from "./store/rolesReducer";
import { UploadInvoices } from "./containers/UploadInvoices/UploadInvoices";
import { Invoices } from "./containers/Invoices/Invoices";
import { InvoiceDetails } from "./containers/Invoices/InvoiceDetails";
import { Documents } from "./containers/Documents/Documents";
import { DocumentDetails } from "./containers/Documents/DocumentDetails";
import { WorkingPlan } from "./containers/WorkingPlans/WorkingPlan";
import { CreateWorkingPlan } from "./containers/WorkingPlans/CreateWorkingPlan";
import { WorkingPlanDetails } from "./containers/WorkingPlans/WorkingPlanDetails";
import { StudentsAndExternalsMonthlySummary } from "./containers/StaffMonthlySummary/StudentsAndExternalsMonthlySummary";
import { WorkAttendances } from "./containers/WorkAttendances/WorkAttendances";
import { CreateWorkAttendance } from "./containers/WorkAttendances/CreateWorkAttendance";
import { ProjectsTable } from "./containers/Projects/ProjectsTable";
import { Project } from "./containers/Projects/Project";
import { ProjectRoles } from "./containers/Projects/ProjectRoles";
import { CustomerProjectReport } from "./containers/Projects/CustomerProjectReport";
import { StaffMonthlySummary } from "./containers/StaffMonthlySummary/StaffMonthlySummary";
import { Reports } from "./containers/Reports/Reports";
import { UserReportsForDate } from "./containers/Reports/UserReportsForDate";
import { EditReport } from "./containers/Reports/EditReport";
import { ReportsSummary } from "./containers/Reports/ReportsSummary";
import { ProjectNotes } from "./containers/ProjectNotes/ProjectNotes";
import { Clients } from "./containers/Clients/Clients";
import { ClientDetails } from "./containers/Clients/ClientDetails";
import { Unauthorized } from "./components/Unauthorized/Unauthorized";
import { NewsFeed } from "./containers/NewsFeed/NewsFeed";
import { ProjectNoteDetails } from "./containers/ProjectNotes/ProjectNoteDetails";
import { AccessRights } from "./containers/AccessRights/AccessRights";
import { fetchStaff } from "./store/staffReducer";
import { ClientContacts } from "./containers/ClientContacts/ClientContacts";
import { ClientContactDetails } from "./containers/ClientContacts/ClientContactDetails";
import { fetchClients } from "./store/clientsReducer";
import { fetchProjects } from "./store/projectsReducer";
import { AccessRightsDetails } from "./containers/AccessRights/AccessRightsDetails";
import { Departments } from "./containers/Departments/Departments";
import { DepartmentDetails } from "./containers/Departments/DepartmentDetails";
import { GanttChart } from "./containers/GanttChart/GanttChart";
import { RecipientDetails } from "./containers/Recipients/RecipientDetails";
import { EditWorkAttendance } from "./containers/WorkAttendances/EditWorkAttendance";
import { ViewWorkAttendance } from "./containers/WorkAttendances/ViewWorkAttendance";
import { Contacts } from "./containers/Contacts/Contacts";
import { ContactDetails } from "./containers/Contacts/ContactDetails";
import { Vehicles } from "./containers/Vehicles/Vehicles";
import { VehicleDetails } from "./containers/Vehicles/VehicleDetails";
import { fetchAppStage } from "./store/appStageReducer";
import { ParentalLeaves } from "./containers/WorkAttendances/ParentalLeave/ParentalLeaves";
import { AddCompany } from "./containers/Company/AddCompany";
import { BuyRequests } from "./containers/BuyRequests/BuyRequests";
import { CreateBuyRequest } from "./containers/BuyRequests/CreateBuyRequest";
import { BuyRequestDetails } from "./containers/BuyRequests/BuyRequestDetails";
import { InventoryItems } from "./containers/InventoryItems/InventoryItems";
import { CreateInventoryItemForm } from "./containers/InventoryItems/CreateInventoryItemForm";
import { InventoryItemDetails } from "./containers/InventoryItems/InventoryItemDetails";
import { UploadDocuments } from "./containers/UploadDocuments/UploadDocuments";
import { CreateDocuments } from "./containers/CreateDocument/CreateDocument";
import axios from "axios";
import { UserData } from "./actions/authentificationActions";
import { Educations } from "./containers/Educations/Educations";
import { CreateEducation } from "./containers/Educations/CreateEducation";
import { EducationDetails } from "./containers/Educations/EducationDetails";
import { VendorDetails } from "./containers/Vendors/VendorDetails";
import { VendorContactDetails } from "./containers/VendorContacts/VendorContactDetails";
import { Vendors } from "./containers/Vendors/Vendors";
import { VendorContacts } from "./containers/VendorContacts/VendorContacts";
import { IdeaBoxes } from "./containers/IdeaBox/IdeaBoxes";
import { IdeaBoxDetails } from "./containers/IdeaBox/IdeaBoxDetails";
import { UserActivity } from "./containers/UsersDashboard/UserActivity";
import { UsersPoints } from "./containers/UsersDashboard/UsersPoints";
import { InvoiceCategories } from "./containers/Invoices/InvoiceCategories";
import { ProjectAccessRequests } from "./containers/Projects/ProjectAccessRequests";
import { Locations } from "./containers/Locations/Locations";
import { Settings } from "./containers/Settings/Settings";
import { SuperAdminUserDetails } from "./containers/UsersDashboard/SuperAdminUserDetails";
import { ServiceDetails } from "./containers/Services/ServiceDetails";
import { CompanyServiceTypeDetails } from "./containers/CompanyServiceTypes/CompanyServiceTypeDetails";
import { CapacityPlanning } from "./containers/CapacityPlanning/CapacityPlanning";
import { EmployeeUtilization } from "./containers/CapacityPlanning/EmployeeUtilization";
import { ProjectsAllocation } from "./containers/CapacityPlanning/ProjectsAllocation";

axios.interceptors.request.use(config => {
    const userData = sessionStorage.getItem("user");
    const user = userData ? (JSON.parse(userData) as UserData) : null;
    config.headers!.Authorization = "Bearer " + user?.token;
    return config;
});

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.error(error);
        return Promise.reject(error);
    }
);

export const App = () => {
    const dispatch = useDispatch();

    const fetchAppData = useCallback(() => {
        fetchAppStage(dispatch);
        fetchRoles(dispatch);
        fetchStaff(dispatch);
        fetchClients(dispatch);
        fetchProjects(dispatch);
    }, [dispatch]);

    useEffect(() => {
        fetchAppData();
    }, [fetchAppData]);

    return (
        <>
            <Layout>
                <Switch>
                    <Route exact path="/unauthorized" component={Unauthorized} />
                    <Route
                        exact
                        path="/invoice-processing/bank-statements/upload"
                        component={UploadBankStatements}
                    />
                    <Route
                        exact
                        path="/invoice-processing/bank-statements/:guid"
                        component={BankStatementDetails}
                    />
                    <Route
                        exact
                        path="/invoice-processing/bank-statements"
                        component={BankStatements}
                    />
                    <Route exact path="/administration/users" component={UsersDashboard} />
                    <Route exact path="/administration/users-points" component={UsersPoints} />
                    <Route exact path="/administration/users/:guid" component={UserDetails} />
                    <Route
                        exact
                        path="/administration/users/:guid/super-admin"
                        component={SuperAdminUserDetails}
                    />
                    <Route exact path="/companies" component={AddCompany} />
                    <Route
                        exact
                        path="/company/projects/access-requests"
                        component={ProjectAccessRequests}
                    />
                    <Route
                        exact
                        path="/company/projects/capacity-planning"
                        component={CapacityPlanning}
                    />
                    <Route
                        exact
                        path="/company/projects/employee-utilization"
                        component={EmployeeUtilization}
                    />
                    <Route
                        exact
                        path="/company/projects/projects-allocation"
                        component={ProjectsAllocation}
                    />
                    <Route exact path="/company/projects" component={ProjectsTable} />
                    <Route exact path="/company/projects/:guid" component={Project} />
                    <Route
                        exact
                        path="/company/projects/customerreport/:guid"
                        component={CustomerProjectReport}
                    />
                    <Route exact path="/projects/project-roles" component={ProjectRoles} />

                    <Route exact path="/company/clients" component={Clients} />
                    <Route exact path="/company/vendors" component={Vendors} />
                    <Route exact path="/company/clients/:guid" component={ClientDetails} />
                    <Route exact path="/company/vendors/:guid" component={VendorDetails} />
                    <Route
                        exact
                        path="/company/clients/client-contacts/:guid"
                        component={ClientContactDetails}
                    />
                    <Route
                        exact
                        path="/company/vendors/vendor-contacts/:guid"
                        component={VendorContactDetails}
                    />
                    <Route path="/company/vendor-contacts/:guid" component={VendorContactDetails} />
                    <Route path="/company/vendor-contacts" component={VendorContacts} />
                    <Route exact path="/company/inventories" component={InventoryItems} />
                    <Route
                        exact
                        path="/company/inventories/create"
                        component={CreateInventoryItemForm}
                    />
                    <Route
                        exact
                        path="/company/inventories/:guid"
                        component={InventoryItemDetails}
                    />
                    <Route exact path="/company/locations" component={Locations} />
                    <Route exact path="/services/:guid" component={ServiceDetails} />
                    <Route
                        exact
                        path="/company-service-type/:guid"
                        component={CompanyServiceTypeDetails}
                    />
                    <Route
                        exact
                        path="/invoice-processing/invoices/upload"
                        component={UploadInvoices}
                    />
                    <Route
                        exact
                        path="/invoice-processing/invoices/:guid"
                        component={InvoiceDetails}
                    />
                    <Route exact path="/invoice-processing/invoices" component={Invoices} />
                    <Route
                        exact
                        path="/invoice-processing/invoice-categories"
                        component={InvoiceCategories}
                    />
                    <Route
                        exact
                        path="/administration/documents/create"
                        component={CreateDocuments}
                    />
                    <Route
                        exact
                        path="/administration/documents/upload"
                        component={UploadDocuments}
                    />
                    <Route
                        exact
                        path="/administration/documents/:guid"
                        component={DocumentDetails}
                    />
                    <Route exact path="/administration/documents" component={Documents} />
                    <Route exact path="/student-plans/create" component={CreateWorkingPlan} />
                    <Route exact path="/student-plans/:guid" component={WorkingPlanDetails} />
                    <Route exact path="/student-plans" component={WorkingPlan} />
                    <Route exact path="/external-plans/create" component={CreateWorkingPlan} />
                    <Route exact path="/external-plans/:guid" component={WorkingPlanDetails} />
                    <Route exact path="/external-plans" component={WorkingPlan} />
                    <Route
                        exact
                        path="/students-monthly-summary"
                        component={StudentsAndExternalsMonthlySummary}
                    />
                    <Route
                        exact
                        path="/employee-data/monthly-report"
                        component={StaffMonthlySummary}
                    />
                    <Route
                        path="/employee-data/(vacations|sick-leaves|remote-work|paid-leaves|parental-leaves|yearly-vacation-plan)/create"
                        component={CreateWorkAttendance}
                    />
                    <Route
                        path="/employee-data/(vacations|sick-leaves|remote-work|paid-leaves|parental-leaves|yearly-vacation-plan)/edit/:guid"
                        component={EditWorkAttendance}
                    />
                    <Route
                        path="/employee-data/(vacations|sick-leaves|remote-work|paid-leaves|yearly-vacation-plan)/view/:guid"
                        component={ViewWorkAttendance}
                    />
                    <Route
                        path="/employee-data/(vacations|sick-leaves|remote-work|paid-leaves|yearly-vacation-plan)/view"
                        component={ViewWorkAttendance}
                    />
                    <Route
                        path="/employee-data/:attendanceType(vacations|sick-leaves|remote-work|paid-leaves|yearly-vacation-plan)/:status"
                        component={WorkAttendances}
                    />
                    <Route exact path="/employee-data/parental-leaves" component={ParentalLeaves} />
                    <Route exact path="/employee-data/educations" component={Educations} />
                    <Route
                        exact
                        path="/employee-data/educations/create"
                        component={CreateEducation}
                    />
                    <Route
                        exact
                        path="/employee-data/educations/edit/:guid"
                        component={EducationDetails}
                    />
                    <Route
                        path="/employee-data/:attendanceType(vacations|sick-leaves|remote-work|paid-leaves|yearly-vacation-plan)"
                        component={WorkAttendances}
                    />
                    <Route path="/company/reports/summary" component={ReportsSummary} />
                    <Route path="/company/reports/daily/:date" component={UserReportsForDate} />
                    <Route path="/company/reports/:guid" component={EditReport} />
                    <Route path="/company/reports" component={Reports} />
                    <Route path="/company/minutes/:guid" component={ProjectNoteDetails} />
                    <Route path="/company/minutes" component={ProjectNotes} />
                    <Route path="/company/user-activity/:userGuid" component={UserActivity} />
                    <Route path="/company/news-feed/:guid" component={NewsFeed} />
                    <Route path="/company/client-contacts/:guid" component={ClientContactDetails} />
                    <Route path="/company/client-contacts" component={ClientContacts} />
                    <Route
                        path="/company/access-rights/:guid/recipients/:guid"
                        component={RecipientDetails}
                    />
                    <Route path="/company/access-rights/:guid" component={AccessRightsDetails} />
                    <Route path="/company/access-rights" component={AccessRights} />
                    <Route exact path="/company/departments/:guid" component={DepartmentDetails} />
                    <Route path="/company/departments" component={Departments} />
                    <Route exact path="/requests/ideas/:guid" component={IdeaBoxDetails} />
                    <Route path="/requests/ideas" component={IdeaBoxes} />
                    <Route path="/company/gantt" component={GanttChart} />
                    <Route exact path="/contacts" component={Contacts} />
                    <Route exact path="/contacts/:guid" component={ContactDetails} />
                    <Route exact path="/company/vehicles" component={Vehicles} />
                    <Route exact path="/company/vehicles/:guid" component={VehicleDetails} />
                    <Route exact path="/requests/buy-requests" component={BuyRequests} />
                    <Route
                        exact
                        path="/requests/buy-requests/create"
                        component={CreateBuyRequest}
                    />
                    <Route path="/requests/buy-requests/edit/:guid" component={BuyRequestDetails} />
                    <Route exact path="/administration/settings" component={Settings} />
                    <Route component={Home} />
                </Switch>
            </Layout>
        </>
    );
};
