import { useCallback, useEffect, useState } from "react";
import { Icon, Loader, Pagination, Segment, Table, Button, Select } from "semantic-ui-react";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { UserRole } from "../../actions/authentificationActions";
import { categories, IVendor } from "../../models/vendor";
import { getVendors } from "../../api/vendors";
import { AddVendorForm } from "./AddVendorForm";
import { VendorRow } from "./VendorRow";
import "../Clients/Clients.css";

export const Vendors = () => {
    const user = useSelector((state: AppState) => state.user);
    const loc = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [vendors, setVendors] = useState<IVendor[]>([]);
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [totalRows, setTotalRows] = useState(0);
    const [vendorModalOpen, setVendorModalOpen] = useState(false);
    const [category, setCategory] = useState("Vendors");

    const fetchVendors = useCallback(async () => {
        setLoading(true);
        const res = await getVendors(page);
        if (res.rows) {
            setVendors(res.rows as IVendor[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page]);

    useEffect(() => {
        category === "Vendors" ? fetchVendors() : history.push("/company/clients");
    }, [category, fetchVendors, history]);

    const handleFetchVendors = () => {
        fetchVendors();
    };

    return (
        <div className="clients-container">
            <div className="container-header">
                <h1>Vendors</h1>
                <div style={{ display: "flex", gap: "5px" }}>
                    <Select
                        placeholder="Select category..."
                        selectOnBlur={false}
                        options={categories}
                        value={category}
                        onChange={(e, data: any) => setCategory(data.value)}
                    />
                    {user.role === UserRole.Admin && (
                        <Button
                            secondary
                            content="Add new vendor"
                            icon="plus"
                            onClick={() => setVendorModalOpen(true)}
                        />
                    )}
                </div>
            </div>
            <div className="clients-display">
                <div className="clients-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment />
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Email</Table.HeaderCell>
                                            <Table.HeaderCell>Phone number</Table.HeaderCell>
                                            {user.role === UserRole.Admin && <Table.HeaderCell>Actions</Table.HeaderCell>}
                                            
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {vendors.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="5">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            vendors.map(x => (
                                                <VendorRow
                                                    key={x.guid}
                                                    vendor={x}
                                                    handleRemoveVendor={handleFetchVendors}
                                                    page={page.toString()}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                            <AddVendorForm
                                open={vendorModalOpen}
                                closeModal={() => setVendorModalOpen(false)}
                                handleAddVendor={handleFetchVendors}
                            />
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
