export interface ICapacityPlanning {
    guid: string;
    allocatedHours: number;
    date: string;
    userGuid: string;
    userName: string;
    projectGuid: string;
    projectName: string;
}

export const capacityPlanningInit: ICapacityPlanning = {
    guid: "",
    allocatedHours: 0,
    date: "",
    userGuid: "",
    userName: "",
    projectGuid: "",
    projectName: "",
};

export interface IUserWeeklyCapacityPlanning {
    userGuid: string;
    userName: string;
    userContractualHours: number;
    userAvailableHours: number;
    allocatedWeeklyHours: number;
    capacityPlannings: ICapacityPlanning[];
}

export interface IProjectWeeklyCapacityPlanning {
    projectGuid: string;
    projectName: string;
    allocatedWeeklyHours: number;
    capacityPlannings: ICapacityPlanning[];
}

// ensure Mondays only to count weeks
export const getMondayDate = (date: Date) => {
    while (date.getDay() !== 1) date.setDate(date.getDate() - 1);
    return date;
};
