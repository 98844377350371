import { Tab } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import { GeneralSettings } from "./GeneralSettings";
import { CompanySettings } from "./CompanySettings";
import { EmployeeSettings } from "./EmployeesSettings";
import { UserRole } from "../../actions/authentificationActions";
import { Unauthorized } from "../../components/Unauthorized/Unauthorized";
import "./Settings.css";

export const Settings = () => {
    const user = useSelector((state: AppState) => state.user);

    const panes = [
        {
            menuItem: "General",
            render: () => (
                <Tab.Pane className="settings-pane">
                    <GeneralSettings />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Company",
            render: () => (
                <Tab.Pane className="settings-pane">
                    <CompanySettings />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Employees",
            render: () => (
                <Tab.Pane className="settings-pane">
                    <EmployeeSettings />
                </Tab.Pane>
            ),
        },
    ];

    return (
        <div className="settings-container">
            {user.role !== UserRole.Admin ? (
                <Unauthorized />
            ) : (
                <>
                    <div className="container-header">
                        <h1>Settings</h1>
                    </div>

                    <Tab
                        id="settings-tabs"
                        menu={{ fluid: true, vertical: true, tabular: true }}
                        panes={panes}
                    />
                </>
            )}
        </div>
    );
};
