import axios from "axios";
import { IProject } from "../models/project";
import { DropdownItemProps } from "semantic-ui-react";
import { IPaginationWrapper } from "../models/common";
import { IProjectProjectRole, IProjectRole } from "../models/projectRole";

export const getProjects = async (page: number, status: number, query?: string) => {
    const params = new URLSearchParams({ page: page + "" });
    params.append("status", status.toString());
    query && params.append("query", query);
    const response = await axios.get("/api/projects?" + params.toString());
    return response.data as IPaginationWrapper;
};

export const getProject = async (guid: string) => {
    const response = await axios.get(`/api/projects/${guid}`);
    return response.data as IProject;
};

export const getProjectsAsProps = async (userGuid?: string) => {
    const params = new URLSearchParams();
    userGuid && params.append("userGuid", userGuid);
    const response = await axios.get(`/api/projects/props?${params}`);
    return response.data as DropdownItemProps[];
};

export const getProjectAccessRequets = async (page: number, status: number) => {
    const params = new URLSearchParams({ page: page.toString(), status: status.toString() });
    params.append("status", status.toString());
    const response = await axios.get(`/api/projects/access-requests?${params.toString()}`);
    return response.data as IPaginationWrapper;
};
export const editProject = async (project: IProject) => {
    const response = await axios.put(`/api/projects/${project.guid}`, project);
    return response;
};
export const deleteProject = async (guid: string) => {
    const response = await axios.delete(`/api/projects/${guid}`);
    return response;
};
export const createProject = async (projectDto: Partial<IProject>) => {
    const response = await axios.post("/api/projects", projectDto);
    return response;
};
export const submitProjectAccessRequest = async (guid: string) => {
    const response = await axios.post(`/api/projects/requests/${guid}`);
    return response;
};
export const processProjectAccessRequest = async (guids: string[], choice: boolean) => {
    const response = await axios.post(`/api/projects/process-requests?choice=${choice}`, guids);
    return response;
};
export const getProjectUsersAsProps = async (projectGuid: string) => {
    const response = await axios.get(`/api/projects/projectUsers/${projectGuid}`);
    return response.data as DropdownItemProps[];
};
export const getProjectRoles = async (page: number) => {
    const response = await axios.get(`/api/projectRoles?page=${page}`);
    return response.data as IPaginationWrapper;
};
export const getProjectRolesByProject = async (projectGuid: string) => {
    const response = await axios.get(`/api/projectRoles/project/${projectGuid}`);
    return response.data as IProjectProjectRole[];
};
export const createProjectRole = async (projectRoleDto: Partial<IProjectRole>) => {
    const response = await axios.post(`/api/projectRoles`, projectRoleDto);
    return response.data as IProjectRole;
};
export const updateProjectRole = async (projectRoleDto: IProjectRole) => {
    const response = await axios.put(`/api/projectRoles/${projectRoleDto.guid}`, projectRoleDto);
    return response;
};
export const getProjetRolesAsProps = async () => {
    const response = await axios.get(`/api/projectRoles/props`);
    return response.data as DropdownItemProps[];
}
export const addRoleToProject = async (projectProjectRoleDto: IProjectProjectRole) => {
    const response = await axios.post(`/api/projectRoles/addRoleToProject`, projectProjectRoleDto);
    return response;
};
export const updateRoleAssignees = async (projectProjectRoleDtos: IProjectProjectRole[]) => {
    const response = await axios.patch(
        `/api/projectRoles/updateRoleAssignees`,
        projectProjectRoleDtos
    );
    return response;
};
export const deleteProjectRole = async (guid: string) => {
    const response = await axios.delete(`/api/projectRoles/${guid}`);
    return response;
};
export const removeRoleFromProject = async (projectProjectRoleGuid: string) => {
    const response = await axios.delete(
        `/api/projectRoles/removeRoleFromProject/${projectProjectRoleGuid}`
    );
};
