import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Button, Modal, Popup, Label } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { deleteClientContract } from "../../api/clientContract";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import { IClientContract } from "../../models/clientContract";
import { DocumentType, IUploadDocument } from "../../models/document";
import { createDocument, downloadDocument } from "../../services/documentsService";
import { AppState } from "../../store/configureStore";
import { EditClientContractForm } from "./EditClientContractForm";

interface IProps {
    clientContract: IClientContract;
    handleFetchClientContracts: () => void;
}
export const ClientContractRow = ({ clientContract, handleFetchClientContracts }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [clientContractDTO, setClientContractDTO] = useState(clientContract);
    const [openDropzone, setOpenDropzone] = useState(false);
    const [loading, setLoading] = useState(false);
    const uploadDocument: IUploadDocument = {
        documentType: DocumentType.Contract,
        entityGuid: clientContractDTO.guid,
    };
    const handleDeleteClientContract = async () => {
        try {
            await deleteClientContract(clientContract.guid);
            handleFetchClientContracts();
            toast("Successfully deleted client contract", true);
        } catch {
            toast("Failed to delete client contract", false);
        }
    };

    const handleUploadDocument = async (files: File[]) => {
        setLoading(true);
        try {
            await createDocument(uploadDocument, files[0]);
            handleFetchClientContracts();
            toast("Contract successfully uploaded", true);
        } catch {
            toast("Failed to upload contract, please try again", false);
        } finally {
            setLoading(false);
            setOpenDropzone(false);
        }
    };

    const onRowClick = () => {
        setEditModalOpen(true);
    };

    return (
        <Fragment>
            <Table.Row key={clientContract.guid} onClick={onRowClick} className="clickable-row">
                <Table.Cell>{getDisplayDateFormat(clientContract.startDate)}</Table.Cell>
                <Table.Cell>{getDisplayDateFormat(clientContract.endDate)}</Table.Cell>
                <Table.Cell>{clientContract.value}</Table.Cell>
                <Table.Cell>
                    {clientContract.contractIsExpiring ? (
                        <Label circular size="large" color="red" content="Contract expiring" />
                    ) : clientContract.contractExpired ? (
                        "Contract expired"
                    ) : (
                        "Active contract"
                    )}
                </Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    <Button
                        color="olive"
                        content="Upload"
                        icon="upload"
                        onClick={() => setOpenDropzone(true)}
                    />
                    <Popup
                        content="No contract uploaded"
                        disabled={clientContract.documentGuid != null}
                        trigger={
                            <span>
                                <Button
                                    disabled={!clientContract.documentGuid}
                                    color="purple"
                                    content="Download"
                                    icon="download"
                                    onClick={() => downloadDocument(clientContract.documentGuid)}
                                />
                            </span>
                        }
                    />
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this client contract?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteClientContract} content="Confirm" />
                </Modal.Actions>
            </Modal>
            <EditClientContractForm
                clientContract={clientContractDTO}
                setClientContract={setClientContractDTO}
                open={editModalOpen}
                closeModal={() => setEditModalOpen(false)}
                handleEditClientContract={handleFetchClientContracts}
            />
            <Modal open={openDropzone} onClose={() => setOpenDropzone(false)} dimmer="blurring">
                <Modal.Header>Please upload client contract</Modal.Header>
                <Modal.Content>
                    <FileDropzone
                        title="Upload contract"
                        handleUploadFiles={handleUploadDocument}
                        loading={loading}
                    />
                </Modal.Content>
            </Modal>
        </Fragment>
    );
};
