import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Icon, Input, Modal, Radio } from "semantic-ui-react";
import { toast } from "../..";
import { vehicleInit } from "../../models/vehicle";
import { createVehicle } from "../../services/vehiclesService";
import { fetchStaff } from "../../store/staffReducer";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddVehicle: () => void;
}

export const AddVehicleForm: React.FC<IProps> = ({ open, closeModal, handleAddVehicle }) => {
    const dispatch = useDispatch();
    const [newVehicle, setNewVehicle] = useState(vehicleInit);

    const handleAddNewVehicle = async () => {
        const res = await createVehicle(newVehicle);
        if (!res.ok) toast("Error creating vehicle", false);
        else {
            handleAddVehicle();
            fetchStaff(dispatch);
            toast("Vehicle successfully added", true, 1500);
        }
        closeModal();
    };

    return (
        <Modal
            className="add-vehicle-modal"
            open={open}
            onClose={closeModal}
            dimmer="blurring"
            centered={false}
        >
            <Modal.Header>
                <h2>Add new vehicle</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-vehicle-form">
                    <div className="input-container">
                        <label>Manufacturer</label>
                        <Input
                            type="manufacturer"
                            value={newVehicle.manufacturer}
                            onChange={(e: any) =>
                                setNewVehicle({ ...newVehicle, manufacturer: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Model</label>
                        <Input
                            type="manufacturer"
                            value={newVehicle.model}
                            onChange={(e: any) =>
                                setNewVehicle({ ...newVehicle, model: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Registration Plate</label>
                        <Input
                            type="registrationPlate"
                            value={newVehicle.registrationPlate}
                            onChange={(e: any) =>
                                setNewVehicle({ ...newVehicle, registrationPlate: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Vehicle Identification number</label>
                        <Input
                            type="vin"
                            value={newVehicle.vin}
                            onChange={(e: any) =>
                                setNewVehicle({ ...newVehicle, vin: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Color</label>
                        <Input
                            value={newVehicle.color}
                            onChange={(e: any) =>
                                setNewVehicle({ ...newVehicle, color: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Active</label>
                        <Radio
                            toggle
                            name="active"
                            checked={newVehicle.active}
                            onChange={(e, data: any) =>
                                setNewVehicle({
                                    ...newVehicle,
                                    active: !newVehicle.active,
                                })
                            }
                            disabled={false}
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new vehicle"
                    disabled={newVehicle.manufacturer === ""}
                    secondary
                    fluid
                    onClick={handleAddNewVehicle}
                />
            </Modal.Actions>
        </Modal>
    );
};
