import { getAuthorizationProperties } from "./authorizationHelper";
import { IPaginationWrapper } from "../models/common";
import { IDepartment } from "../models/department";
import axios from "axios";

export async function getDepartments(page: number): Promise<IPaginationWrapper> {
    return await fetch("/api/departments?page=" + page, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export const getDepartmentsAsProps = async () => {
    const response = await axios.get("/api/departments/list/");
    return response.data as IDepartment[];
};

export async function deleteDepartment(guid: string): Promise<Response> {
    return await fetch("/api/departments/" + guid, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function createDepartment(departmentDto: Partial<IDepartment>): Promise<Response> {
    return await fetch("/api/departments/", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(departmentDto),
    });
}

export async function getDepartment(guid: string): Promise<IDepartment> {
    return await fetch("/api/departments/" + guid, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function editDepartment(department: IDepartment): Promise<Response> {
    return await fetch("/api/departments/" + department.guid, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(department),
    });
}
