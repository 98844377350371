import { ApproveStatus } from "./common";

export interface IIdeaBox {
    guid: string;
    user: string;
    userGuid: string;
    title: string;
    description: string;
    created: string;
    status: ApproveStatus;
    comment: string;
    isAnonymous: boolean;
}

export const ideaInit: Partial<IIdeaBox> = {
    title: "",
    description: "",
    user: "",
    userGuid: "",
    comment: "",
    isAnonymous: false,
};
