import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, DropdownItemProps, Segment, Select, Table } from "semantic-ui-react";
import { toast } from "../..";
import { AppState } from "../../store/configureStore";
import { UserRole } from "../../actions/authentificationActions";
import { updateBuyRequest, processBuyRequest, completeBuyRequest } from "../../api/buyRequests";
import { IBuyRequest } from "../../models/buyRequests";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { ApproveStatus } from "../../models/common";
import { getInvoiceList } from "../../services/invoicesService";
import { getInventoryItemsList } from "../../api/inventoryItems";
import { TextInput } from "../../components/TextInput/TextInput";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import "./BuyRequests.css";

interface IProps {
    buyRequest: IBuyRequest;
    setBuyRequest: Dispatch<SetStateAction<IBuyRequest | undefined>>;
}

export const EditBuyRequest = ({ buyRequest, setBuyRequest }: IProps) => {
    const staff = useSelector((state: AppState) => state.staff);
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const disabled = user.role === UserRole.Admin || buyRequest.status !== ApproveStatus.Draft;
    const [loadingApprove, setLoadingApprove] = useState(false);
    const [loadingReject, setLoadingReject] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [invoices, setInvoices] = useState<DropdownItemProps[]>([]);
    const [inventoryItems, setInventoryItems] = useState<DropdownItemProps[]>([]);

    const fetchInvoices = useCallback(async () => {
        const res = await getInvoiceList();
        setInvoices(res);
    }, []);

    const fetchInventoryItems = useCallback(async () => {
        const res = await getInventoryItemsList(buyRequest.invoiceGuid);
        setInventoryItems(res);
    }, [buyRequest.invoiceGuid]);

    const handleInputChange = (e: any) =>
        setBuyRequest({ ...buyRequest!, [e.target.name]: e.target.value });

    const handleEditBuyRequest = async () => {
        try {
            await updateBuyRequest(buyRequest);
            toast("Successfully updated buy request", true);
            history.push("/requests/buy-requests");
        } catch {
            toast("Error updating buy request", false);
        }
    };

    const handleAcceptBuyRequest = async () => {
        try {
            setLoadingApprove(true);
            if (buyRequest.purchaseAssigneeGuid !== "00000000-0000-0000-0000-000000000000") {
                await updateBuyRequest(buyRequest);
                await processBuyRequest(buyRequest.guid, true);
                toast("Buy request successfully approved", true);
                history.push("/requests/buy-requests");
            } else {
                toast(
                    "You must select an employee responsible for purchase before approving this buy request",
                    false
                );
            }
        } catch {
            toast("Error saving buy request", false);
        } finally {
            setLoadingApprove(false);
        }
    };

    const handleRejectBuyRequest = async () => {
        try {
            setLoadingReject(true);
            if (buyRequest.rejectReason) {
                await updateBuyRequest(buyRequest);
                await processBuyRequest(buyRequest.guid, false);
                toast("Buy request successfully rejected", true);
                history.push("/requests/buy-requests");
            } else {
                toast("Please enter a reason why the buy request was rejected", false);
            }
        } catch {
            toast("Error saving buy request", false);
        } finally {
            setLoadingReject(false);
        }
    };

    const handleCompleteBuyRequest = async () => {
        setLoadingApprove(true);
        setLoadingComplete(true);
        try {
            await completeBuyRequest(buyRequest.guid);
            toast("Buy request successfully completed", true);
            history.push("/requests/buy-requests");
        } catch {
            toast("Unable to complete buy request", false);
        } finally {
            setLoadingApprove(false);
            setLoadingComplete(false);
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, [fetchInvoices]);

    useEffect(() => {
        fetchInventoryItems();
    }, [fetchInventoryItems]);

    return (
        <Segment.Group>
            <Segment>
                <h2>Edit buy request</h2>
                {user.role === UserRole.Admin && (
                    <Button
                        secondary
                        content="Save changes"
                        icon="save"
                        onClick={handleEditBuyRequest}
                    />
                )}
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Item</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="title"
                                    value={buyRequest.title}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Estimated value (HRK)</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="estimatedValue"
                                    value={buyRequest.estimatedValue}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Description</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="description"
                                    value={buyRequest.description}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                    isTextArea
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Reason for purchase</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="reason"
                                    value={buyRequest.reason}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                    isTextArea
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Date of request</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="dateOfRequest"
                                    value={getDisplayDateFormat(buyRequest.dateOfRequest)}
                                    handleInputChange={handleInputChange}
                                    disabled
                                />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>Requested from</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={buyRequest.userGuid}
                                    options={staff}
                                    disabled={true}
                                    onChange={(e, data: any) =>
                                        setBuyRequest({
                                            ...buyRequest,
                                            user: data.value,
                                        })
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                        {user.role === UserRole.Admin && (
                            <>
                                <Table.Row>
                                    <Table.Cell>Reject reason</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="rejectReason"
                                            value={buyRequest.rejectReason}
                                            handleInputChange={handleInputChange}
                                            disabled={user.role !== UserRole.Admin}
                                            isTextArea
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row className="row-with-spacing">
                                    <Table.Cell>Employee responsible for purchase</Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            id="select"
                                            selectOnBlur={false}
                                            closeOnChange
                                            clearable
                                            search
                                            placeholder={"Not assigned"}
                                            value={buyRequest.purchaseAssigneeGuid}
                                            options={staff}
                                            onChange={(e, data: any) => {
                                                setBuyRequest({
                                                    ...buyRequest,
                                                    purchaseAssigneeGuid: data.value,
                                                });
                                                let input = document.getElementById("select")!
                                                    .firstChild! as HTMLElement;
                                                setTimeout(x => input.blur(), 1); //only for gangsters!
                                            }}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Invoice</Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            clearable
                                            selectOnBlur={false}
                                            placeholder={"No related invoice"}
                                            value={buyRequest.invoiceGuid}
                                            options={invoices}
                                            onChange={(e, data: any) =>
                                                setBuyRequest({
                                                    ...buyRequest,
                                                    invoiceGuid: data.value,
                                                })
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                {buyRequest.invoiceGuid && (
                                    <Table.Row>
                                        <Table.Cell>Inventory items</Table.Cell>
                                        <Table.Cell>
                                            <Select
                                                multiple
                                                clearable
                                                selectOnBlur={false}
                                                placeholder="No related inventory item"
                                                value={buyRequest.inventoryItemGuids}
                                                options={inventoryItems}
                                                onChange={(e, data: any) =>
                                                    setBuyRequest({
                                                        ...buyRequest,
                                                        inventoryItemGuids: data.value,
                                                    })
                                                }
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                                <Table.Row>
                                    <Table.Cell>Status</Table.Cell>
                                    <Table.Cell>
                                        <Button
                                            className="buy-request-details-button"
                                            color="green"
                                            content="Approve"
                                            icon="calendar check"
                                            disabled={buyRequest.status === ApproveStatus.Approved}
                                            loading={loadingApprove}
                                            onClick={handleAcceptBuyRequest}
                                        />
                                        <Button
                                            className="buy-request-details-button"
                                            color="red"
                                            content="Reject"
                                            icon="calendar times"
                                            disabled={buyRequest.status === ApproveStatus.Rejected}
                                            loading={loadingReject}
                                            onClick={handleRejectBuyRequest}
                                        />

                                        {buyRequest.status !== ApproveStatus.Completed && (
                                            <Button
                                                className="buy-request-details-button"
                                                color="olive"
                                                content="Complete"
                                                icon="check"
                                                disabled={user.role !== UserRole.Admin}
                                                loading={loadingComplete}
                                                onClick={() => handleCompleteBuyRequest()}
                                            />
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            </>
                        )}
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                {user.role === UserRole.Admin && (
                    <Button
                        secondary
                        content="Save changes"
                        icon="save"
                        onClick={handleEditBuyRequest}
                    />
                )}
            </Segment>
        </Segment.Group>
    );
};
