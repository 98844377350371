import { Label } from "semantic-ui-react";
import { WorkAttendance } from "../../models/workAttendance";

export const getHomepageStatusColor = (status: WorkAttendance | string) => {
    switch (status) {
        case WorkAttendance.Present:
        case WorkAttendance.Office:
            return "green";
        case WorkAttendance.Absent:
        case WorkAttendance.ParentalLeave:
        case WorkAttendance.Vacation:
        case WorkAttendance.PaidLeave:
            return "red";
        case WorkAttendance.RemoteWork:
        case WorkAttendance.Remote:
            return "purple";
        case WorkAttendance.Weekend:
            return "yellow";
        case WorkAttendance.SickLeave:
            return "black";
        default:
            return "grey";
    }
};

export const HomeInfoLegend = () => {
    return (
        <div className="home-info-legend-container">
            <div>
                <span>At work</span>
                <Label size="large" circular color="green" />
            </div>
            <div>
                <span>Remote</span>
                <Label size="large" circular color="purple" />
            </div>
            <div>
                <span>Absent</span>
                <Label size="large" circular color="red" />
            </div>
            <div>
                <span>Sick leave</span>
                <Label size="large" circular color="black" />
            </div>
            <div>
                <span>Weekend/Holiday</span>
                <Label size="large" circular color="yellow" />
            </div>
        </div>
    );
};