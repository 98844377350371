import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { HeaderDropdown } from "../HeaderDropdown";
import {
    clientsAndVendorsItems,
    employeeDataItems,
    projectsItems,
    requestItems,
} from "../navbarItems";

export const EmployeeHeaders = () => {
    return (
        <Fragment>
            <Menu.Item>
                <HeaderDropdown title="My Data" items={employeeDataItems} />
            </Menu.Item>
            <Menu.Item>
                <HeaderDropdown title="Projects" items={projectsItems} />
            </Menu.Item>
            <Menu.Item>
                <HeaderDropdown title="Clients and Vendors" items={clientsAndVendorsItems} />
            </Menu.Item>
            <Menu.Item>
                <HeaderDropdown title="Requests" items={requestItems} />
            </Menu.Item>
            <Menu.Item>
                <Link
                    className="link-header"
                    to={{
                        pathname: "/contacts",
                    }}
                >
                    Contacts
                </Link>
            </Menu.Item>
        </Fragment>
    );
};
