import { DropdownItemProps } from "semantic-ui-react";

export interface IClientContact {
    guid: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    client: string;
    clientGuid: string;
    accessRights: string[];
}

export const clientContactInit: Partial<IClientContact> = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    client: "",
    clientGuid: "",
    accessRights: [],
};
export interface IClientsDropdown {
    clients: DropdownItemProps[]
};
