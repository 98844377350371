import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Button, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { AppState } from "../../store/configureStore";
import { UserRole } from "../../actions/authentificationActions";
import { IService } from "../../models/service";
import { deleteService } from "../../api/services";

interface IProps {
    service: IService;
    handleRemoveService: (d: IService) => void;
    page: string;
}

export const ServiceRow = ({ service, handleRemoveService, page }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteService = async () => {
        try {
            const res = await deleteService(service.guid);
            handleRemoveService(service);
            toast("Successfully deleted service", true);
        } catch {
            toast("Failed to delete service", false);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `/services/${service.guid}`,
            state: page,
        });
    };

    return (
        <Fragment>
            <Table.Row onClick={onRowClick} className="clickable-row">
                <Table.Cell>{service.code}</Table.Cell>
                <Table.Cell>{service.serviceDescription}</Table.Cell>
                <Table.Cell>{service.unitMeasure}</Table.Cell>
                <Table.Cell>{service.netPrice}</Table.Cell>
                <Table.Cell>{service.discount}</Table.Cell>
                <Table.Cell>{service.taxRate}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this service?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteService} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
