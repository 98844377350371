import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Button, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { AppState } from "../../store/configureStore";
import { UserRole } from "../../actions/authentificationActions";
import { ICompanyServiceType } from "../../models/companyServiceType";
import { deleteCompanyServiceType } from "../../api/companyServicesType";

interface IProps {
    companyServiceType: ICompanyServiceType;
    handleRemoveCompanyServiceType: (d: ICompanyServiceType) => void;
    page: string;
}

export const CompanyServiceTypeRow = ({
    companyServiceType,
    handleRemoveCompanyServiceType,
    page,
}: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteCompanyServiceType = async () => {
        try {
            const res = await deleteCompanyServiceType(companyServiceType.guid);
            handleRemoveCompanyServiceType(companyServiceType);
            toast("Successfully deleted company service type", true);
        } catch {
            toast("Failed to delete company service type", false);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `/company-service-type/${companyServiceType.guid}`,
            state: page,
        });
    };

    return (
        <Fragment>
            <Table.Row onClick={onRowClick} className="clickable-row">
                <Table.Cell>{companyServiceType.number}</Table.Cell>
                <Table.Cell>{companyServiceType.type}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>
                    Are you sure you want to delete this company service type?
                </Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button
                        color="green"
                        onClick={handleDeleteCompanyServiceType}
                        content="Confirm"
                    />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
