import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    Button,
    DropdownItemProps,
    Input,
    Segment,
    Select,
    Table,
    TextArea,
} from "semantic-ui-react";
import { toast } from "../..";
import QRCode from "qrcode.react";
import { documentClassificationProps, documentTypeProps, IDocument } from "../../models/document";
import { getClientsAsProps } from "../../services/clientsService";
import { createDocumentWithoutUpload } from "../../api/documents";
import { AppState } from "../../store/configureStore";
import "./CreateDocument.css";

export const CreateDocuments = () => {
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [doc, setDoc] = useState<IDocument>({
        name: "",
        username: user.username ? user.username : "",
        guid: "",
        date: "",
        documentClassification: 0,
        documentType: 0,
        path: "",
        comment: "",
        userOfDocument: "",
        externalGuid: "",
    });
    const [userOfDocumentProps, setUserOfDocumentProps] = useState<DropdownItemProps[]>([]);
    const [disableCreate, setDisableCreate] = useState(true);

    const fetchDropdownItemProps = async () => {
        const clientProps = await getClientsAsProps();
        let index = 0;
        let props: DropdownItemProps[] = [];
        clientProps.forEach(c => {
            props.push({ key: index, text: c.text, value: c.text?.toString() });
            index++;
        });
        props.push({ key: index, text: "Other", value: "Other" });
        setUserOfDocumentProps(props);
    };

    const handleInputChange = (e: any) => setDoc({ ...doc!, [e.target.name]: e.target.value });

    const handleSaveDocument = async () => {
        setLoading(true);
        try {
            await createDocumentWithoutUpload(doc!);
            toast("Document successfully created", true);
            history.push("/administration/documents");
        } catch {
            toast("Document creation failed, please try again", false);
            setLoading(false)
        }
    };

    const updateDisableCreate = () => {
        if (!doc.name) setDisableCreate(true);
        else setDisableCreate(false);
    };

    const handleGenerateGuid = () => {
        const guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
            let r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
        setDoc({ ...doc, externalGuid: guid });
    };

    useEffect(() => {
        fetchDropdownItemProps();
    }, []);

    useEffect(() => {
        updateDisableCreate();
    }, [doc]);

    return (
        <div className="create-documents-container">
            <div className="container-header">
                <h1>Create a document</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/administration/documents", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            <Segment.Group>
                <Segment>
                    <h2>Document details</h2>
                </Segment>
                <Segment>
                    <Table basic="very" celled compact className="create-document-table">
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Name</Table.Cell>
                                <Table.Cell>
                                    <Input name="name" onChange={handleInputChange} />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Type</Table.Cell>
                                <Table.Cell>
                                    <Select
                                        name="documentType"
                                        defaultValue={doc.documentType}
                                        options={documentTypeProps}
                                        onChange={(e, data: any) =>
                                            setDoc({
                                                ...doc,
                                                documentType: data.value,
                                            })
                                        }
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Classification</Table.Cell>
                                <Table.Cell>
                                    <Select
                                        name="documentClassification"
                                        defaultValue={doc.documentClassification}
                                        options={documentClassificationProps}
                                        onChange={(e, data: any) =>
                                            setDoc({
                                                ...doc,
                                                documentClassification: data.value,
                                            })
                                        }
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>User of the document</Table.Cell>
                                <Table.Cell>
                                    <Input
                                        name="userOfDocument"
                                        defaultValue={doc.userOfDocument}
                                        list="list"
                                        clearable
                                        onChange={handleInputChange}
                                    />
                                    <datalist id="list">
                                        {userOfDocumentProps.map(u => {
                                            return (
                                                <option
                                                    key={u.value?.toString()}
                                                    value={u.value?.toString()}
                                                >
                                                    {u.value}
                                                </option>
                                            );
                                        })}
                                    </datalist>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>External GUID</Table.Cell>
                                <Table.Cell>
                                    <Input
                                        name="externalGuid"
                                        value={doc.externalGuid}
                                        onChange={handleInputChange}
                                    />
                                    <Button
                                        content="Generate"
                                        color="green"
                                        className="generate-guid-button"
                                        onClick={handleGenerateGuid}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            {doc.externalGuid && (
                                <Table.Row>
                                    <Table.Cell>QR code</Table.Cell>
                                    <Table.Cell>
                                        <QRCode
                                            size={180}
                                            id="guid-qr-code"
                                            value={`${window.location.host}/document-verification/${doc.externalGuid}`}
                                            bgColor="white"
                                            fgColor="black"
                                            level="H"
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            <Table.Row>
                                <Table.Cell>Comment</Table.Cell>
                                <Table.Cell>
                                    <TextArea
                                        name="comment"
                                        value={doc.comment}
                                        onChange={handleInputChange}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Segment>
            </Segment.Group>

            <div>
                <Button
                    secondary
                    fluid
                    content="Create"
                    icon="add"
                    onClick={handleSaveDocument}
                    disabled={loading || !doc || disableCreate}
                />
            </div>
        </div>
    );
};
