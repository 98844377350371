import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { updateIdeaBox } from "../../api/ideaBoxes";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import { TextInput } from "../../components/TextInput/TextInput";
import { approveStatusTypes } from "../../models/approveStatus";
import { IIdeaBox } from "../../models/ideaBox";
import { AppState } from "../../store/configureStore";

interface IProps {
    idea: IIdeaBox;
    setIdea: Dispatch<SetStateAction<IIdeaBox | undefined>>;
}

export const IdeaBoxEdit = ({ idea, setIdea }: IProps) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const user = useSelector((state: AppState) => state.user);

    const handleEditIdea = async () => {
        setLoading(true);
        try {
            await updateIdeaBox(idea);
            toast("Successfully saved changes", true);
            history.push("/requests/ideas");
        } catch {
            toast("Failed to save changes.", false);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e: any) => {
        setIdea({ ...idea, [e.target.name]: e.target.value });
    };

    return (
        <Segment.Group>
            <Segment>
                <h2>Edit idea details</h2>
            </Segment>
            <Segment>
                <Table basic="very">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Title</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="title"
                                    value={idea.title}
                                    handleInputChange={handleInputChange}
                                    disabled
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Description</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="description"
                                    value={idea.description}
                                    handleInputChange={handleInputChange}
                                    disabled
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Status</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    options={approveStatusTypes}
                                    value={idea.status ?? ""}
                                    onChange={(e, data: any) =>
                                        setIdea({ ...idea, status: +data.value })
                                    }
                                    disabled={user.role !== UserRole.Admin}
                                />
                            </Table.Cell>
                        </Table.Row>
                        {user.role === UserRole.Admin && (
                            <Table.Row>
                                <Table.Cell>Comment</Table.Cell>
                                <Table.Cell>
                                    <TextInput
                                        name="comment"
                                        value={idea.comment}
                                        handleInputChange={handleInputChange}
                                        disabled={user.role !== UserRole.Admin}
                                        isTextArea
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                <Button
                    secondary
                    loading={loading}
                    icon="save"
                    content="Save"
                    onClick={handleEditIdea}
                    disabled={loading}
                />
            </Segment>
        </Segment.Group>
    );
};
