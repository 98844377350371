import { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Segment,
    Input,
    Select,
    TextArea,
    Button,
    Icon,
    DropdownItemProps,
    Checkbox,
} from "semantic-ui-react";
import { IInventoryItem, inventoryItemEmpty, inventoryItemTypes } from "../../models/inventoryItem";
import { AppState } from "../../store/configureStore";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { saveInventoryItems } from "../../api/inventoryItems";
import { toast } from "../..";
import { useHistory, useLocation } from "react-router-dom";
import { getInvoiceList } from "../../services/invoicesService";
import "./InventoryItem.css";

export const CreateInventoryItemForm = () => {
    const loc = useLocation();
    const staff = useSelector((state: AppState) => state.staff);
    const [inventoryItem, setInventoryItem] = useState<IInventoryItem[]>([]);
    const [saving, setSaving] = useState(false);
    const history = useHistory();
    const [invoices, setInvoices] = useState<DropdownItemProps[]>([]);
    const [disabled, setDisabled] = useState(true);

    const handleSaveChanges = async () => {
        setSaving(true);
        try {
            await saveInventoryItems(inventoryItem);
            toast("Successfully saved changes", true);
            history.push("/company/inventories");
        } catch (error: any) {
            if (error.response.data.length > 0 && error.response.data[0].description) {
                toast(error.response.data[0].description, false);
            } else {
                toast("Problem saving changes", false);
            }
        } finally {
            setSaving(false);
        }
    };

    const fetchInvoices = useCallback(async () => {
        const res = await getInvoiceList();
        setInvoices(res);
    }, []);

    const handleInputChange = (e: any, i: number) => {
        let newState = [...inventoryItem];
        newState[i][e.target.name] = e.target.value;
        setInventoryItem(newState);
        handleDisabled();
    };

    const handleBoolChange = (e: any, i: number) => {
        let newState = [...inventoryItem];
        newState[i].isComputer = !newState[i].isComputer;
        setInventoryItem(newState);
        handleDisabled();
    };

    const handleDateChange = (e: any, i: number) => {
        let newState = [...inventoryItem];
        newState[i].date = e;
        setInventoryItem(newState);
    };

    const handleAssign = (data: any, i: number) => {
        let newState = [...inventoryItem];
        newState[i].assignedToGuid = data.value;
        setInventoryItem(newState);
    };

    const handleInvoice = (data: any, i: number) => {
        let newState = [...inventoryItem];
        newState[i].invoiceGuid = data.value;
        setInventoryItem(newState);
    };

    const handleCategory = (data: any, i: number) => {
        let newState = [...inventoryItem];
        newState[i].category = data.value;
        setInventoryItem(newState);
    };

    const handleDisabled = () =>
        inventoryItem.forEach(i => {
            setDisabled(!i.name || !i.value || !i.serialNo);
        });

    const handleAddNewInventoryItem = () => {
        setInventoryItem(inventoryItem.concat({ ...inventoryItemEmpty }));
    };

    useEffect(() => {
        handleAddNewInventoryItem();
        fetchInvoices();
    }, [fetchInvoices]);

    useEffect(() => {
        loc.state && inventoryItem.map(x => (x.invoiceGuid = loc.state as string));
    }, [inventoryItem, inventoryItem.length, loc.state]);

    const handleRemoveInventoryItem = (r: IInventoryItem) =>
        setInventoryItem(inventoryItem.filter(x => x !== r));

    return (
        <div className="inventory-items-container">
            <Segment.Group>
                <Segment>
                    <h2>Create inventory item</h2>
                    <Button
                        secondary
                        content="Back to list"
                        icon="arrow left"
                        onClick={() => history.push("/company/inventories")}
                    />
                </Segment>
                <Segment>
                    {inventoryItem.map((x, i) => (
                        <section key={i} className="inventory-item">
                            <div className="inventory-item-form">
                                <div className="input-container">
                                    <label>Name*</label>
                                    <Input
                                        name="name"
                                        value={x.name}
                                        onChange={(e: any) =>
                                            handleInputChange(
                                                e,
                                                inventoryItem.findIndex(u => u === x)
                                            )
                                        }
                                    />
                                </div>
                                <div className="input-container">
                                    <div className="label-checkbox">
                                        <label id="label">Serial No*</label>
                                        <Checkbox
                                            className="is-computer-checkbox"
                                            name="isComputer"
                                            label="Item is computer"
                                            toggle
                                            checked={x.isComputer}
                                            onChange={(e: any) =>
                                                handleBoolChange(
                                                    e,
                                                    inventoryItem.findIndex(u => u === x)
                                                )
                                            }
                                        />
                                    </div>
                                    <Input
                                        name="serialNo"
                                        value={x.serialNo}
                                        onChange={(e: any) =>
                                            handleInputChange(
                                                e,
                                                inventoryItem.findIndex(u => u === x)
                                            )
                                        }
                                    />
                                    {x.isComputer && (
                                        <div className="input-container">
                                            <label>Computer name</label>
                                            <Input
                                                name="computerName"
                                                value={x.computerName}
                                                onChange={(e: any) =>
                                                    handleInputChange(
                                                        e,
                                                        inventoryItem.findIndex(u => u === x)
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="input-container">
                                    <label>Date of purchase</label>
                                    <CalendarInput
                                        date={x.date}
                                        placeholder="Select date..."
                                        handleDateChange={e =>
                                            handleDateChange(
                                                getISODateFormat(e),
                                                inventoryItem.findIndex(u => u === x)
                                            )
                                        }
                                    />
                                </div>
                                <div className="input-container">
                                    <label>Assign to</label>
                                    <Select
                                        clearable
                                        fluid
                                        name="assignedToGuid"
                                        placeholder="Select user..."
                                        selectOnBlur={false}
                                        options={staff}
                                        value={inventoryItem[i].assignedTo}
                                        onChange={(e, data: any) =>
                                            handleAssign(
                                                data,
                                                inventoryItem.findIndex(u => u === x)
                                            )
                                        }
                                    />
                                </div>
                                <div className="input-container">
                                    <label>Set Invoice</label>
                                    <Select
                                        search
                                        clearable
                                        fluid
                                        name="invoiceGuid"
                                        placeholder="Select invoice..."
                                        selectOnBlur={false}
                                        options={invoices}
                                        value={inventoryItem[i].invoiceGuid}
                                        onChange={(e, data: any) =>
                                            handleInvoice(
                                                data,
                                                inventoryItem.findIndex(u => u === x)
                                            )
                                        }
                                    />
                                </div>
                                <div className="input-container">
                                    <label>Value (HRK)*</label>
                                    <Input
                                        name="value"
                                        value={x.value}
                                        onChange={(e: any) =>
                                            handleInputChange(
                                                e,
                                                inventoryItem.findIndex(u => u === x)
                                            )
                                        }
                                    />
                                </div>
                                <div className="input-container">
                                    <label>Set Category</label>
                                    <Select
                                        clearable
                                        fluid
                                        name="category"
                                        placeholder="Select category..."
                                        selectOnBlur={false}
                                        options={inventoryItemTypes}
                                        value={inventoryItem[i].category}
                                        onChange={(e, data: any) =>
                                            handleCategory(
                                                data,
                                                inventoryItem.findIndex(u => u === x)
                                            )
                                        }
                                    />
                                </div>
                                <div className="input-container">
                                    <label>Location</label>
                                    <Input
                                        name="location"
                                        value={x.location}
                                        onChange={(e: any) =>
                                            handleInputChange(
                                                e,
                                                inventoryItem.findIndex(u => u === x)
                                            )
                                        }
                                    />
                                </div>
                                <div className="input-container">
                                    <label>Description</label>
                                    <TextArea
                                        rows={5}
                                        name="description"
                                        value={x.description}
                                        onChange={(e: any) =>
                                            handleInputChange(
                                                e,
                                                inventoryItem.findIndex(u => u === x)
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <Icon
                                className="remove-inventory-icon"
                                link
                                name="close"
                                size="big"
                                disabled={x.guid !== ""}
                                onClick={() => handleRemoveInventoryItem(x)}
                            />
                        </section>
                    ))}
                    <Button
                        className="add-new-inventory-item"
                        fluid
                        secondary
                        content="Add new inventory item"
                        icon="plus"
                        onClick={handleAddNewInventoryItem}
                    />
                </Segment>
                <Segment>
                    {inventoryItem.length > 0 && (
                        <Button
                            fluid
                            secondary
                            content="Save changes"
                            icon="save"
                            onClick={handleSaveChanges}
                            loading={saving}
                            disabled={disabled}
                        />
                    )}
                </Segment>
            </Segment.Group>
        </div>
    );
};
