export interface IDepartment {
    guid: string;
    name: string;
    users: string[];
}

export const departmentInit: Partial<IDepartment> = {
    name: "",
    users: [],
};
