import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, DropdownItemProps, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { getVendorContactsAsProps, updateVendor } from "../../api/vendors";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import { TextInput } from "../../components/TextInput/TextInput";
import { IVendor } from "../../models/vendor";
import { AppState } from "../../store/configureStore";

interface IProps {
    vendor: IVendor;
    setVendor: Dispatch<SetStateAction<IVendor | undefined>>;
}

export const EditVendor = ({ vendor, setVendor }: IProps) => {
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [saving, setSaving] = useState(false);
    const unauthorized = user.role !== UserRole.Admin;
    const [vendorContacts, setVendorContacts] = useState<DropdownItemProps[]>([]);

    const handleInputChange = (e: any) =>
        setVendor({ ...vendor!, [e.target.name]: e.target.value });

    const handleUpdateVendor = async () => {
        setSaving(true);
        try {
            await updateVendor(vendor);
            toast("Successfully saved changes", true);
            history.push("/company/vendors");
        } catch {
            toast("Failed to save changes, please try again", false);
        }
        setSaving(false);
    };

    const fetchVendorContacts = useCallback(async () => {
        const vendors = await getVendorContactsAsProps(vendor.guid);
        setVendorContacts(vendors);
    }, [vendor.guid]);

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateVendor}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    useEffect(() => {
        fetchVendorContacts();
    }, [fetchVendorContacts]);

    return (
        <Segment.Group>
            <Segment>
                <h2>Edit vendor details</h2>
                <SaveButton />
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={3}>Company name</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="companyName"
                                    value={vendor.companyName ?? ""}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Address</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="address"
                                    value={vendor.address}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Contact person</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={vendor.contactPersonGuid}
                                    options={vendorContacts}
                                    onChange={(e, data: any) => {
                                        setVendor({ ...vendor, contactPersonGuid: data.value });
                                    }}
                                    disabled={true}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Contact person email</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="email"
                                    value={vendor.email}
                                    handleInputChange={handleInputChange}
                                    disabled={true}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Contact person phone number</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="phoneNumber"
                                    value={vendor.phoneNumber}
                                    handleInputChange={handleInputChange}
                                    disabled={true}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>VAT number</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="vatNumber"
                                    value={vendor.vatNumber}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                <SaveButton />
            </Segment>
        </Segment.Group>
    );
};
