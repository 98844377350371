export interface IBoardData {
    guid?: string;
    title: string;
    boardCreateDate?: string;
    description?: string;
    imgSrc?: string;
    imgGuid: string;
    active: boolean;
    oldImgGuid: string;
    pinned: boolean;
    isRead: boolean;
}
export const newBoardInit: IBoardData = {
    //guid: "",
    title: "",
    description: "",
    imgGuid: "00000000-0000-0000-0000-000000000000",
    imgSrc: "",
    active: true,
    oldImgGuid: "",
    pinned: false,
    isRead: false,
};

export interface IBoardDataWrapper {
    pinned: IBoardData[];
    unpinned: IBoardData[];
}
