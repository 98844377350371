import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Label, Modal, Table, TableCell } from "semantic-ui-react";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { getRoleName } from "../../store/rolesReducer";
import { toast } from "../..";
import { useSelector } from "react-redux";
import { deleteUser } from "../../api/users";
interface IProps {
    user: UserData;
    handleDeleteUser: () => void;
}

export const UserRow = ({ user, handleDeleteUser }: IProps) => {
    const currentUser = useSelector((state: AppState) => state.user);
    const userRole = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();
    const handleDeleteClient = async () => {
        try {
            await deleteUser(user.guid);
            handleDeleteUser();
            toast("User successfully deleted", true, 1500);
        } catch {
            setModalOpen(false);
            toast("Failed to delete user", false);
        }
    };

    const onRowClick = () => {
        user.role !== UserRole.SuperAdmin && history.push(`/administration/users/${user.guid}`);
    };

    return (
        <Fragment>
            <Table.Row wid={5} key={user.guid} onClick={onRowClick} className="clickable-row">
                <Table.Cell>
                    {user.firstName} {user.lastName}{" "}
                    {!user.isActive && <span className="user-inactive">(inactive)</span>}
                </Table.Cell>
                <Table.Cell>{user.username}</Table.Cell>
                <Table.Cell>{getRoleName(user.role)}</Table.Cell>
                {currentUser.role !== UserRole.SuperAdmin && (
                    <TableCell>
                        <Label
                            circular
                            size="large"
                            color={user.hasRequiredDocuments ? "green" : "yellow"}
                            content={
                                user.hasRequiredDocuments
                                    ? "User documents uploaded"
                                    : "User documents missing"
                            }
                        />
                    </TableCell>
                )}
                <Table.Cell>
                    {user.isContractExpiring && (
                        <Label circular size="large" color="red" content="User contract expiring" />
                    )}
                </Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    <Button
                        color="red"
                        content="Delete"
                        icon="delete"
                        onClick={() => setModalOpen(true)}
                        disabled={userRole.role !== UserRole.SuperAdmin}
                    />
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this user?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteClient} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
