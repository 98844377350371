import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, DropdownItemProps, Icon, Loader } from "semantic-ui-react";
import { getVendorsAsProps } from "../../api/vendors";
import { getVendorContact } from "../../api/vendorsContacts";
import { IVendorContact } from "../../models/vendorContact";
import { EditVendorContact } from "./EditVendorContact";

export const VendorContactDetails = () => {
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [vendorContact, setVendorContact] = useState<IVendorContact>();
    const [vendors, setVendors] = useState<DropdownItemProps[]>([]);

    const fetchVendorContact = useCallback(async (id) => {
        setLoading(true);
        const res = await getVendorContact(id);
        const vendors = await getVendorsAsProps();
        res.guid && setVendorContact(res);
        Array.isArray(vendors) && setVendors(vendors);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchVendorContact(params.guid);
    }, [fetchVendorContact, params.guid]);
    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="client-contact-details-container">
            <div className="container-header">
                <h1>Vendor contact details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/company/vendor-contacts", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
                </div>
                {vendorContact ? (
                    <EditVendorContact
                        vendorContact={vendorContact}
                        setVendorContact={setVendorContact}
                        vendors={vendors}
                />
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Vendor contact
                </h2>
            )}
        </div>
    );
};
