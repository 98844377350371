import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Item, Loader, Message, Segment } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import agent from "../../api/agent";
import { IBoardData } from "../../models/board";
import { AppState } from "../../store/configureStore";
import { AddNewBoard } from "./AddNewBoard";
import BoardListItem from "./BoardListItem";
import { ResetUnread } from "../../actions/unreadBoardNewsActions";
import "./Board.css";

export const BoardList = () => {
    const [regularArticles, setRegularArticles] = useState<IBoardData[]>([]);
    const [pinnedArticles, setPinnedArticles] = useState<IBoardData[]>([]);
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [pinnedArticle, setPinnedArticle] = useState<IBoardData>();
    const dispatch = useDispatch();

    const fetchBoardNews = useCallback(async () => {
        setLoading(true);
        const res = await agent.Board.list(checked);
        setRegularArticles(res.unpinned);
        setPinnedArticles(res.pinned);
        setLoading(false);
    }, [checked]);

    const changePinToBoard = useCallback(async () => {
        if (pinnedArticle) {
            setLoading(true);
            await agent.Board.pinBoardNews(pinnedArticle.guid!);
            setPinnedArticle(undefined);
            fetchBoardNews();
        }
    }, [fetchBoardNews, pinnedArticle]);

    useEffect(() => {
        changePinToBoard();
    }, [changePinToBoard]);

    useEffect(() => {
        fetchBoardNews();
    }, [fetchBoardNews]);

    useEffect(() => {
        dispatch(ResetUnread());
    }, [dispatch]);

    function setMessage() {
        if (checked) {
            return "No deactivated news items found!";
        } else {
            return "No news items found!";
        }
    }

    const handleNewsRead = (boardItemGuid: string) => {
        let newRegularArticles = [...regularArticles];
        let targetArcticle = newRegularArticles.find(x => x.guid === boardItemGuid);
        if (targetArcticle) {
            targetArcticle.isRead = true;
            setRegularArticles(newRegularArticles);
            return;
        }

        let newPinnedArticles = [...pinnedArticles];
        targetArcticle = newPinnedArticles.find(x => x.guid === boardItemGuid);
        if (targetArcticle) {
            targetArcticle.isRead = true;
            setPinnedArticles(newPinnedArticles);
        }
    };

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <Fragment>
            {user.role === UserRole.Admin && (
                <Button
                    secondary
                    content="Add news"
                    icon="add"
                    onClick={() => setModalOpen(true)}
                />
            )}
            {user.role === UserRole.Admin && (
                <Checkbox
                    toggle
                    label="Show deactivated news"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                />
            )}
            <AddNewBoard
                open={modalOpen}
                closeModal={() => setModalOpen(false)}
                refreshBoard={fetchBoardNews}
            />
            {user.role !== UserRole.SuperAdmin && (
                <Segment>
                    {pinnedArticles.length === 0 && regularArticles.length === 0 ? (
                        <Message icon="info circle" header={setMessage()} floating size="small" />
                    ) : (
                        <div>
                            {pinnedArticles.length > 0 && (
                                <Item.Group divided className="pinned-articles-container">
                                    {pinnedArticles &&
                                        pinnedArticles.map(boardData => (
                                            <BoardListItem
                                                key={boardData.guid}
                                                boardData={boardData}
                                                refreshBoard={fetchBoardNews}
                                                user={user}
                                                setPinned={setPinnedArticle}
                                                boardDataRead={handleNewsRead}
                                            />
                                        ))}
                                </Item.Group>
                            )}
                            {regularArticles.length > 0 && (
                                <Item.Group divided className="regular-articles-container">
                                    {regularArticles &&
                                        regularArticles.map(boardData => (
                                            <BoardListItem
                                                key={boardData.guid}
                                                boardData={boardData}
                                                refreshBoard={fetchBoardNews}
                                                user={user}
                                                setPinned={setPinnedArticle}
                                                pinnedArticlesCount={pinnedArticles.length}
                                                boardDataRead={handleNewsRead}
                                            />
                                        ))}
                                </Item.Group>
                            )}
                        </div>
                    )}
                </Segment>
            )}
        </Fragment>
    );
};
