import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, DropdownItemProps, Icon, Loader, Segment, Select, Table } from "semantic-ui-react";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { getUser } from "../../api/users";
import { AppState } from "../../store/configureStore";
import { Image } from "../../components/Image/Image";
import { jobTitleTypes, locationOptions } from "../../models/appUser";
import { DocumentType } from "../../models/document";
import { getCurrentWorkAttendance } from "../../services/workAttendancesService";
import { getWorkAttendanceStatus } from "../../models/workAttendance";
import { CurrentWorkAttendance } from "../../models/workAttendance";
import { getProjectsAsProps } from "../../api/projects";

export const ContactDetails = () => {
    const user = useSelector((state: AppState) => state.user);
    const params: { guid: string } = useParams();
    const history = useHistory();
    const staff = useSelector((state: AppState) => state.staff);
    const [loading, setLoading] = useState(true);
    const [contactDetails, setContactDetails] = useState<UserData>();
    const [workAttendance, setWorkAttendance] = useState<CurrentWorkAttendance>();
    const [userProjects, setUserProjects] = useState<DropdownItemProps[]>([]);

    const fetchUser = useCallback(async () => {
        setLoading(true);
        const res = await getUser(params.guid);
        res.guid && setContactDetails(res);
        if (contactDetails?.guid) {
            const res = await getCurrentWorkAttendance(contactDetails.guid);
            setWorkAttendance(res);
        }
        setLoading(false);
    }, [contactDetails?.guid, params.guid]);

    const fetchUserProjects = useCallback(async () => {
        const projects = await getProjectsAsProps(params.guid);
        setUserProjects(projects);
    }, [params.guid]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    useEffect(() => {
        fetchUserProjects();
    }, [fetchUserProjects]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="contact-container">
            <div className="container-header">
                <h1>Contact details</h1>
                {user.role === UserRole.Admin && (
                    <Button
                        secondary
                        content="Back to list"
                        icon="arrow left"
                        onClick={() => history.push("/contacts")}
                    />
                )}
            </div>
            {contactDetails ? (
                <Fragment>
                    <Segment.Group>
                        <Segment>{<h2>Contact details</h2>}</Segment>
                        <Segment>
                            <Table basic="very" celled compact columns={4}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Work attendance</Table.Cell>
                                        <Table.Cell>
                                            {getWorkAttendanceStatus(
                                                workAttendance?.attendance,
                                                contactDetails?.role ?? ""
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>Phone number</Table.Cell>
                                        <Table.Cell>{contactDetails.phoneNumber}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Name</Table.Cell>
                                        <Table.Cell>{`${contactDetails.firstName} ${contactDetails.lastName}`}</Table.Cell>
                                        <Table.Cell>Manager</Table.Cell>
                                        <Table.Cell>{contactDetails.managerName}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Username</Table.Cell>
                                        <Table.Cell>{contactDetails.username}</Table.Cell>
                                        <Table.Cell>Team members</Table.Cell>
                                        <Table.Cell>
                                            <Select
                                                multiple
                                                value={contactDetails.teamMembersGuid}
                                                options={staff}
                                                disabled={true}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Job title</Table.Cell>
                                        <Table.Cell>
                                            {
                                                jobTitleTypes.find(
                                                    x => x.key === contactDetails?.jobTitle
                                                )?.text
                                            }
                                        </Table.Cell>
                                        <Table.Cell>User projects</Table.Cell>
                                        <Table.Cell>
                                            <Select
                                                multiple
                                                value={contactDetails.userProjectsGuid}
                                                options={userProjects}
                                                disabled={true}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Department</Table.Cell>
                                        <Table.Cell>{contactDetails.departmentGuid}</Table.Cell>
                                        <Table.Cell>Gitlab user name</Table.Cell>
                                        <Table.Cell>{contactDetails.gitlabUsername}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Company</Table.Cell>
                                        <Table.Cell>{contactDetails.company}</Table.Cell>
                                        <Table.Cell>Location</Table.Cell>
                                        <Table.Cell>
                                            {
                                                locationOptions.find(
                                                    x => x.key === contactDetails?.location
                                                )?.text
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Profile photo</Table.Cell>
                                        <Table.Cell>
                                            <Image
                                                guid={contactDetails?.guid ?? ""}
                                                type={DocumentType.UserPhoto}
                                            />
                                        </Table.Cell>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Segment.Group>
                </Fragment>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    User not found
                </h2>
            )}
        </div>
    );
};
