import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { Button, Feed, Message } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { getUserActivityForDate } from "../../api/newsFeed";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { formatDisplayDate } from "../../models/common";
import { INewsFeedItem } from "../../models/newsFeedItem";
import { AppState } from "../../store/configureStore";
import { NewsFeedItem } from "../NewsFeed/NewsFeedItem";

export const UserActivity = () => {
    const user = useSelector((state: AppState) => state.user);
    const params: { userGuid: string } = useParams();
    const loc = useLocation();
    const date = loc.state ? (loc.state as string) : getISODateFormat(new Date());
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [newsFeedItems, setNewsFeedItems] = useState<INewsFeedItem[]>([]);
    const [page, setPage] = useState(1);

    const fetchUserActivityForDate = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getUserActivityForDate(params.userGuid, date, page);
            setNewsFeedItems(nf => nf.concat(response.rows as INewsFeedItem[]));
            setTotalRows(response.totalRows);
        } catch {
            toast("Failed to load user activity for selected date", false);
        }
        setLoading(false);
    }, [params.userGuid, date, page]);

    useEffect(() => {
        fetchUserActivityForDate();
    }, [fetchUserActivityForDate]);

    return user.role !== UserRole.Admin ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="news-feed-container">
            <div className="container-header">
                <h1>User activity for {formatDisplayDate(date)}</h1>
            </div>
            {!loading && newsFeedItems.length === 0 ? (
                <Message
                    icon="info circle"
                    header="No news feed items found for selected user"
                    floating
                    size="small"
                />
            ) : (
                <div className="news-feed-display">
                    <Feed>
                        {newsFeedItems.map(x => (
                            <Fragment key={x.guid}>
                                <NewsFeedItem item={x} />
                                <div className="news-feed-separator"></div>
                            </Fragment>
                        ))}
                    </Feed>
                    {totalRows > page * 10 && (
                        <Button
                            secondary
                            fluid
                            content="Load more"
                            icon="spinner"
                            onClick={() => setPage(page + 1)}
                            disabled={loading}
                            loading={loading}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
