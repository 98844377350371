import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, DropdownItemProps, Segment, Table } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import { IInvoice } from "../../models/invoice";
import { AppState } from "../../store/configureStore";
import { toast } from "../..";
import { editInvoice } from "../../services/invoicesService";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { TextInput } from "../../components/TextInput/TextInput";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";

interface IProps {
    invoice: IInvoice;
    setInvoice: Dispatch<SetStateAction<IInvoice | undefined>>;
    employees: DropdownItemProps[];
}

export const InvoiceLiquidation = ({ invoice, setInvoice, employees }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const disabled = user.role !== UserRole.Admin;
    const [saving, setSaving] = useState(false);
    const history = useHistory();

    const SaveButton = () =>
        !disabled ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateInvoice}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    const handleInputChange = (e: any) => {
        setInvoice({ ...invoice!, [e.target.name]: e.target.value });
    };

    const handleUpdateInvoice = async () => {
        setSaving(true);
        try {
            await editInvoice(invoice);
            toast("Successfully saved changes", true);
            history.push("/invoice-processing/invoices");
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    return (
        <div>
            <Segment.Group>
                <Segment>
                    <h2>Invoice liquidation</h2>
                </Segment>
                <Segment>
                    <Table basic="very" celled compact>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Recognition date</Table.Cell>
                                <Table.Cell>
                                    <CalendarInput
                                        date={invoice.recognitionDate}
                                        handleDateChange={(e: any) =>
                                            setInvoice({
                                                ...invoice,
                                                recognitionDate: getISODateFormat(e),
                                            })
                                        }
                                        placeholder="Recognition date"
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Purpose</Table.Cell>
                                <Table.Cell>
                                    <TextInput
                                        name="purpose"
                                        value={invoice.purpose}
                                        handleInputChange={handleInputChange}
                                        disabled={disabled}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Liquidation date</Table.Cell>
                                <Table.Cell>
                                    <CalendarInput
                                        date={
                                            invoice.liquidationDate !== "0001-01-01"
                                                ? invoice.liquidationDate
                                                : ""
                                        }
                                        handleDateChange={(e: any) =>
                                            setInvoice({
                                                ...invoice,
                                                liquidationDate: getISODateFormat(e),
                                            })
                                        }
                                        placeholder="Liquidation date"
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Responsible</Table.Cell>
                                <Table.Cell>
                                    <DropdownInput
                                        value={invoice.responsible}
                                        options={employees}
                                        onChange={(e, data: any) =>
                                            setInvoice({ ...invoice, responsible: data.value })
                                        }
                                        disabled={disabled}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Segment>
                <Segment>
                    <SaveButton />
                </Segment>
            </Segment.Group>
        </div>
    );
};
