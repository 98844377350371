import { useSelector } from "react-redux";
import { Label } from "semantic-ui-react";
import { AppState } from "../../store/configureStore";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { ResetPasswordFirstLogin } from "../ResetPassword/ResetPasswordFirstLogin";

export const Layout = (props: any) => {
    const { stage } = useSelector((state: AppState) => state.appStage);

    return (
        <>
            <ResetPasswordFirstLogin />
            <div id="layout">
                {stage.toUpperCase() !== "PRODUCTION" && (
                    <Label id="stage-label" className={stage?.toLowerCase()}>
                        {stage?.toUpperCase()}
                    </Label>
                )}
                <Header />
                <main className="main">{props.children}</main>
                <Footer />
            </div>
        </>
    );
};
