import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { UserRole } from "../../../actions/authentificationActions";
import { AppState } from "../../../store/configureStore";
import { HeaderDropdown } from "../HeaderDropdown";
import { externalItems, projectsItems, requestItems, studentItems } from "../navbarItems";

export const StudentAndExternalHeaders = () => {
    const user = useSelector((state: AppState) => state.user);
    return (
        <Fragment>
            <Menu.Item>
                <HeaderDropdown
                    title="My Data"
                    items={user.role == UserRole.Student ? studentItems : externalItems}
                />
            </Menu.Item>
            <Menu.Item>
                <HeaderDropdown title="Projects" items={projectsItems} />
            </Menu.Item>
            <Menu.Item>
                <HeaderDropdown title="Requests" items={requestItems} />
            </Menu.Item>
            <Menu.Item>
                <Link
                    className="link-header"
                    to={{
                        pathname: "/contacts",
                    }}
                >
                    Contacts
                </Link>
            </Menu.Item>
        </Fragment>
    );
};
