import axios from "axios";
import { IClientContract } from "../models/clientContract";
import { IPaginationWrapper } from "../models/common";

export const getClientContracts = async (page: number, clientGuid: string) => {
    const params = new URLSearchParams({
        page: page.toString(),
        clientGuid: clientGuid.toString(),
    });
    const response = await axios.get(`/api/clientContracts`, { params: params });
    return response.data as IPaginationWrapper;
};

export const createClientContract = async (clientContractDto: IClientContract) => {
    const response = await axios.post("/api/clientContracts", clientContractDto);
    return response;
};

export const editClientContract = async (clientContractDto: IClientContract) => {
    const response = await axios.put(
        `/api/clientContracts/${clientContractDto.guid}`,
        clientContractDto
    );
    return response;
};

export const deleteClientContract = async (guid: string) => {
    const response = await axios.delete(`/api/clientContracts/${guid}`);
    return response;
};
