import { Fragment } from "react";
import { Button, Checkbox, Label, Table } from "semantic-ui-react";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { getStatusColor, getStatusLabel, ApproveStatus } from "../../models/common";
import { IProjectAccessRequest } from "../../models/projectAccessRequests";

interface IProps {
    projectAccessRequest: IProjectAccessRequest;
    selected: boolean;
    handleSelect: (sp: IProjectAccessRequest) => void;
    handleProcessRequest: (requests: IProjectAccessRequest[], choice: boolean) => void;
}

export const ProjectAccessRequestRow = ({
    projectAccessRequest,
    selected,
    handleSelect,
    handleProcessRequest,
}: IProps) => {
    return (
        <Fragment>
            <Table.Row>
                <Table.Cell textAlign="center" collapsing>
                    <Checkbox
                        toggle
                        checked={selected}
                        onClick={() => handleSelect(projectAccessRequest)}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Label
                        className="work-attendance-label"
                        size="large"
                        circular
                        color={getStatusColor(projectAccessRequest.requestStatus)}
                        content={getStatusLabel(projectAccessRequest.requestStatus)}
                    />
                </Table.Cell>
                <Table.Cell>{projectAccessRequest.userName}</Table.Cell>
                <Table.Cell>{projectAccessRequest.projectName}</Table.Cell>
                <Table.Cell>{getDisplayDateFormat(projectAccessRequest.date)}</Table.Cell>
                <Table.Cell collapsing>
                    {projectAccessRequest.requestStatus === ApproveStatus.Requested ? (
                        <Button.Group>
                            <Button
                                color="green"
                                icon="thumbs up"
                                content="Approve"
                                onClick={() => handleProcessRequest([projectAccessRequest], true)}
                            />
                            <Button.Or />
                            <Button
                                color="red"
                                icon="thumbs down"
                                content="Reject"
                                onClick={() => handleProcessRequest([projectAccessRequest], false)}
                            />
                        </Button.Group>
                    ) : projectAccessRequest.requestStatus === ApproveStatus.Approved ? (
                        <Button
                            color="red"
                            icon="thumbs down"
                            content="Reject"
                            onClick={() => handleProcessRequest([projectAccessRequest], false)}
                        />
                    ) : (
                        <Button
                            color="green"
                            icon="thumbs up"
                            content="Approve"
                            onClick={() => handleProcessRequest([projectAccessRequest], true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
        </Fragment>
    );
};
