import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { Button, Loader, Segment, Table } from "semantic-ui-react";
import { AppState } from "../../store/configureStore";
import { getCompanies } from "../../api/companies";
import { ICompany } from "../../models/company";
import { CompanyRow } from "./CompanyRow";
import { AddCompanyModal } from "./AddCompanyModal";
import { UserRole } from "../../actions/authentificationActions";

export const AddCompany = () => {
    const user = useSelector((state: AppState) => state.user);
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
    

    const fetchCompanies = useCallback(async () => {
        setLoading(true);
        const companies = await getCompanies();
        Array.isArray(companies) && setCompanies(companies);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

  
    return user.role !== UserRole.SuperAdmin ? (
        <Redirect to="/unauthorized" />
    ) : loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div>
            <div className="container-header">
                <h1>List of companies</h1>
                <Button
                    secondary
                    content="Add new company"
                    icon="add circle"
                    onClick={() => setAddCompanyModalOpen(true)}
                />
            </div>
            <Segment.Group className="companies-table-container">
                <Segment>
                    <h2>Companies</h2>
                </Segment>
                <Segment className="table-container">
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {companies.map(company => (
                                <CompanyRow
                                    key={company.guid}
                                    company={company}
                                    fetchCompanies={fetchCompanies}
                                />
                            ))}
                            <Table.Row/>
                        </Table.Body>
                    </Table>
                </Segment>
            </Segment.Group>
            <AddCompanyModal
                open={addCompanyModalOpen}
                closeModal={() => setAddCompanyModalOpen(false)}
                fetchCompanies={fetchCompanies}
            />
        </div>
    );
};
