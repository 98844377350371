import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { DropdownItemProps, Select, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { getLocationsList } from "../../api/locations";
import { getProjectsAsProps } from "../../api/projects";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import { TextInput } from "../../components/TextInput/TextInput";
import { getDepartmentsAsProps } from "../../services/departmentsService";
import { AppState } from "../../store/configureStore";
import { jobTitleTypes } from "../../models/appUser";

interface IProps {
    loggedUser: UserData;
    userDetails: UserData;
    setUserDetails: Dispatch<SetStateAction<UserData | undefined>>;
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
}

export const UserCompany = ({
    loggedUser,
    userDetails,
    setUserDetails,
    handleInputChange,
}: IProps) => {
    const roles = useSelector((state: AppState) => state.roles);
    const staff = useSelector((state: AppState) => state.staff);
    const [locationOptions, setLocationOptions] = useState<DropdownItemProps[]>([]);
    const [departments, setDepartments] = useState<DropdownItemProps[]>([]);
    const [userProjects, setUserProjects] = useState<DropdownItemProps[]>([]);

    const history = useHistory();
    const params: { guid: string } = useParams();

    const fetchLocationOptions = useCallback(async () => {
        try {
            const res = await getLocationsList();
            setLocationOptions(res);
        } catch {
            toast("Failed to load locations", false);
        }
    }, []);

    useEffect(() => {
        fetchLocationOptions();
    }, [fetchLocationOptions]);

    const fetchDepartments = useCallback(async () => {
        try {
            const res = await getDepartmentsAsProps();
            setDepartments(res);
        } catch {
            toast("Failed to load departments", false);
        }
    }, []);

    useEffect(() => {
        fetchDepartments();
    }, [fetchDepartments]);

    const fetchUserProjects = useCallback(async () => {
        const projects = await getProjectsAsProps(params.guid);
        setUserProjects(projects);
    }, [params.guid]);

    useEffect(() => {
        fetchUserProjects();
    }, [fetchUserProjects]);

    const DaysInMonth = (date2_UTC: Date) => {
        const monthStart = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth(), 1);
        const monthEnd = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth() + 1, 1);
        const monthLength = (monthEnd.getTime() - monthStart.getTime()) / (1000 * 60 * 60 * 24);
        return monthLength;
    };

    const calculateYearsFromDates = (today: Date, startDate: Date) => {
        let date2_UTC = new Date(
            Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())
        );
        let date1_UTC = new Date(
            Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate())
        );

        let days = date2_UTC.getDate() - date1_UTC.getDate();
        if (days < 0) {
            date2_UTC.setMonth(date2_UTC.getMonth() - 1);
            days += DaysInMonth(date2_UTC);
        }
        let months = date2_UTC.getMonth() - date1_UTC.getMonth();
        if (months < 0) {
            date2_UTC.setFullYear(date2_UTC.getFullYear() - 1);
            months += 12;
        }
        let years = date2_UTC.getFullYear() - date1_UTC.getFullYear();
        return [years, months, days];
    };

    const calculateWorkExperienceAtTheCompany = () => {
        const today = new Date();
        const startDate = new Date(userDetails?.startDate!);

        if (startDate > today) {
            return "Wrong start date!";
        }

        const [years, months, days] = calculateYearsFromDates(today, startDate);

        let yAppendix, mAppendix, dAppendix;
        if (years > 1) yAppendix = " years";
        else yAppendix = " year";
        if (months > 1) mAppendix = " months";
        else mAppendix = " month";
        if (days > 1) dAppendix = " days";
        else dAppendix = " day";

        let returnStr =
            (years > 0 ? `${years} ${yAppendix} ` : "") +
            (months > 0 ? `${months} ${mAppendix} ` : "") +
            (days > 0 ? `${days} ${dAppendix}` : "");

        return returnStr;
    };

    return (
        <Table basic="very" celled collapsing>
            <Table.Row>
                <Table.Cell>Location</Table.Cell>
                <Table.Cell>
                    <DropdownInput
                        options={locationOptions}
                        value={userDetails.location}
                        onChange={(e, data: any) =>
                            setUserDetails({
                                ...userDetails,
                                location: data.value,
                            })
                        }
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Company</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="company"
                        value={userDetails.company ?? ""}
                        handleInputChange={handleInputChange}
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Department</Table.Cell>
                <Table.Cell>
                    <DropdownInput
                        placeholder="Department"
                        options={departments}
                        value={userDetails.departmentGuid}
                        onChange={(e, data: any) =>
                            setUserDetails({
                                ...userDetails,
                                departmentGuid: data.value,
                            })
                        }
                        disabled={loggedUser.role !== UserRole.Admin}
                        clearable
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Role</Table.Cell>
                <Table.Cell>
                    <DropdownInput
                        options={roles}
                        value={userDetails?.role ?? ""}
                        onChange={(e, data: any) =>
                            setUserDetails({
                                ...userDetails,
                                role: data.value,
                            })
                        }
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Job title</Table.Cell>
                <Table.Cell>
                    <DropdownInput
                        options={jobTitleTypes}
                        value={userDetails.jobTitle}
                        onChange={(e, data: any) =>
                            setUserDetails({
                                ...userDetails,
                                jobTitle: data.value,
                            })
                        }
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Job description</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="jobDescription"
                        disabled={loggedUser.role !== UserRole.Admin}
                        value={userDetails.jobDescription ?? ""}
                        handleInputChange={handleInputChange}
                        isTextArea
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Job requirements</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="jobRequirements"
                        disabled={loggedUser.role !== UserRole.Admin}
                        value={userDetails.jobRequirements ?? ""}
                        handleInputChange={handleInputChange}
                        isTextArea
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Start date</Table.Cell>
                <Table.Cell>
                    <CalendarInput
                        date={userDetails.startDate?.substring(0, 10)}
                        handleDateChange={(e: any) =>
                            setUserDetails({
                                ...userDetails,
                                startDate: getISODateFormat(e),
                            })
                        }
                        placeholder="Start date"
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Manager</Table.Cell>
                <Table.Cell>
                    <DropdownInput
                        value={userDetails.managerGuid}
                        options={staff}
                        onChange={(e, data: any) =>
                            setUserDetails({
                                ...userDetails,
                                managerGuid: data.value,
                            })
                        }
                        disabled={loggedUser.role !== UserRole.Admin}
                        clearable
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Team members</Table.Cell>
                <Table.Cell>
                    <DropdownInput
                        value={userDetails.teamMembersGuid}
                        options={staff}
                        onChange={(e, data: any) =>
                            setUserDetails({
                                ...userDetails,
                                teamMembersGuid: data.value,
                            })
                        }
                        multiple
                        search
                        clearable
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>User projects</Table.Cell>
                <Table.Cell>
                    <Select
                        placeholder={
                            userProjects.length !== 0
                                ? "Select user project"
                                : "No user projects assigned"
                        }
                        options={userProjects}
                        onChange={(e, data: any) => {
                            history.push(`/company/projects/${data.value}`);
                        }}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Work experience at the company</Table.Cell>
                <Table.Cell>{calculateWorkExperienceAtTheCompany()}</Table.Cell>
            </Table.Row>
        </Table>
    );
};
