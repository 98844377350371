export const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 10,
    borderColor: "#414042",
    borderStyle: "dashed",
    backgroundColor: "#e8e8e8",
    color: "#414042",
    outline: "none",
    transition: "all 0.2s ease-in-out",
    cursor: "pointer",
    minHeight: "80px",
    justifyContent: "center",
};

export const activeStyle = {
    borderColor: "#1c3968",
};

export const acceptStyle = {
    borderColor: "#99BFBE",
    backgroundColor: "#99BFBE33",
};

export const rejectStyle = {
    borderColor: "#C15F5F",
    backgroundColor: "#C15F5F33",
};

export const disabledStyle = {
    opacity: 0.5,
    pointerEvents: "none",
};
