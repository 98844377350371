import { DropdownItemProps } from "semantic-ui-react";
import { ContractType } from "./common";

export const jobTitleTypes: DropdownItemProps[] = [
    { key: 0, text: "Unknown", value: 0 },
    { key: 1, text: "Product Manager", value: 1 },
    { key: 2, text: "Project Manager", value: 2 },
    { key: 3, text: "Customer Support Specialist", value: 3 },
    { key: 4, text: "Managing Director", value: 4 },
    { key: 5, text: "Animator/Technical Director", value: 5 },
    { key: 6, text: "Creative Director", value: 6 },
    { key: 7, text: "Student", value: 7 },
    { key: 8, text: "Research and Development Engineer", value: 8 },
    { key: 9, text: "Business Analyst", value: 9 },
    { key: 10, text: "Other", value: 10 },
];


export const locationOptions: DropdownItemProps[] = [
    { key: 0, text: "Trg Drage Iblera 10, Zagreb", value: 0 },
    { key: 1, text: "Petrova 15, Zagreb", value: 1 },
];

export const contractOptions: DropdownItemProps[] = [
    { key: 0, text: "Open-ended", value: ContractType.OpenEnded },
    { key: 1, text: "Temporary", value:  ContractType.Temporary },
];
