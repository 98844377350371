import { Dispatch, Reducer } from "redux";
import { GET_UNREAD_BOARD_NEWS, RESET_UNREAD } from "../actions/unreadBoardNewsActions";
import agent from "../api/agent";

export interface UnreadBoardNewsState {
    count: number;
}

const initialState: UnreadBoardNewsState = {
    count: 0,
};

export const unreadBoardNewsReducer: Reducer<UnreadBoardNewsState> = (
    state: UnreadBoardNewsState = initialState,
    action: any
): UnreadBoardNewsState => {
    switch (action.type) {
        case GET_UNREAD_BOARD_NEWS:
            return { ...state, count: action.payload };
        case RESET_UNREAD:
            return { ...state, count: 0 };
        default:
            return state;
    }
};

export async function fetchUnreadBoardNews(dispatch: Dispatch) {
    const res = await agent.Board.getUnreadBoardNews();
    dispatch({ type: "GET_UNREAD_BOARD_NEWS", payload: res });
}
