import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Checkbox, Dropdown, Feed, Message, Select } from "semantic-ui-react";
import { DropdownItemProps } from "semantic-ui-react/src/modules/Dropdown/DropdownItem";
import { INewsFeedItem, newsFeedFiltersInit } from "../../models/newsFeedItem";
import { getActionsList, getNewsFeedItems } from "../../api/newsFeed";
import { AppState } from "../../store/configureStore";
import { NewsFeedItem } from "./NewsFeedItem";
import { AddCommentForm } from "./AddCommentForm";
import { UserRole } from "../../actions/authentificationActions";
import "./NewsFeed.css";

export const NewsFeed = () => {
    const staff = useSelector((state: AppState) => state.staff);
    const user = useSelector((state: AppState) => state.user);
    const projects = useSelector((state: AppState) => state.projects);
    const [newsFeedItems, setNewsFeedItems] = useState<INewsFeedItem[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const params: { guid: string } = useParams();
    const [projectText, setProjectText] = useState("");
    const [actions, setActions] = useState<DropdownItemProps[]>([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [filters, setFilters] = useState(
        params.guid
            ? { ...newsFeedFiltersInit, projectGuid: params.guid as string }
            : newsFeedFiltersInit
    );

    const fetchNewsFeedItems = useCallback(async () => {
        setLoading(true);
        const res = await getNewsFeedItems(filters);
        if (res.rows) {
            setNewsFeedItems(nf => nf.concat(res.rows as INewsFeedItem[]));
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [filters]);

    useEffect(() => {
        if (projects.length > 0 && filters.projectGuid !== "") {
            setProjectText(projects.filter(x => x.value === filters.projectGuid)[0].text as string);
        }
    }, [filters.projectGuid, projects]);

    useEffect(() => {
        fetchNewsFeedItems();
    }, [fetchNewsFeedItems]);

    const fetchActionsList = useCallback(async () => {
        const res = await getActionsList();
        setActions(res);
    }, []);

    useEffect(() => {
        fetchActionsList();
    }, [fetchActionsList]);

    useEffect(() => {
        setNewsFeedItems([]);
    }, [filters.projectGuid, filters.userGuid, filters.types]);

    const toggleSelection = (e: any, { label, checked }: any) => {
        if (checked) {
            setFilters({ ...filters, types: [...filters.types, label], page: 1 });
        } else {
            setFilters({ ...filters, types: filters.types.filter(el => el !== label), page: 1 });
        }
    };

    return (
        <>
            {user.role !== UserRole.SuperAdmin && (
                <div className="news-feed-container">
                    <div className="container-header">
                        <h1>News feed{projectText !== "" && ` - ${projectText}`}</h1>
                        <div>
                            {!params.guid && (
                                <Select
                                    clearable
                                    placeholder="Select project..."
                                    selectOnBlur={false}
                                    options={projects}
                                    value={filters.projectGuid}
                                    onChange={(e, data: any) => {
                                        setFilters({
                                            ...filters,
                                            projectGuid: data.value,
                                            page: 1,
                                        });
                                    }}
                                />
                            )}
                            <Select
                                clearable
                                placeholder="Select user..."
                                selectOnBlur={false}
                                options={staff}
                                value={filters.userGuid}
                                onChange={(e, data: any) =>
                                    setFilters({ ...filters, userGuid: data.value, page: 1 })
                                }
                            />

                            <Dropdown
                                fluid
                                scrolling
                                closeOnBlur={false}
                                closeOnChange={false}
                                text="Select actions"
                                className="ui selection dropdown"
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                            >
                                <Dropdown.Menu open={dropdownOpen}>
                                    {actions.map(({ key, text }) => (
                                        <Dropdown.Item key={key} onClick={e => e.stopPropagation()}>
                                            <Checkbox
                                                slider
                                                label={text}
                                                onChange={toggleSelection}
                                            />
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    {(filters.projectGuid || params.guid) && (
                        <Button
                            className="comment-modal-button"
                            secondary
                            content="Add new comment"
                            icon="plus"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                    {!loading && newsFeedItems.length === 0 ? (
                        <Message
                            icon="info circle"
                            header="No news feed items found for selected project/user"
                            floating
                            size="small"
                        />
                    ) : (
                        <div className="news-feed-display">
                            <Feed>
                                {newsFeedItems.map(x => (
                                    <Fragment key={x.guid}>
                                        <NewsFeedItem item={x} />
                                        <div className="news-feed-separator"></div>
                                    </Fragment>
                                ))}
                            </Feed>
                            {totalRows > filters.page * 10 && (
                                <Button
                                    secondary
                                    fluid
                                    content="Load more"
                                    icon="spinner"
                                    onClick={() =>
                                        setFilters({ ...filters, page: filters.page + 1 })
                                    }
                                    disabled={loading}
                                    loading={loading}
                                />
                            )}
                        </div>
                    )}
                    <AddCommentForm
                        open={modalOpen}
                        closeModal={() => setModalOpen(false)}
                        handleAddComment={fetchNewsFeedItems}
                        filters={filters}
                        clearNewsFeed={() => setNewsFeedItems([])}
                    />
                </div>
            )}
        </>
    );
};
