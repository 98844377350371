import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import { documentTypeProps, IDocument, IUploadDocument } from "../../models/document";
import {
    createDocument,
    deleteDocument,
    downloadDocument,
    getDocuments,
} from "../../services/documentsService";
import { AppState } from "../../store/configureStore";

interface IProps {
    userGuid: string;
}

export const UserDocumentsTable: React.FC<IProps> = ({ userGuid }) => {
    const user = useSelector((state: AppState) => state.user);
    const [documents, setDocuments] = useState<IDocument[]>([]);

    const [loading, setLoading] = useState(false);

    const fetchUserDocuments = useCallback(async () => {
        const res = await getDocuments(1, -1, undefined, userGuid);
        res.rows && setDocuments(res.rows as IDocument[]);
    }, [userGuid]);

    const handleDeleteDocument = async (guid: string) => {
        try {
            const res = await deleteDocument(guid);
            fetchUserDocuments();
            toast("Document succesfully deleted", true);
        } catch {
            toast("Failed to delete selected document", false);
        }
    };

    const handleUploadDocument = async (files: File[], type?: number) => {
        const uploadDocument: IUploadDocument = {
            documentType: type!,
            userGuid: userGuid,
        };
        setLoading(true);
        const res = await createDocument(uploadDocument, files[0]);
        if (res.guid) {
            setDocuments([...documents].concat(res));
            toast("Document successfully uploaded", true);
        } else toast("Failed to upload document, please try again", false);
        setLoading(false);
    };

    useEffect(() => {
        fetchUserDocuments();
    }, [fetchUserDocuments]);

    return (
        <div>
            <Segment.Group>
                <Segment>
                    <h2>User documents</h2>
                </Segment>
                <Segment>
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {documents.map(doc => (
                                <Table.Row key={doc.guid}>
                                    <Table.Cell>{doc.name}</Table.Cell>
                                    <Table.Cell>
                                        {
                                            documentTypeProps.find(
                                                p => p.value === doc.documentType
                                            )?.text
                                        }
                                    </Table.Cell>
                                    <Table.Cell>{getDisplayDateFormat(doc.date, true)}</Table.Cell>
                                    <Table.Cell collapsing>
                                        <Button
                                            color="purple"
                                            content="Download"
                                            icon="download"
                                            onClick={() => downloadDocument(doc.guid)}
                                        />
                                        {user.role === UserRole.Admin && (
                                            <Button
                                                color="red"
                                                content="Delete"
                                                icon="delete"
                                                onClick={() => handleDeleteDocument(doc.guid)}
                                            />
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
            </Segment.Group>
            {user.role === UserRole.Admin && (
                <FileDropzone
                    title="Upload document"
                    loading={loading}
                    handleUploadFiles={handleUploadDocument}
                    selectType={true}
                />
            )}
        </div>
    );
};
