import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { DocumentType, IDocument, IUploadDocument } from "../../models/document";
import {
    createDocument,
    deleteDocument,
    downloadDocument,
    getDocuments,
} from "../../services/documentsService";
import { FileDropzone } from "../FileDropzone/FileDropzone";

interface IProps {
    entityGuid: string;
    documentType: DocumentType;
    handleIsProcessed: () => void;
}

export const DocumentsComponentTable: React.FC<IProps> = ({
    entityGuid,
    documentType,
    handleIsProcessed,
}) => {
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const uploadDocument: IUploadDocument = { documentType: documentType, entityGuid: entityGuid };
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState("");

    const fetchDocuments = useCallback(async () => {
        const res = await getDocuments(1, documentType, entityGuid);
        res.rows && setDocuments(res.rows as IDocument[]);
    }, [documentType, entityGuid]);

    const handleUploadDocument = async (files: File[]) => {
        setLoading(true);
        const res = await createDocument(uploadDocument, files[0]);
        if (res.guid) {
            handleIsProcessed();
            setDocuments([...documents].concat(res));
            toast("Document successfully uploaded", true);
        } else toast("Failed to upload document, please try again", false);
        setLoading(false);
    };

    const handleOpenModal = (guid: string) => {
        setModalOpen(true);
        setSelectedDocument(guid);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedDocument("");
    };

    const handleDeleteDocument = async () => {
        try {
            const res = await deleteDocument(selectedDocument);
            if (!res.ok) toast("Failed to delete document", false);
            else {
                handleIsProcessed();
                setDocuments(documents.filter((x) => x.guid !== selectedDocument));
                toast("Document successfully deleted", true);
            }
        } catch {
            toast("Failed to delete document", false);
        } finally {
            handleCloseModal();
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments]);

    return (
        <div>
            <Segment.Group>
                <Segment>
                    <h2>Documents</h2>
                </Segment>
                <Segment>
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Comment</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {documents.map((doc) => (
                                    <Table.Row key={doc.guid}>
                                        <Table.Cell>{doc.name}</Table.Cell>
                                        <Table.Cell>{doc.date}</Table.Cell>
                                        <Table.Cell>{doc.comment}</Table.Cell>
                                        <Table.Cell collapsing>
                                            <Button
                                                color="purple"
                                                content="Download"
                                                icon="download"
                                                onClick={() => downloadDocument(doc.guid)}
                                            />
                                            <Button
                                                color="red"
                                                content="Delete"
                                                icon="delete"
                                                onClick={() => handleOpenModal(doc.guid)}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
            </Segment.Group>
            <FileDropzone
                title="Upload document"
                loading={loading}
                handleUploadFiles={handleUploadDocument}
            />
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => handleCloseModal()}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this document?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => handleCloseModal()} content="Cancel" />
                    <Button
                        color="green"
                        onClick={() => handleDeleteDocument()}
                        content="Confirm"
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
};
