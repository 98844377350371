import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Icon, Loader, Pagination, Segment, Select, Table } from "semantic-ui-react";
import { AppState } from "../../store/configureStore";
import { EducationRow } from "./EducationRow";
import { UserRole } from "../../actions/authentificationActions";
import { IEducation } from "../../models/educationsModel";
import { getEducations } from "../../api/educations";
import { CSVLink } from "react-csv";
import "./Education.css";

export interface IEducationCSV {
    totalHours: number;
    title: string;
    description: string;
    date: string;
    certificateId?: string;
}

export const Educations = () => {
    const user = useSelector((state: AppState) => state.user);
    const staff = useSelector((state: AppState) => state.staff);
    const [educations, setEducations] = useState<IEducation[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState<IEducation[]>([]);
    const [educationsCSV, setEducationsCSV] = useState<IEducationCSV[]>([]);
    const [userGuid, setUserGuid] = useState("");

    const handleSelect = (sp: IEducation) => {
        if (selected.includes(sp)) setSelected(selected.filter(x => x !== sp));
        else setSelected(selected.concat(sp));
    };

    const handleDownload = () => {
        const CSVdata: IEducationCSV[] = selected.map(
            ({ guid, certificates, userGuid, ...rest }) => rest
        );
        setEducationsCSV(CSVdata);
    };

    const headers = [
        { label: "Name", key: "user" },
        { label: "Duration in hours", key: "totalHours" },
        { label: "Title", key: "title" },
        { label: "Duration in hours", key: "totalHours" },
        { label: "Date of completion", key: "date" },
        { label: "Certificate Id", key: "certificateId" },
        { label: "Description", key: "description" },
    ];

    const handleToggleAll = () =>
        selected.length === educations.length ? setSelected([]) : setSelected(educations);

    const fetchEducations = useCallback(async (userGuid?: string | undefined) => {
            setLoading(true);
            setEducations([]);
            const res = await getEducations(page, userGuid);
            if (res.rows) {
                setEducations(res.rows as IEducation[]);
                setTotalRows(res.totalRows);
            }
            setLoading(false);
        },
        [page]
    );

    useEffect(() => {
        fetchEducations(userGuid);
    }, [fetchEducations, userGuid]);

    return (
        <div className="education-container">
            <div className="container-header">
                <h1>Educations</h1>
                <div>
                    {user.role === UserRole.Admin && (
                        <Select
                            clearable
                            placeholder="Select user..."
                            selectOnBlur={false}
                            options={staff}
                            value={userGuid}
                            onChange={(e, data: any) => setUserGuid(data.value)}
                        />
                    )}
                    {user.role === UserRole.Admin && (
                        <CSVLink
                            style={{ marginLeft: "5px" }}
                            data={educationsCSV}
                            headers={headers}
                            filename={
                                userGuid
                                    ? `${user.firstName} ${user.lastName}'s educations.csv`
                                    : "Employees educations list"
                            }
                            onClick={() => handleDownload()}
                        >
                            <Button secondary content="Download csv file" icon="download" />
                        </CSVLink>
                    )}
                    <Button
                        as={Link}
                        to={`/employee-data/educations/create`}
                        secondary
                        content="Create new"
                        icon="plus"
                        disabled={user.role === UserRole.Admin}
                    />
                </div>
            </div>
            <div className="education-display">
                <div className="education-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment className="education-options">
                                {user.role === UserRole.Admin && (
                                    <Button
                                        secondary
                                        size="small"
                                        content="Select all"
                                        icon={
                                            educations.length > 0 &&
                                            selected.length === educations.length
                                                ? "toggle off"
                                                : "toggle on"
                                        }
                                        onClick={handleToggleAll}
                                    />
                                )}
                            </Segment>
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            {user.role === UserRole.Admin && (
                                                <Table.HeaderCell width="1">
                                                    Select
                                                </Table.HeaderCell>
                                            )}
                                            <Table.HeaderCell>User</Table.HeaderCell>
                                            <Table.HeaderCell>Title</Table.HeaderCell>
                                            <Table.HeaderCell>Date of completion</Table.HeaderCell>
                                            <Table.HeaderCell>Duration</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {educations.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="8">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            educations.map(x => (
                                                <EducationRow
                                                    key={x.guid}
                                                    education={x}
                                                    selected={selected.includes(x)}
                                                    handleSelect={handleSelect}
                                                    user={user}
                                                    fetchEducations={fetchEducations}
                                                    userGuid={userGuid}
                                                />
                                            ))
                                        )}
                                        <Table.Row/>
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{ content: <Icon name="angle double left" /> }}
                                        lastItem={{ content: <Icon name="angle double right" /> }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
