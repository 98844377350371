import { ADD_IMAGE_GUID, REFRESH_IMAGE } from '../actions/imageAction';
import { Reducer } from "redux";

export interface ImagesState {
    guids: Array<any>;
    timestamps: Array<any>;
}

const initialState: ImagesState = {
    guids: [],
    timestamps: [],
};

export const imageReducer: Reducer<ImagesState> = (
    state: ImagesState = initialState,
    action: any
): ImagesState => {
    switch (action.type) {
        case ADD_IMAGE_GUID:
            if (!state.guids.includes(action.imageGuid)) {
                return {
                    ...state,
                    guids: [...state.guids, action.imageGuid],
                };
            }
            return state;
        case REFRESH_IMAGE:
            let newTimestamps = state.timestamps.filter(
                (t) => t.guid !== action.imageGuid
            );
            newTimestamps.push({
                guid: action.imageGuid,
                timestamp: performance.now(),
            });
            return { ...state, timestamps: newTimestamps };
        default:
            return state;
    }
};
