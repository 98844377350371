import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import {
    Button,
    DropdownItemProps,
    Icon,
    Input,
    Loader,
    Pagination,
    Segment,
    Select,
    Table,
} from "semantic-ui-react";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { invoiceFiltersInit, statusOptions } from "../../models/bankStatement";
import {
    currencyOptions,
    IInvoice,
    invoiceOrigins,
    invoiceSources,
    invoiceTypes,
    serviceTypeOptions,
} from "../../models/invoice";
import {
    downloadInvoices,
    getInvoiceCategoriesList,
    getInvoices,
} from "../../services/invoicesService";
import { AppState } from "../../store/configureStore";
import { InvoiceRow } from "./InvoiceRow";
import { CSVLink } from "react-csv";
import { IVehicle } from "../../models/vehicle";
import { UserRole } from "../../actions/authentificationActions";
import "./Invoices.css";
import { toast } from "../..";

export interface IInvoiceCSV {
    name: string;
    amount: string;
    bankStatementAmount: string;
    isProcessed: boolean | string;
    created: string;
    bankStatementDate: string;
    bankStatementName: string;
    currency: any;
    isTravelExpense: boolean | string;
    isPaid: boolean | string;
    invoiceType: any;
    invoiceOrigin: any;
    invoiceSource: any;
    serviceType: any;
    isInVatSystem: boolean | string;
    invoiceCountryFullName: string;
    info: string;
    tags: string[];
    vehicle?: IVehicle | string;
}

interface LocationState {
    page: number;
    fromDashboard: {
        obj: boolean;
    };
}

export const Invoices = () => {
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles = ["admin", "accountant"];
    const loc = useLocation<LocationState>();
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState<IInvoice[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(loc.state && loc.state.page ? loc.state.page : 1);
    const [filters, setFilters] = useState(invoiceFiltersInit);
    const [appliedFilters, setAppliedFilters] = useState(invoiceFiltersInit);
    const [CSVInvoices, setCSVInvoices] = useState<IInvoiceCSV[]>([]);
    const [categories, setCategories] = useState<DropdownItemProps[]>([]);

    const fetchCategories = useCallback(async () => {
        const res = await getInvoiceCategoriesList();
        setCategories(res);
    }, []);

    const fetchInvoices = useCallback(async () => {
        setLoading(true);
        const res = await getInvoices(page, appliedFilters);
        if (res.rows) {
            setInvoices(res.rows as IInvoice[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page, appliedFilters]);

    const handleRemoveInvoice = (invoice: IInvoice) =>
        setInvoices([...invoices].filter(x => x !== invoice));

    const handleClearFilters = () => {
        setFilters(invoiceFiltersInit);
        setAppliedFilters(invoiceFiltersInit);
    };

    const downloadInvoicesExcel = async () => {
        setLoading(true);
        try {
            await downloadInvoices();
        } catch {
            toast("Unable to download invoices", false);
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = () => {
        const invoicesCSV: IInvoiceCSV[] = invoices.map(
            ({
                guid,
                documentGuid,
                paymentType,
                invoiceCountry,
                invoiceCountryString,
                ibaNs,
                vehicleGuid,
                paidByGuid,
                projectGuid,
                ...rest
            }) => rest
        );
        invoicesCSV.map(i => {
            let currencyValue = currencyOptions.find(c => c.value === i.currency);
            let invoiceTypeValue = invoiceTypes.find(t => t.value === i.invoiceType);
            let invoiceOriginValue = invoiceOrigins.find(t => t.value === i.invoiceOrigin);
            let invoiceSourceValue = invoiceSources.find(t => t.value === i.invoiceSource);
            let serviceTypeValue = serviceTypeOptions.find(t => t.value === i.serviceType);
            currencyValue!.text
                ? (i.currency = currencyValue!.text.toString())
                : (i.currency = "Unknown");
            invoiceTypeValue?.text
                ? (i.invoiceType = invoiceTypeValue.text)
                : (i.invoiceType = "Unknown");
            invoiceOriginValue?.text
                ? (i.invoiceOrigin = invoiceOriginValue.text)
                : (i.invoiceType = "Unknown");
            invoiceSourceValue?.text
                ? (i.invoiceSource = invoiceSourceValue.text)
                : (i.invoiceType = "Unknown");
            serviceTypeValue?.text
                ? (i.serviceType = serviceTypeValue.text)
                : (i.serviceType = "Unknown");
            i.isProcessed === true ? (i.isProcessed = "True") : (i.isProcessed = "False");
            i.isTravelExpense === true
                ? (i.isTravelExpense = "True")
                : (i.isTravelExpense = "False");
            i.isPaid === true ? (i.isPaid = "True") : (i.isPaid = "False");
            i.isInVatSystem === true ? (i.isInVatSystem = "True") : (i.isInVatSystem = "False");
            i.vehicle = "Unknown";
        });
        setCSVInvoices(invoicesCSV);
    };

    const headers = [
        { label: "Name", key: "name" },
        { label: "Amount", key: "amount" },
        { label: "Bank Statement Amount", key: "bankStatementAmount" },
        { label: "Invoice Is Processed", key: "isProcessed" },
        { label: "Date Created", key: "created" },
        { label: "Bank Statement Date", key: "bankStatementDate" },
        { label: "Bank Statement Name", key: "bankStatementName" },
        { label: "Currency", key: "currency" },
        { label: "Invoice Is Travel Expense", key: "isTravelExpens" },
        { label: "Invoice Is Paid", key: "isPaid" },
        { label: "Invoice Type", key: "invoiceType" },
        { label: "Invoice Origin", key: "invoiceOrigin" },
        { label: "Invoice Source", key: "invoiceSource" },
        { label: "Info", key: "info" },
        { label: "Is In VAT System", key: "isInVatSystem" },
        { label: "Service Type", key: "serviceType" },
        { label: "Tags", key: "tags" },
        { label: "Vehicle", key: "vehicle" },
        { label: "Country", key: "invoiceCountryFullName" },
    ];

    const setQuery = () => {
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("page", page.toString());
        window.history.pushState(null, "Page", "?" + queryParams.toString());
    };

    useEffect(() => {
        setPage(loc.state ? loc.state.page : 1);
        if (loc.state && loc.state.fromDashboard) {
            setFilters({ ...filters, status: false });
            setAppliedFilters({ ...appliedFilters, status: false });
        }
    }, [loc.state]);

    useEffect(() => {
        fetchInvoices();
        setQuery();
        fetchCategories();
    }, [fetchInvoices, fetchCategories]);

    return !allowedRoles.includes(user.role!) ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="invoices-container">
            <div className="container-header">
                <h1>Invoices</h1>
                {user.role === UserRole.Admin && (
                    <Button
                        as={Link}
                        to="/invoice-processing/invoices/upload"
                        secondary
                        content="Upload invoices"
                        icon="upload"
                    />
                )}
            </div>
            <div className="invoices-display">
                <div className="invoices-table">
                    <Segment className="bank-statements-options">
                        <CalendarInput
                            date={filters.startDate}
                            handleDateChange={(e: any) =>
                                setFilters({
                                    ...filters,
                                    startDate: getISODateFormat(e),
                                })
                            }
                            placeholder="Start date"
                        />
                        <CalendarInput
                            date={filters.endDate}
                            handleDateChange={(e: any) =>
                                setFilters({
                                    ...filters,
                                    endDate: getISODateFormat(e),
                                })
                            }
                            placeholder="End date"
                        />
                        <Select
                            placeholder="Status"
                            selectOnBlur={false}
                            options={statusOptions}
                            value={filters.status}
                            onChange={(e, data: any) => {
                                setFilters({ ...filters, status: data.value });
                                setAppliedFilters({ ...appliedFilters, status: data.value });
                            }}
                        />
                        <Input
                            placeholder="Search..."
                            value={filters.query}
                            onChange={e => {
                                e.preventDefault();
                                setFilters({ ...filters, query: e.target.value });
                                setAppliedFilters({ ...filters, query: e.target.value });
                            }}
                        />
                        <Select
                            placeholder="Category..."
                            selectOnBlur={false}
                            options={categories}
                            value={filters.categoryGuid}
                            onChange={(e, data: any) => {
                                setFilters({ ...filters, categoryGuid: data.value });
                                setAppliedFilters({ ...appliedFilters, categoryGuid: data.value });
                            }}
                        />
                        <div className="filters-buttons">
                            <Button
                                secondary
                                content="Download Excel file"
                                icon="download"
                                onClick={() => downloadInvoicesExcel()}
                                disabled={loading}
                            />
                            <CSVLink
                                data={CSVInvoices}
                                headers={headers}
                                filename={"Aconto_filtered_invoices.csv"}
                                onClick={() => handleDownload()}
                            >
                                <Button secondary content="Download csv file" icon="download" />
                            </CSVLink>
                            <Button
                                secondary
                                content="Apply filters"
                                icon="filter"
                                onClick={() => setAppliedFilters(filters)}
                            />
                            <Button
                                secondary
                                content="Clear filters"
                                icon="undo"
                                onClick={handleClearFilters}
                            />
                        </div>
                    </Segment>
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>

                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Created</Table.HeaderCell>
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                            <Table.HeaderCell collapsing>
                                                Amount (HRK)
                                            </Table.HeaderCell>

                                            <Table.HeaderCell>Category</Table.HeaderCell>
                                            <Table.HeaderCell>Country</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {invoices.map(x => (
                                            <InvoiceRow
                                                key={x.guid}
                                                invoice={x}
                                                handleRemoveInvoice={handleRemoveInvoice}
                                                page={page + ""}
                                            />
                                        ))}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
