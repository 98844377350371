import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, DropdownItemProps, Icon, Input, Modal, Radio, Select } from "semantic-ui-react";
import { toast } from "../..";
import { accessRightInit } from "../../models/accessRight";
import { createAccessRight } from "../../services/accessRightsService";
import { getClientContactsAsProps, getClientsAsProps } from "../../services/clientsService";
import { AppState } from "../../store/configureStore";
import "./AccessRights.css";

interface IProps {
    open: boolean;
    closeModal: () => void;
    fetchAccessRights: () => void;
}

export const AddAccessRightForm: React.FC<IProps> = ({ open, closeModal, fetchAccessRights }) => {
    const staff = useSelector((state: AppState) => state.staff);
    const [newAccessRight, setNewAccessRight] = useState(accessRightInit);
    const [clientContacts, setClientContacts] = useState<DropdownItemProps[]>([]);
    const [clients, setClients] = useState<DropdownItemProps[]>([]);
    const disabled = newAccessRight.claim === "" || newAccessRight.assigneeGuid === "";

    const handleAddNewAccessRight = async () => {
        const res = await createAccessRight(newAccessRight);
        if (!res.ok) toast("Error creating new resource group", false);
        else {
            fetchAccessRights();
            setNewAccessRight(accessRightInit);
            toast("Resource group successfully added", true, 1500);
        }
        closeModal();
    };

    const fetchClients = useCallback(async () => {
        const clients = await getClientsAsProps();
        Array.isArray(clients) && setClients(clients);
    }, []);

    const fetchClientContacts = useCallback(async () => {
        const res = await getClientContactsAsProps(newAccessRight.clientGuid);
        Array.isArray(res) && setClientContacts(res);
    }, [newAccessRight.clientGuid]);

    useEffect(() => {
        if (clients.length !== 0) fetchClientContacts();
    }, [clients.length, fetchClientContacts]);

    useEffect(() => {
        open && fetchClients();
        return () => {};
    }, [fetchClients, open]);

    return (
        <Modal
            className="add-access-right-modal"
            open={open}
            onClose={closeModal}
            dimmer="blurring"
            centered={false}
        >
            <Modal.Header>
                <h2>Add new access rights resource group</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-access-right-form">
                    <div className="add-access-right-container">
                        <div className="input-container">
                            <label>Resource group</label>
                            <Input
                                type="claim"
                                placeholder="Enter resource group"
                                value={newAccessRight.claim}
                                onChange={(e: any) =>
                                    setNewAccessRight({ ...newAccessRight, claim: e.target.value })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Client</label>
                            <Select
                                placeholder="Select client"
                                selectOnBlur={false}
                                fluid
                                search
                                disabled={newAccessRight.isInternal}
                                value={newAccessRight.clientGuid}
                                options={clients}
                                onChange={(e, data: any) =>
                                    setNewAccessRight({ ...newAccessRight, clientGuid: data.value })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Is internal</label>
                            <Radio
                                toggle
                                checked={newAccessRight.isInternal}
                                onClick={() =>
                                    setNewAccessRight({
                                        ...newAccessRight,
                                        isInternal: !newAccessRight.isInternal,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Responsible person</label>
                            <Select
                                placeholder="Select responsible person"
                                selectOnBlur={false}
                                fluid
                                search
                                value={newAccessRight.assigneeGuid}
                                options={newAccessRight.isInternal ? staff : clientContacts}
                                onChange={(e, data: any) =>
                                    setNewAccessRight({
                                        ...newAccessRight,
                                        assigneeGuid: data.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new resource group"
                    disabled={disabled}
                    secondary
                    fluid
                    onClick={handleAddNewAccessRight}
                />
            </Modal.Actions>
        </Modal>
    );
};
