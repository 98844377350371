import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Label, Button, Modal, Flag, FlagNameValues, Popup } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { IInvoice } from "../../models/invoice";
import { downloadDocument } from "../../services/documentsService";
import { deleteInvoice } from "../../services/invoicesService";
import { AppState } from "../../store/configureStore";

interface IProps {
    invoice: IInvoice;
    handleRemoveInvoice: (invoice: IInvoice) => void;
    page: string;
}

export const InvoiceRow = ({ invoice, handleRemoveInvoice, page }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteInvoice = async () => {
        const res = await deleteInvoice(invoice.guid);
        if (!res.ok) toast("Failed to delete invoice", false);
        else {
            handleRemoveInvoice(invoice);
            toast("Successfully deleted invoice", true);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `invoices/${invoice.guid}`,
            state: { page: page },
        });
    };

    return (
        <Fragment>
            <Table.Row key={invoice.guid} onClick={onRowClick} className="clickable-row">
                <Table.Cell>
                    <p>{invoice.name}</p>
                    <span>{invoice.info}</span>
                </Table.Cell>
                <Table.Cell>{getDisplayDateFormat(invoice.created, true)}</Table.Cell>
                <Table.Cell>
                    <Label
                        circular
                        size="large"
                        color={invoice.isProcessed ? "green" : "red"}
                        content={invoice.isProcessed ? "Processed" : "Not processed"}
                    />
                </Table.Cell>
                <Table.Cell textAlign="right">
                    {
                        (invoice.amount = invoice.amount.replace(/[,.]/g, m =>
                            m === "," ? "." : ","
                        ))
                    }
                </Table.Cell>
                <Table.Cell textAlign="left">
                    {invoice.invoiceCategoryName && (
                        <Label
                            className="invoice-label"
                            size="large"
                            circular
                            color={"olive"}
                            content={invoice.invoiceCategoryName}
                        />
                    )}
                </Table.Cell>
                <Table.Cell>
                    <p>{invoice.invoiceCountryFullName}</p>
                    <Flag name={invoice?.invoiceCountryString.toLowerCase() as FlagNameValues} />
                </Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    <Popup
                        content="No invoice uploaded"
                        disabled={invoice.documentGuid !== ""}
                        trigger={
                            <span>
                                <Button
                                    disabled={invoice.documentGuid === ""}
                                    color="purple"
                                    content="Download"
                                    icon="download"
                                    onClick={() => downloadDocument(invoice.documentGuid)}
                                />
                            </span>
                        }
                    />
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this invoice?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteInvoice} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
