import { IBankStatement, IInvoiceFilters } from "../models/bankStatement";
import { IPaginationWrapper } from "../models/common";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getBankStatements(
    page: number,
    filters: IInvoiceFilters
): Promise<IPaginationWrapper> {
    const params = new URLSearchParams({ page: page + "" });
    filters.status !== undefined && params.append("status", filters.status + "");
    filters.startDate && params.append("startDate", filters.startDate);
    filters.endDate && params.append("endDate", filters.endDate);
    filters.query && params.append("query", filters.query);
    return await fetch("/api/bankStatements?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function getBankStatement(guid: string): Promise<IBankStatement> {
    return await fetch("/api/bankStatements/" + guid, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function uploadBankStatements(files: File[]): Promise<Response> {
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file, file.name));
    return await fetch("/api/bankStatements/upload", {
        method: "POST",
        ...getAuthorizationProperties(),
        body: formData,
    });
}

export async function createBankStatements(bankStatements: IBankStatement[]): Promise<Response> {
    return await fetch("/api/bankStatements", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(bankStatements),
    });
}

export async function editBankStatement(bankStatement: IBankStatement): Promise<Response> {
    return await fetch("/api/bankStatements/" + bankStatement.guid, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(bankStatement),
    });
}

export async function deleteBankStatement(guid: string): Promise<Response> {
    return await fetch("/api/bankStatements/" + guid, {
        method: "DELETE",
        ...getAuthorizationProperties(),
    });
}

export async function downloadZippedDocuments(startDate: string, endDate: string) {
    const url = `/api/documents/${startDate}/${endDate}/zip`;
    const a = document.createElement("a");
    a.href = url;
    a.type = "document";
    a.click();
}
