import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import { DocumentsComponentTable } from "../../components/DocumentsComponentTable/DocumentsComponentTable";
import { IBankStatement } from "../../models/bankStatement";
import { DocumentType } from "../../models/document";
import { getBankStatement } from "../../services/bankStatementsService";
import { AppState } from "../../store/configureStore";
import { EditBankStatement } from "./EditBankStatement";
import { InvoiceTable } from "./InvoiceTable";

export const BankStatementDetails = () => {
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles = ["admin", "accountant"];
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [bankStatement, setBankStatement] = useState<IBankStatement>();

    const handleIsProcessed = () => setBankStatement({ ...bankStatement!, isProcessed: true });

    const fetchBankStatementDetails = useCallback(async id => {
        setLoading(true);
        const res = await getBankStatement(id);
        res.guid && setBankStatement(res);
        setLoading(false);
    }, []);

    useEffect(() => {
        return () => {
            if (history.action === "POP") {
                console.log(loc);
                history.push({ pathname: "/invoice-processing/bank-statements", state: loc.state });
            }
        }
    });

    useEffect(() => {
        fetchBankStatementDetails(params.guid);
    }, [fetchBankStatementDetails, params.guid]);

    return !allowedRoles.includes(user.role!) ? (
        <Redirect to="/unauthorized" />
    ) : loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="bank-statement-container">
            <div className="container-header">
                <h1>Bank statement details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/invoice-processing/bank-statements", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {bankStatement ? (
                <Fragment>
                    <EditBankStatement
                        bankStatement={bankStatement}
                        setBankStatement={setBankStatement}
                    />
                    {bankStatement.invoice && <InvoiceTable invoice={bankStatement.invoice} />}
                    <DocumentsComponentTable
                        entityGuid={bankStatement.invoiceGuid!}
                        documentType={DocumentType.Invoice}
                        handleIsProcessed={handleIsProcessed}
                    />
                </Fragment>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Bank statement not found
                </h2>
            )}
        </div>
    );
};
