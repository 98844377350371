import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Loader } from "semantic-ui-react";
import { IUserPoints, UserData, UserRole } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { getUsers, updatePoints } from "../../api/users";
import { UsersPointsTable } from "./UsersPointsTable";
import "./UsersDashboard.css";
import { toast } from "../..";
import { ICompany, newCompanyInit } from "../../models/company";
import { getMyCompany } from "../../api/companies";

export const UsersPoints = () => {
    const user = useSelector((state: AppState) => state.user);
    const [employees, setEmployees] = useState<UserData[]>([]);
    const [students, setStudents] = useState<UserData[]>([]);
    const [others, setOthers] = useState<UserData[]>([]);
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState<ICompany>(newCompanyInit);

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        const res = await getUsers();
        const e: UserData[] = [];
        const s: UserData[] = [];
        const o: UserData[] = [];
        res.forEach(u => {
            if (u.role === UserRole.Employee) e.push(u);
            else if (u.role === UserRole.Student) s.push(u);
            else o.push(u);
        });
        setEmployees(e);
        setStudents(s);
        setOthers(o);
        setLoading(false);
    }, []);

    const fetchCompany = useCallback(async () => {
        try {
            setLoading(true);
            setCompany(await getMyCompany());
        } catch {
            toast("Unable to load company info", false);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    const handleSaveChanges = async () => {
        setLoading(true);
        try {
            const users = [...employees, ...students, ...others];
            const usersPoints: IUserPoints[] = users.map(u => {
                return { guid: u.guid, points: u.points, negativePoints: u.negativePoints };
            });
            await updatePoints(usersPoints);
            toast("Successfully saved users points", true);
        } catch {
            toast("Could not save users points", false);
        } finally {
            setLoading(false);
        }
    };

    const SaveChangesButton = () => {
        return <Button secondary icon="save" content="Save changes" onClick={handleSaveChanges} />;
    };

    return user.role !== UserRole.Admin && user.role !== UserRole.SuperAdmin ? (
        <Redirect to="/unauthorized" />
    ) : loading ? (
        <Loader active inline="centered" size="huge" />
    ) : company.gamificationAllowed ? (
        <div className="users-dashboard-container">
            <div className="container-header">
                <h1>List of users points</h1>
                <SaveChangesButton />
            </div>
            <UsersPointsTable title="Employees" users={employees} refreshUsers={setEmployees} />
            <UsersPointsTable title="Students" users={students} refreshUsers={setStudents} />
            <UsersPointsTable title="Others" users={others} refreshUsers={setOthers} />
        </div>
    ) : (
        <div className="users-dashboard-container">
            <div className="container-header">
                <h1>List of users points</h1>
            </div>
            <h2 className="gamification-disabled-h2">
                Gamification is currently disabled for your company.
            </h2>
        </div>
    );
};
