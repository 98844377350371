import { Segment, Table } from "semantic-ui-react";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { getPaymentType, IInvoice } from "../../models/invoice";

interface IProps {
    invoice: IInvoice;
}

export const InvoiceTable = ({ invoice }: IProps) => {
    return (
        <Segment.Group>
            <Segment>
                <h2>Invoice</h2>
            </Segment>
            <Segment>
                <Table basic="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.HeaderCell>IBAN</Table.HeaderCell>
                            <Table.HeaderCell>Payment type</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{invoice.name}</Table.Cell>
                            <Table.Cell>
                                {invoice.amount.replace(/[,.]/g, m => (m === "," ? "." : ","))}
                            </Table.Cell>
                            <Table.Cell>{invoice.ibaNs.join(", ")}</Table.Cell>
                            <Table.Cell>{getPaymentType(invoice.paymentType)}</Table.Cell>
                            <Table.Cell>{getDisplayDateFormat(invoice.created, true)}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};
