import { useHistory } from "react-router";
import { Feed } from "semantic-ui-react";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { INewsFeedItem, NewsFeedAction } from "../../models/newsFeedItem";
import { Image } from "../../components/Image/Image";
import { DocumentType } from "../../models/document";

interface IProps {
    item: INewsFeedItem;
}

export const NewsFeedItem = ({ item }: IProps) => {
    const history = useHistory();

    const NewsFeedItemContent = () => {
        switch (item.actionType) {
            case NewsFeedAction.ProjectCreated:
                return (
                    <span>
                        <strong
                            onClick={() => history.push({ pathname: `/contacts/${item.userGuid}` })}
                        >
                            {item.user}
                        </strong>{" "}
                        created a new project -{" "}
                        <strong
                            onClick={() => history.push(`/company/projects/${item.projectGuid}`)}
                        >
                            {item.project}
                        </strong>
                    </span>
                );
            case NewsFeedAction.ProjectRolesModified:
                return (
                    <span>
                        <strong
                            onClick={() => history.push({ pathname: `/contacts/${item.userGuid}` })}
                        >
                            {item.user}
                        </strong>{" "}
                        modified project roles for the project{" "}
                        <strong
                            onClick={() => history.push(`/company/projects/${item.projectGuid}`)}
                        >
                            {item.project}
                        </strong>
                    </span>
                );
            case NewsFeedAction.ProjectDetailsModified:
                return (
                    <span>
                        <strong
                            onClick={() => history.push({ pathname: `/contacts/${item.userGuid}` })}
                        >
                            {item.user}
                        </strong>{" "}
                        modified details for the project{" "}
                        <strong
                            onClick={() => history.push(`/company/projects/${item.projectGuid}`)}
                        >
                            {item.project}
                        </strong>
                    </span>
                );
            case NewsFeedAction.UserAddedReport:
                return (
                    <span>
                        <strong
                            onClick={() => history.push({ pathname: `/contacts/${item.userGuid}` })}
                        >
                            {item.user}
                        </strong>{" "}
                        added a{" "}
                        <strong
                            onClick={() =>
                                item.reportGuid &&
                                history.push(`/company/reports/${item.reportGuid}`)
                            }
                        >
                            report
                        </strong>{" "}
                        for the project{" "}
                        <strong
                            onClick={() =>
                                item.project &&
                                history.push(`/company/projects/${item.projectGuid}`)
                            }
                        >
                            {item.project ?? "Other project"}
                        </strong>
                    </span>
                );
            case NewsFeedAction.MinutesOfTheMeetingCreated:
                return (
                    <span>
                        <strong
                            onClick={() =>
                                history.push({
                                    pathname: `/contacts/${item.userGuid}`,
                                })
                            }
                        >
                            {item.user}
                        </strong>{" "}
                        added{" "}
                        <strong
                            onClick={() =>
                                item.minutesGuid &&
                                history.push(`/company/minutes/${item.minutesGuid}`)
                            }
                        >
                            minutes of the meeting
                        </strong>{" "}
                        for the project{" "}
                        <strong
                            onClick={() => history.push(`/company/projects/${item.projectGuid}`)}
                        >
                            {item.project}
                        </strong>
                    </span>
                );
            case NewsFeedAction.MinutesOfTheMeetingModified:
                return (
                    <span>
                        <strong
                            onClick={() =>
                                history.push({
                                    pathname: `/contacts/${item.userGuid}`,
                                })
                            }
                        >
                            {item.user}
                        </strong>{" "}
                        modified{" "}
                        <strong
                            onClick={() =>
                                item.minutesGuid &&
                                history.push(`/company/minutes/${item.minutesGuid}`)
                            }
                        >
                            minutes of the meeting
                        </strong>{" "}
                        for the project{" "}
                        <strong
                            onClick={() => history.push(`/company/projects/${item.projectGuid}`)}
                        >
                            {item.project}
                        </strong>
                    </span>
                );
            case NewsFeedAction.GitlabWebhookEvent:
                var eventTypeText = item?.gitlabEvent?.eventType;
                if (item?.gitlabEvent?.action) {
                    eventTypeText = item?.gitlabEvent?.action + " " + eventTypeText;
                }
                if (item?.gitlabEvent?.additionalAction) {
                    eventTypeText = eventTypeText + " " + item?.gitlabEvent?.additionalAction;
                }
                return (
                    <span>
                        <strong
                            onClick={() => history.push({ pathname: `/contacts/${item.userGuid}` })}
                        >
                            {item.user}
                        </strong>{" "}
                        triggered a new Gitlab {eventTypeText} event for the project{" "}
                        <strong
                            onClick={() => history.push(`/company/projects/${item.projectGuid}`)}
                        >
                            {item.project}
                        </strong>
                    </span>
                );
            case NewsFeedAction.CommentAdded:
                return (
                    <span>
                        <strong
                            onClick={() => history.push({ pathname: `/contacts/${item.userGuid}` })}
                        >
                            {item.user}
                        </strong>{" "}
                        left a comment for project -{" "}
                        <strong
                            onClick={() => history.push(`/company/projects/${item.projectGuid}`)}
                        >
                            {item.project}
                        </strong>
                    </span>
                );
            default:
                return null;
        }
    };

    return (
        <Feed.Event>
            <Feed.Label className="lable">
                <Image guid={item.userGuid} type={DocumentType.UserPhoto} avatar={true} />
            </Feed.Label>
            <Feed.Content>
                <Feed.Summary>
                    <NewsFeedItemContent />
                    <Feed.Date>{getDisplayDateFormat(item.timestamp, true)}</Feed.Date>
                </Feed.Summary>
                {item.gitlabEvent && item.gitlabEvent.gitlabItems !== null
                    ? item.gitlabEvent.gitlabItems.map(x => (
                          <Feed.Meta>
                              {x.text && `${x.text}`}
                              <a href={x.url} target="_blank" rel="noreferrer">
                                  {x.urlText}
                              </a>{" "}
                          </Feed.Meta>
                      ))
                    : item.additionalText && (
                          <Feed.Meta>
                              {item.additionalText.split("\n").length < 3
                                  ? item.additionalText.split("\n").map(x => (
                                        <>
                                            {x}
                                            <br />
                                        </>
                                    ))
                                  : item.additionalText.split("\n", 3).map((x, i) => (
                                        <>
                                            {x}
                                            {i < 2 ? <br /> : "..."}
                                        </>
                                    ))}
                          </Feed.Meta>
                      )}
            </Feed.Content>
        </Feed.Event>
    );
};
