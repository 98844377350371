import { Dispatch, Reducer } from "redux";
import { GET_APP_STAGE } from "../actions/appStageActions";
import { getAppStage } from "../services/homepageService";

export interface AppStageState {
    stage: string;
    timestamp: string;
}

const initialState: AppStageState = { stage: "", timestamp: "1630488004" };

export const appStageReducer: Reducer<AppStageState> = (
    state: AppStageState = initialState,
    action: any
): AppStageState => {
    switch (action.type) {
        case GET_APP_STAGE:
            return action.payload;
        default:
            return state;
    }
};

export async function fetchAppStage(dispatch: Dispatch) {
    const res = await getAppStage();
    dispatch({ type: "GET_APP_STAGE", payload: res });
}
