import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Icon, Loader, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { addEducationCertificate, getEducation } from "../../api/educations";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import { IEducation } from "../../models/educationsModel";
import { CertificateTable } from "./CertificateTable";
import { EditEducation } from "./EditEducation";
import "./Education.css";

export const EducationDetails = () => {
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [education, setEducation] = useState<IEducation>();
    const [modalOpen, setModalOpen] = useState(false);

    const fetchEducation = useCallback(async id => {
        setLoading(true);
        const res = await getEducation(id);
        res.guid && setEducation(res);
        setLoading(false);
    }, []);

    const handleUploadFile = async (files: File[]) => {
        setLoading(true);
        try {
            await addEducationCertificate(education!.guid, files[0]);
            toast("Certificate successfully uploaded", true);
            fetchEducation(education!.guid);
        } catch {
            toast("Unable to upload certificate", false);
        } finally {
            setLoading(false);
            setModalOpen(false);
        }
    };

    useEffect(() => {
        fetchEducation(params.guid);
    }, [fetchEducation, params.guid]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="education-container">
            <div className="container-header">
                <h1>Education details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/employee-data/educations", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {education ? (
                <Fragment>
                    <EditEducation education={education} setEducation={setEducation} />
                    <CertificateTable education={education} setEducation={setEducation} />
                </Fragment>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Education not found
                </h2>
            )}
            <Button
                secondary
                content="Upload certificate"
                icon="upload"
                onClick={() => setModalOpen(true)}
            />
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} dimmer="blurring">
                <Modal.Header>Please upload your certificate</Modal.Header>
                <Modal.Content>
                    <FileDropzone title="" handleUploadFiles={handleUploadFile} loading={loading} />
                </Modal.Content>
            </Modal>
        </div>
    );
};
