import { DropdownItemProps } from "semantic-ui-react";

export interface IVendor {
    guid: string;
    email: string;
    companyName: string;
    address: string;
    oib: string;
    vatNumber: string;
    contactFirstName: string;
    contactLastName: string;
    phoneNumber: string;
    contactPersonGuid: string;
}

export const vendorInit: Partial<IVendor> = {
    email: "",
    companyName: "",
    address: "",
    vatNumber: "",
    contactFirstName: "",
    contactLastName: "",
    phoneNumber: "",
};

export const categories: DropdownItemProps[] = [
    { key: 1, text: "Clients", value: "Clients" },
    { key: 2, text: "Vendors", value: "Vendors" }
]