import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, Segment, Select, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { IProject, projectVisibilityTypes } from "../../models/project";
import { editProject } from "../../api/projects";
import { AppState } from "../../store/configureStore";
import { ImageDropzone } from "../../components/FileDropzone/ImageDropzone";
import { getCroppedImg } from "../UsersDashboard/getCroppedImage";
import { putImage } from "../../services/imageService";
import { RefreshImage } from "../../actions/imageAction";
import { DocumentType } from "../../models/document";
import { TextInput } from "../../components/TextInput/TextInput";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";

interface IProps {
    project: IProject;
    setProject: Dispatch<SetStateAction<IProject | undefined>>;
    viewEnabled: boolean | undefined;
}

export const EditProject = ({ project, setProject, viewEnabled }: IProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loc = useLocation();
    const user = useSelector((state: AppState) => state.user);
    const staff = useSelector((state: AppState) => state.staff);
    const clients = useSelector((state: AppState) => state.clients);
    const [saving, setSaving] = useState(false);
    const unauthorized = user.role !== UserRole.Admin;
    const [crop, setCrop] = useState(false);
    const [imageDropped, setImageDropped] = useState(false);

    const handleInputChange = (e: any) =>
        setProject({ ...project, [e.target.name]: e.target.value });

    const handleUpdateProject = async () => {
        setSaving(true);
        if (imageDropped && !unauthorized) {
            const img = await getCroppedImg(crop, project!.guid!);
            const res = await putImage(project!.guid!, img, DocumentType.ProjectPhotos);
            if (res.ok) dispatch(RefreshImage(project?.guid));
            else toast("Failed to update project image, please try again", false);
        }
        try {
            await editProject(project);
            toast("Successfully saved changes", true);
            history.push("/company/projects");
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateProject}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    const BackToListButton = () => (
        <Button
            as={Link}
            to={{ pathname: "/company/projects", state: loc.state }}
            secondary
            content="Back to list"
            icon="arrow left"
        />
    );

    return (
        <Segment.Group>
            <Segment>
                {viewEnabled ? <h2>Edit project details</h2> : <h2>Project details</h2>}
                <div>
                    <SaveButton />
                    <BackToListButton />
                </div>
            </Segment>
            <Segment>
                <Table basic="very" celled compact className="project-edit-container">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Name</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="name"
                                    value={project.name}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Description</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="description"
                                    value={project.description}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        {viewEnabled && (
                            <>
                                <Table.Row>
                                    <Table.Cell>Client</Table.Cell>
                                    <Table.Cell>
                                        <DropdownInput
                                            placeholder="Select project client..."
                                            value={project.clientGuid}
                                            options={clients}
                                            onChange={(e, data: any) =>
                                                setProject({ ...project, clientGuid: data.value })
                                            }
                                            disabled={unauthorized}
                                            clearable
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Project code</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="projectCode"
                                            value={project.projectCode}
                                            handleInputChange={handleInputChange}
                                            disabled={unauthorized}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>URL</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="url"
                                            value={project.url}
                                            handleInputChange={handleInputChange}
                                            disabled={unauthorized}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Gitlab URL</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="gitlabUrl"
                                            value={project.gitlabUrl}
                                            handleInputChange={handleInputChange}
                                            disabled={unauthorized}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Gitlab Project ID</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="gitlabProjectId"
                                            value={project.gitlabProjectId}
                                            handleInputChange={handleInputChange}
                                            disabled={unauthorized}
                                            isNumber
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Gitlab Webhook Token</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="gitlabWebhookToken"
                                            value={project.gitlabWebhookToken}
                                            handleInputChange={handleInputChange}
                                            disabled={unauthorized}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Google drive</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="googleDrive"
                                            value={project.googleDrive}
                                            handleInputChange={handleInputChange}
                                            disabled={unauthorized}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Visibility</Table.Cell>
                                    <Table.Cell>
                                        <DropdownInput
                                            value={project.visibility}
                                            options={projectVisibilityTypes}
                                            onChange={(e, data: any) =>
                                                setProject({ ...project, visibility: data.value })
                                            }
                                            disabled={unauthorized}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </>
                        )}
                        <Table.Row>
                            <Table.Cell>Project members</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    placeholder="Add project members..."
                                    multiple
                                    search
                                    fluid
                                    value={project.projectUsers}
                                    options={staff}
                                    onChange={(e, data: any) =>
                                        setProject({ ...project, projectUsers: data.value })
                                    }
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Project photo</Table.Cell>
                            <Table.Cell>
                                <ImageDropzone
                                    documentType={DocumentType.ProjectPhotos}
                                    guid={project?.guid ?? ""}
                                    onDrop={() => setImageDropped(true)}
                                    crop={crop}
                                    setCrop={setCrop}
                                    disabled={user.role !== UserRole.Admin}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                <SaveButton />
                <BackToListButton />
            </Segment>
        </Segment.Group>
    );
};
