import { DropdownItemProps } from "semantic-ui-react";

export interface IVendorContact {
    guid: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    vendor: string;
    vendorGuid: string;
}

export const vendorContactInit: Partial<IVendorContact> = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    vendor: "",
    vendorGuid: "",
};
export interface VendorDropDown {
    vendors: DropdownItemProps[]
};
