import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, DropdownItemProps, Input, Radio, Segment, Select, Table } from "semantic-ui-react";
import { toast } from "../..";
import { getInvoiceList } from "../../services/invoicesService";
import { AppState } from "../../store/configureStore";
import { IInventoryItem, inventoryItemTypes } from "../../models/inventoryItem";
import { editInventoryItem } from "../../api/inventoryItems";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { getBuyRequestsList } from "../../api/buyRequests";
import { TextInput } from "../../components/TextInput/TextInput";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";

interface IProps {
    inventoryItem: IInventoryItem;
    setInventoryItem: Dispatch<SetStateAction<IInventoryItem | undefined>>;
}

export const EditInventoryItem = ({ inventoryItem, setInventoryItem }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const [saving, setSaving] = useState(false);
    const disabled = user.role !== "admin";
    const staff = useSelector((state: AppState) => state.staff);
    const [invoices, setInvoices] = useState<DropdownItemProps[]>([]);
    const [buyRequests, setBuyRequests] = useState<DropdownItemProps[]>([]);

    const fetchInvoices = useCallback(async () => {
        const res = await getInvoiceList();
        setInvoices(res);
    }, []);

    const fetchBuyRequests = useCallback(async () => {
        const res = await getBuyRequestsList(inventoryItem.invoiceGuid);
        setBuyRequests(res);
    }, [inventoryItem.invoiceGuid]);

    const handleInputChange = (e: any) =>
        setInventoryItem({ ...inventoryItem!, [e.target.name]: e.target.value });

    const handleUpdateInventoryItem = async () => {
        setSaving(true);
        try {
            await editInventoryItem(inventoryItem);
            toast("Successfully saved changes", true);
            history.push("/company/inventories");
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchBuyRequests();
    }, [fetchBuyRequests]);

    const SaveButton = () =>
        !disabled ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateInventoryItem}
                disabled={
                    saving ||
                    inventoryItem.name === "" ||
                    inventoryItem.serialNo === "" ||
                    inventoryItem.value === ""
                }
                loading={saving}
            />
        ) : null;

    useEffect(() => {
        fetchInvoices();
    }, [fetchInvoices]);

    return (
        <Segment.Group>
            <Segment>
                <h2>Edit inventory item</h2>
                <SaveButton />
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Item</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="name"
                                    value={inventoryItem.name}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />

                                <Radio
                                    className="is-computer-checkbox"
                                    label="Item is computer"
                                    toggle
                                    checked={inventoryItem.isComputer}
                                    onChange={() =>
                                        setInventoryItem({
                                            ...inventoryItem,
                                            isComputer: !inventoryItem.isComputer,
                                        })
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                        {inventoryItem.isComputer && (
                            <Table.Row>
                                <Table.Cell>Computer name</Table.Cell>
                                <Table.Cell>
                                    <TextInput
                                        name="computerName"
                                        value={inventoryItem.computerName ?? ""}
                                        handleInputChange={handleInputChange}
                                        disabled={disabled}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )}
                        <Table.Row>
                            <Table.Cell>Inventory item Id</Table.Cell>
                            <Table.Cell>
                                <Input name="itemId" value={inventoryItem.itemId} disabled={true} />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Guid</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="guid"
                                    value={inventoryItem.guid}
                                    handleInputChange={handleInputChange}
                                    disabled={true}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Serial No</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="serialNo"
                                    value={inventoryItem.serialNo}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Description</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="description"
                                    value={inventoryItem.description ?? ""}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Value (HRK)</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="value"
                                    value={inventoryItem.value}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Date of purchase</Table.Cell>
                            <Table.Cell>
                                <CalendarInput
                                    date={inventoryItem.date}
                                    handleDateChange={(e: any) =>
                                        setInventoryItem({
                                            ...inventoryItem,
                                            date: getISODateFormat(e),
                                        })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Assigned to</Table.Cell>
                            <Table.Cell>
                                <Select
                                    selectOnBlur={false}
                                    clearable
                                    placeholder={
                                        inventoryItem.assignedStaff === null ? "Not assigned" : ""
                                    }
                                    value={inventoryItem.assignedToGuid}
                                    options={staff}
                                    onChange={(e, data: any) =>
                                        setInventoryItem({
                                            ...inventoryItem,
                                            assignedToGuid: data.value,
                                        })
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Invoice</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    placeholder={"No related invoice"}
                                    value={inventoryItem.invoiceGuid}
                                    options={invoices}
                                    onChange={(e, data: any) => {
                                        setInventoryItem({
                                            ...inventoryItem,
                                            invoiceGuid: data.value,
                                            buyRequestGuid:
                                                data.value === ""
                                                    ? ""
                                                    : inventoryItem.buyRequestGuid,
                                        });
                                    }}
                                    disabled={disabled}
                                    clearable
                                />
                            </Table.Cell>
                        </Table.Row>
                        {inventoryItem.invoiceGuid && (
                            <Table.Row>
                                <Table.Cell>Buy requests</Table.Cell>
                                <Table.Cell>
                                    <DropdownInput
                                        placeholder="No related buy request"
                                        value={inventoryItem.buyRequestGuid}
                                        options={buyRequests}
                                        onChange={(e, data: any) =>
                                            setInventoryItem({
                                                ...inventoryItem,
                                                buyRequestGuid: data.value,
                                            })
                                        }
                                        disabled={disabled}
                                        clearable
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )}
                        <Table.Row>
                            <Table.Cell>Category</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    value={inventoryItem.category}
                                    options={inventoryItemTypes}
                                    onChange={(e, data: any) =>
                                        setInventoryItem({
                                            ...inventoryItem,
                                            category: data.value,
                                        })
                                    }
                                    disabled={disabled}
                                    clearable
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Location</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="location"
                                    value={inventoryItem.location ?? ""}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                <SaveButton />
            </Segment>
        </Segment.Group>
    );
};
