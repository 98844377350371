export interface ILocation {
    guid: string;
    name?: string;
    users?: string[];
    isHQ?: boolean;
}

export const locationInit: Partial<ILocation> = {
    name: "",
    users: [],
    isHQ: false,
};
