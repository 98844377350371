import React from "react";
import { Input, TextArea } from "semantic-ui-react";
import "./TextInput.css";

interface IProps {
    name: string;
    disabled: boolean;
    value: string | number;
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
    isTextArea?: boolean;
    error?: boolean;
    isNumber?: boolean;
    className?: string;
}

export const TextInput = ({
    disabled,
    value,
    handleInputChange,
    name,
    isTextArea,
    error,
    isNumber,
    className,
}: IProps) => {
    return (
        <>
            {disabled ? (
                <p className="text-container">{value}</p>
            ) : isTextArea ? (
                <TextArea
                    name={name}
                    className={className}
                    value={value}
                    onChange={e => handleInputChange(e)}
                    rows={5}
                />
            ) : (
                <Input
                    className={className}
                    name={name}
                    type={isNumber ? "number" : "string"}
                    value={value}
                    error={error}
                    onChange={e => handleInputChange(e)}
                />
            )}
        </>
    );
};
