import { UserData } from "../actions/authentificationActions";

export async function login(username: string, password: string): Promise<UserData> {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
    };
    const response = await fetch(`/users/authenticate`, requestOptions);
    const user = await handleResponse(response);
    if (user.token) {
        sessionStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("loggedIn", JSON.stringify(true));
    }
    return user;
}

export function logout() {
    sessionStorage.removeItem("user");
    sessionStorage.setItem("loggedIn", JSON.stringify(false));
}

async function handleResponse(response: Response) {
    const text = await response.text();
    const data = text && JSON.parse(text);
    if (!response.ok) {
        if (response.status === 401) logout();
        if (response.status === 403) return Promise.reject("403");
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }
    return data || response.ok;
}

export function forgotPassword(username: string) {
    return fetch("/users/forgotPassword", {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: username,
    });
}

export function checkPasswordToken(token: string) {
    return fetch(`/users/resetPassword?token=${token}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    });
}

export function resetPassword(resetPasswordDto: any) {
    return fetch("/users/resetPassword", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(resetPasswordDto),
    });
}
