import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Segment, Button, Table, Radio } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { TextInput } from "../../components/TextInput/TextInput";
import { IBankStatement } from "../../models/bankStatement";
import { editBankStatement } from "../../services/bankStatementsService";
import { AppState } from "../../store/configureStore";

interface IProps {
    bankStatement: IBankStatement;
    setBankStatement: Dispatch<SetStateAction<IBankStatement | undefined>>;
}

export const EditBankStatement = ({ bankStatement, setBankStatement }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const [saving, setSaving] = useState(false);
    const unauthorized = user.role !== UserRole.Admin;

    const handleInputChange = (e: any) =>
        setBankStatement({ ...bankStatement!, [e.target.name]: e.target.value });

    const handleUpdateBankStatement = async () => {
        setSaving(true);
        const res = await editBankStatement(bankStatement);
        if (!res.ok) toast("Failed to save changes, please try again", false);
        else {
            history.push("/invoice-processing/bank-statements");
            toast("Successfully saved changes", true);
        }
        setSaving(false);
    };

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateBankStatement}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    return (
        <Segment.Group>
            <Segment>
                <h2>Edit bank statement details</h2>
                <SaveButton />
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Name</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="name"
                                    value={bankStatement.name}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Amount (HRK)</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="amount"
                                    value={bankStatement.amount.replace(/[,.]/g, m =>
                                        m === "," ? "." : ","
                                    )}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Is processed</Table.Cell>
                            <Table.Cell>
                                <Radio
                                    toggle
                                    checked={bankStatement.isProcessed}
                                    onClick={() =>
                                        setBankStatement({
                                            ...bankStatement,
                                            isProcessed: !bankStatement.isProcessed,
                                        })
                                    }
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Date</Table.Cell>
                            <Table.Cell>
                                <CalendarInput
                                    date={bankStatement.date}
                                    handleDateChange={(e: any) =>
                                        setBankStatement({
                                            ...bankStatement,
                                            date: getISODateFormat(e),
                                        })
                                    }
                                    placeholder="Date"
                                    disabled
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Created</Table.Cell>
                            <Table.Cell>
                                <CalendarInput
                                    date={bankStatement.created}
                                    handleDateChange={(e: any) =>
                                        setBankStatement({
                                            ...bankStatement,
                                            created: getISODateFormat(e),
                                        })
                                    }
                                    placeholder="Created"
                                    disabled
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>IBAN</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="iban"
                                    value={bankStatement.iban}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Details</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="additionalRemittanceInformation"
                                    value={bankStatement.additionalRemittanceInformation}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Address</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="address"
                                    value={bankStatement.address}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                <SaveButton />
            </Segment>
        </Segment.Group>
    );
};
