import { useState } from "react";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { createVendor } from "../../api/vendors";
import { vendorInit } from "../../models/vendor";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddVendor: () => void;
}

export const AddVendorForm = ({ open, closeModal, handleAddVendor }: IProps) => {
    const [newVendor, setNewVendor] = useState(vendorInit);
    const disabled = newVendor.companyName === "";

    const handleInputChange = (e: any) =>
        setNewVendor({ ...newVendor, [e.target.name]: e.target.value });

    const handleAddNewVendor = async () => {
        try {
            await createVendor(newVendor);
            handleAddVendor();
            toast("Vendor successfully added", true, 1500);
        } catch {
            toast("Error creating vendor", false, 1500);
        } finally {
            closeModal();
        }
    };

    return (
        <Modal
            className="add-client-modal"
            open={open}
            onClose={closeModal}
            dimmer="blurring"
            centered={false}
        >
            <Modal.Header>
                <h2>Add new vendor</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-client-form">
                    <div className="input-container">
                        <label>Company name</label>
                        <Input
                            name="companyName"
                            value={newVendor.companyName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>Address</label>
                        <Input
                            name="address"
                            value={newVendor.address}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>Contact person first name</label>
                        <Input
                            name="contactFirstName"
                            value={newVendor.contactFirstName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>Contact person last name</label>
                        <Input
                            name="contactLastName"
                            value={newVendor.contactLastName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>Email</label>
                        <Input name="email" value={newVendor.email} onChange={handleInputChange} />
                    </div>
                    <div className="input-container">
                        <label>Phone number</label>
                        <Input
                            name="phoneNumber"
                            value={newVendor.phoneNumber}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>VAT number</label>
                        <Input
                            name="vatNumber"
                            value={newVendor.vatNumber}
                            onChange={handleInputChange}
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new vendor"
                    disabled={disabled}
                    secondary
                    fluid
                    onClick={handleAddNewVendor}
                />
            </Modal.Actions>
        </Modal>
    );
};
