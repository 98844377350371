import { Icon } from "semantic-ui-react";
import "./Popup.css";
import { PopupIcons } from "./PopupIcons";

interface IProps {
    popupIcon: PopupIcons;
    popupText: string;
}

export const Popup = ({ popupIcon, popupText }: IProps) => {
    const onPopupHover = () => {
        const popup = document.getElementById("myPopup");
        popup?.classList.toggle("show");
    };

    const getPopupIcon = (popupIcon: PopupIcons) => {
        switch (popupIcon) {
            case PopupIcons.InfoCircle:
                return <Icon size="large" name="info circle" />;
            default:
                return <p>Popup</p>;
        }
    };

    return (
        <div className="popup" onMouseEnter={onPopupHover} onMouseLeave={onPopupHover}>
            {getPopupIcon(popupIcon)}
            <span className="popuptext" id="myPopup">
                {popupText}
            </span>
        </div>
    );
};
