import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, DropdownItemProps, Icon, Loader } from "semantic-ui-react";
import { IClientContact } from "../../models/clientContact";
import { getClientContact } from "../../services/clientContactService";
import { getClientsAsProps } from "../../services/clientsService";
import { EditClientContact } from "./EditClientContact";

export const ClientContactDetails = () => {
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(true);
    const [clientContact, setClientContact] = useState<IClientContact>();
    const [clients, setClients] = useState<DropdownItemProps[]>([]);
    const fetchClientContact = useCallback(async id => {
        setLoading(true);
        const res = await getClientContact(id);
        const clientsres = await getClientsAsProps();
        Array.isArray(clientsres) && setClients(clientsres);
        res.guid && setClientContact(res);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchClientContact(params.guid);
    }, [fetchClientContact, params.guid]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="client-contact-details-container">
            <div className="container-header">
                <h1>Client contact details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/company/client-contacts", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {clientContact ? (
                <EditClientContact
                    clientContact={clientContact}
                    setClientContact={setClientContact}
                    clients={clients}
                />
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Client contact
                </h2>
            )}
        </div>
    );
};
