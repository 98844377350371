import { Dispatch, Reducer } from "redux";
import { DropdownItemProps } from "semantic-ui-react";
import { GET_STAFF } from "../actions/staffActions";
import { getStaff } from "../api/users";

export interface StaffState extends DropdownItemProps {
    departmentguid: string;
}

const initialState: StaffState[] = [];

export const staffReducer: Reducer<StaffState[]> = (
    state: StaffState[] = initialState,
    action: any
): StaffState[] => {
    switch (action.type) {
        case GET_STAFF:
            return action.payload;
        default:
            return state;
    }
};

export async function fetchStaff(dispatch: Dispatch) {
    const res = await getStaff();
    dispatch({ type: "GET_STAFF", payload: res });
}

export const getRoleName = (role: string | undefined) => {
    if (role) {
        const clean = role.replace(/_/g, " ");
        return clean.charAt(0).toUpperCase() + clean.slice(1);
    } else return "";
};
