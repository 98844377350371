import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    Button,
    DropdownItemProps,
    Input,
    Segment,
    Select,
    Table,
    TextArea,
} from "semantic-ui-react";
import { toast } from "../..";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import {
    documentClassificationProps,
    documentTypeProps,
    IUploadDocument,
    IDocument,
} from "../../models/document";
import { getClientsAsProps } from "../../services/clientsService";
import { createDocument, editDocument } from "../../api/documents";
import { AppState } from "../../store/configureStore";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { TextInput } from "../../components/TextInput/TextInput";
import "./UploadDocuments.css";

export const UploadDocuments = () => {
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [document, setDocument] = useState<IDocument>();
    const [userOfDocumentProps, setUserOfDocumentProps] = useState<DropdownItemProps[]>([]);

    const fetchDropdownItemProps = async () => {
        const clientProps = await getClientsAsProps();
        var index = 0;
        var props: DropdownItemProps[] = [];
        clientProps.forEach(c => {
            props.push({ key: index, text: c.text, value: c.text?.toString() });
            index++;
        });
        props.push({ key: index, text: "Other", value: "Other" });
        setUserOfDocumentProps(props);
    };

    const handleInputChange = (e: any) =>
        setDocument({ ...document!, [e.target.name]: e.target.value });

    const handleUploadDocument = async (files: File[], type?: number) => {
        const uploadDocument: IUploadDocument = {
            documentType: type!,
            userGuid: user.guid,
        };
        setLoading(true);
        try {
            const res = await createDocument(uploadDocument, files[0]);
            setDocument(res);
            toast("Document successfully uploaded", true);
        } catch {
            toast("Failed to upload document, please try again", false);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveDocument = async () => {
        setLoading(true);
        try {
            await editDocument(document!);
            toast("Successfully saved changes", true);
            history.push("/administration/documents");
        } catch {
            toast("Failed to save changes, please try again", false);
            setLoading(false);
        }
    };

    const handleGenerateGuid = () => {
        const guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
            let r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
        setDocument({ ...document!, externalGuid: guid });
    };

    useEffect(() => {
        fetchDropdownItemProps();
    }, []);

    return (
        <div className="upload-documents-container">
            <div className="container-header">
                <h1>Upload a document</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/administration/documents", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            <FileDropzone
                title="Select a document to upload"
                loading={loading}
                handleUploadFiles={handleUploadDocument}
                selectType={true}
            />
            {document && (
                <Segment.Group>
                    <Segment>
                        <h2>Edit document details</h2>
                    </Segment>
                    <Segment>
                        <Table basic="very" celled compact>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>Name</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="name"
                                            value={document.name}
                                            onChange={handleInputChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Type</Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            name="documentType"
                                            defaultValue={document.documentType}
                                            options={documentTypeProps}
                                            onChange={(e, data: any) =>
                                                setDocument({
                                                    ...document,
                                                    documentType: data.value,
                                                })
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Classification</Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            name="documentClassification"
                                            defaultValue={document.documentClassification}
                                            options={documentClassificationProps}
                                            onChange={(e, data: any) =>
                                                setDocument({
                                                    ...document,
                                                    documentClassification: data.value,
                                                })
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Created</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="date"
                                            value={getDisplayDateFormat(document.date, true)}
                                            handleInputChange={handleInputChange}
                                            disabled
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Added by</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="username"
                                            value={document.username}
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>User of the document</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="userOfDocument"
                                            defaultValue={document.userOfDocument}
                                            list="list"
                                            clearable={true}
                                            onChange={handleInputChange}
                                        />
                                        <datalist id="list">
                                            {userOfDocumentProps.map(u => {
                                                return (
                                                    <option key={u.value?.toString()} value={u.value?.toString()}>
                                                        {u.value}
                                                    </option>
                                                );
                                            })}
                                        </datalist>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>External GUID</Table.Cell>
                                    <Table.Cell>
                                        <Input
                                            name="externalGuid"
                                            value={document.externalGuid ?? ""}
                                            onChange={handleInputChange}
                                        />
                                        <Button
                                            content="Generate"
                                            color="green"
                                            className="generate-guid-button"
                                            onClick={handleGenerateGuid}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                {document.externalGuid && (
                                    <Table.Row>
                                        <Table.Cell>QR code (External GUID)</Table.Cell>
                                        <Table.Cell>
                                            <QRCode
                                                size={180}
                                                id="guid-qr-code"
                                                value={`${window.location.host}/document-verification/${document.externalGuid}`}
                                                bgColor="white"
                                                fgColor="black"
                                                level="H"
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                                <Table.Row>
                                    <Table.Cell>Database GUID</Table.Cell>
                                    <Table.Cell>{document.guid}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>QR code (Database GUID)</Table.Cell>
                                    <Table.Cell>
                                        <QRCode
                                            size={180}
                                            id="guid-qr-code"
                                            value={`${window.location.host}/document-verification/${document.guid}`}
                                            bgColor="white"
                                            fgColor="black"
                                            level="H"
                                        />
                                    </Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Comment</Table.Cell>
                                    <Table.Cell>
                                        <TextArea
                                            name="comment"
                                            value={document.comment}
                                            onChange={handleInputChange}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Segment>
                </Segment.Group>
            )}
            <div>
                <Button
                    secondary
                    fluid
                    content="Save"
                    icon="save"
                    onClick={handleSaveDocument}
                    disabled={loading || !document}
                />
            </div>
        </div>
    );
};
