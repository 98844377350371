import axios from "axios";
import { IPaginationWrapper } from "../models/common";
import { IEducation } from "../models/educationsModel";

export const getEducations = async (page: number, userGuid?: string | undefined) => {
    const response = await axios.get(`/api/educations?page=${page}&userGuid=${userGuid}`);
    return response.data as IPaginationWrapper;
};

export const processEducations = async (guid: string, choice: boolean) => {
    const response = await axios.post(`/api/educations/sent-requests?choice=${choice}`, guid);
    return response;
};

export const getEducation = async (guid: string) => {
    const response = await axios.get(`/api/educations/${guid}`);
    return response.data as IEducation;
};

export const createEducation = async (educationDto: IEducation) => {
    const response = await axios.post("/api/educations", educationDto);
    return response;
};

export const addEducationCertificate = async (guid: string, file: File) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const response = await axios.post(`/api/educations/${guid}/file`, formData);
    return response;
};

export const updateEducation = async (educationDto: IEducation) => {
    const response = await axios.put(`/api/educations/${educationDto.guid}`, educationDto);
    return response;
};

export const deleteEducation = async (guid: string) => {
    const response = await axios.delete(`/api/educations/${guid}`);
    return response;
};
