import axios from "axios";
import { DropdownItemProps } from "semantic-ui-react";
import { IPaginationWrapper } from "../models/common";
import { IService } from "../models/service";

export const getServices = async (page: number) => {
    const response = await axios.get("/api/services", { params: { page: page } });
    return response.data as IPaginationWrapper;
};

export const getService = async (guid: string) => {
    const response = await axios.get(`/api/services/${guid}`);
    return response.data as IService;
};

export const updateService = async (serviceDto: IService) => {
    const response = await axios.put("/api/services", serviceDto);
    return response;
};

export const deleteService = async (guid: string) => {
    const response = await axios.delete(`/api/services/${guid}`);
    return response;
};
export const createService = async (serviceDto: IService) => {
    const response = await axios.post("/api/services", serviceDto);
    return response;
};

export const getServicesList = async () => {
    const response = await axios.get("/api/services/list/");
    return response.data as DropdownItemProps[];
};
