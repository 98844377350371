export interface ICompanyServiceType {
    guid: string;
    type: string;
    number: number;
}

export const companyServiceTypeInit: ICompanyServiceType = {
    guid: "",
    type: "",
    number: 0,
};
