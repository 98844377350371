import { Placeholder, Table } from "semantic-ui-react";

type Props = {
    columns: number;
    rows?: number;
};

export const TableLoader = ({ rows, columns }: Props) => {
    return (
        <Table.Body>
            {[...new Array(rows ?? 5)].map((r, i) => (
                <Table.Row key={i}>
                    {[...new Array(columns)].map((c, j) => (
                        <Table.Cell key={j}>
                            <Placeholder>
                                <Placeholder.Line />
                            </Placeholder>
                        </Table.Cell>
                    ))}
                </Table.Row>
            ))}
        </Table.Body>
    );
};
