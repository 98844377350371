import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Modal, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { IAccessRight } from "../../models/accessRight";
import { deleteAccessRight } from "../../services/accessRightsService";
import { AppState } from "../../store/configureStore";

interface IProps {
    accessRight: IAccessRight;
    handleRemoveAccessRight: (ar: IAccessRight) => void;
}

export const AccessRightsRow = ({ accessRight, handleRemoveAccessRight }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteAccessRight = async () => {
        try {
            await deleteAccessRight(accessRight.guid);
            handleRemoveAccessRight(accessRight);
            toast("Successfully archived access right", true);
        } catch {
            toast("Failed to archive access right", false);
        }
    };

    const onRowClick = () => {
        history.push(`access-rights/${accessRight.guid}`);
    };

    return (
        <Fragment>
            <Table.Row key={accessRight.guid} onClick={onRowClick} className="clickable-row">
                <Table.Cell>{accessRight.claim}</Table.Cell>
                <Table.Cell>
                    {accessRight.assignee ? accessRight.assignee : accessRight.internalAssignee}
                </Table.Cell>
                <Table.Cell>{accessRight.recipients.length}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Button
                            color="purple"
                            content="Archive"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to archive this access right?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteAccessRight} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
