import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import { IIdeaBox } from "../../models/ideaBox";
import { getIdeaBox } from "../../api/ideaBoxes";
import { IdeaBoxEdit } from "./IdeaBoxEdit";
import { toast } from "../..";

export const IdeaBoxDetails = () => {
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [idea, setIdea] = useState<IIdeaBox>();

    const fetchIdea = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getIdeaBox(params.guid);
            res.guid && setIdea(res);
        } catch {
            toast("Failed to fetch idea", false);
        } finally {
            setLoading(false);
        }
    }, [params.guid]);

    useEffect(() => {
        fetchIdea();
    }, [fetchIdea]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="ideas-container">
            <div className="container-header">
                <h1>Idea details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/requests/ideas", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {idea ? (
                <IdeaBoxEdit idea={idea} setIdea={setIdea} />
            ) : (
                <h2>
                    <Icon name="warning circle" />
                    Idea not found
                </h2>
            )}
        </div>
    );
};
