import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Button, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { IClientContact } from "../../models/clientContact";
import { deleteClientContact } from "../../services/clientContactService";
import { AppState } from "../../store/configureStore";

interface IProps {
    clientContact: IClientContact;
    handleRemoveClientContact: () => void;
    page: string;
}

export const ClientContactRow = ({ clientContact, handleRemoveClientContact, page }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteClientContact = async () => {
        const res = await deleteClientContact(clientContact.guid);
        if (!res.ok) toast("Failed to delete client contact", false);
        else {
            handleRemoveClientContact();
            toast("Successfully deleted client contact", true);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `client-contacts/${clientContact.guid}`,
            state: page,
        });
    };

    return (
        <Fragment>
            <Table.Row key={clientContact.guid} onClick={onRowClick} className="clickable-row">
                <Table.Cell>{clientContact.firstName + " " + clientContact.lastName}</Table.Cell>
                <Table.Cell>{clientContact.client}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this client contact?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteClientContact} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
