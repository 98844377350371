import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Loader, Segment, TextArea } from "semantic-ui-react";
import { toast } from "../..";
import { IBuyRequest, initRequest } from "../../models/buyRequests";
import { createBuyRequest } from "../../api/buyRequests";
import "./BuyRequests.css";

export const CreateBuyRequest = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState<IBuyRequest>(initRequest);

    const handleCreateBuyRequest = async () => {
        setLoading(true);
        try {
            await createBuyRequest(request);
            toast("Successfully created new buy request", true);
            history.push("/requests/buy-requests");
        } catch {
            toast("Error creating new buy request", false);
        } finally {
            setLoading(false);
        }
    };

    const handleOnChange = (event: any) => {
        setRequest({ ...request, [event.target.name]: event.target.value });
    };

    return (
        <div className="create-buy-request-container">
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <Segment.Group>
                    <Segment>
                        <h2>Create buy request</h2>
                        <Button
                            secondary
                            content="Back to list"
                            icon="arrow left"
                            onClick={() => history.push("/requests/buy-requests")}
                        />
                    </Segment>
                    <Segment>
                        <Form>
                            <section className="add-project-notes-row">
                                <div className="input-container">
                                    <label>Request for</label>
                                    <Input
                                        fluid
                                        name="title"
                                        placeholder="Enter requested item(s)"
                                        onChange={(event: any) => handleOnChange(event)}
                                    />
                                </div>
                                <div className="input-container">
                                    <label>Estimated value (HRK)</label>
                                    <Input
                                        name="estimatedValue"
                                        placeholder="Enter estimated value in HRK"
                                        value={request.estimatedValue}
                                        onChange={(event: any) => handleOnChange(event)}
                                    />
                                </div>
                            </section>
                            <div className="input-container">
                                <label>Reason for purchase</label>
                                <TextArea
                                    rows={5}
                                    name="reason"
                                    value={request.reason}
                                    onChange={(event: any) => handleOnChange(event)}
                                />
                            </div>
                            <div className="input-container">
                                <label>Description</label>
                                <TextArea
                                    rows={5}
                                    name="description"
                                    value={request.description}
                                    onChange={(event: any) => handleOnChange(event)}
                                />
                            </div>
                        </Form>
                    </Segment>
                    <Segment>
                        <Button
                            fluid
                            secondary
                            content="Create request"
                            icon="cart arrow down"
                            onClick={() => {
                                handleCreateBuyRequest();
                            }}
                            loading={loading}
                            disabled={request.title === ""}
                        />
                    </Segment>
                </Segment.Group>
            )}
        </div>
    );
};
