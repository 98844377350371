import { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Radio, Segment, Select, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import {
    IBankStatement,
    IUploadedBankStatements,
    UploadedBankStatementsInit,
} from "../../models/bankStatement";
import { createBankStatements, uploadBankStatements } from "../../services/bankStatementsService";
import { AppState } from "../../store/configureStore";
import "./UploadBankStatements.css";

export const UploadBankStatements = () => {
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [uploadedStatements, setUploadedStatements] = useState<IUploadedBankStatements>(
        UploadedBankStatementsInit
    );
    const [selectedStatements, setSelectedStatements] = useState<IBankStatement[]>([]);

    const handleCreateBankStatements = async () => {
        setLoading(true);
        try {
            await createBankStatements(selectedStatements);
            toast("Successfully created bank statements", true);
            history.push("/invoice-processing/bank-statements");
        } catch {
            toast("Failed to create bank statements, please try again", false);
        } finally {
            setLoading(false);
        }
    };

    const handleSetSelectedStatements = (stmnt: IBankStatement) => {
        if (selectedStatements.includes(stmnt))
            setSelectedStatements([...selectedStatements.filter(x => x !== stmnt)]);
        else setSelectedStatements([...selectedStatements].concat(stmnt));
    };

    const handleUploadFiles = async (files: File[]) => {
        setLoading(true);
        try {
            const res = await uploadBankStatements(files);
            const parsedFile: IUploadedBankStatements = await res.json();
            let newUploadedStatements: IUploadedBankStatements = {
                ...uploadedStatements!, statements: [...uploadedStatements!.statements, ...parsedFile.statements],

            };
            newUploadedStatements.invoices = [...parsedFile.invoices];
            newUploadedStatements.invoiceIBANs = [...parsedFile.invoiceIBANs];
            console.log(newUploadedStatements);
            setUploadedStatements(newUploadedStatements);
            toast("Uploaded and parsed new bank statements", true);
        } catch {
            toast("Failed to upload and parse new bank statements, please try again", false);
        } finally {
            setLoading(false);
        }
    };

    const toggleSelectAll = () => {
        if (selectedStatements.length === uploadedStatements?.statements.length)
            setSelectedStatements([]);
        else setSelectedStatements([...uploadedStatements!.statements]);
    };

    const ToggleButton = () => (
        <Button
            secondary
            content="Select all"
            icon={
                selectedStatements.length === uploadedStatements?.statements.length
                    ? "toggle off"
                    : "toggle on"
            }
            size="small"
            onClick={toggleSelectAll}
        />
    );

    const handleSetBoolean = (invoiceIndex: number) => {
        let uploadedStatementsTemp: IBankStatement[] = [...uploadedStatements.statements];
        uploadedStatementsTemp[invoiceIndex].researchAndDevelopment =
            !uploadedStatementsTemp[invoiceIndex].researchAndDevelopment;

        setUploadedStatements({ ...uploadedStatements, statements: uploadedStatementsTemp });
    };

    return user.role !== UserRole.Admin ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="upload-bank-statements-container">
            <FileDropzone
                title="Upload bank statements"
                loading={loading}
                accept="xml"
                handleUploadFiles={handleUploadFiles}
            />
            {uploadedStatements && (
                <div className="upload-bank-statements-display">
                    <Segment.Group>
                        <Segment>
                            <ToggleButton />
                        </Segment>
                        <Segment>
                            <Table basic="very">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Select</Table.HeaderCell>
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.HeaderCell>Amount</Table.HeaderCell>
                                        <Table.HeaderCell>Currency</Table.HeaderCell>
                                        <Table.HeaderCell>IBAN</Table.HeaderCell>
                                        <Table.HeaderCell>Date</Table.HeaderCell>
                                        <Table.HeaderCell>Invoice</Table.HeaderCell>
                                        <Table.HeaderCell>R&D</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {uploadedStatements.statements.map((x, index) => (
                                        <Table.Row key={x.name + x.amount + x.date}>
                                            <Table.Cell>
                                                <Radio
                                                    slider
                                                    checked={selectedStatements.includes(x)}
                                                    onClick={() => handleSetSelectedStatements(x)}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>{x.name}</Table.Cell>
                                            <Table.Cell>{x.amount}</Table.Cell>
                                            <Table.Cell>{x.currency}</Table.Cell>
                                            <Table.Cell>{x.iban}</Table.Cell>
                                            <Table.Cell>{getDisplayDateFormat(x.date)}</Table.Cell>
                                            <Table.Cell>
                                                <Select
                                                    clearable
                                                    placeholder = "Select invoice"
                                                    selectOnBlur={false}
                                                    options={uploadedStatements.invoices
                                                        .filter(i => !i.isProcessed)
                                                        .map(i => {
                                                            return { text: i.name, value: i.guid };
                                                        })}
                                                    onChange={(e, data: any) => {
                                                        x.invoiceGuid = data.value;
                                                    }}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Radio
                                                    slider
                                                    checked={x.researchAndDevelopment}
                                                    onClick={() => handleSetBoolean(index)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </Segment>
                        <Segment>
                            <ToggleButton />
                        </Segment>
                    </Segment.Group>
                    <Button
                        secondary
                        fluid
                        content="Upload selected bank statements"
                        icon="upload"
                        onClick={handleCreateBankStatements}
                        disabled={loading || selectedStatements.length === 0}
                    />
                </div>
            )}
        </div>
    );
};
