import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Button, Modal, DropdownItemProps } from "semantic-ui-react";
import { toast } from "../../..";
import { AppState } from "../../../store/configureStore";
import { UserRole } from "../../../actions/authentificationActions";
import { removeRoleFromProject } from "../../../api/projects";
import { IProjectProjectRole } from "../../../models/projectRole";
import { DropdownInput } from "../../../components/DropdownInput/DropdownInput";

interface IProps {
    projectRole: IProjectProjectRole;
    fetchProjectRoles: () => void;
    projectRoles: IProjectProjectRole[];
    setProjectRoles: Dispatch<SetStateAction<IProjectProjectRole[]>>;
    assigneesOptions: DropdownItemProps[];
}

export const ProjectRolesTableRow = ({
    projectRole,
    fetchProjectRoles,
    projectRoles,
    setProjectRoles,
    assigneesOptions,
}: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleDeleteProjectRole = async () => {
        try {
            await removeRoleFromProject(projectRole.guid!);
            fetchProjectRoles();
            toast("Project role successfully removed", true);
        } catch {
            toast("Failed to remove project role", false);
        } finally {
            setDeleteModalOpen(false);
        }
    };

    const handleAssigneesChange = (e: any, data: any) => {
        const index = projectRoles.findIndex(p => p.guid == projectRole.guid);
        const newProjectRole = { ...projectRole, assignees: data.value };
        setProjectRoles([
            ...projectRoles.slice(0, index),
            newProjectRole,
            ...projectRoles.slice(index + 1),
        ]);
    };

    return (
        <Fragment>
            <Table.Row>
                <Table.Cell>{projectRole.roleName}</Table.Cell>
                <Table.Cell>
                    <DropdownInput
                        placeholder="Add assignees..."
                        selectOnBlur={false}
                        fluid
                        multiple
                        search
                        value={projectRole.assignees}
                        options={assigneesOptions}
                        onChange={(e, data: any) => handleAssigneesChange(e, data)}
                        disabled={user.role !== UserRole.Admin}
                    />
                </Table.Cell>
                <Table.Cell>
                    <span>{projectRole.roleDescription}</span>
                </Table.Cell>
                {user.role === UserRole.Admin && (
                    <Table.Cell collapsing>
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setDeleteModalOpen(true)}
                        />
                    </Table.Cell>
                )}
            </Table.Row>
            <Modal
                size="mini"
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>{`Are you sure you want to remove ${projectRole.roleName} project role?`}</Modal.Header>
                <Modal.Actions>
                    <Button
                        color="red"
                        onClick={() => setDeleteModalOpen(false)}
                        content="Cancel"
                    />
                    <Button color="green" onClick={handleDeleteProjectRole} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
