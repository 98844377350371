import React, { useState, useEffect } from "react";
import { Item, Search } from "semantic-ui-react";
import _ from "lodash";
import agent from "../../api/agent";
import { Link } from "react-router-dom";
import { ISearchbarData } from "../../models/searchbarData";
import { Image } from "../../components/Image/Image";
import { DocumentType } from "../../models/document";

const initialState = {
    loading: false,
    results: [],
    value: "",
};

function exampleReducer(state: any, action: any) {
    switch (action.type) {
        case "CLEAN_QUERY":
            return initialState;
        case "START_SEARCH":
            return { ...state, loading: true, value: action.query };
        case "FINISH_SEARCH":
            return { ...state, loading: false, results: action.results };
        case "UPDATE_SELECTION":
            return { ...state, value: action.selection };

        default:
            throw new Error();
    }
}

function TitleEditor(title: string, description: string) {
    let stringArray: string[] = [];
    let descArray: string[] = [];
    descArray = description.split(" ");
    stringArray = title.split(" ");
    if (stringArray.length > 1 && descArray[0] === "Users") {
        stringArray.splice(-1);
        return stringArray.join(" ");
    } else return stringArray.join(" ");
}
function ChangeImageStyleSize(imgUrl: string) {
    if (imgUrl === "" || imgUrl === null) return "0px";
    else return "50px";
}

const resultRenderer = ({ title, description, url, imgUrl }: any) => (
    <Link to={{ pathname: url }}>
        <Item.Group link>
            <Item>
                <Item.Image
                    style={{
                        marginTop: "1rem",
                        width: ChangeImageStyleSize(imgUrl),
                        height: ChangeImageStyleSize(imgUrl),
                    }}
                >
                    <Image guid={imgUrl ?? ""} type={DocumentType.UserPhoto} />
                </Item.Image>
                <Item.Content>
                    <Item.Extra style={{ marginLeft: "0.4rem" }}>
                        <h4>{title}</h4>
                        <p style={{ fontSize: "12px" }}>{description}</p>
                    </Item.Extra>
                </Item.Content>
            </Item>
        </Item.Group>
    </Link>
);
const SearchBar = () => {
    const [state, dispatch] = React.useReducer(exampleReducer, initialState);
    const [source, setSource] = useState<ISearchbarData[]>([]);

    useEffect(() => {
        agent.SearchBar.list().then(response => {
            setSource(response);
        });
    }, []);

    const { loading, results, value } = state;
    const timeoutRef: any = React.useRef();

    const handleSearchChange = React.useCallback(
        (e, data) => {
            clearTimeout(timeoutRef.current);
            dispatch({ type: "START_SEARCH", query: data.value });

            timeoutRef.current = setTimeout(() => {
                if (data.value.length === 0) {
                    dispatch({ type: "CLEAN_QUERY" });
                    return;
                }

                const re = new RegExp(_.escapeRegExp(data.value), "i");
                const isMatch = (result: any) => re.test(result.title);

                dispatch({
                    type: "FINISH_SEARCH",
                    results: _.filter(source, isMatch),
                });
            }, 300);
        },

        [source]
    );

    React.useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    return (
        <Search
            size="tiny"
            loading={loading}
            onSearchChange={handleSearchChange}
            resultRenderer={resultRenderer}
            results={results}
            value={value}
            aligned="left"
        />
    );
};

export default SearchBar;
