import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Modal, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { IReport, reportTypeOptions } from "../../models/report";
import { deleteReport } from "../../api/reports";
import { AppState } from "../../store/configureStore";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";

interface IProps {
    report: IReport;
    handleRemoveReport: (r: IReport) => void;
    page: string;
}

export const ReportsRow = ({ report, handleRemoveReport, page }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteReport = async () => {
        try {
            await deleteReport(report.guid);
            handleRemoveReport(report);
            toast("Successfully deleted report", true);
        } catch {
            toast("Failed to delete report", false);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `reports/${report.guid}`,
            state: page,
        });
    };

    return (
        <Fragment>
            <Table.Row onClick={onRowClick} className="clickable-row">
                <Table.Cell>{getDisplayDateFormat(report.date)}</Table.Cell>
                <Table.Cell>{report.project ?? "Other project"}</Table.Cell>
                <Table.Cell>{report.user}</Table.Cell>
                <Table.Cell>{reportTypeOptions.find(x => x.key === report.type)?.text}</Table.Cell>
                <Table.Cell>{report.hours}</Table.Cell>
                <Table.Cell>
                    {report.text.length <= 10 ? report.text : report.text.substring(0, 10) + "..."}
                </Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {(user.role === UserRole.Admin || user.guid === report.userGuid) && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this report?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteReport} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
