import React, { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { TextInput } from "../../components/TextInput/TextInput";

interface IProps {
    loggedUser: UserData;
    userDetails: UserData;
    setUserDetails: Dispatch<SetStateAction<UserData | undefined>>;
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
}

export const UserPersonalDetails = ({
    loggedUser,
    userDetails,
    setUserDetails,
    handleInputChange,
}: IProps) => {
    const params: { guid: string } = useParams();
    const authorized = loggedUser.role === UserRole.Admin || loggedUser.guid === params.guid;

    const handlePriorExperienceInputChange = (e: any) => {
        let splitPriorExperience = userDetails!.priorExperience?.split("-");
        let years = splitPriorExperience[0];
        let months = splitPriorExperience[1];
        let days = splitPriorExperience[2];

        switch (e.currentTarget.name) {
            case "years":
                years = e.target.value;
                break;
            case "months":
                months = e.target.value;
                break;
            case "days":
                days = e.target.value;
                break;
        }
        const value = `${years}-${months}-${days}`;
        setUserDetails({ ...userDetails!, priorExperience: value });
    };

    return (
        <Table basic="very" celled collapsing>
            <Table.Row>
                <Table.Cell>OIB</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="oib"
                        value={userDetails.oib ?? ""}
                        handleInputChange={handleInputChange}
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Address</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="address"
                        value={userDetails.address ?? ""}
                        handleInputChange={handleInputChange}
                        disabled={!authorized}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>City</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="city"
                        value={userDetails.city ?? ""}
                        handleInputChange={handleInputChange}
                        disabled={!authorized}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Phone number</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="phoneNumber"
                        value={userDetails.phoneNumber ?? ""}
                        handleInputChange={handleInputChange}
                        disabled={!authorized}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Date of birth</Table.Cell>
                <Table.Cell>
                    <CalendarInput
                        date={userDetails.dayOfBirth?.substring(0, 10)}
                        handleDateChange={(e: any) =>
                            setUserDetails({
                                ...userDetails,
                                dayOfBirth: getISODateFormat(e),
                            })
                        }
                        placeholder="Date of birth"
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>Prior work experience</Table.Cell>
                <Table.Cell className="prior-experience-cell">
                    <TextInput
                        className="priorExperience"
                        name="years"
                        value={userDetails.priorExperience?.split("-")[0]}
                        handleInputChange={handlePriorExperienceInputChange}
                        disabled={loggedUser.role !== UserRole.Admin}
                        isNumber
                    />
                    Year(s)
                    <TextInput
                        className="priorExperience"
                        name="months"
                        value={userDetails.priorExperience?.split("-")[1]}
                        handleInputChange={handlePriorExperienceInputChange}
                        disabled={loggedUser.role !== UserRole.Admin}
                        isNumber
                    />
                    Month(s)
                    <TextInput
                        className="priorExperience"
                        name="days"
                        value={userDetails.priorExperience?.split("-")[2]}
                        handleInputChange={handlePriorExperienceInputChange}
                        disabled={loggedUser.role !== UserRole.Admin}
                        isNumber
                    />
                    Day(s)
                </Table.Cell>
            </Table.Row>
        </Table>
    );
};
