import { useEffect, useState } from "react";
import { Modal, Icon, Select, Button, Input, Radio } from "semantic-ui-react";
import { toast } from "../..";
import { clientContractInit } from "../../models/clientContract";
import { createClientContract } from "../../api/clientContract";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { contractOptions } from "../../models/clientContract";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import { createDocument } from "../../services/documentsService";
import { DocumentType, IUploadDocument } from "../../models/document";

interface IProps {
    clientGuid: string;
    open: boolean;
    closeModal: () => void;
    handleAddClientContracts: () => void;
}

export const AddClientContractsForm = ({
    clientGuid,
    open,
    closeModal,
    handleAddClientContracts,
}: IProps) => {
    const [newClientContract, setNewClientContract] = useState(clientContractInit);
    const [loading, setLoading] = useState(false);
    const uploadDocument: IUploadDocument = {
        documentType: DocumentType.Contract,
        entityGuid: clientGuid,
    };

    const handleAddNewClientContract = async () => {
        setLoading(true);
        try {
            await createClientContract(newClientContract);
            handleAddClientContracts();
            setNewClientContract(clientContractInit);
            toast("Client contract successfully added", true, 1500);
        } catch {
            toast("Error creating client contract", false, 1500);
        } finally {
            setLoading(false);
            closeModal();
        }
    };

    const handleUploadDocument = async (files: File[]) => {
        setLoading(true);
        try {
            await createDocument(uploadDocument, files[0]);
            toast("Contract successfully uploaded", true);
        } catch {
            toast("Failed to upload contract, please try again", false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        clientGuid && setNewClientContract({ ...newClientContract, clientGuid: clientGuid });
    }, [clientGuid]);

    return (
        <Modal open={open} onClose={closeModal} centered={false} size="tiny">
            <Modal.Header>
                <h2>Add new client contract</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-client-contract-form">
                    <div className="client-contract-container">
                        <div className="input-container">
                            <label>Start date</label>
                            <CalendarInput
                                date={newClientContract.startDate}
                                placeholder="Select date..."
                                handleDateChange={e =>
                                    setNewClientContract({
                                        ...newClientContract,
                                        startDate: getISODateFormat(e),
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>End date</label>
                            <CalendarInput
                                date={newClientContract.endDate}
                                placeholder="Select date..."
                                handleDateChange={e =>
                                    setNewClientContract({
                                        ...newClientContract,
                                        endDate: getISODateFormat(e),
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Value (HRK)</label>
                            <Input
                                value={newClientContract.value}
                                onChange={(e: any) =>
                                    setNewClientContract({
                                        ...newClientContract,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Responsible person</label>
                            <Input
                                value={newClientContract.responsiblePerson}
                                onChange={(e: any) =>
                                    setNewClientContract({
                                        ...newClientContract,
                                        responsiblePerson: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Type</label>
                            <Select
                                fluid
                                placeholder="Select contract type..."
                                selectOnBlur={false}
                                options={contractOptions}
                                value={newClientContract.contractType}
                                onChange={(e, data: any) =>
                                    setNewClientContract({
                                        ...newClientContract,
                                        contractType: data.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container" id="isSigned-radio">
                            <label>Signed</label>
                            <Radio
                                toggle
                                label={newClientContract.isSigned ? "Yes" : "No"}
                                checked={newClientContract.isSigned}
                                onChange={e =>
                                    setNewClientContract({
                                        ...newClientContract,
                                        isSigned: !newClientContract.isSigned,
                                    })
                                }
                            />
                        </div>
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new client contract"
                    secondary
                    fluid
                    onClick={handleAddNewClientContract}
                    disabled={loading || newClientContract.startDate === ""}
                />
                <FileDropzone
                    title="Upload contract"
                    handleUploadFiles={handleUploadDocument}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};
