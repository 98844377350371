import { useCallback, useEffect, useState } from "react";
import { Modal, Icon, Select, Button, DropdownItemProps, Input } from "semantic-ui-react";
import { clientContactInit } from "../../models/clientContact";
import { toast } from "../..";
import { getClientsAsProps } from "../../services/clientsService";
import { createClientContact } from "../../services/clientContactService";

interface IProps {
    clientGuid: string;
    open: boolean;
    closeModal: () => void;
    handleAddClientContacts: () => void;
}

export const AddClientContactsForm = ({
    clientGuid,
    open,
    closeModal,
    handleAddClientContacts,
}: IProps) => {
    const [newClientContact, setNewClientContact] = useState(clientContactInit);
    const [clients, setClients] = useState<DropdownItemProps[]>([]);

    const fetchClients = useCallback(async () => {
        const clients = await getClientsAsProps();
        Array.isArray(clients) && setClients(clients);
    }, []);

    useEffect(() => {
        open && fetchClients();
    }, [fetchClients, open]);

    useEffect(() => {
        clientGuid.length > 0 && setNewClientContact(c => ({ ...c, clientGuid: clientGuid }));
    }, [clientGuid]);

    const handleAddNewClientContact = async () => {
        const res = await createClientContact(newClientContact);
        if (!res.ok) {
            toast("Error creating client contact", false, 1500);
        } else {
            handleAddClientContacts();
            setNewClientContact(clientContactInit);
            toast("Client contact successfully added", true, 1500);
        }
        closeModal();
    };

    return (
        <Modal open={open} onClose={closeModal} dimmer="blurring" centered={false}>
            <Modal.Header>
                <h2>Add new client contact</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-client-contact-form">
                    <div className="datetime-container" id="client-contact-container">
                        <div className="input-container">
                            <label>First name</label>
                            <Input
                                value={newClientContact.firstName}
                                onChange={(e: any) =>
                                    setNewClientContact({
                                        ...newClientContact,
                                        firstName: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Last name</label>
                            <Input
                                value={newClientContact.lastName}
                                onChange={(e: any) =>
                                    setNewClientContact({
                                        ...newClientContact,
                                        lastName: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Employer</label>
                            <Select
                                fluid
                                placeholder="Select employer..."
                                selectOnBlur={false}
                                options={clients}
                                value={
                                    clientGuid.length > 0
                                        ? clientGuid
                                        : newClientContact.clientGuid!
                                }
                                disabled={clientGuid.length > 0 ? true : false}
                                onChange={(e, data: any) =>
                                    setNewClientContact({
                                        ...newClientContact,
                                        clientGuid: data.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Email</label>
                            <Input
                                value={newClientContact.email}
                                onChange={(e: any) =>
                                    setNewClientContact({
                                        ...newClientContact,
                                        email: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="input-container">
                            <label>Phone number</label>
                            <Input
                                value={newClientContact.phoneNumber}
                                onChange={(e: any) =>
                                    setNewClientContact({
                                        ...newClientContact,
                                        phoneNumber: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new client contact"
                    secondary
                    fluid
                    onClick={handleAddNewClientContact}
                    disabled={
                        newClientContact.firstName === "" ||
                        newClientContact.lastName === "" ||
                        (newClientContact.clientGuid === "" && clientGuid === "")
                    }
                />
            </Modal.Actions>
        </Modal>
    );
};
