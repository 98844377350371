import { useCallback, useEffect, useState } from "react";
import { IIdeaBox } from "../../models/ideaBox";
import { useLocation } from "react-router-dom";
import { getIdeaBoxes } from "../../api/ideaBoxes";
import { Button, Icon, Loader, Pagination, Segment, Table } from "semantic-ui-react";
import { AddIdeaForm } from "./AddIdeaForm";
import { IdeaBoxRow } from "./IdeaBoxRow";
import { getMyCompany } from "../../api/companies";
import { toast } from "../..";

export const IdeaBoxes = () => {
    const [loading, setLoading] = useState(true);
    const loc = useLocation();
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [totalRows, setTotalRows] = useState(0);
    const [ideaBoxes, setIdeaBoxes] = useState<IIdeaBox[]>([]);
    const [anonymousIdeasAllowed, setAnonymousIdeasAllowed] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const fetchIdeaBoxes = useCallback(async () => {
        try {
            setLoading(true);
            const res = await getIdeaBoxes(page);
            if (res.rows) {
                setIdeaBoxes(res.rows as IIdeaBox[]);
                setTotalRows(res.totalRows);
            }
            const response = await getMyCompany();
            if (response) setAnonymousIdeasAllowed(response.anonymousIdeasAllowed);
        } catch (error) {
            toast("Failed to load Idea boxes.", false);
        } finally {
            setLoading(false);
        }
    }, [page]);

    useEffect(() => {
        fetchIdeaBoxes();
    }, [fetchIdeaBoxes]);

    const handleFetchIdeas = () => {
        setPage(1);
        fetchIdeaBoxes();
    };

    const handleRemoveIdea = (i: IIdeaBox) => setIdeaBoxes([...ideaBoxes].filter(y => y !== i));

    return (
        <div className="idea-boxes-container">
            <div className="container-header">
                <h1>Idea box</h1>
                <Button
                    secondary
                    content="Add new idea"
                    icon="plus"
                    onClick={() => setModalOpen(true)}
                />
            </div>
            <div className="idea-boxes-display">
                <div className="idea-box-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment />
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Title</Table.HeaderCell>
                                            <Table.HeaderCell>Author</Table.HeaderCell>
                                            <Table.HeaderCell>Created</Table.HeaderCell>
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {ideaBoxes.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="5">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            ideaBoxes.map(ib => (
                                                <IdeaBoxRow
                                                    key={ib.guid}
                                                    ideaBox={ib}
                                                    page={page.toString()}
                                                    handleRemoveIdea={handleRemoveIdea}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                            <AddIdeaForm
                                open={modalOpen}
                                closeModal={() => setModalOpen(false)}
                                handleAddIdea={handleFetchIdeas}
                                anonymousAllowed={anonymousIdeasAllowed}
                            />
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
