import { useCallback, useEffect, useState } from "react";
import { Icon, Loader, Pagination, Segment, Table, Button, Select } from "semantic-ui-react";
import { IClient } from "../../models/client";
import { getClients } from "../../services/clientsService";
import { ClientRow } from "./ClientRow";
import { AddClientForm } from "./AddClientForm";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { UserRole } from "../../actions/authentificationActions";
import { categories } from "../../models/vendor";
import "./Clients.css";

export const Clients = () => {
    const user = useSelector((state: AppState) => state.user);
    const loc = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState<IClient[]>([]);
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [totalRows, setTotalRows] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [category, setCategory] = useState("Clients");

    const fetchClients = useCallback(async () => {
        setLoading(true);
        setClients([]);
        const res = await getClients(page);
        setLoading(false);
        if (res.rows) {
            setClients(res.rows as IClient[]);
            setTotalRows(res.totalRows);
        }
    }, [page]);

    useEffect(() => {
        category === "Clients" ? fetchClients() : history.push("/company/vendors");
    }, [category, fetchClients, history]);

    const handleFetchClients = () => {
        setPage(1);
        fetchClients();
    };

    return (
        <div className="clients-container">
            <div className="container-header">
                <h1>Clients</h1>
                <div id="categories">
                    <Select
                        placeholder="Select category..."
                        selectOnBlur={false}
                        options={categories}
                        value={category}
                        onChange={(e, data: any) => setCategory(data.value)}
                    />
                    {user.role === UserRole.Admin && (
                        <Button
                            secondary
                            content="Add new client"
                            icon="plus"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </div>
            </div>
            <div className="clients-display">
                <div className="clients-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment />
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Email</Table.HeaderCell>
                                            <Table.HeaderCell>Phone number</Table.HeaderCell>
                                            {user.role === UserRole.Admin && (
                                                <>
                                                    <Table.HeaderCell>
                                                        Contract status
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                                </>
                                            )}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {clients.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="5">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            clients.map(x => (
                                                <ClientRow
                                                    key={x.guid}
                                                    client={x}
                                                    handleRemoveClient={handleFetchClients}
                                                    page={page.toString()}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                            <AddClientForm
                                open={modalOpen}
                                closeModal={() => setModalOpen(false)}
                                handleAddClient={handleFetchClients}
                            />
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
