import { Departments } from "../Departments/Departments";
import { InvoiceCategories } from "../Invoices/InvoiceCategories";
import { InventoryItems } from "../InventoryItems/InventoryItems";
import { Vehicles } from "../Vehicles/Vehicles";
import { Locations } from "../Locations/Locations";
import { Services } from "../Services/Services";
import { CompanyServiceTypes } from "../CompanyServiceTypes/CompanyServicesTypes";

export const CompanySettings = () => {
    return (
        <div>
            <Departments />
            <Locations />
            <InvoiceCategories />
            <Services />
            <CompanyServiceTypes />
            <InventoryItems />
            <Vehicles />
        </div>
    );
};
