import { useSelector } from "react-redux";
import { Table, Segment } from "semantic-ui-react";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { UserRow } from "./UserRow";

interface IProps {
    title: string;
    users: UserData[];
    handleDeleteUser: () => void;
}

export const UsersTable = ({ title, users, handleDeleteUser }: IProps) => {
    const currentUser = useSelector((state: AppState) => state.user);
    return (
        <Segment.Group className="users-table-container">
            <Segment>
                <h2>{title}</h2>
            </Segment>
            <Segment className="table-container">
                <Table basic="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Username</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Role</Table.HeaderCell>
                            {currentUser.role !== UserRole.SuperAdmin && (
                                <Table.HeaderCell width={3}>Required documents</Table.HeaderCell>
                            )}
                            <Table.HeaderCell width={3}>Contract status</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {users.map(user => (
                            <UserRow
                                key={user.guid}
                                user={user}
                                handleDeleteUser={handleDeleteUser}
                            />
                        ))}
                        <Table.Row />
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};
