import axios from "axios";
import { IPaginationWrapper } from "../models/common";
import { IDailyReportsWrapper, IReport, IReportFilter } from "../models/report";

export const getReports = async (filters: IReportFilter) => {
    const params = new URLSearchParams({ page: filters.page + "" });
    filters.projectGuid && params.append("projectGuid", filters.projectGuid);
    filters.userGuid && params.append("userGuid", filters.userGuid);
    if (filters.date) {
        const date = new Date(Date.parse(filters.date));
        params.append("year", date.getFullYear().toString());
        params.append("month", (date.getMonth() + 1).toString());
    }

    const response = await axios.get("/api/reports?" + params.toString());
    return response.data as IPaginationWrapper;
};

export const getReport = async (guid: string) => {
    const response = await axios.get(`/api/reports/${guid}`);
    return response.data as IReport;
};

export const getUserReportsForDate = async (date: string) => {
    const response = await axios.get(`/api/reports/daily/${date}`);
    return response.data as IDailyReportsWrapper;
};

export const getUserReportsSummaryForDate = async (
    date: string,
    projectGuid: string,
    userGuid: string
) => {
    const params = new URLSearchParams();
    projectGuid !== "" && params.append("projectGuid", projectGuid);
    userGuid !== "" && params.append("userGuid", userGuid);

    const response = await axios.get("/api/reports/summary/" + date + "?" + params.toString());
    return response.data as IReport[];
};

export const saveUserReports = async (reports: IReport[]) => {
    const response = await axios.put("/api/reports", reports);
    return response;
};

export const deleteReport = async (guid: string) => {
    const response = await axios.delete(`/api/reports/${guid}`);
    return response;
};
