import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Button, Segment, Pagination, Icon, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { getAllUsersWeeklyCapacityPlannings } from "../../api/capacityPlanning";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { TableLoader } from "../../components/TableLoader/TableLoader";
import { getMondayDate, IUserWeeklyCapacityPlanning } from "../../models/capacityPlanning";
import { AppState } from "../../store/configureStore";

const FTE = 40; // Full-time equivalent

export const CapacityPlanning = () => {
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles: string[] = [UserRole.Admin, UserRole.SuperAdmin];
    const [loading, setLoading] = useState(true);
    const [capacityPlannings, setCapacityPlannings] = useState<IUserWeeklyCapacityPlanning[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [date, setDate] = useState(getISODateFormat(getMondayDate(new Date())));

    const fetchUserWeeklyCapacityPlannings = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getAllUsersWeeklyCapacityPlannings(page, date);
            setCapacityPlannings(res.rows as IUserWeeklyCapacityPlanning[]);
            setTotalRows(res.totalRows);
        } catch {
            toast("Unable to fetch user weekly capacity plannings", false);
        } finally {
            setLoading(false);
        }
    }, [date, page]);

    useEffect(() => {
        fetchUserWeeklyCapacityPlannings();
    }, [fetchUserWeeklyCapacityPlannings]);

    return !allowedRoles.includes(user.role ?? "") ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div>
            <div className="container-header">
                <h1>Capacity planning</h1>
                {user.role === UserRole.Admin && (
                    <Button
                        as={Link}
                        to="/invoice-processing/bank-statements/upload"
                        secondary
                        content="Plan project activities"
                        icon="clipboard list"
                    />
                )}
            </div>
            <Segment.Group>
                <Segment>
                    <CalendarInput
                        date={date}
                        handleDateChange={(e: any) => setDate(getISODateFormat(getMondayDate(e)))}
                    />
                </Segment>
                <Segment>
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>User</Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    Contractual FTE
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    Available FTE/Week
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    Allocated FTE/Week
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {loading ? (
                            <TableLoader columns={4} />
                        ) : (
                            <Table.Body>
                                {capacityPlannings.map(cp => (
                                    <Table.Row key={cp.userGuid}>
                                        <Table.Cell>{cp.userName}</Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {parseFloat((cp.userContractualHours / FTE).toFixed(1))}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {parseFloat((cp.userAvailableHours / FTE).toFixed(1))}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {parseFloat((cp.allocatedWeeklyHours / FTE).toFixed(1))}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        )}
                    </Table>
                </Segment>
                <Segment>
                    {totalRows > 10 && (
                        <Pagination
                            size="small"
                            activePage={page}
                            onPageChange={(e, data: any) => setPage(+data.activePage)}
                            ellipsisItem={{
                                content: <Icon name="ellipsis horizontal" />,
                            }}
                            firstItem={{ content: <Icon name="angle double left" /> }}
                            lastItem={{ content: <Icon name="angle double right" /> }}
                            prevItem={{ content: <Icon name="angle left" /> }}
                            nextItem={{ content: <Icon name="angle right" /> }}
                            totalPages={Math.ceil(totalRows / 10)}
                        />
                    )}
                </Segment>
            </Segment.Group>
        </div>
    );
};
