import { useCallback, useEffect, useState } from "react";
import { Button, Icon, Loader, Pagination, Segment, Table, Transition } from "semantic-ui-react";
import { toast } from "../..";
import { getProjectAccessRequets, processProjectAccessRequest } from "../../api/projects";
import { commonStatusOptions } from "../../models/common";
import { IProjectAccessRequest } from "../../models/projectAccessRequests";
import { ProjectAccessRequestRow } from "./ProjectAccessRequestRow";
import "./Projects.css";

export const ProjectAccessRequests = () => {
    const [projectAccessRequests, setProjectAccessRequests] = useState<IProjectAccessRequest[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState<IProjectAccessRequest[]>([]);
    const [status, setStatus] = useState(commonStatusOptions[0].key);
    const statusOptions = commonStatusOptions;

    const handleSelect = (sp: IProjectAccessRequest) => {
        if (selected.includes(sp)) setSelected(selected.filter(x => x !== sp));
        else setSelected(selected.concat(sp));
    };

    const handleToggleAll = () => {
        setSelected(selected.length === projectAccessRequests.length ? [] : projectAccessRequests);
    };

    const handleProcessRequest = async (requests: IProjectAccessRequest[], choice: boolean) => {
        setLoading(true);
        try {
            const guids = requests.map(x => x.guid);
            await processProjectAccessRequest(guids, choice);
            toast("Project access requests successfully processed", true);
            fetchProjectAccessRequests();
            setSelected([]);
        } catch {
            toast("Unable to process project access requests", false);
        } finally {
            setLoading(false);
        }
    };

    const fetchProjectAccessRequests = useCallback(async () => {
        setLoading(true);
        const res = await getProjectAccessRequets(page, status);

        if (res.rows) {
            setProjectAccessRequests(res.rows as IProjectAccessRequest[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page, status]);

    useEffect(() => {
        fetchProjectAccessRequests();
    }, [fetchProjectAccessRequests]);

    return (
        <div className="project-access-container">
            <div className="container-header">
                <h1>Access requests</h1>
            </div>
            <div className="project-access-display">
                <div className="project-access-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment className="project-access-options">
                                <Button
                                    secondary
                                    size="small"
                                    content="Select all"
                                    icon={
                                        projectAccessRequests.length > 0 &&
                                        selected.length === projectAccessRequests.length
                                            ? "toggle off"
                                            : "toggle on"
                                    }
                                    onClick={handleToggleAll}
                                />
                                <div>
                                    {statusOptions.map(x => (
                                        <span
                                            className={status === x.key ? "selected" : ""}
                                            key={x.key}
                                            onClick={() => setStatus(x.key)}
                                        >
                                            {x.label}
                                        </span>
                                    ))}
                                </div>
                                <Transition
                                    visible={selected.length > 0}
                                    animation="scale"
                                    duration={300}
                                >
                                    <Button.Group size="small">
                                        <Button
                                            color="green"
                                            icon="thumbs up"
                                            content="Approve selected"
                                            onClick={() => handleProcessRequest(selected, true)}
                                        />
                                        <Button.Or />
                                        <Button
                                            color="red"
                                            icon="thumbs down"
                                            content="Reject selected"
                                            onClick={() => handleProcessRequest(selected, false)}
                                        />
                                    </Button.Group>
                                </Transition>
                            </Segment>
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width="1">Select</Table.HeaderCell>
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                            <Table.HeaderCell>User</Table.HeaderCell>
                                            <Table.HeaderCell>Project</Table.HeaderCell>
                                            <Table.HeaderCell>Date of request</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {projectAccessRequests.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="8">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            projectAccessRequests.map(x => (
                                                <ProjectAccessRequestRow
                                                    key={x.guid}
                                                    projectAccessRequest={x}
                                                    selected={selected.includes(x)}
                                                    handleSelect={handleSelect}
                                                    handleProcessRequest={handleProcessRequest}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{ content: <Icon name="angle double left" /> }}
                                        lastItem={{ content: <Icon name="angle double right" /> }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
