import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import { IVehicle } from "../../models/vehicle";
import { getVehicle } from "../../services/vehiclesService";
import { VehicleEdit } from "./VehicleEdit";

export const VehicleDetails = () => {
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [vehicle, setVehicle] = useState<IVehicle>();

    const fetchVehicle = useCallback(async (guid: string) => {
        setLoading(true);
        const res = await getVehicle(guid);
        res.guid && setVehicle(res);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchVehicle(params.guid);
    }, [fetchVehicle, params.guid]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="vehicles-container">
            <div className="container-header">
                <h1>Vehicle details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/company/vehicles", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {vehicle ? (
                <VehicleEdit vehicle={vehicle} setVehicle={setVehicle} />
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Vehicle not found
                </h2>
            )}
        </div>
    );
};
