import axios from "axios";
import { DropdownItemProps } from "semantic-ui-react";
import { IPaginationWrapper } from "../models/common";
import { ICompanyServiceType } from "../models/companyServiceType";

export const getCompanyServiceTypes = async (page: number) => {
    const response = await axios.get("/api/companyServiceTypes", { params: { page: page } });
    return response.data as IPaginationWrapper;
};

export const getCompanyServiceType = async (guid: string) => {
    const response = await axios.get(`/api/companyServiceTypes/${guid}`);
    return response.data as ICompanyServiceType;
};

export const updateCompanyServiceType = async (companyServiceTypeDto: ICompanyServiceType) => {
    const response = await axios.put("/api/companyServiceTypes", companyServiceTypeDto);
    return response;
};

export const deleteCompanyServiceType = async (guid: string) => {
    const response = await axios.delete(`/api/companyServiceTypes/${guid}`);
    return response;
};
export const createCompanyServiceType = async (companyServiceTypeDto: ICompanyServiceType) => {
    const response = await axios.post("/api/companyServiceTypes", companyServiceTypeDto);
    return response;
};

export const getCompanyServiceTypesList = async () => {
    const response = await axios.get("/api/companyServiceTypes/list/");
    return response.data as DropdownItemProps[];
};
