import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Button, Icon, Loader, Pagination, Segment, Table, Transition } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { ApproveStatus, commonStatusOptions } from "../../models/common";
import { IWorkingPlan, WorkingPlanType } from "../../models/workingPlan";
import {
    getWorkingPlans,
    processWorkingPlanRequests,
    rejectRemoteWork,
} from "../../api/workingPlans";
import { AppState } from "../../store/configureStore";
import "./WorkingPlan.css";
import { WorkingPlanRow } from "./WorkingPlanRow";

export const getTypeString = (type: string) => {
    switch (type) {
        case "student-plans":
            return "Student plan";
        case "external-plans":
            return "External plan";
        default:
            return "";
    }
};

export const getPlanType = (type: string) => {
    switch (type) {
        case "student-plans":
            return 1;
        case "external-plans":
            return 2;
        default:
            return 0;
    }
};

export const WorkingPlan = () => {
    const user = useSelector((state: AppState) => state.user);
    const loc = useLocation();
    const type = loc.pathname.split("/").pop()!;
    const [workingPlans, setWorkingPlans] = useState<IWorkingPlan[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState<IWorkingPlan[]>([]);
    const [status, setStatus] = useState(
        sessionStorage.getItem("prevStudStatus") != null
            ? Number.parseInt(sessionStorage.getItem("prevStudStatus")!)
            : user.role === UserRole.Admin
            ? 4
            : 2
    );

    const statusOptions =
        user.role === UserRole.Admin
            ? commonStatusOptions.concat({ key: -1, label: "All" })
            : user.role === UserRole.Student || user.role === UserRole.External
            ? [{ key: ApproveStatus.Draft, label: "Draft" }].concat(commonStatusOptions)
            : commonStatusOptions;

    const handleSelect = (sp: IWorkingPlan) => {
        if (selected.includes(sp)) setSelected(selected.filter(x => x !== sp));
        else setSelected(selected.concat(sp));
    };

    const handleToggleAll = () =>
        selected.length === workingPlans.length ? setSelected([]) : setSelected(workingPlans);

    const handleProcessRequest = async (plans: IWorkingPlan[], choice: boolean) => {
        setLoading(true);
        const guids = plans.map(x => x.guid);
        try {
            await processWorkingPlanRequests(guids, choice);
            toast(`${getTypeString(type)} requests successfully processed`, true);
            fetchWorkingPlans();
            setSelected([]);
        } catch {
            toast(`Unable to process ${getTypeString(type).toLocaleLowerCase()} requests`, false);
        } finally {
            setLoading(false);
        }
    };

    const handleRejectRemoteWork = async (workingPlans: IWorkingPlan[]) => {
        setLoading(true);
        const guids = workingPlans.map(x => x.guid);
        try {
            await rejectRemoteWork(guids);
            toast("Remote work rejected", true);
            fetchWorkingPlans();
            setSelected([]);
        } catch {
            toast("Failed to reject remote work request", false);
        } finally {
            setLoading(false);
        }
    };

    const fetchWorkingPlans = useCallback(async () => {
        setLoading(true);
        const res = await getWorkingPlans(page, status, getPlanType(type));
        if (res.rows) {
            setWorkingPlans(res.rows as IWorkingPlan[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page, status]);

    useEffect(() => {
        fetchWorkingPlans();
    }, [fetchWorkingPlans]);

    useEffect(() => {
        setPage(1);
    }, [status]);

    useEffect(() => {
        sessionStorage.getItem("prevStatus") &&
            status === Number.parseInt(sessionStorage.getItem("prevStatus")!) &&
            sessionStorage.removeItem("prevStatus");
    }, [status]);

    return (
        <div className="working-plans-container">
            <div className="container-header">
                <h1>{`${getTypeString(type)}s`}</h1>
                <Button
                    as={Link}
                    to={`/${type}/create`}
                    secondary
                    content="Create new"
                    icon="plus"
                />
            </div>
            <div className="working-plans-display">
                <div className="working-plans-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment className="working-plans-options">
                                {user.role === UserRole.Admin && (
                                    <Button
                                        secondary
                                        size="small"
                                        content="Select all"
                                        icon={
                                            workingPlans.length > 0 &&
                                            selected.length === workingPlans.length
                                                ? "toggle off"
                                                : "toggle on"
                                        }
                                        onClick={handleToggleAll}
                                    />
                                )}
                                {statusOptions.map(x => (
                                    <span
                                        className={status === x.key ? "selected" : ""}
                                        key={x.key}
                                        onClick={() => setStatus(x.key)}
                                    >
                                        {x.label}
                                    </span>
                                ))}
                                <Transition
                                    visible={selected.length > 0}
                                    animation="scale"
                                    duration={300}
                                >
                                    <Button.Group size="small">
                                        <Button
                                            color="green"
                                            content="Approve selected"
                                            icon="calendar check"
                                            onClick={() => handleProcessRequest(selected, true)}
                                        />
                                        <Button.Or />
                                        <Button
                                            color="red"
                                            content="Reject selected"
                                            icon="calendar times"
                                            onClick={() => handleProcessRequest(selected, false)}
                                        />
                                    </Button.Group>
                                </Transition>
                            </Segment>
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            {user.role === UserRole.Admin && (
                                                <Table.HeaderCell width="1">
                                                    Select
                                                </Table.HeaderCell>
                                            )}
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                            <Table.HeaderCell>User</Table.HeaderCell>
                                            <Table.HeaderCell>Period</Table.HeaderCell>
                                            <Table.HeaderCell>Total hours</Table.HeaderCell>
                                            <Table.HeaderCell>Submit date</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {workingPlans.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="7">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            workingPlans.map(x => (
                                                <WorkingPlanRow
                                                    key={x.guid}
                                                    workingPlan={x}
                                                    selected={selected.includes(x)}
                                                    handleSelect={handleSelect}
                                                    user={user}
                                                    handleProcessRequest={handleProcessRequest}
                                                    handleRejectRemoteWork={handleRejectRemoteWork}
                                                    fetchWorkingPlans={fetchWorkingPlans}
                                                    page={page.toString()}
                                                    status={status}
                                                    type={type}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{ content: <Icon name="angle double left" /> }}
                                        lastItem={{ content: <Icon name="angle double right" /> }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
