import axios from "axios";
import { IBuyRequest } from "../models/buyRequests";
import { IPaginationWrapper } from "../models/common";
import { ISignUp } from "../models/signUp";

export const sendSignUpData = async (signUpData: ISignUp) => {
    const response = await axios.post("/users/signUp", signUpData);
    return response;
};




