import { useState, Dispatch, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import { TextInput } from "../../components/TextInput/TextInput";
import { IDepartment } from "../../models/department";
import { editDepartment } from "../../services/departmentsService";
import { AppState } from "../../store/configureStore";
import { fetchStaff } from "../../store/staffReducer";

interface IProps {
    department: IDepartment;
    setDepartment: Dispatch<SetStateAction<IDepartment | undefined>>;
}

export const DepartmentEdit = ({ department, setDepartment }: IProps) => {
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const staff = useSelector((state: AppState) => state.staff).filter(
        x => x.departmentguid === "" || x.departmentguid === department.guid
    );
    const [saving, setSaving] = useState(false);
    const unauthorized = user.role !== UserRole.Admin;
    const dispatch = useDispatch();

    const handleDepartmentUpdate = async () => {
        setSaving(true);
        const res = await editDepartment(department);
        if (!res.ok) {
            toast("Failed to save changes, please try again", false);
        } else {
            toast("Successfully saved changes", true);
            fetchStaff(dispatch);
            history.push("/company/departments");
        }
    };

    const handleInputChange = (e: any) => {
        setDepartment({ ...department!, [e.target.name]: e.target.value });
    };

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleDepartmentUpdate}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    return (
        <Segment.Group>
            <Segment>
                <h2>Edit department details</h2>
                <SaveButton />
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Name</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="name"
                                    value={department.name}
                                    handleInputChange={handleInputChange}
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Department members</Table.Cell>
                            <Table.Cell>
                                <DropdownInput
                                    placeholder="Add department members..."
                                    multiple
                                    search
                                    fluid
                                    value={department.users}
                                    options={staff}
                                    onChange={(e, data: any) =>
                                        setDepartment({ ...department, users: data.value })
                                    }
                                    disabled={unauthorized}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Segment>
                <SaveButton />
            </Segment>
        </Segment.Group>
    );
};
