import { useState } from "react";
import { Button, Table } from "semantic-ui-react";
import { toast } from "../..";
import { ICompany } from "../../models/company";
import { deleteCompany } from "../../api/companies";
import { useHistory } from "react-router-dom";

interface IProps {
    company: ICompany;
    fetchCompanies: () => void;
}

export const CompanyRow = ({ company, fetchCompanies }: IProps) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleDeleteCompany = async () => {
        setLoading(true);
        try {
            await deleteCompany(company.guid);
            toast("Company successfully deleted", true, 1500);
            fetchCompanies();
        } catch {
            toast("Failed to delete company", false);
        } finally {
            setLoading(false);
        }
    };

    const onRowClick = () => {
        history.push("#");
    }

    return (
        <Table.Row key={company.guid} onClick={onRowClick} className="clickable-row">
            <Table.Cell>{company.name}</Table.Cell>
            <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                <Button
                    color="red"
                    content="Delete"
                    icon="delete"
                    loading={loading}
                    onClick={handleDeleteCompany}
                />
            </Table.Cell>
        </Table.Row>
    );
};
