import { useMemo, useState, Dispatch, SetStateAction } from "react";
import { useDropzone } from "react-dropzone";
import ReactCrop from "react-image-crop";
import { Image } from "../Image/Image";
import "react-image-crop/dist/ReactCrop.css";
import "./ImageDropzone.css";
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from "./dropzoneStyles";
import { DocumentType } from "../../models/document";

interface IProps {
    guid: string;
    onDrop: () => void;
    crop: any;
    setCrop: Dispatch<SetStateAction<any>>;
    documentType: DocumentType;
    disabled?: boolean;
}

export const ImageDropzone = ({
    guid,
    onDrop,
    crop,
    setCrop,
    documentType,
    disabled,
}: IProps) => {
    const [file, setFile] = useState<File>();
    const [preview, setPreview] = useState("");
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        maxSize: 5242880,
        maxFiles: 1,
        multiple: false,
        accept: "image/*",
        onDrop: (acceptedFiles: File[]) => {
            setPreview((window.URL ? URL : webkitURL).createObjectURL(acceptedFiles[0]));
            setFile(acceptedFiles[0]);
            onDrop();
        },
    });

    const style = useMemo(
        () =>
            ({
                ...baseStyle,
                ...(isDragActive ? activeStyle : {}),
                ...(isDragAccept ? acceptStyle : {}),
                ...(isDragReject ? rejectStyle : {}),
            } as any),
        [isDragActive, isDragReject, isDragAccept]
    );

    const handleChangeCrop = (newCrop: any) => setCrop(newCrop);

    const onImageLoaded = (image: any) => {
        let cropDim = 350;
        if (image.width < 350) cropDim = image.width;
        if (image.height < 350) cropDim = image.height;
        setCrop({ width: cropDim, height: cropDim, aspect: 1, x: 0, y: 0 });
        return false;
    };

    return (
        (
            <div className="image-dropzone-container">
                <section className="image-container">
                    <Image guid={guid} type={documentType} />
                    {!disabled && (
                        <div className="image-dropzone" {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <p>Drag and drop the image here or click to select image</p>
                        </div>
                    )}
                </section>
                {!disabled && (
                    <section>
                        <div className="crop-container">
                            {file && (
                                <ReactCrop
                                    src={preview}
                                    crop={crop}
                                    onChange={handleChangeCrop.bind(this)}
                                    onImageLoaded={onImageLoaded.bind(this)}
                                />
                            )}
                        </div>
                    </section>
                )}
            </div>
        )
    );
};
