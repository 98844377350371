import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import { IDepartment } from "../../models/department";
import { getDepartment } from "../../services/departmentsService";
import { DepartmentEdit } from "./DepartmentEdit";

export const DepartmentDetails = () => {
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(true);
    const [department, setDepartment] = useState<IDepartment>();

    const fetchDepartment = useCallback(async (guid: string) => {
        setLoading(true);
        const res = await getDepartment(guid);
        res.guid && setDepartment(res);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchDepartment(params.guid);
    }, [fetchDepartment, params.guid]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="departments-container">
            <div className="container-header">
                <h1>Department details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/company/departments", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {department ? (
                <DepartmentEdit department={department} setDepartment={setDepartment} />
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Department not found
                </h2>
            )}
        </div>
    );
};
