import { useCallback, useEffect, useState } from "react";
import { Button, Icon, Modal, Pagination, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { addEducationCertificate, getEducations } from "../../api/educations";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import { IEducation } from "../../models/educationsModel";
import { downloadDocument } from "../../services/documentsService";
import { useHistory } from "react-router-dom";

interface IProps {
    userGuid: string;
}

export const UserCertificatesTable = ({ userGuid }: IProps) => {
    const history = useHistory();
    const [educations, setEducations] = useState<IEducation[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [educationGuidToUpload, setEducationGuidToUpload] = useState("");

    const [loading, setLoading] = useState(false);

    const fetchEducations = useCallback(
        async (userGuid?: string | undefined) => {
            setLoading(true);
            const res = await getEducations(page, userGuid);
            if (res.rows) {
                setEducations(res.rows as IEducation[]);
                setTotalRows(res.totalRows);
            }
            setLoading(false);
        },
        [page]
    );

    const handleUploadFile = async (files: File[]) => {
        setLoading(true);
        try {
            await addEducationCertificate(educationGuidToUpload, files[0]);
            toast("Certificate successfully uploaded", true);
            fetchEducations(userGuid);
        } catch {
            toast("Unable to upload certificate", false);
        } finally {
            setLoading(false);
            setEducationGuidToUpload("");
        }
    };

    const onRowClick = (guid: string) => {
        let path = `/employee-data/educations/edit/${guid}`;
        history.push(path);
    };

    useEffect(() => {
        fetchEducations(userGuid);
    }, [fetchEducations, userGuid]);

    return (
        <div>
            <Segment.Group>
                <Segment>
                    <h2>User certificates</h2>
                </Segment>
                <Segment>
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Title</Table.HeaderCell>
                                <Table.HeaderCell>Date of completion</Table.HeaderCell>
                                <Table.HeaderCell>Duration</Table.HeaderCell>
                                <Table.HeaderCell>Issuing institution</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {educations.length === 0 ? (
                                <Table.Row>
                                    <Table.Cell textAlign="center" colSpan="8">
                                        No matching records found
                                    </Table.Cell>
                                </Table.Row>
                            ) : (
                                educations.map(x => (
                                    <>
                                        <Table.Row onClick={() => onRowClick(x.guid)} className="clickable-row">
                                            <Table.Cell>{x.title}</Table.Cell>
                                            <Table.Cell>{getDisplayDateFormat(x.date)}</Table.Cell>
                                            <Table.Cell>
                                                {x.totalHours} hour
                                                {x.totalHours > 1 ? "s" : ""}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {x.issuingInstitution}
                                            </Table.Cell>
                                            <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                                                <Button
                                                    color="olive"
                                                    content="Upload"
                                                    icon="upload"
                                                    onClick={() => {
                                                        setEducationGuidToUpload(x.guid);
                                                    }}
                                                />
                                                <Button
                                                    color="purple"
                                                    content="Download"
                                                    icon="download"
                                                    onClick={() => downloadDocument(x.guid)}
                                                    disabled={x.certificates.length === 0}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        <Modal
                                            open={educationGuidToUpload !== ""}
                                            onClose={() => {
                                                setEducationGuidToUpload("");
                                                fetchEducations();
                                            }}
                                            dimmer="blurring"
                                        >
                                            <Modal.Header>
                                                Please upload your certificate
                                            </Modal.Header>
                                            <Modal.Content>
                                                <FileDropzone
                                                    title=""
                                                    handleUploadFiles={files => {
                                                        handleUploadFile(files);
                                                        setEducationGuidToUpload("");
                                                    }}
                                                    loading={loading}
                                                />
                                            </Modal.Content>
                                        </Modal>
                                    </>
                                ))
                            )}
                        </Table.Body>
                    </Table>
                </Segment>
                <Segment>
                    {totalRows > 10 && (
                        <Pagination
                            size="small"
                            activePage={page}
                            onPageChange={(e, data: any) => setPage(+data.activePage)}
                            ellipsisItem={{
                                content: <Icon name="ellipsis horizontal" />,
                            }}
                            firstItem={{ content: <Icon name="angle double left" /> }}
                            lastItem={{ content: <Icon name="angle double right" /> }}
                            prevItem={{ content: <Icon name="angle left" /> }}
                            nextItem={{ content: <Icon name="angle right" /> }}
                            totalPages={Math.ceil(totalRows / 10)}
                        />
                    )}
                </Segment>
            </Segment.Group>
        </div>
    );
};
