import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon, Input, Modal, Select } from "semantic-ui-react";
import { toast } from "../..";
import { departmentInit } from "../../models/department";
import { createDepartment } from "../../services/departmentsService";
import { AppState } from "../../store/configureStore";
import { fetchStaff } from "../../store/staffReducer";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddDepartment: () => void;
}

export const AddDepartmentForm: React.FC<IProps> = ({ open, closeModal, handleAddDepartment }) => {
    const dispatch = useDispatch();
    const staff = useSelector((state: AppState) => state.staff).filter(
        (x) => x.departmentguid === ""
    );
    const [newDepartment, setNewDepartment] = useState(departmentInit);

    const handleAddNewDepartment = async () => {
        const res = await createDepartment(newDepartment);
        if (!res.ok) toast("Error creating department", false);
        else {
            handleAddDepartment();
            fetchStaff(dispatch);
            toast("Department successfully added", true, 1500);
        }
        closeModal();
    };

    return (
        <Modal open={open} onClose={closeModal} dimmer="blurring" centered={false}>
            <Modal.Header>
                <h2>Add new department</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form>
                    <div className="input-container">
                        <label>Name</label>
                        <Input
                            type="name"
                            value={newDepartment.name}
                            onChange={(e: any) =>
                                setNewDepartment({ ...newDepartment, name: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Department members</label>
                        <Select
                            placeholder="Add users to department"
                            selectOnBlur={false}
                            fluid
                            multiple
                            search
                            value={newDepartment.users}
                            options={staff}
                            onChange={(e, data: any) =>
                                setNewDepartment({ ...newDepartment, users: data.value })
                            }
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new department"
                    disabled={newDepartment.name === ""}
                    secondary
                    fluid
                    onClick={handleAddNewDepartment}
                />
            </Modal.Actions>
        </Modal>
    );
};
