import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Segment, Loader, Button } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { getAllUsersWeeklyCapacityPlannings } from "../../api/capacityPlanning";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { getMondayDate, IUserWeeklyCapacityPlanning } from "../../models/capacityPlanning";
import { AppState } from "../../store/configureStore";
import { UtilizationBar } from "./UtilizationBar";
import { AddTasksToStaffModal } from "./AddTasksToStaffModal";
import "./CapacityPlanning.css";

export const EmployeeUtilization = () => {
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles: string[] = [UserRole.Admin, UserRole.SuperAdmin];
    const [loading, setLoading] = useState(true);
    const [employeeUtilization, setEmployeeUtilization] = useState<IUserWeeklyCapacityPlanning[]>(
        []
    );
    const [date, setDate] = useState(getISODateFormat(getMondayDate(new Date())));
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUserGuid, setSelectedUserGuid] = useState("");

    const fetchUserWeeklyCapacityPlannings = useCallback(async () => {
        setLoading(true);
        try {
            const page = 0; // because no pagination needed here (for now)
            const res = await getAllUsersWeeklyCapacityPlannings(page, date);
            setEmployeeUtilization(res.rows as IUserWeeklyCapacityPlanning[]);
        } catch {
            toast("Unable to fetch users' weekly capacity plannings", false);
        } finally {
            setLoading(false);
        }
    }, [date]);

    const handleOpenModal = (userGuid: string) => {
        setSelectedUserGuid(userGuid);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedUserGuid("");
        setModalOpen(false);
    };

    useEffect(() => {
        fetchUserWeeklyCapacityPlannings();
    }, [fetchUserWeeklyCapacityPlannings]);

    return !allowedRoles.includes(user.role ?? "") ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div>
            <div className="container-header">
                <h1>Employee utilization</h1>
            </div>
            <Segment.Group>
                <Segment>
                    <CalendarInput
                        date={date}
                        handleDateChange={(e: any) => setDate(getISODateFormat(getMondayDate(e)))}
                    />
                </Segment>
                <Segment>
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <div className="utilization-charts">
                            {employeeUtilization.map(e => (
                                <section key={e.userGuid}>
                                    <UtilizationBar
                                        allocated={e.allocatedWeeklyHours}
                                        available={e.userAvailableHours}
                                        label={e.userName}
                                    />
                                    <Button
                                        secondary
                                        content="Add more tasks"
                                        onClick={() => handleOpenModal(e.userGuid)}
                                    />
                                </section>
                            ))}
                        </div>
                    )}
                </Segment>
                <Segment></Segment>
            </Segment.Group>
            {modalOpen && (
                <AddTasksToStaffModal
                    open={modalOpen}
                    closeModal={handleCloseModal}
                    userGuid={selectedUserGuid}
                    date={date}
                    refresh={fetchUserWeeklyCapacityPlannings}
                />
            )}
        </div>
    );
};
