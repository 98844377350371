import { IDate } from "../../models/date";

export const getPlanHours = (d: IDate) => {
    let start = Number.parseInt(d.start.split(":")[0]);
    if (d.start.includes(":30")) start += 0.5;
    let end = Number.parseInt(d.end.split(":")[0]);
    if (d.end.includes(":30")) end += 0.5;
    const result = end - start;
    if (result > 0) return end - start;
    else return null;
};

export const validatePlanHours = (dates: IDate[]) => {
    let valid = 0;
    dates.forEach((d) => {
        if (d.start && d.end && d.hours)
            if (Number.parseFloat(d.hours + "") && getPlanHours(d)) {
                let start = Number.parseInt(d.start.split(":")[0]);
                if (d.start.includes(":30")) start += 0.5;
                let end = Number.parseInt(d.end.split(":")[0]);
                if (d.end.includes(":30")) end += 0.5;
                if (Number.parseFloat(d.hours + "") <= end - start) valid++;
            }
    });
    return valid;
};
