import { IInvoiceFilters } from "../models/bankStatement";
import { IPaginationWrapper } from "../models/common";
import { IInvoiceDocument, IInvoiceDocumentsWrapper } from "../models/document";
import { IInvoice, IInvoiceCategory } from "../models/invoice";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getInvoices(
    page: number,
    filters: IInvoiceFilters
): Promise<IPaginationWrapper> {
    const params = new URLSearchParams({ page: page + "" });
    filters.status !== undefined && params.append("status", filters.status + "");
    filters.startDate && params.append("startDate", filters.startDate);
    filters.endDate && params.append("endDate", filters.endDate);
    filters.query && params.append("query", filters.query);
    filters.categoryGuid && params.append("categoryGuid", filters.categoryGuid);
    return await fetch(`/api/invoices?${params.toString()}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getInvoice(guid: string): Promise<IInvoice> {
    return await fetch(`/api/invoices/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getInvoiceList(): Promise<IInvoice[]> {
    return await fetch("/api/invoices/list/", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    });
}

export async function editInvoice(invoice: IInvoice): Promise<Response> {
    return await fetch(`/api/invoices/${invoice.guid}`, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(invoice),
    });
}

export async function uploadInvoiceDocuments(files: File[]): Promise<IInvoiceDocumentsWrapper> {
    const formData = new FormData();
    files.forEach(file => formData.append("files", file, file.name));
    return await fetch("/api/invoices/upload", {
        method: "POST",
        ...getAuthorizationProperties(),
        body: formData,
    }).then(res => {
        return res.json();
    });
}

export async function downloadInvoices() {
    const res = await fetch(`/api/invoices/download-invoices`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    if (!res.ok) return false;
    const blob = await res.blob();
    const url = window.URL.createObjectURL(new Blob([blob], { type: "application/zip" }));
    const link = document.createElement("a");
    link.href = url;
    const fileName = "Aconto_invoices";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
    return true;
}

export async function createInvoiceDocuments(
    invoiceDocuments: IInvoiceDocument[],
    projectId: number
): Promise<Response> {
    return await fetch(`/api/invoices?projectId=${projectId}`, {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(invoiceDocuments),
    }).then(res => {
        return res.json();
    });
}

export async function deleteInvoice(guid: string): Promise<Response> {
    return await fetch(`/api/invoices/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function getInvoiceCategories(page: number): Promise<IPaginationWrapper> {
    return await fetch(`/api/invoices/getInvoiceCategories?page=${page}`, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then(res => {
        return res.json();
    });
}

export async function getInvoiceCategoriesList(): Promise<IInvoiceCategory[]> {
    const response = await fetch("/api/invoices/getInvoiceCategoriesList/", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
    return response.json();
}

export async function createInvoiceCategory(
    invoiceCategoryDto: Partial<IInvoiceCategory>
): Promise<Response> {
    return await fetch("/api/invoices/createInvoiceCategory/", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(invoiceCategoryDto),
    });
}

export async function deleteInvoiceCategory(guid: string): Promise<Response> {
    return await fetch(`/api/invoices/deleteInvoiceCategory/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    });
}

export async function editInvoiceCategory(invoiceCategory: IInvoiceCategory): Promise<Response> {
    return await fetch(`/api/invoices/updateInvoiceCategory/${invoiceCategory.guid}`, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(invoiceCategory),
    });
}
