import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Button, Radio, Table } from "semantic-ui-react";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { TextInput } from "../../components/TextInput/TextInput";
import { getMyCompany } from "../../api/companies";
import { ICompany, newCompanyInit } from "../../models/company";
import { toast } from "../..";

interface IProps {
    loggedUser: UserData;
    userDetails: UserData;
    setUserDetails: Dispatch<SetStateAction<UserData | undefined>>;
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
}

export const UserGeneral = ({
    loggedUser,
    userDetails,
    setUserDetails,
    handleInputChange,
}: IProps) => {
    const [company, setCompany] = useState<ICompany>(newCompanyInit);

    const fetchMyCompany = useCallback(async () => {
        try {
            setCompany(await getMyCompany());
        } catch {
            toast("Failed to fetch my company", false);
        }
    }, []);

    useEffect(() => {
        fetchMyCompany();
    }, [fetchMyCompany]);

    return (
        <Table basic="very" celled collapsing>
            {loggedUser.role === UserRole.Admin && (
                <Table.Row>
                    <Table.Cell>Is enabled</Table.Cell>
                    <Table.Cell>
                        <Radio
                            toggle
                            checked={userDetails.isActive}
                            onChange={() =>
                                setUserDetails({
                                    ...userDetails,
                                    isActive: !userDetails.isActive,
                                })
                            }
                        />
                    </Table.Cell>
                </Table.Row>
            )}
            {loggedUser.role === UserRole.Admin && (
                <Table.Row>
                    <Table.Cell>Is employed</Table.Cell>
                    <Table.Cell>
                        <Radio
                            toggle
                            checked={userDetails.isEmployed}
                            onChange={() =>
                                setUserDetails({
                                    ...userDetails,
                                    isEmployed: !userDetails.isEmployed,
                                })
                            }
                        />
                    </Table.Cell>
                </Table.Row>
            )}
            <Table.Row>
                <Table.Cell>Job title for signature</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="jobTitleForSignature"
                        value={userDetails.jobTitleForSignature ?? ""}
                        handleInputChange={handleInputChange}
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell collapsing>Academic title for signature</Table.Cell>
                <Table.Cell>
                    <TextInput
                        name="academicTitleForSignature"
                        value={userDetails.academicTitleForSignature ?? ""}
                        handleInputChange={handleInputChange}
                        disabled={loggedUser.role !== UserRole.Admin}
                    />
                </Table.Cell>
            </Table.Row>
            {company.gamificationAllowed && (
                <>
                    <Table.Row>
                        <Table.Cell>Positive points</Table.Cell>
                        <Table.Cell>
                            <TextInput
                                className="pointsInput"
                                name="negativePoints"
                                value={userDetails.points!}
                                handleInputChange={(e: any) =>
                                    setUserDetails({
                                        ...userDetails,
                                        points: e.target.value,
                                    })
                                }
                                disabled={loggedUser.role !== UserRole.Admin}
                                isNumber
                            />
                            {loggedUser.role === UserRole.Admin && (
                                <Button
                                    className="points-button"
                                    color="green"
                                    icon="plus"
                                    onClick={(e, data: any) =>
                                        setUserDetails({
                                            ...userDetails,
                                            points: Number(userDetails.points!) + 1,
                                        })
                                    }
                                />
                            )}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Negative points</Table.Cell>
                        <Table.Cell>
                            <TextInput
                                className="pointsInput"
                                name="negativePoints"
                                value={userDetails.negativePoints!}
                                handleInputChange={(e: any) =>
                                    setUserDetails({
                                        ...userDetails,
                                        negativePoints: e.target.value,
                                    })
                                }
                                disabled={loggedUser.role !== UserRole.Admin}
                                isNumber
                            />
                            {loggedUser.role === UserRole.Admin && (
                                <Button
                                    className="points-button"
                                    color="red"
                                    icon="minus"
                                    onClick={(e, data: any) =>
                                        setUserDetails({
                                            ...userDetails,
                                            negativePoints: Number(userDetails.negativePoints!) + 1,
                                        })
                                    }
                                />
                            )}
                        </Table.Cell>
                    </Table.Row>
                </>
            )}
        </Table>
    );
};
