import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AppState } from "../../store/configureStore";

interface PrivateRouteProps extends RouteProps {
    component: any;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
    const token = useSelector((state: AppState) => state.user.token);
    let loggedIn = useSelector((state: AppState) => state.login.loggedIn);

    return (
        <Route
            {...rest}
            render={(props) =>
                token && loggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};
