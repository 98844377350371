import { ApproveStatus } from "./common";

export interface IWorkingPlan {
    guid: string;
    submitDate: string;
    status: ApproveStatus;
    user: string;
    userEmail: string;
    totalHours: number;
    companyGuid: string;
    dates: string[];
    period: string;
    hasRemoteDays: boolean;
    workingPlanType: WorkingPlanType;
}

export enum WorkingPlanType {
    Unknown = 0,
    Student = 1,
    External = 2,
}
