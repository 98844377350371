import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Loader, Segment, Table } from "semantic-ui-react";
import { AppState } from "../../store/configureStore";
import { UserRole } from "../../actions/authentificationActions";
import { ILocation } from "../../models/location";
import { getLocations } from "../../api/locations";
import { toast } from "../..";
import { LocationRow } from "./LocationRow";
import { AddLocationForm } from "./AddLocationForm";
import "../Invoices/Invoices.css";
import "./Locations.css";

export const Locations = () => {
    const user = useSelector((state: AppState) => state.user);
    const authorized = user.role === UserRole.Admin;
    const [loading, setLoading] = useState(false);
    const [locations, setLocatios] = useState<ILocation[]>([]);
    const [modalOpen, setModalOpen] = useState(false);

    const fetchLocations = useCallback(async () => {
        setLoading(true);
        try {
            const res = await getLocations();
            setLocatios(res);
        } catch {
            toast("Could not fetch locations", false);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);

    const handleFetchLocations = () => {
        fetchLocations();
    };

    return !authorized ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="locations-container">
            <div className="container-header">
                <h1>Locations</h1>
                <Button
                    secondary
                    content="Add new location"
                    icon="plus"
                    onClick={() => setModalOpen(true)}
                />
            </div>
            <div className="locations-display">
                <div className="locations-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment />
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className="label-column-header"></Table.HeaderCell>
                                            <Table.HeaderCell>Name</Table.HeaderCell>

                                            <Table.HeaderCell>
                                                No. of users at location
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {locations.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="5">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            locations.map(x => (
                                                <LocationRow
                                                    key={x.guid}
                                                    location={x}
                                                    fetchLocations={fetchLocations}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment />
                            <AddLocationForm
                                open={modalOpen}
                                closeModal={() => setModalOpen(false)}
                                handleAddLocation={handleFetchLocations}
                            />
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
