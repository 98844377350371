import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { LoginState, loginReducer as login } from "./loginReducer";
import { UserData } from "../actions/authentificationActions";
import { userReducer as user } from "./userReducer";
import { rolesReducer as roles, RolesState } from "./rolesReducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/root";
import { toastMessageReducer as toastMessage, ToastMessageState } from "./toastMessageReducer";
import { imageReducer as images, ImagesState } from "./imageReducer";
import { staffReducer as staff, StaffState } from "./staffReducer";
import { clientsReducer as clients, ClientsState } from "./clientsReducer";
import { projectsReducer as projects, ProjectsState } from "./projectsReducer";
import { appStageReducer as appStage, AppStageState } from "./appStageReducer";
import { UnreadBoardNewsState, unreadBoardNewsReducer as unreadBoardNews } from "./unreadBoardNews";

export interface AppState {
    login: LoginState;
    user: UserData;
    roles: RolesState[];
    toastMessage: ToastMessageState;
    images: ImagesState;
    staff: StaffState[];
    clients: ClientsState[];
    projects: ProjectsState[];
    appStage: AppStageState;
    unreadBoardNews: UnreadBoardNewsState;
}

export default function configureStore(initialState?: AppState) {
    const rootReducer = combineReducers<AppState>({
        login,
        user,
        toastMessage,
        roles,
        images,
        staff,
        clients,
        projects,
        appStage,
        unreadBoardNews,
    });

    const sagaMiddleware = createSagaMiddleware();

    const enhancers = [];
    const windowIfDefined = typeof window === "undefined" ? null : (window as any);
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const result = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(sagaMiddleware), ...enhancers)
    );

    sagaMiddleware.run(rootSaga);

    return result;
}
