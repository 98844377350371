export interface IVehicle {
    guid: string;
    manufacturer: string;
    model: string;
    registrationPlate: number;
    vin: string;
    color: string;
    active: boolean;
    invoiceGuid: string;
}

export const vehicleInit: Partial<IVehicle> = {
    guid: "",
    manufacturer: "",
    model: "",
    registrationPlate: undefined,
    vin: "",
    color: "",
    active: false,
    invoiceGuid: "",
};
