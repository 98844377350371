import { useSelector } from "react-redux";
import { Table, Segment } from "semantic-ui-react";
import { UserData } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { SuperAdminUsersRow } from "./SuperAdminUsersRow";

interface IProps {
    title: string;
    users: UserData[];
    fetchUsers: () => void;
}

export const SuperAdminUsersTable = ({ title, users, fetchUsers }: IProps) => {
    return (
        <Segment.Group className="users-table-container">
            <Segment>
                <h2>{title}</h2>
            </Segment>
            <Segment className="table-container">
                <Table basic="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={4}>User name</Table.HeaderCell>
                            <Table.HeaderCell width={4}>User email</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Company</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {users.map(user => (
                            <SuperAdminUsersRow
                                key={user.guid}
                                user={user}
                                fetchUsers={fetchUsers}
                            />
                        ))}
                        <Table.Row />
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};
