import { useHistory } from "react-router-dom";
import { Label, Segment, Table } from "semantic-ui-react";
import { IDayRange, IHomepageUserInfo } from "../../models/homepageInfo";
import { getHomepageStatusColor, HomeInfoLegend } from "./HomeInfoLegend";
import { isMobile } from "react-device-detect";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import "./Home.css";

interface IProps {
    title: string;
    dateRange?: string[];
    users: IHomepageUserInfo[];
    dayRangeToDisplay: IDayRange;
    loggedInUser: UserData;
}

export const HomepageUsersTable = ({
    title,
    dateRange,
    users,
    dayRangeToDisplay,
    loggedInUser,
}: IProps) => {
    const history = useHistory();
    const startDate = dayRangeToDisplay.startDayIndex;
    const endDate = dayRangeToDisplay.endDayIndex + 1;
    const currentUser = useSelector((state: AppState) => state.user);

    return (
        <Segment.Group>
            <Segment>
                <h2>{title}</h2>
            </Segment>
            <Segment>
                <Table basic="very" unstackable={isMobile}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2} />
                            {dateRange?.slice(startDate, endDate).map(x => (
                                <Table.HeaderCell key={x} textAlign="center">
                                    {x}
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {users.map(user => (
                            <Table.Row key={user.userName}>
                                <Table.Cell
                                    width={2}
                                    onClick={() => {
                                        currentUser.role === UserRole.Admin ||
                                        currentUser.guid === user.userGuid
                                            ? history.push(`/administration/users/${user.userGuid}`)
                                            : history.push(`/contacts/${user.userGuid}`);
                                    }}
                                >
                                    {user.userName}{" "}
                                </Table.Cell>
                                {user.dates.slice(startDate, endDate).map(x => (
                                    <Table.Cell key={x.date} textAlign="center">
                                        <Label
                                            className={
                                                loggedInUser.role === UserRole.Admin
                                                    ? "user-status-label"
                                                    : undefined
                                            }
                                            circular
                                            size="large"
                                            color={getHomepageStatusColor(x.status)}
                                            onClick={() =>
                                                loggedInUser.role === UserRole.Admin &&
                                                history.push({
                                                    pathname: `/company/user-activity/${user.userGuid}`,
                                                    state: x.date,
                                                })
                                            }
                                        />
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Segment>
            {!isMobile ? (
                <Segment className="homepage-legend">
                    <HomeInfoLegend />
                </Segment>
            ) : null}
        </Segment.Group>
    );
};
