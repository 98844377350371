import { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Button, DropdownItemProps, Input, Radio, Segment, Select, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import agent from "../../api/agent";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import { IInvoiceDocument } from "../../models/document";
import { invoiceTypes } from "../../models/invoice";
import { uploadInvoiceDocuments } from "../../services/invoicesService";
import { AppState } from "../../store/configureStore";
import "./UploadInvoices.css";

export const UploadInvoices = () => {
    const user = useSelector((state: AppState) => state.user);
    const projects = useSelector((state: AppState) => state.projects);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [invoiceDocuments, setInvoiceDocuments] = useState<IInvoiceDocument[]>([]);
    const [bankStatements, setBankStatements] = useState<DropdownItemProps[]>([]);
    const [selectedInvoiceDocuments, setSelectedInvoiceDocuments] = useState<string[]>([]);
    const [selectedProject, setSelectedProject] = useState(-1);
    const [invoiceCategories, setInvoiceCategories] = useState<DropdownItemProps[]>([]);
    const [filesToUpload, setfilestoUpload] = useState<File[]>([]);

    const handleDelteInvoices = () => {
        setInvoiceDocuments([]);
        setfilestoUpload([]);
    }
    const handleSetBankStatement = (docGuid: string, bsGuid: string) => {
        const docIndex = invoiceDocuments.findIndex(x => x.guid === docGuid);
        let newDocs = [...invoiceDocuments];
        newDocs[docIndex].bankStatementGuid = bsGuid;
        setInvoiceDocuments(newDocs);
    };

    const handleSetInvoiceType = (docGuid: string, type: number) => {
        const docIndex = invoiceDocuments.findIndex(x => x.guid === docGuid);
        let newDocs = [...invoiceDocuments];
        newDocs[docIndex].invoiceType = type;
        setInvoiceDocuments(newDocs);
    };

    const handleSetInvoiceCategory = (docGuid: string, icGuid: string) => {
        const docIndex = invoiceDocuments.findIndex(x => x.guid === docGuid);
        let newDocs = [...invoiceDocuments];
        newDocs[docIndex].invoiceCategoryGuid = icGuid;
        setInvoiceDocuments(newDocs);
    };

    const handleSetSelectedInvoiceDocuments = (guid: string) => {
        if (selectedInvoiceDocuments.includes(guid))
            setSelectedInvoiceDocuments([...selectedInvoiceDocuments.filter(x => x !== guid)]);
        else setSelectedInvoiceDocuments([...selectedInvoiceDocuments].concat(guid));
    };

    const toggleSelectAll = () => {
        if (selectedInvoiceDocuments.length === invoiceDocuments.length)
            setSelectedInvoiceDocuments([]);
        else setSelectedInvoiceDocuments(invoiceDocuments.map(x => x.guid));
    };

    const ToggleButton = () => (
        <Button
            secondary
            content="Select all"
            icon={
                selectedInvoiceDocuments.length === invoiceDocuments.length
                    ? "toggle off"
                    : "toggle on"
            }
            size="small"
            onClick={toggleSelectAll}
        />
    );

    const handleUploadFiles = async (files: File[]) => {
        setLoading(true);
        try {
            setfilestoUpload([...filesToUpload, ...files]);
            const res = await uploadInvoiceDocuments(files);
            const newInvoiceDocuments: IInvoiceDocument[] = [...invoiceDocuments!, ...res.uploadedPDFs];
            setInvoiceDocuments(newInvoiceDocuments);
            const bankStatementProps: DropdownItemProps[] = [];
            res.bankStatements.forEach(x =>
                bankStatementProps.push({ key: x.guid, text: x.name, value: x.guid })
            );
            setBankStatements(bankStatementProps);
            const invoiceCategoryProps: DropdownItemProps[] = [];
            res.invoiceCategories.forEach(x =>
                invoiceCategoryProps.push({ key: x.guid, text: x.name, value: x.guid })
            );
            setInvoiceCategories(invoiceCategoryProps);
            const connectWithProjectProps: DropdownItemProps[] = [];
            projects.forEach(x => connectWithProjectProps.push({key: x.key, text: x.text,value:x.value}))
           
            toast("Uploaded and parsed invoice documents", true);
        } catch {
            toast("Failed to upload and parse invoice documents, please try again", false);
        } finally {
            setLoading(false);
        }
    };

    const handleCreateInvoiceDocuments = async () => {
        setLoading(true);
        try {
            const selected = invoiceDocuments.filter(x =>
                selectedInvoiceDocuments.includes(x.guid)
            );
            const resFiles = await agent.Invoices.create(selected, selectedProject);
            const formData = new FormData();
            formData.append("documents", JSON.stringify(resFiles));
            console.log("file to uplaod" + filesToUpload);
            filesToUpload.forEach(file => formData.append("files", file, file.name));
            console.log(formData);
            const res = await agent.Documents.UploadMultiDoc(formData);
            toast("Successfully created invoice documents", true);
            history.push("/invoice-processing/invoices");
        } catch {
            toast("Failed to create invoice documents, please try again", false);
        } finally {
            setLoading(false);
        }
    };

    const handleIbansChanged = (ibanIndex: number, invoiceIndex: number, value: string) => {
        let mutableInvoiceArray: IInvoiceDocument[] = [...invoiceDocuments];
        mutableInvoiceArray[invoiceIndex].ibaNs[ibanIndex] = value;

        setInvoiceDocuments(mutableInvoiceArray);
    };

    const addAdditionalIban = (invoiceIndex: number) => {
        let mutableInvoiceArray: IInvoiceDocument[] = [...invoiceDocuments];
        mutableInvoiceArray[invoiceIndex].ibaNs.push(""); // Add new empty iban field.

        setInvoiceDocuments(mutableInvoiceArray);
    };

    const removeIban = (invoiceIndex: number, ibanIndex: number) => {
        let mutableInvoiceArray: IInvoiceDocument[] = [...invoiceDocuments];
        mutableInvoiceArray[invoiceIndex].ibaNs.splice(ibanIndex, 1);

        setInvoiceDocuments(mutableInvoiceArray);
    };

    const handleAmountChange = (invoiceIndex: number, value: string) => {
        let mutableInvoiceArray: IInvoiceDocument[] = [...invoiceDocuments];
        mutableInvoiceArray[invoiceIndex].amount = value;

        setInvoiceDocuments(mutableInvoiceArray);
    };

    const handleSetBoolean = (invoiceIndex: number) => {
        let mutableInvoiceArray: IInvoiceDocument[] = [...invoiceDocuments];
        mutableInvoiceArray[invoiceIndex].researchAndDevelopment =
            !mutableInvoiceArray[invoiceIndex].researchAndDevelopment;

        setInvoiceDocuments(mutableInvoiceArray);
    };

    return user.role !== UserRole.Admin ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="upload-invoices-container">
            <FileDropzone
                title="Upload invoices"
                loading={loading}
                accept="pdf"
                handleUploadFiles={handleUploadFiles}
            />
            {invoiceDocuments.length > 0 && (
                <div className="upload-invoices-display">
                    <Segment.Group>
                        <Segment>
                            <ToggleButton />
                            <div>
                                <Button
                                    secondary
                                    size="small"
                                    content="Delete invoices"
                                    onClick={() => handleDelteInvoices()}
                                />
                                    <Select
                                        clearable
                                    placeholder="Connect with project..."
                                    selectOnBlur={false}
                                    value={selectedProject}
                                    options={projects}
                                    onChange={(e, data: any) => setSelectedProject(data.value)}
                                />
                            </div>
                        </Segment>
                        <Segment>
                            <Table basic="very">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Select</Table.HeaderCell>
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.HeaderCell>Amount</Table.HeaderCell>
                                        <Table.HeaderCell>IBAN(s)</Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Bank statement to connect with
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>Invoice type</Table.HeaderCell>
                                        <Table.HeaderCell>Category</Table.HeaderCell>
                                        <Table.HeaderCell>R&D</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {invoiceDocuments.map((x, index) => (
                                        <Table.Row key={x.guid}>
                                            <Table.Cell>
                                                <Radio
                                                    slider
                                                    checked={selectedInvoiceDocuments.includes(
                                                        x.guid
                                                    )}
                                                    onClick={() =>
                                                        handleSetSelectedInvoiceDocuments(x.guid)
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell>{x.name}</Table.Cell>
                                            <Table.Cell>
                                                <Input
                                                    id="amount-edit"
                                                    value={x.amount}
                                                    onChange={e =>
                                                        handleAmountChange(index, e.target.value)
                                                    }
                                                />{" "}
                                                {x.currency}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div id="iban-edit">
                                                    {x.ibaNs.map((iban, ibanIndex) => {
                                                        return (
                                                            <div className="iban-row">
                                                                <Input
                                                                    key={"iban" + ibanIndex}
                                                                    name={`${index}`}
                                                                    value={iban}
                                                                    onChange={e =>
                                                                        handleIbansChanged(
                                                                            ibanIndex,
                                                                            index,
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                />
                                                                <Button
                                                                    color="red"
                                                                    icon="delete"
                                                                    size="small"
                                                                    onClick={() =>
                                                                        removeIban(index, ibanIndex)
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    })}

                                                    <Button
                                                        fluid
                                                        secondary
                                                        icon="plus"
                                                        size="small"
                                                        content="Add additional IBAN"
                                                        onClick={() => addAdditionalIban(index)}
                                                    />
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Select
                                                    clearable 
                                                    id="selectBankStatement"
                                                    placeholder="New bank statement"
                                                    selectOnBlur={false}
                                                    value={x.bankStatementGuid}
                                                    options={bankStatements}
                                                    disabled={x.invoiceType > 1}
                                                    onChange={(e, data: any) =>
                                                        handleSetBankStatement(x.guid, data.value)
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Select
                                                    clearable
                                                    id="selectType"
                                                    placeholder="Select type..."
                                                    selectOnBlur={false}
                                                    value={x.invoiceType}
                                                    options={invoiceTypes}
                                                    onChange={(e, data: any) => {
                                                        handleSetInvoiceType(x.guid, data.value);
                                                        console.log("etst")
                                                        }
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Select
                                                    clearable
                                                    id="selectCategory"
                                                    placeholder="Select category..."
                                                    selectOnBlur={false}
                                                    value={x.invoiceCategoryGuid}
                                                    options={invoiceCategories}
                                                    onChange={(e, data: any) =>
                                                        handleSetInvoiceCategory(x.guid, data.value)
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Radio
                                                    id="researchAndDevelopment"
                                                    slider
                                                    checked={x.researchAndDevelopment}
                                                    onClick={() => handleSetBoolean(index)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </Segment>
                        <Segment>
                            <ToggleButton />
                        </Segment>
                    </Segment.Group>
                    <Button
                        secondary
                        fluid
                        content="Upload selected invoice documents"
                        icon="upload"
                        onClick={handleCreateInvoiceDocuments}
                        disabled={loading || selectedInvoiceDocuments.length === 0}
                    />
                </div>
            )}
        </div>
    );
};
