export interface IProjectRole {
    guid: string;
    name: string;
    description: string;
}

export interface IProjectProjectRole {
    guid: string;
    projectGuid: string;
    roleGuid: string;
    roleName: string;
    roleDescription: string;
    assignees: string[];
}

// UserProjectProjectRoleDto on backend
export interface IProjectRoleAssignee {
    guid: string;
    roleGuid: string;
    roleName: string;
    roleDescription: string;
    projectGuid: string;
    projectName: string;
    userGuid: string;
}

export const projectRoleInit: Partial<IProjectRole> = {
    name: "",
    description: "",
};

export const projectProjectRoleInit: IProjectProjectRole = {
    guid: "",
    projectGuid: "",
    roleGuid: "",
    roleName: "",
    roleDescription: "",
    assignees: [],
};
