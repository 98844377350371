import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Label, Modal, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { deleteIdeaBox } from "../../api/ideaBoxes";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { IIdeaBox } from "../../models/ideaBox";
import { AppState } from "../../store/configureStore";
import { getStatusColor, getStatusLabel } from "../../models/common";
import "./IdeaBox.css";

interface IProps {
    ideaBox: IIdeaBox;
    page: string;
    handleRemoveIdea: (i: IIdeaBox) => void;
}

export const IdeaBoxRow = ({ ideaBox, page, handleRemoveIdea }: IProps) => {
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);

    const handleDeleteIdea = async () => {
        try {
            await deleteIdeaBox(ideaBox.guid);
            handleRemoveIdea(ideaBox);
            toast("Successfully deleted idea", true);
        } catch {
            toast("Failed to delete idea", false);
        }
    };

    const onRowClick = () => {
        history.push({
            pathname: `/requests/ideas/${ideaBox.guid}`,
            state: page,
        });
    };

    return (
        <Fragment>
            <Table.Row onClick={onRowClick} className="clickable-row">
                <Table.Cell>{ideaBox.title}</Table.Cell>
                <Table.Cell>{ideaBox.user}</Table.Cell>
                <Table.Cell>{getDisplayDateFormat(ideaBox.created, false)}</Table.Cell>
                <Table.Cell>
                    <Label
                        className="idea-box-label"
                        size="large"
                        circular
                        color={getStatusColor(ideaBox.status)}
                        content={getStatusLabel(ideaBox.status)}
                    />
                </Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {(user.role === UserRole.Admin || user.guid === ideaBox.userGuid) && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this idea?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" content="Confirm" onClick={handleDeleteIdea} />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
