import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppState } from "../../store/configureStore";
import { Icon, Loader, Pagination, Segment, Table, Button } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import "./CompanyServiceTypes.css";
import { ICompanyServiceType } from "../../models/companyServiceType";
import { getCompanyServiceTypes } from "../../api/companyServicesType";
import { AddCompanyServiceTypeForm } from "./AddCompanyServiceTypeForm";
import { CompanyServiceTypeRow } from "./CompanyServiceTypeRow";

export const CompanyServiceTypes = () => {
    const user = useSelector((state: AppState) => state.user);
    const [loading, setLoading] = useState(true);
    const loc = useLocation();
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [totalRows, setTotalRows] = useState(0);
    const [companyServiceTypes, setCompanyServiceTypes] = useState<ICompanyServiceType[]>([]);
    const [modalOpen, setModalOpen] = useState(false);

    const fetchCompanyServiceTypes = useCallback(async () => {
        setLoading(true);
        const res = await getCompanyServiceTypes(page);
        if (res.rows) {
            setCompanyServiceTypes(res.rows as ICompanyServiceType[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page]);

    useEffect(() => {
        fetchCompanyServiceTypes();
    }, [fetchCompanyServiceTypes]);

    const handleRemoveCompanyServiceType = (d: ICompanyServiceType) =>
        setCompanyServiceTypes([...companyServiceTypes].filter(x => x !== d));

    const handleFetchCompanyServiceTypes = () => {
        setPage(1);
        fetchCompanyServiceTypes();
    };

    return (
        <div className="cCompanyompany-service-types-container">
            <div className="container-header">
                <h1>Service types</h1>
                {user.role === UserRole.Admin && (
                    <Button
                        secondary
                        content="Add new company service type"
                        icon="plus"
                        onClick={() => setModalOpen(true)}
                    />
                )}
            </div>
            <div className="company-service-type-display">
                <div className="company-service-type-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment />
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Number</Table.HeaderCell>
                                            <Table.HeaderCell>Service type</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {companyServiceTypes.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="5">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            companyServiceTypes.map(x => (
                                                <CompanyServiceTypeRow
                                                    key={x.guid}
                                                    companyServiceType={x}
                                                    handleRemoveCompanyServiceType={
                                                        handleRemoveCompanyServiceType
                                                    }
                                                    page={page + ""}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                            <AddCompanyServiceTypeForm
                                open={modalOpen}
                                closeModal={() => setModalOpen(false)}
                                handleAddCompanyServiceType={handleFetchCompanyServiceTypes}
                            />
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
