import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Icon, Input, Form, Label } from "semantic-ui-react";
import { loginRequest } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { RedirectIfLoggedIn } from "./RedirectIfLoggedIn";
import "./Login.css";
import { ReactComponent as LogoIcon } from "../../icons/Aconto_logo_small.svg";
import { ReactComponent as Logo } from "../../icons/Aconto_logo_log_in.svg";
import { useHistory, useLocation } from "react-router-dom";
import { SignUpForm } from "./SignUpForm";

export const Login = () => {
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const loginState = useSelector((state: AppState) => state.login);
    const [error, setError] = useState("");
    const location: any = useLocation();
    const history = useHistory();
    const [modalOpen, setModalOpen] = useState(false);

    const handleLogin = useCallback(
        (username, password) => {
            if (username === "" || password === "")
                setError("Username and password cannot be empty");
            else dispatch(loginRequest({ username, password }));
        },
        [dispatch]
    );

    const handleKeyPress = useCallback(
        (e: any, password, username) => {
            if (e.keyCode === 13) {
                e.preventDefault();
                handleLogin(password, username);
            }
        },
        [handleLogin]
    );

    useEffect(() => {
        if (!loginState.currentlySending && !loginState.loggedIn)
            setError("Incorrect username or password");
    }, [loginState.currentlySending, loginState.loggedIn]);

    useEffect(() => {
        setError("");
    }, [username, password]);

    return (
        <div className="login-container">
            <RedirectIfLoggedIn
                path={location.state !== undefined ? location.state.from.pathname : "/login"}
            />
            <div className="center-box">
                <div className="login-form-container">
                    <LogoIcon title="" id="logo-icon" />
                    <Logo title="" id="logo" />
                    <Form>
                        <Input
                            id="email"
                            placeholder="Email"
                            size="large"
                            value={username}
                            onChange={(e: any) => setUsername(e.target.value)}
                        />
                        <Input
                            id="password"
                            type="password"
                            size="large"
                            placeholder="Password"
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value)}
                        />
                        <div className="login-options">
                            <label id="cursor" onClick={() => history.push("/forgot-password")}>
                                <Icon name="lock" />
                                Forgot password?
                            </label>
                        </div>
                        <p id="login-error-message">
                            {loginState.error ? loginState.error : error.length > 0 ? error : " "}
                        </p>
                        <Button
                            fluid
                            size="large"
                            color="yellow"
                            type="submit"
                            onKeyDown={(e: any) => handleKeyPress(e, password, username)}
                            loading={loginState.currentlySending}
                            disabled={loginState.currentlySending}
                            onClick={() => handleLogin(username, password)}
                            content="Log in"
                        />
                        <label id="sign-up-label">Don't have an account yet?</label>
                        <Button
                            fluid
                            size="large"
                            color="purple"
                            type="submit"
                            onClick={() => setModalOpen(true)}
                            content="Sign up"
                        />
                    </Form>
                </div>
                <div id="divider"></div>
                <div className="aconto-logo-container">
                    <Logo title="" />
                    <h1>Business administration made easy</h1>
                </div>
                <SignUpForm open={modalOpen} closeModal={() => setModalOpen(false)} />
            </div>
        </div>
    );
};
