import { DropdownItemProps } from "semantic-ui-react";
import {  EmployeeDto, IHomepageInfo } from "../models/homepageInfo";
import { AppStageState } from "../store/appStageReducer";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getHomepageInfo(): Promise<IHomepageInfo> {
    return await fetch("/api/home/info" , {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}
export async function getWorkHomepageInfo(employeeList: EmployeeDto): Promise<EmployeeDto> {
    return await fetch("/api/home/gettable", {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(employeeList)
    }).then((res) => {
        return res.json();
    });
}

export async function getRoles(): Promise<DropdownItemProps[]> {
    return await fetch("/users/roles", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function getAppStage(): Promise<AppStageState> {
    return await fetch("/api/home/stage", {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}
