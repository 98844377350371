import axios from "axios";
import { IPaginationWrapper } from "../models/common";
import { DocumentType, IDocument, IUploadDocument } from "../models/document";

export const getDocuments = async (
    page: number,
    documentType: DocumentType,
    entityGuid?: string,
    userGuid?: string,
    query?: string
) => {
    const params = new URLSearchParams({ page: page + "", documentType: documentType + "" });
    entityGuid && params.append("entityGuid", entityGuid);
    userGuid && params.append("userGuid", userGuid);
    query && params.append("query", query);
    const response = await axios.get("/api/documents?" + params.toString());
    return response.data as IPaginationWrapper;
};

export const getDocument = async (guid: string) => {
    const response = await axios.get(`/api/documents/${guid}`);
    return response.data as IDocument;
};

export const createDocument = async (doc: IUploadDocument, file: File) => {
    const formData = new FormData();
    formData.append("document", JSON.stringify(doc));
    formData.append("file", file, file.name);
    const response = await axios.post("/api/documents", formData);
    return response.data as IDocument;
};

export const uploadExistingDocument = async (docGuid: string, doc: IUploadDocument, file: File) => {
    const formData = new FormData();
    formData.append("document", JSON.stringify(doc));
    formData.append("file", file, file.name);
    const response = await axios.post(`/api/documents/existingUpload/${docGuid}`, formData);
    return response.data as IDocument;
};

export const createDocumentWithoutUpload = async (doc: IDocument) => {
    const formData = new FormData();
    formData.append("document", JSON.stringify(doc));
    const response = await axios.post("/api/documents/noUpload", formData);
    return response.data as IDocument;
};

export const editDocument = async (doc: IDocument) => {
    const response = await axios.put("/api/documents/" + doc.guid, doc);
    return response;
};

export const downloadDocument = async (guid: string) => {
    const url = `/api/documents/${guid}/download`;
    const a = document.createElement("a");
    a.href = url;
    a.type = "document";
    a.click();
};

export const cleanUnuploadedDocuments = async () => {
    const response = await axios.delete("/api/documents/clean");
    return response;
};

export const deleteDocument = async (guid: string) => {
    const response = await axios.delete("/api/documents/" + guid);
    return response;
};

export const verifyDocument = async (guid: string) => {
    const response = await axios.get(`/api/documents/verify/${guid}`);
    return response.data as boolean;
};
