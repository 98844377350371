import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { clientInit } from "../../models/client";
import { createClient } from "../../services/clientsService";
import { fetchClients } from "../../store/clientsReducer";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddClient: () => void;
}

export const AddClientForm = ({ open, closeModal, handleAddClient }: IProps) => {
    const dispatch = useDispatch();
    const [newClient, setNewClient] = useState(clientInit);
    const disabled = newClient.companyName === "";

    const handleInputChange = (e: any) =>
        setNewClient({ ...newClient, [e.target.name]: e.target.value });

    const handleAddNewClient = async () => {
        const res = await createClient(newClient);
        if (!res.ok) toast("Error creating client", false, 1500);
        else {
            handleAddClient();
            fetchClients(dispatch);
            toast("Client successfully added", true, 1500);
        }
        closeModal();
    };

    return (
        <Modal
            className="add-client-modal"
            open={open}
            onClose={closeModal}
            dimmer="blurring"
            centered={false}
        >
            <Modal.Header>
                <h2>Add new client</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-client-form">
                    <div className="input-container">
                        <label>Company name</label>
                        <Input
                            name="companyName"
                            value={newClient.companyName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>Address</label>
                        <Input
                            name="address"
                            value={newClient.address}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>Contact person first name</label>
                        <Input
                            name="contactFirstName"
                            value={newClient.contactFirstName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>Contact person last name</label>
                        <Input
                            name="contactLastName"
                            value={newClient.contactLastName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>Email</label>
                        <Input name="email" value={newClient.email} onChange={handleInputChange} />
                    </div>
                    <div className="input-container">
                        <label>Phone number</label>
                        <Input
                            name="phoneNumber"
                            value={newClient.phoneNumber}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>VAT number</label>
                        <Input
                            name="vaTnumber"
                            value={newClient.vaTnumber}
                            onChange={handleInputChange}
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new client"
                    disabled={disabled}
                    secondary
                    fluid
                    onClick={handleAddNewClient}
                />
            </Modal.Actions>
        </Modal>
    );
};
