import { DropdownItemProps } from "semantic-ui-react";
import { getISODateFormat } from "../components/CalendarSelector/getAllDatesHelper";

export interface IProjectNote {
    guid: string;
    title: string;
    date: string;
    from: string;
    to: string;
    content: string;
    minutesOfTheMeeting: string;
    location: string;
    projectGuid: string;
    project: string;
    attendees: string[];
    clientAttendees: string[];
    authorName: string;
}

export const projectNoteInit: Partial<IProjectNote> = {
    title: "",
    date: getISODateFormat(new Date()),
    from: "",
    to: "",
    content: "",
    minutesOfTheMeeting: "",
    location: "",
    projectGuid: "",
    attendees: [],
    clientAttendees: [],
    authorName: "",
};

export interface IAttendeesWrapper {
    attendees: DropdownItemProps[];
    clientAttendees: DropdownItemProps[];
}
