import React from "react";
import { Segment, Table } from "semantic-ui-react";
import { IEmployeeMonthlyReportItem } from "../../models/staffMonthlySummary";

interface IProps {
    title: string;
    rows: IEmployeeMonthlyReportItem[];
}

export const EmployeeMonthlyReportTable: React.FC<IProps> = ({ title, rows }) => {
    return (
        <Segment.Group>
            <Segment>
                <h2>{title}</h2>
            </Segment>
            <Segment>
                <Table basic="very" fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>User</Table.HeaderCell>
                            <Table.HeaderCell>Total days</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {rows.length === 0 ? (
                            <Table.Row>
                                <Table.Cell textAlign="center" colSpan="2">
                                    No records available for selected parameters
                                </Table.Cell>
                            </Table.Row>
                        ) : (
                            rows.map((x) => (
                                <Table.Row key={x.user}>
                                    <Table.Cell>{x.user}</Table.Cell>
                                    <Table.Cell>{x.totalDays}</Table.Cell>
                                </Table.Row>
                            ))
                        )}
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};
