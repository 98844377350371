import { useState } from "react";
import { Button, Checkbox, Icon, Input, Modal, TextArea } from "semantic-ui-react";
import { toast } from "../..";
import { ideaInit } from "../../models/ideaBox";
import { createIdeaBox } from "../../api/ideaBoxes";
import { UserRole } from "../../actions/authentificationActions";
import { useSelector } from "react-redux";
import { AppState } from "../../store/configureStore";
import './IdeaBox.css';
import { PopupIcons } from "../../components/Popup/PopupIcons";
import { Popup } from "../../components/Popup/Popup";

interface IProps {
    open: boolean;
    closeModal: () => void;
    handleAddIdea: () => void;
    anonymousAllowed: boolean;
}

export const AddIdeaForm = ({ open, closeModal, handleAddIdea, anonymousAllowed }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [newIdea, setNewIdea] = useState(ideaInit);
    const [loading, setLoading] = useState(false);

    const handleAddNewIdea = async () => {
        setLoading(true);
        try {
            await createIdeaBox(newIdea);
            toast("Idea successfully added", true, 1500);
            handleAddIdea();
            closeModal();
        } catch {
            toast("Error adding idea", false, 1500);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={closeModal} dimmer="blurring" centered={false}>
            <Modal.Header>
                <h2>Add new idea</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form>
                    <div className="input-container">
                        <div className="add-idea-form-title-container">
                            <label>Title</label>
                            {anonymousAllowed && (
                                <div
                                    id="anonymous-checkbox"
                                    className="add-idea-form-anonymous-select"
                                >
                                    <Popup
                                        popupIcon={PopupIcons.InfoCircle}
                                        popupText="Enable this if you want your idea to remain anonymous."
                                    />
                                    <label>Anonymous</label>
                                    <Checkbox
                                        toggle
                                        checked={newIdea.isAnonymous}
                                        onClick={(e: any) =>
                                            setNewIdea({
                                                ...newIdea,
                                                isAnonymous: !newIdea.isAnonymous,
                                            })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <Input
                            type="title"
                            value={newIdea.title}
                            onChange={(e: any) => setNewIdea({ ...newIdea, title: e.target.value })}
                        />
                    </div>
                    <div className="input-container">
                        <label>Description</label>
                        <TextArea
                            type="description"
                            value={newIdea.description}
                            onChange={(e: any) =>
                                setNewIdea({ ...newIdea, description: e.target.value })
                            }
                        />
                    </div>
                    {user.role === UserRole.Admin && (
                        <div className="input-container">
                            <label>Comment</label>
                            <TextArea
                                type="comment"
                                value={newIdea.comment}
                                onChange={(e: any) =>
                                    setNewIdea({ ...newIdea, comment: e.target.value })
                                }
                            />
                        </div>
                    )}
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add new idea"
                    loading={loading}
                    disabled={newIdea.title === "" || newIdea.description === ""}
                    secondary
                    fluid
                    onClick={handleAddNewIdea}
                />
            </Modal.Actions>
        </Modal>
    );
};
