export interface IService {
    guid: string;
    code: string;
    serviceDescription: string;
    unitMeasure: string;
    netPrice: number;
    discount: number;
    taxRate: number;
    quantity: number;
    date: string;
}

export const serviceInit: IService = {
    guid: "",
    code: "",
    serviceDescription: "",
    unitMeasure: "",
    netPrice: 0,
    discount: 0,
    taxRate: 0,
    quantity: 0,
    date: "",
};
