import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Segment, Input, Button, Loader } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole, UserVacations } from "../../actions/authentificationActions";
import { getUserVacation, updateUserVacations } from "../../api/users";
import { AppState } from "../../store/configureStore";

interface IProps {
    title: string;
    startYear: number;
    userDetails: UserData;
    history: any;
    params: any;
}

export const VacationsTable: React.FC<IProps> = ({
    title,
    startYear,
    userDetails,
    history,
    params,
}) => {
    const user = useSelector((state: AppState) => state.user);
    const disabled = userDetails?.firstName === "" || userDetails?.lastName === "";
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [vacations, setVacations] = useState<UserVacations[]>([]);
    const [newVacations, setNewVacations] = useState<UserVacations[]>([]);
    const [daysLeft, setDaysLeft] = useState(0);

    const fetchVacations = useCallback(async () => {
        setLoading(true);
        const resVacations = await getUserVacation(params.guid);
        setVacations(resVacations.vacations);
        setDaysLeft(resVacations.daysLeft);
        setLoading(false);
    }, [params.guid]);

    const getDaysLeft = (year: number) => {
        let daysLeft = 0;
        startYear = startYear < 2015 ? 2015 : startYear;
        if (year < 0) year = new Date().getFullYear();
        getYearsArray(startYear, year).forEach(year => {
            var vacation = vacations.find(v => v.year === year);
            var contract = vacation === undefined ? 30 : vacation.contractDays;
            var correction = vacation === undefined ? 30 : vacation.correctionDays;
            if (vacation !== undefined) {
                daysLeft -= vacation.daysUsed;
                if (year <= new Date().getFullYear()) daysLeft += contract + correction;
            } else {
                if (year <= new Date().getFullYear()) daysLeft += contract;
            }
        });
        return daysLeft;
    };

    const getOldDays = (year: number) => {
        return getDaysLeft(year - 1);
    };

    // start year = 2017
    // current year = 2021
    // returns [2021, 2020, 2019, 2018, 2017]
    const getYearsArray = (start: number, end: number) => {
        if (end < start) return [];
        var year = end;
        var yearsArray = [];
        while (year >= start) {
            yearsArray.push(year);
            year--;
        }
        return yearsArray;
    };

    const handleVacationInputChange = (e: any) => {
        const name = e.target.name;
        const inputYear = Number(name.slice(-4));
        const type = name.slice(0, -4);
        const value = Number(e.target.value);
        var newVacation = newVacations.find(v => v.year === inputYear);
        if (newVacation === undefined) {
            // add new vacation details
            newVacation = {
                year: inputYear,
                contractDays: type === "contract" ? value : 30,
                correctionDays: type === "correction" ? value : 0,
                daysUsed: 0,
            };
        } else {
            if (type === "contract") {
                // edit contract days on existing vacation details
                newVacation = {
                    year: inputYear,
                    contractDays: value,
                    correctionDays: newVacation.correctionDays,
                    daysUsed: newVacation.daysUsed,
                };
            }
            if (type === "correction") {
                // edit correction days on existing vacation details
                newVacation = {
                    year: inputYear,
                    contractDays: newVacation.contractDays,
                    correctionDays: value,
                    daysUsed: newVacation.daysUsed,
                };
            }
        }
        var updated = newVacations.filter(v => v.year !== inputYear);
        updated = [...updated, newVacation];
        setNewVacations(updated);
    };

    const handleUpdateUserVacations = async () => {
        setSaving(true);
        try {
            await updateUserVacations(userDetails.guid!, newVacations);
            toast("Successfully saved changes", true);
            if (
                (user.role === UserRole.Admin && user.guid === params.guid) ||
                user.guid === params.guid
            ) {
                history.push("/administration/users/" + params.guid);
            } else if (user.role === UserRole.Admin) {
                history.push("/administration/users");
            }
        } catch {
            toast("Failed to save changes, please try again", false);
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        setNewVacations(vacations.filter(v => v.year >= startYear));
    }, [vacations, startYear]);

    useEffect(() => {
        fetchVacations();
    }, [fetchVacations]);

    return loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <Segment.Group className="vacation-table-container">
            <Segment>
                <h2>{title}</h2>
                {user.role === UserRole.Admin && (
                    <Button
                        secondary
                        content="Save changes"
                        icon="save"
                        onClick={handleUpdateUserVacations}
                        disabled={saving || disabled}
                        loading={saving}
                    />
                )}
            </Segment>
            <Segment>
                <h4>Vacation days left: {daysLeft}</h4>
            </Segment>
            <Segment>
                <Table basic="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Year</Table.HeaderCell>
                            <Table.HeaderCell>Contract</Table.HeaderCell>
                            <Table.HeaderCell>Old</Table.HeaderCell>
                            <Table.HeaderCell>Correction</Table.HeaderCell>
                            <Table.HeaderCell>Days used</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {getYearsArray(startYear, new Date().getFullYear()).map(year => (
                            <Table.Row key={year}>
                                <Table.Cell>{year}</Table.Cell>
                                <Table.Cell>
                                    <Input
                                        type="number"
                                        name={"contract" + year}
                                        defaultValue={
                                            vacations.find(v => v.year === year) === undefined
                                                ? 30
                                                : vacations.find(v => v.year === year)?.contractDays
                                        }
                                        onChange={handleVacationInputChange}
                                        disabled={user.role !== UserRole.Admin}
                                    />
                                </Table.Cell>
                                <Table.Cell>{year !== startYear ? getOldDays(year) : 0}</Table.Cell>
                                <Table.Cell>
                                    <Input
                                        type="number"
                                        name={"correction" + year}
                                        defaultValue={
                                            vacations.find(v => v.year === year) === undefined
                                                ? 0
                                                : vacations.find(v => v.year === year)
                                                      ?.correctionDays
                                        }
                                        onChange={handleVacationInputChange}
                                        disabled={user.role !== UserRole.Admin}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    {vacations.find(v => v.year === year) === undefined
                                        ? 0
                                        : vacations.find(v => v.year === year)?.daysUsed}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};
