import { IPaginationWrapper } from "../models/common";
import { IAttendeesWrapper, IProjectNote } from "../models/projectNote";
import { getAuthorizationProperties } from "./authorizationHelper";

export async function getProjectNotes(
    page: number,
    projectGuid: string,
    date: string
): Promise<IPaginationWrapper> {
    const params = new URLSearchParams({ page: page + "" });
    projectGuid !== "" && params.append("projectGuid", projectGuid);
    date !== "" && params.append("date", date);
    return await fetch("/api/projectnotes?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function getProjectNote(guid: string): Promise<IProjectNote> {
    return await fetch("/api/projectnotes/" + guid, {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}

export async function deleteProjectNote(guid: string): Promise<Response> {
    return await fetch("/api/projectnotes/" + guid, {
        method: "DELETE",
        ...getAuthorizationProperties(),
    });
}

export async function editProjectNote(projectNote: IProjectNote): Promise<Response> {
    return await fetch("/api/projectnotes/" + projectNote.guid, {
        method: "PUT",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(projectNote),
    });
}

export async function createProjectNote(projectNoteDto: Partial<IProjectNote>): Promise<Response> {
    return await fetch("/api/projectnotes/", {
        method: "POST",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
        body: JSON.stringify(projectNoteDto),
    });
}

export async function getProjectNoteAttendeesAndClientAttendees(
    projectGuid: string
): Promise<IAttendeesWrapper> {
    const params = new URLSearchParams({ projectGuid: projectGuid });
    return await fetch("/api/projectnotes/attendees?" + params.toString(), {
        method: "GET",
        ...getAuthorizationProperties({ "Content-Type": "application/json" }),
    }).then((res) => {
        return res.json();
    });
}
