import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Modal, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { deleteUser } from "../../api/users";
import { jobTitleTypes } from "../../models/appUser";
import { AppState } from "../../store/configureStore";
import { getRoleName } from "../../store/rolesReducer";

interface IProps {
    user: UserData;
    handleRemoveContact: (contact: UserData) => void;
}

export const ContactsRow = ({ user, handleRemoveContact }: IProps) => {
    const history = useHistory();
    const currentUser = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);

    const onRowClick = () => {
        history.push(`/contacts/${user.guid}`);
    };

    const handleDeleteContact = async () => {
        const res = await deleteUser(user.guid);
        if (!res) toast("Failed to delete contact", false);
        else {
            handleRemoveContact(user);
            toast("Successfully deleted contact", true);
        }
        setModalOpen(false);
    };

    return (
        <Fragment>
            <Table.Row onClick={onRowClick} className="clickable-row">
                <Table.Cell>
                    {user.firstName} {user.lastName}
                </Table.Cell>
                <Table.Cell>{user.username}</Table.Cell>
                <Table.Cell className="contact-role">{getRoleName(user.role)}</Table.Cell>
                <Table.Cell>{jobTitleTypes.find(x => x.value === user.jobTitle)?.text}</Table.Cell>
                {currentUser.role === UserRole.Admin && (
                    <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                        <Button
                            color="red"
                            icon="delete"
                            content="Delete"
                            onClick={() => setModalOpen(true)}
                        />
                    </Table.Cell>
                )}
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this contact?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteContact} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
