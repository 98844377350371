import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import { toast } from "../..";
import { CalendarSelector } from "../../components/CalendarSelector/CalendarSelector";
import { createWorkingPlan, getUserPreviousDates } from "../../api/workingPlans";
import { AppState } from "../../store/configureStore";
import {
    getPreselectedDates,
    getSelectedDates,
} from "../../components/CalendarSelector/getAllDatesHelper";
import { getPlanType } from "./WorkingPlan";

export const CreateWorkingPlan = () => {
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const loc = useLocation();
    const type = loc.pathname.split("/")[1]!;
    const [dates, setDates] = useState<string[]>([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [loading, setLoading] = useState(false);
    const [previousSameType, setPreviousSameType] = useState<string[]>([]);
    const [holidays, setHolidays] = useState<string[]>([]);
    const [firstPickDate, setFirstPickDate] = useState<string>("");
    const [preselectionDates, setPreselectionDates] = useState<string[]>([]);

    const fetchPreviousWorkingPlans = useCallback(async () => {
        setLoading(true);
        const res = await getUserPreviousDates(user.guid!);
        if (res.previousSameType) {
            setPreviousSameType(res.previousSameType);
            setHolidays(res.holidays);
        }
        setLoading(false);
    }, [user.guid]);

    const handleDates = (d: string) => {
        const { pickedDates } = getSelectedDates(
            firstPickDate,
            d,
            holidays,
            previousSameType,
            [],
            setFirstPickDate
        );
        setDates(pickedDates);
    };

    const handlePreselectionDates = (d: string) => {
        const preselectedDates = getPreselectedDates(firstPickDate, d, holidays);
        setPreselectionDates(preselectedDates);
    };

    const handleCreateWorkingPlan = async () => {
        setLoading(true);
        try {
            const res = await createWorkingPlan(dates, getPlanType(type));
            const guid = await res.data;
            history.push(`/${type}/${guid}`, { fromCreate: true });
        } catch {
            toast("Error while creating working plan", false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPreviousWorkingPlans();
    }, [fetchPreviousWorkingPlans]);

    return (
        <div>
            <div className="create-plan-header">
                <Icon size="big" name="angle left" onClick={() => setYear(year - 1)} />
                <h1>{year}</h1>
                <Icon size="big" name="angle right" onClick={() => setYear(year + 1)} />
            </div>
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <Fragment>
                    <CalendarSelector
                        year={year}
                        selectedDates={dates}
                        handleDates={handleDates}
                        previousSameType={previousSameType}
                        previousOtherTypes={[]}
                        holidays={holidays}
                        enableEdit={true}
                        preselectionDates={preselectionDates}
                        handlePreselectionDates={handlePreselectionDates}
                    />
                    <Button
                        fluid
                        secondary
                        content="Create working plan"
                        icon="calendar plus"
                        onClick={handleCreateWorkingPlan}
                        loading={loading}
                        disabled={dates.length === 0 || loading}
                    />
                </Fragment>
            )}
        </div>
    );
};
