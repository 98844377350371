import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, DropdownItemProps, Loader, Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { UserRole } from "../../actions/authentificationActions";
import { updateVendorContact } from "../../api/vendorsContacts";
import { DropdownInput } from "../../components/DropdownInput/DropdownInput";
import { TextInput } from "../../components/TextInput/TextInput";
import { IVendorContact } from "../../models/vendorContact";
import { AppState } from "../../store/configureStore";

interface IProps {
    vendorContact: IVendorContact;
    setVendorContact: Dispatch<SetStateAction<IVendorContact | undefined>>;
    vendors: DropdownItemProps[];
}

export const EditVendorContact = ({ vendorContact, setVendorContact, vendors }: IProps) => {
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [saving, setSaving] = useState(false);
    //const [vendors, setVendors] = useState<DropdownItemProps[]>([]);
    const unauthorized = user.role !== UserRole.Admin;
    const [loading, setLoading] = useState(false);
    const handleInputChange = (e: any) =>
        setVendorContact({ ...vendorContact!, [e.target.name]: e.target.value });

    const handleUpdateVendorContact = async () => {
        setSaving(true);
        try {
            await updateVendorContact(vendorContact);
            toast("Successfully saved changes", true);
            history.push("/company/vendor-contacts");
        } catch {
            toast("Failed to save changes, please try again", false);
        }
        setSaving(false);
    };
    //const fetchVendors = useCallback(async () => {
    //    setLoading(true);
    //    const vendors = await getVendorsAsProps();
    //    Array.isArray(vendors) && setVendors(vendors);
    //    setLoading(false);
    //}, []);

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateVendorContact}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    //useEffect(() => {
    //    fetchVendors();
    //    return () => {};
    //}, [fetchVendors]);

    return (
        <div>
            {loading ? (
                <Loader active inline="centered" size="huge" />
            ) : (
                <Segment.Group>
                    <Segment className="edit-header">
                        <h2>Edit vendor contact</h2>
                        <SaveButton />
                    </Segment>
                    <Segment>
                        <Table basic="very" celled compact>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={3}>First name</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="firstName"
                                            value={vendorContact.firstName}
                                            handleInputChange={handleInputChange}
                                            disabled={unauthorized}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Last name</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="lastName"
                                            value={vendorContact.lastName}
                                            handleInputChange={handleInputChange}
                                            disabled={unauthorized}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Email</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="email"
                                            value={vendorContact.email}
                                            handleInputChange={handleInputChange}
                                            disabled={unauthorized}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Phone number</Table.Cell>
                                    <Table.Cell>
                                        <TextInput
                                            name="phoneNumber"
                                            value={vendorContact.phoneNumber}
                                            handleInputChange={handleInputChange}
                                            disabled={unauthorized}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Employer</Table.Cell>
                                    <Table.Cell>
                                        <DropdownInput
                                            placeholder="Choose vendor..."
                                            search
                                            fluid
                                            value={vendorContact.vendorGuid}
                                            options={vendors}
                                            onChange={(e, data: any) =>
                                                setVendorContact({
                                                    ...vendorContact,
                                                    vendorGuid: data.value,
                                                })
                                            }
                                            disabled={unauthorized}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Segment>
                    <Segment className="edit-footer">
                        <SaveButton />
                    </Segment>
                </Segment.Group>
            )}
        </div>
    );
};
