import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table, Button, Modal } from "semantic-ui-react";
import { toast } from "../..";
import { IVehicle } from "../../models/vehicle";
import { AppState } from "../../store/configureStore";
import { deleteVehicle } from "../../services/vehiclesService";
import { UserRole } from "../../actions/authentificationActions";

interface IProps {
    vehicle: IVehicle;
    handleRemoveVehicle: (d: IVehicle) => void;
    page: string;
}

export const VehicleRow = ({ vehicle, handleRemoveVehicle, page }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const handleDeleteVehicle = async () => {
        try {
            await deleteVehicle(vehicle.guid);
            handleRemoveVehicle(vehicle);
            toast("Successfully deleted vehicle", true);
        } catch {
            toast("Failed to delete vehicle", false);
        }
    };

    return (
        <Fragment>
            <Table.Row
                onClick={() =>
                    history.push({
                        pathname: `vehicles/${vehicle.guid}`,
                        state: page,
                    })
                }
                className="clickable-row"
            >
                <Table.Cell>{vehicle.manufacturer}</Table.Cell>
                <Table.Cell>{vehicle.model}</Table.Cell>
                <Table.Cell>{vehicle.vin}</Table.Cell>
                <Table.Cell>{vehicle.registrationPlate}</Table.Cell>
                <Table.Cell>{vehicle.color}</Table.Cell>
                <Table.Cell>{vehicle.active?.toString()}</Table.Cell>
                <Table.Cell onClick={(e: any) => e.stopPropagation()} collapsing>
                    {user.role === UserRole.Admin && (
                        <Button
                            color="red"
                            content="Delete"
                            icon="delete"
                            onClick={() => setModalOpen(true)}
                        />
                    )}
                </Table.Cell>
            </Table.Row>
            <Modal
                size="mini"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                dimmer="blurring"
            >
                <Modal.Header>Are you sure you want to delete this vehicle?</Modal.Header>
                <Modal.Actions>
                    <Button color="red" onClick={() => setModalOpen(false)} content="Cancel" />
                    <Button color="green" onClick={handleDeleteVehicle} content="Confirm" />
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
};
