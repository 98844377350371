import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, useLocation, useParams } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";
import { IInventoryItem } from "../../models/inventoryItem";
import { AppState } from "../../store/configureStore";
import { getInventoryItem } from "../../api/inventoryItems";
import { EditInventoryItem } from "./EditInventoryItem";

export const InventoryItemDetails = () => {
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles = ["admin", "accountant"];
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [inventoryItem, setInventoryItem] = useState<IInventoryItem>();

    const fetchInventoryItem = useCallback(async id => {
        setLoading(true);
        const res = await getInventoryItem(id);
        res.guid && setInventoryItem(res);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchInventoryItem(params.guid);
    }, [fetchInventoryItem, params.guid]);

    return !allowedRoles.includes(user.role!) ? (
        <Redirect to="/unauthorized" />
    ) : loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="invoice-container">
            <div className="container-header">
                <h1>Inventory item details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/company/inventories", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {inventoryItem ? (
                <Fragment>
                    <EditInventoryItem
                        inventoryItem={inventoryItem}
                        setInventoryItem={setInventoryItem}
                    />
                </Fragment>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Inventory item not found
                </h2>
            )}
        </div>
    );
};
