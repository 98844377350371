import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button,  Segment, Table } from "semantic-ui-react";
import { toast } from "../..";
import { AppState } from "../../store/configureStore";
import { UserRole } from "../../actions/authentificationActions";
import { IEducation } from "../../models/educationsModel";
import { updateEducation } from "../../api/educations";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { TextInput } from "../../components/TextInput/TextInput";

interface IProps {
    education: IEducation;
    setEducation: Dispatch<SetStateAction<IEducation | undefined>>;
}

export const EditEducation = ({ education, setEducation }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    const history = useHistory();
    const [saving, setSaving] = useState(false);
    const disabled = user.role === UserRole.Admin;

    const handleInputChange = (e: any) =>
        setEducation({ ...education!, [e.target.name]: e.target.value });

    const handleUpdateEducation = async () => {
        setSaving(true);
        try {
            await updateEducation(education);
            toast("Education successfully updated", true);
            history.push("/employee-data/educations");
        } catch {
            toast("Error updating education", false);
        } finally {
            setSaving(false);
        }
    };

    const SaveButton = () =>
        !disabled ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateEducation}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    return (
        <Segment.Group>
            <Segment>
                <h2>Edit education details</h2>
                <SaveButton />
            </Segment>
            <Segment>
                <Table basic="very" celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell width={2}>Title</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="title"
                                    value={education.title}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Duration</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="totalHours"
                                    value={education.totalHours}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                    isNumber
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Issuing institution</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="issuingInstitution"
                                    value={education.issuingInstitution}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Date of completion</Table.Cell>
                            <Table.Cell>
                                <CalendarInput
                                    date={education.date}
                                    handleDateChange={e =>
                                        setEducation({ ...education, date: getISODateFormat(e) })
                                    }
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Certificate Id</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="certificateId"
                                    value={education.certificateId ?? ""}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Description</Table.Cell>
                            <Table.Cell>
                                <TextInput
                                    name="description"
                                    value={education.description}
                                    handleInputChange={handleInputChange}
                                    disabled={disabled}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};
