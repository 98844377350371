import React from "react";
import { Segment, Table } from "semantic-ui-react";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { IRecipient } from "../../models/accessRight";

interface IProps {
    recipient: IRecipient;
}

export const HistoryNotesDetails = ({ recipient }: IProps) => {
    return (
        <React.Fragment>
            <Segment.Group>
                <Segment className="edit-header">
                    <h2>Recipient history</h2>
                </Segment>
                <Segment>
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Timestamp</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                                <Table.HeaderCell>Comment</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {recipient.recipientHistoryNotes
                                .sort((x, y) => +new Date(x.timestamp) - +new Date(y.timestamp))
                                .map(note => (
                                    <Table.Row key={note.guid}>
                                        <Table.Cell>
                                            {getDisplayDateFormat(note.timestamp, true)}
                                        </Table.Cell>
                                        <Table.Cell>{note.action}</Table.Cell>
                                        <Table.Cell>{note.comment}</Table.Cell>
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                </Segment>
            </Segment.Group>
        </React.Fragment>
    );
};
