import { useSelector } from "react-redux";
import { Table, Segment } from "semantic-ui-react";
import { UserBirthday, UserRole } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";

interface IProps {
    title: string;
    birthdays: UserBirthday[];
}

const checkBirthday = (birthday: Date) => {
    let bDay = new Date(birthday).getDate();
    let today = new Date().getDate();
    if (today < bDay) {
        return "Birthday tomorrow";
    } else {
        return "Happy birthday";
    }
};

export const BirthdaysTable = ({ title, birthdays }: IProps) => {
    const user = useSelector((state: AppState) => state.user);
    return (
        <Segment.Group className="vacation-table-container">
            <Segment>
                <h2>{title}</h2>
            </Segment>
            <Segment>
                <Table basic="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={5}>User</Table.HeaderCell>
                            {user.role === UserRole.Admin && (
                                <Table.HeaderCell width={5}>Birthday</Table.HeaderCell>
                            )}
                            <Table.HeaderCell width={5}>Message</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {birthdays?.map(birthday => (
                            <Table.Row>
                                <Table.Cell width={5}> {birthday.fullName}</Table.Cell>
                                {user.role === UserRole.Admin && (
                                    <Table.Cell width={5}>
                                        {`${birthday.dateOfBirthString} (${birthday.years})`}
                                    </Table.Cell>
                                )}
                                <Table.Cell width={5}>
                                    {checkBirthday(birthday.dateOfBirth)}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};
