import axios from "axios";
import { ICapacityPlanning, IUserWeeklyCapacityPlanning } from "../models/capacityPlanning";
import { IPaginationWrapper } from "../models/common";

export const getAllUsersWeeklyCapacityPlannings = async (page: number, date: string) => {
    const response = await axios.get(`/api/capacityPlanning`, { params: { page, date } });
    return response.data as IPaginationWrapper;
};

export const getUserWeeklyCapacityPlannings = async (userGuid: string, date: string) => {
    const response = await axios.get(`/api/capacityPlanning/${userGuid}`, { params: { date } });
    return response.data as IUserWeeklyCapacityPlanning;
};

export const getAllProjectsWeeklyCapacityPlannings = async (date: string) => {
    const response = await axios.get(`/api/capacityPlanning/projects`, { params: { date } });
    return response.data as IPaginationWrapper; // rows are dtos, total rows is availability count
};

export const createOrUpdateCapacityPlanning = async (dto: ICapacityPlanning) => {
    await axios.put(`/api/capacityPlanning`, dto);
};
