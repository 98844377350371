import { IAccessRight } from "../models/accessRight";

export const SET_AUTH = "SET_AUTH";
export const SENDING_LOGIN_REQUEST = "SENDING_LOGIN_REQUEST";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const LOGOUT = "LOGOUT";
export const REQUEST_LOGIN_ERROR = "REQUEST_LOGIN_ERROR";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const USER_DISABLED_ERROR = "USER_DISABLED_ERROR";
export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";
export const SET_SUBSCRIBING = "SET_SUBSCRIBING";
export const CLEAR_SUBSCRIBING = "CLEAR_SUBSCRIBING";
export const CLEAR_PASSWORD_RECOVERY_TOKEN = "CLEAR_PASSWORD_RECOVERY_TOKEN";

export interface LoginRequestData {
    username: string;
    password: string;
}
export interface UserData {
    id?: number;
    guid?: string;
    username?: string;
    firstName: string;
    lastName: string;
    role?: string;
    token?: string;
    points?: number;
    negativePoints?: number;
    isActive?: boolean;
    isEmployed?: boolean;
    hasRequiredDocuments?: boolean;
    oib?: string;
    address?: string;
    city?: string;
    startDate?: string;
    endDate?: string;
    dayOfBirth?: string;
    accessRights?: Array<IAccessRight>;
    passwordRecoveryToken?: string;
    gitlabUsername?: string;
    jobTitle?: number;
    contractType?: number;
    jobDescription?: string;
    jobRequirements?: string;
    phoneNumber?: string;
    location?: string;
    cardNumber?: number;
    inventoryItemsGuid?: string[];
    company?: string;
    departmentGuid?: string;
    companyGuid?: string;
    jobTitleForSignature?: string;
    academicTitleForSignature?: string;
    priorExperience: string;
    activeDirectoryUsername?: string;
    managerGuid?: string;
    managerName?: string;
    teamMembersGuid?: string[];
    userProjectsGuid?: string[];
    isContractExpiring?: boolean;
}

export interface IUserPoints extends Partial<UserData> {
    guid?: string;
    points?: number;
    negativePoints?: number;
}

export interface INewUser extends Partial<UserData> {
    password: string;
}

export const newUserInit: INewUser = {
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    role: "",
    startDate: "",
};

export interface UserVacationDetails {
    vacations: UserVacations[];
    daysLeft: number;
}

export interface UserVacations {
    year: number;
    contractDays: number;
    correctionDays: number;
    daysUsed: number;
}

export interface UserBirthday {
    fullName: string;
    dateOfBirthString: string;
    dateOfBirth: Date;
    years: number;
}

export enum UserRole {
    SuperAdmin = "super_admin",
    Admin = "admin",
    Accountant = "accountant",
    InvoiceInputOperator = "invoice_input_operator",
    Employee = "employee",
    Student = "student",
    External = "external",
    CompanyAdmin = "company_admin",
    Cashier = "cashier",
    Liquidator = "liquidator",
    StudentAdmin = "student_admin",
}

export interface SetAuthAction {
    type: typeof SET_AUTH;
    newAuthState: boolean;
}
export interface SendingLoginRequestAction {
    type: typeof SENDING_LOGIN_REQUEST;
    sending: boolean;
}
export interface LoginRequestAction {
    type: typeof LOGIN_REQUEST;
    data: LoginRequestData;
}
export interface LogoutAction {
    type: typeof LOGOUT;
}
export interface RequestLoginErrorAction {
    type: typeof REQUEST_LOGIN_ERROR;
    error: string;
}
export interface ClearLoginErrorAction {
    type: typeof CLEAR_LOGIN_ERROR;
}
export interface UserDisabledErrorAction {
    type: typeof USER_DISABLED_ERROR;
}
export interface SetUserAction {
    type: typeof SET_USER;
    user: UserData;
}
export interface ClearUserAction {
    type: typeof CLEAR_USER;
}
export interface SetSubscribingAction {
    type: typeof SET_SUBSCRIBING;
}
export interface ClearSubscribingAction {
    type: typeof CLEAR_SUBSCRIBING;
}

export interface ClearPasswordRecoveryToken {
    type: typeof CLEAR_PASSWORD_RECOVERY_TOKEN;
}

export type AuthentificationActionType =
    | SetAuthAction
    | SendingLoginRequestAction
    | LoginRequestAction
    | LogoutAction
    | RequestLoginErrorAction
    | ClearLoginErrorAction
    | SetUserAction
    | ClearUserAction
    | SetSubscribingAction
    | ClearSubscribingAction
    | UserDisabledErrorAction
    | ClearPasswordRecoveryToken;

/**
 * Sets the authentication state of the application
 * @param  {boolean} newAuthState True means a user is logged in, false means no user is logged in
 */
export function setAuthState(newAuthState: boolean): AuthentificationActionType {
    return { type: SET_AUTH, newAuthState };
}

/**
 * Sets the `currentlySending` state, which displays a loading indicator during requests
 * @param  {boolean} sending True means we're sending a request, false means we're not
 */
export function sendingLoginRequest(sending: boolean): AuthentificationActionType {
    return { type: SENDING_LOGIN_REQUEST, sending };
}

/**
 * Tells the app we want to log in a user
 * @param  {object} data          The data we're sending for log in
 * @param  {string} data.username The username of the user to log in
 * @param  {string} data.password The password of the user to log in
 */
export function loginRequest(data: LoginRequestData): AuthentificationActionType {
    return { type: LOGIN_REQUEST, data };
}

/**
 * Tells the app we want to log out a user
 */
export function logout() {
    return { type: LOGOUT };
}

/**
 * Sets the `error` state to the error received
 * @param  {object} error The error we got when trying to make the request
 */
export function requestError(error: string): AuthentificationActionType {
    return { type: REQUEST_LOGIN_ERROR, error };
}

/**
 * Sets the `error` state as empty
 */
export function clearLoginError(): AuthentificationActionType {
    return { type: CLEAR_LOGIN_ERROR };
}

export function userDisabledError(): AuthentificationActionType {
    return { type: USER_DISABLED_ERROR };
}

/**
 * Sets the `user` state
 */
export function setUser(user: UserData): AuthentificationActionType {
    return { type: SET_USER, user };
}

/**
 * Sets the `user` state as empty
 */
export function clearUser(): AuthentificationActionType {
    return { type: CLEAR_USER };
}

export function setSubscribing(): AuthentificationActionType {
    return { type: SET_SUBSCRIBING };
}

export function clearSubscribing(): AuthentificationActionType {
    return { type: CLEAR_SUBSCRIBING };
}

export function clearPasswordRecoveryToken(): AuthentificationActionType {
    return { type: CLEAR_PASSWORD_RECOVERY_TOKEN };
}
