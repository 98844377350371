import { DropdownItemProps } from "semantic-ui-react";

export const approveStatusTypes: DropdownItemProps[] = [
    { key: 0, text: "Rejected", value: 0 },
    { key: 1, text: "Approved", value: 1 },
    { key: 2, text: "Draft", value: 2 },
    { key: 4, text: "Requested", value: 4 },
    { key: 5, text: "In review", value: 5 },
    { key: 6, text: "Postponed", value: 6 },
];

export const getApproveStatus = (type: number) => {
    switch (type) {
        case 0:
            return "Rejected";
        case 1:
            return "Approved";
        case 2:
            return "Draft";
        case 4:
            return "Requested";
        case 5:
            return "In review";
        case 6:
            return "Postponed";
        default:
            return "Unknown";
    }
};
