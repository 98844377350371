import axios from "axios";
import { IPaginationWrapper } from "../models/common";
import { IInventoryItem, IInventoryItemFilters } from "../models/inventoryItem";

export const getInventoryItems = async (page: number, filters: IInventoryItemFilters) => {
    const params = new URLSearchParams({ page: page.toString() });
    filters.query && params.append("query", filters.query);
    const response = await axios.get(`/api/inventoryItems`, { params: params });
    return response.data as IPaginationWrapper;
};

export const getInventoryItemsList = async (invoiceGuid?: string) => {
    const response = await axios.get(`/api/inventoryItems/list`, {
        params: { invoiceGuid: invoiceGuid },
    });
    return response.data as IInventoryItem[];
};

export const getInventoryItem = async (guid: string) => {
    const response = await axios.get(`/api/inventoryItems/${guid}`);
    return response.data as IInventoryItem;
};

export const editInventoryItem = async (inventoryItem: IInventoryItem) => {
    const response = await axios.put(`/api/inventoryItems/${inventoryItem.guid}`, inventoryItem);
    return response;
};

export const deleteInventoryItem = async (guid: string) => {
    const response = await axios.delete(`/api/inventoryItems/${guid}`);
    return response;
};
export const saveInventoryItems = async (inventoryItem: IInventoryItem[]) => {
    const response = await axios.post("/api/inventoryItems", inventoryItem);
    return response;
};
