import axios from "axios";
import { IPaginationWrapper } from "../models/common";
import { IDate, IUnselectableDates } from "../models/date";
import { IWorkingPlan } from "../models/workingPlan";

export const getWorkingPlans = async (page: number, status: number, type: number) => {
    const params = new URLSearchParams({
        page: page.toString(),
        status: status.toString(),
        type: type.toString(),
    });
    const response = await axios.get("/api/workingPlans", { params: params });
    return response.data as IPaginationWrapper;
};

export const getWorkingPlan = async (guid: string) => {
    const response = await axios.get(`/api/workingPlans/${guid}`);
    return response.data as IWorkingPlan;
};

export const getWorkingPlanDates = async (guid: string) => {
    const response = await axios.get(`/api/workingPlans/${guid}/dates`);
    return response.data as IDate[];
};

export const getUserPreviousDates = async (guid: string) => {
    const response = await axios.get(`/api/workingPlans/${guid}/previousDates`);
    return response.data as IUnselectableDates;
};

export const createWorkingPlan = async (dates: string[], type: number) => {
    const response = await axios.post("/api/workingPlans", dates, {
        params: { type: type.toString() },
    });
    return response;
};

export const updateWorkingPlan = async (guid: string, dates: IDate[]) => {
    const response = await axios.put(`/api/workingPlans/${guid}`, dates);
    return response;
};

export const submitWorkingPlanRequest = async (guid: string) => {
    const response = await axios.post(`/api/workingPlans/${guid}`);
    return response;
};

export const processWorkingPlanRequests = async (guids: string[], choice: boolean) => {
    const response = await axios.post(`/api/workingPlans/requests?choice=${choice}`, guids, {
        params: { choice: choice.toString() },
    });
    return response;
};

export const rejectRemoteWork = async (guids: string[]) => {
    const response = await axios.post(`/api/workingPlans/rejectRemote`, guids);
    return response;
};

export const deleteWorkingPlanRequest = async (guid: string) => {
    const response = await axios.delete(`/api/workingPlans/${guid}`);
    return response;
};
