import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, useLocation, useParams } from "react-router-dom";
import {
    Button,
    DropdownItemProps,
    Icon,
    Input,
    Loader,
    Segment,
    SegmentGroup,
    Select,
    Table,
} from "semantic-ui-react";
import { toast } from "../..";
import { IDocument, IUploadDocument } from "../../models/document";
import { editDocument, getDocument } from "../../services/documentsService";
import { documentClassificationProps, documentTypeProps } from "../../models/document";
import { getClientsAsProps } from "../../services/clientsService";
import { AppState } from "../../store/configureStore";
import QRCode from "qrcode.react";
import { UserRole } from "../../actions/authentificationActions";
import { getDisplayDateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { FileDropzone } from "../../components/FileDropzone/FileDropzone";
import { uploadExistingDocument } from "../../api/documents";
import { TextInput } from "../../components/TextInput/TextInput";

export const DocumentDetails = () => {
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles = ["admin", "accountant", "employee", "student"];
    const [saving, setSaving] = useState(false);
    const unauthorized = user.role !== UserRole.Admin;
    const params: { guid: string } = useParams();
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [document, setDocument] = useState<IDocument>();
    const [userOfDocumentProps, setUserOfDocumentProps] = useState<DropdownItemProps[]>([]);

    const fetchDocument = useCallback(async id => {
        setLoading(true);
        const res = await getDocument(id);
        res.guid && setDocument(res);
        setLoading(false);
    }, []);

    const fetchUserOfDocumentProps = async () => {
        const clientProps = await getClientsAsProps();
        var index = 0;
        var props: DropdownItemProps[] = [];
        clientProps.forEach(c => {
            props.push({ key: index, text: c.text, value: c.text?.toString() });
            index++;
        });
        props.push({ key: index, text: "Other", value: "Other" });
        setUserOfDocumentProps(props);
    };

    const handleInputChange = (e: any) =>
        setDocument({ ...document!, [e.target.name]: e.target.value });

    const handleUpdateDocument = async () => {
        setSaving(true);
        const res = await editDocument(document!);
        if (!res.ok) toast("Failed to save changes, please try again", false);
        else toast("Successfully saved changes", true);
        setSaving(false);
    };

    const handleUploadDocument = async (files: File[]) => {
        const uploadDocument: IUploadDocument = {
            documentType: document!.documentType,
            userGuid: user.guid,
        };
        setLoading(true);
        try {
            if (document) {
                const res = await uploadExistingDocument(document.guid, uploadDocument, files[0]);
                setDocument(res);
                toast("Document successfully uploaded", true);
            }
        } catch {
            toast("Failed to upload document, please try again", false);
        } finally {
            setLoading(false);
        }
    };

    const SaveButton = () =>
        !unauthorized ? (
            <Button
                secondary
                content="Save changes"
                icon="save"
                onClick={handleUpdateDocument}
                disabled={saving}
                loading={saving}
            />
        ) : null;

    useEffect(() => {
        fetchDocument(params.guid);
        fetchUserOfDocumentProps();
    }, [fetchDocument, params.guid]);

    return !allowedRoles.includes(user.role!) ? (
        <Redirect to="/unauthorized" />
    ) : loading ? (
        <Loader active inline="centered" size="huge" />
    ) : (
        <div className="document-container">
            <div className="container-header">
                <h1>Document details</h1>
                <Button
                    as={Link}
                    to={{ pathname: "/administration/documents", state: loc.state }}
                    secondary
                    content="Back to list"
                    icon="arrow left"
                />
            </div>
            {document ? (
                <>
                    <Segment.Group>
                        <Segment>
                            <h2>Edit document details</h2>
                            <SaveButton />
                        </Segment>
                        <Segment>
                            <Table basic="very" celled compact>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Name</Table.Cell>
                                        <Table.Cell>
                                            <TextInput
                                                name="name"
                                                value={document.name ?? ""}
                                                handleInputChange={handleInputChange}
                                                disabled={unauthorized}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Type</Table.Cell>
                                        <Table.Cell>
                                            <Select
                                                name="documentType"
                                                defaultValue={document.documentType}
                                                options={documentTypeProps}
                                                onChange={(e, data: any) =>
                                                    setDocument({
                                                        ...document,
                                                        documentType: data.value,
                                                    })
                                                }
                                                disabled={unauthorized}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Classification</Table.Cell>
                                        <Table.Cell>
                                            <Select
                                                name="documentClassification"
                                                defaultValue={document.documentClassification}
                                                options={documentClassificationProps}
                                                onChange={(e, data: any) =>
                                                    setDocument({
                                                        ...document,
                                                        documentClassification: data.value,
                                                    })
                                                }
                                                disabled={unauthorized}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Created</Table.Cell>
                                        <Table.Cell>
                                            <TextInput
                                                name="date"
                                                value={getDisplayDateFormat(document.date, true)}
                                                handleInputChange={handleInputChange}
                                                disabled
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Added by</Table.Cell>
                                        <Table.Cell>
                                            <TextInput
                                                name="username"
                                                value={document.username ?? ""}
                                                handleInputChange={handleInputChange}
                                                disabled
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>User of the document</Table.Cell>
                                        <Table.Cell>
                                            <Input
                                                name="userOfDocument"
                                                defaultValue={document.userOfDocument ?? ""}
                                                list="list"
                                                clearable
                                                onChange={handleInputChange}
                                                disabled={unauthorized}
                                            />
                                            <datalist id="list">
                                                {userOfDocumentProps.map(u => {
                                                    return (
                                                        <option
                                                            key={u.value?.toString()}
                                                            value={u.value?.toString()}
                                                        >
                                                            {u.value}
                                                        </option>
                                                    );
                                                })}
                                            </datalist>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>External GUID</Table.Cell>
                                        <Table.Cell>
                                            <Input
                                                name="externalGuid"
                                                value={document.externalGuid ?? ""}
                                                onChange={handleInputChange}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                    {document.externalGuid && (
                                        <Table.Row>
                                            <Table.Cell>QR code (external GUID)</Table.Cell>
                                            <Table.Cell>
                                                <QRCode
                                                    size={180}
                                                    id="guid-qr-code"
                                                    value={`${window.location.host}/document-verification/${document.externalGuid}`}
                                                    bgColor="white"
                                                    fgColor="black"
                                                    level="H"
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                    <Table.Row>
                                        <Table.Cell>Database GUID</Table.Cell>
                                        <Table.Cell>{document.guid}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>QR code (Database GUID)</Table.Cell>
                                        <Table.Cell>
                                            <QRCode
                                                size={180}
                                                id="guid-qr-code"
                                                value={`${window.location.host}/document-verification/${document.guid}`}
                                                bgColor="white"
                                                fgColor="black"
                                                level="H"
                                            />
                                        </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Comment</Table.Cell>
                                        <Table.Cell>
                                            <TextInput
                                                name="comment"
                                                value={document.comment}
                                                handleInputChange={handleInputChange}
                                                disabled={unauthorized}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                        <Segment>
                            <SaveButton />
                        </Segment>
                    </Segment.Group>
                    {!document.hasUploadedFile && document.createdWithoutUpload && (
                        <SegmentGroup>
                            <FileDropzone
                                title="Select a document to upload"
                                loading={loading}
                                handleUploadFiles={handleUploadDocument}
                                selectType={false}
                            />
                        </SegmentGroup>
                    )}
                </>
            ) : (
                <h2 style={{ textAlign: "center" }}>
                    <Icon name="warning circle" />
                    Document not found
                </h2>
            )}
        </div>
    );
};
