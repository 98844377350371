import React, { useState } from "react";
import { Button, Icon, Modal, TextArea } from "semantic-ui-react";
import { IBoardData, newBoardInit } from "../../models/board";
import { toast } from "../..";
import agent from "../../api/agent";
import { DocumentType } from "../../models/document";
import { ImageDropzone } from "../../components/FileDropzone/ImageDropzone";
import { getCroppedImg } from "../UsersDashboard/getCroppedImage";
import { putImage } from "../../services/imageService";
import { v4 as uuidv4 } from "uuid";
import { TextInput } from "../../components/TextInput/TextInput";

interface IProps {
    open: boolean;
    closeModal: () => void;
    refreshBoard: () => void;
}

export const AddNewBoard = ({ open, closeModal, refreshBoard }: IProps) => {
    const [loading, setLoading] = useState(false);
    const [news, setNews] = useState<IBoardData>(newBoardInit);
    const [crop, setCrop] = useState(false);
    const [imageDropped, setImageDropped] = useState(false);

    const handleAddNews = async () => {
        let imgGuid = "00000000-0000-0000-0000-000000000000";

        if (imageDropped) {
            imgGuid = uuidv4();
            const img = await getCroppedImg(crop, imgGuid);
            const res = await putImage(imgGuid, img, DocumentType.Images);
            if (!res.ok) {
                toast("Failed to upload board image, please try again", false);
            }
        }

        try {
            news.imgGuid = imgGuid;
            news.oldImgGuid = imgGuid;
            await agent.Board.create(news);
            refreshBoard();
            toast("Board news successfully added", true);
        } catch (e: any) {
            toast(e.message, false);
        } finally {
            closeModal();
            setLoading(false);
        }
    };

    return (
        <Modal
            className="add-board-modal"
            open={open}
            onClose={closeModal}
            dimmer="blurring"
            centered={false}
        >
            <Modal.Header>
                <h2>Add board news</h2>
                <Icon name="close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Content>
                <form className="add-board-form">
                    <div className="input-container">
                        <label>Title</label>
                        <TextInput
                            disabled={false}
                            name={"title"}
                            value={news.title}
                            handleInputChange={(e: any) =>
                                setNews({ ...news, title: e.target.value })
                            }
                        />
                    </div>
                    <div className="input-container">
                        <label>Description</label>
                        <TextArea
                            className="text-area"
                            value={news.description}
                            placeholder="tell us more"
                            onChange={(e: any) => setNews({ ...news, description: e.target.value })}
                        />
                    </div>
                    <div className="input-container">
                        <label>News image</label>
                        <ImageDropzone
                            guid={news?.imgGuid}
                            onDrop={() => setImageDropped(true)}
                            crop={crop}
                            setCrop={setCrop}
                            documentType={DocumentType.Images}
                        />
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add news"
                    icon="add user"
                    loading={loading}
                    secondary
                    fluid
                    onClick={handleAddNews}
                />
            </Modal.Actions>
        </Modal>
    );
};
