import { useCallback, useEffect, useState } from "react";
import { Button, Icon, Loader, Pagination, Segment, Table } from "semantic-ui-react";
import { IAccessRight } from "../../models/accessRight";
import { getAccessRights } from "../../services/accessRightsService";
import { AccessRightsRow } from "./AccessRightsRow";
import { AddAccessRightForm } from "./AddAccessRightForm";

export const AccessRights = () => {
    const [loading, setLoading] = useState(false);
    const [accessRights, setAccessRights] = useState<IAccessRight[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);

    const fetchAccessRights = useCallback(async (page) => {
        setLoading(true);
        const res = await getAccessRights(page);
        if (res.rows) {
            setAccessRights(res.rows as IAccessRight[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, []);

    const handleRemoveAccessRight = (ar: IAccessRight) =>
        setAccessRights([...accessRights].filter((x) => x !== ar));

    useEffect(() => {
        fetchAccessRights(page);
    }, [fetchAccessRights, page]);

    return (
        <div className="access-rights-container">
            <div className="container-header">
                <h1>Access rights</h1>
                <Button
                    secondary
                    content="Add new resource group"
                    icon="key"
                    onClick={() => setModalOpen(true)}
                />
            </div>
            <div className="access-rights-display">
                <div className="access-rights-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment className="access-rights-options"></Segment>
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Resource group</Table.HeaderCell>
                                            <Table.HeaderCell>Responsible person</Table.HeaderCell>
                                            <Table.HeaderCell>No. of recipients</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {accessRights.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="4">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            accessRights.map((x) => (
                                                <AccessRightsRow
                                                    key={x.guid}
                                                    accessRight={x}
                                                    handleRemoveAccessRight={
                                                        handleRemoveAccessRight
                                                    }
                                                />
                                            ))
                                        )}
                                        <Table.Row/>
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{ content: <Icon name="angle double left" /> }}
                                        lastItem={{ content: <Icon name="angle double right" /> }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                            <AddAccessRightForm
                                open={modalOpen}
                                closeModal={() => setModalOpen(false)}
                                fetchAccessRights={() => fetchAccessRights(1)}
                            />
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
