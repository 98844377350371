import axios from "axios";
import { DropdownItemProps } from "semantic-ui-react";
import { ILocation } from "../models/location";

export const getLocations = async () => {
    const response = await axios.get(`/api/locations`);
    return response.data as ILocation[];
};

export const getLocation = async (guid: string) => {
    const response = await axios.get(`/api/locations/${guid}`);
    return response.data as ILocation;
};

export const createLocation = async (locationDto: Partial<ILocation>) => {
    const response = await axios.put("/api/locations/create", locationDto);
    return response;
};

export const updateLocation = async (locationDto: ILocation) => {
    const response = await axios.put(`/api/locations/${locationDto.guid}`, locationDto);
    return response;
};

export const deleteLocation = async (guid: string) => {
    const response = await axios.delete(`/api/locations/${guid}`);
    return response;
};

export const setLocationToHQ = async (guid: string) => {
    const response = await axios.patch(`/api/locations/setHQ/${guid}`);
    return response;
}

export const getLocationsList = async () => {
    const response = await axios.get(`/api/locations/list`);
    return response.data as DropdownItemProps[];
}
