import axios from "axios";
import { IIdeaBox } from "../models/ideaBox";
import { IPaginationWrapper } from "../models/common";

export const getIdeaBoxes = async (page: number, userGuid?: string) => {
    const params = new URLSearchParams({ page: page + "" });
    userGuid && params.append("userGuid", userGuid);
    const response = await axios.get(`/api/ideaBoxes?${params.toString()}`);
    return response.data as IPaginationWrapper;
};

export const getIdeaBox = async (guid: string) => {
    const response = await axios.get(`/api/ideaBoxes/${guid}`);
    return response.data as IIdeaBox;
};

export const createIdeaBox = async (ideaBoxDto: Partial<IIdeaBox>) => {
    const response = await axios.post("/api/ideaBoxes", ideaBoxDto);
    return response;
};

export const updateIdeaBox = async (ideaBoxDto: IIdeaBox) => {
    const response = await axios.put(`/api/ideaBoxes/${ideaBoxDto.guid}`, ideaBoxDto);
    return response;
};

export const deleteIdeaBox = async (guid: string) => {
    const response = await axios.delete(`/api/ideaBoxes/${guid}`);
    return response;
};
