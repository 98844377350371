import { useState } from "react";
import { newCompanyInit } from "../../models/company";
import { Icon, Modal, Input, Button } from "semantic-ui-react";
import { toast } from "../..";
import { createCompany } from "../../api/companies";

interface IProps {
    open: boolean;
    closeModal: () => void;
    fetchCompanies: () => void;
}

export const AddCompanyModal = ({ open, closeModal, fetchCompanies }: IProps) => {
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [newCompany, setNewCompany] = useState(newCompanyInit);

    const handleAddNewCompany = async () => {
        setLoading(true);
        try {
            await createCompany(newCompany);
            toast("Company successfully added", true, 1500);
            setNewCompany(newCompanyInit)
            fetchCompanies();
            closeModal();
        } catch (ex: any) {
            setResponseMessage(ex.response.data);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = async () => {
        setResponseMessage("");
        closeModal();
    };

    return (
        <Modal open={open} onClose={handleCloseModal} dimmer="blurring">
            <Modal.Header>
                <h2>Add new company</h2>
                <Icon name="close" onClick={handleCloseModal} />
            </Modal.Header>
            <Modal.Content>
                <form>
                    <div className="input-container">
                        <label>Name</label>
                        <Input
                            value={newCompany.name}
                            onChange={(e: any) =>
                                setNewCompany({ ...newCompany, name: e.target.value })
                            }
                        />
                        {responseMessage.length > 0 && (
                            <div className="ui negative message">
                                <div className="header">{responseMessage}</div>
                            </div>
                        )}
                    </div>
                </form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    secondary
                    fluid
                    icon="add circle"
                    loading={loading}
                    disabled={newCompany.name === ""}
                    content="Add new company"
                    onClick={handleAddNewCompany}
                />
            </Modal.Actions>
        </Modal>
    );
};
