import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Button, Icon, Loader, Pagination, Segment, Select, Table } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import { IReport, IReportFilter, reportFiltersInit } from "../../models/report";
import { getReports } from "../../api/reports";
import { AppState } from "../../store/configureStore";
import { ReportsRow } from "./ReportsRow";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { toast } from "../..";
import "./Reports.css";

export const Reports = () => {
    const user = useSelector((state: AppState) => state.user);
    const staff = useSelector((state: AppState) => state.staff);
    const projects = useSelector((state: AppState) => state.projects);
    const loc = useLocation();
    const date = new Date().toISOString().split("T")[0];
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState<IReport[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [filters, setFilters] = useState<IReportFilter>(reportFiltersInit);

    const fetchReports = useCallback(async (filters: IReportFilter) => {
        setLoading(true);
        try {
            const res = await getReports(filters);
            if (res.rows) {
                setReports(res.rows);
                setTotalRows(res.totalRows);
            }
        } catch (error) {
            toast("Problem while fetching reports", false);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleRemoveReport = (r: IReport) => setReports([...reports].filter(x => x !== r));

    useEffect(() => {
        fetchReports(filters);
    }, [fetchReports, filters]);

    return (
        <div className="reports-container">
            <div className="container-header">
                <h1>Reports</h1>
                {user.role === UserRole.Admin ? (
                    <Button
                        as={Link}
                        to="/company/reports/summary"
                        secondary
                        content="View reports summary"
                        icon="clipboard list"
                    />
                ) : (
                    <Button
                        as={Link}
                        to={"/company/reports/daily/" + date}
                        secondary
                        content="Add or modify your reports for today"
                        icon="clipboard list"
                    />
                )}
            </div>
            <div className="reports-display">
                <div className="reports-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment className="reports-options">
                                <Select
                                    clearable
                                    placeholder="Select project..."
                                    selectOnBlur={false}
                                    options={projects}
                                    value={filters.projectGuid}
                                    onChange={(e, data: any) =>
                                        setFilters({
                                            ...filters,
                                            projectGuid: data.value,
                                        })
                                    }
                                />
                                <CalendarInput
                                    clearable
                                    date={filters.date}
                                    handleDateChange={(e: any) =>
                                        setFilters({
                                            ...filters,
                                            date: getISODateFormat(e),
                                        })
                                    }
                                    detail="year"
                                />
                                {user.role === UserRole.Admin && (
                                    <Select
                                        clearable
                                        placeholder="Select user..."
                                        selectOnBlur={false}
                                        options={staff}
                                        value={filters.userGuid}
                                        onChange={(e, data: any) =>
                                            setFilters({
                                                ...filters,
                                                userGuid: data.value,
                                            })
                                        }
                                    />
                                )}
                            </Segment>
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Work day</Table.HeaderCell>
                                            <Table.HeaderCell>Project</Table.HeaderCell>
                                            <Table.HeaderCell>User</Table.HeaderCell>
                                            <Table.HeaderCell>Type</Table.HeaderCell>
                                            <Table.HeaderCell>Hours</Table.HeaderCell>
                                            <Table.HeaderCell>Description</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {reports.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="4">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            reports.map(x => (
                                                <ReportsRow
                                                    key={x.guid}
                                                    report={x}
                                                    handleRemoveReport={handleRemoveReport}
                                                    page={page + ""}
                                                />
                                            ))
                                        )}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => {
                                            setPage(+data.activePage);
                                            setFilters({
                                                ...filters,
                                                page: +data.activePage,
                                            });
                                        }}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{ content: <Icon name="angle double left" /> }}
                                        lastItem={{ content: <Icon name="angle double right" /> }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
