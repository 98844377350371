import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import { Button, Loader, Segment, Table, Pagination, Icon, Select, Input } from "semantic-ui-react";
import { UserRole } from "../../actions/authentificationActions";
import { CalendarInput } from "../../components/CalendarInput/CalendarInput";
import { getISODateFormat } from "../../components/CalendarSelector/getAllDatesHelper";
import { invoiceFiltersInit, IBankStatement, statusOptions } from "../../models/bankStatement";
import { downloadZippedDocuments, getBankStatements } from "../../services/bankStatementsService";
import { AppState } from "../../store/configureStore";
import { BankStatementRow } from "./BankStatementRow";
import "./BankStatements.css";

export const BankStatements = () => {
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles = ["admin", "accountant"];
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [bankStatements, setBankStatements] = useState<IBankStatement[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [forExport, setForExport] = useState<IBankStatement[]>([]);
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [filters, setFilters] = useState(invoiceFiltersInit);
    const [appliedFilters, setAppliedFilters] = useState(invoiceFiltersInit);

    const fetchBankStatements = useCallback(async () => {
        setLoading(true);
        const res = await getBankStatements(page, appliedFilters);
        if (res.rows) {
            setBankStatements(res.rows as IBankStatement[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page, appliedFilters]);

    const handleForExport = (stmnt: IBankStatement) => {
        if (forExport.includes(stmnt)) setForExport([...forExport.filter(x => x !== stmnt)]);
        else setForExport([...forExport].concat(stmnt));
    };

    const handleRemoveBankStatement = (bs: IBankStatement) =>
        setBankStatements([...bankStatements].filter(x => x !== bs));

    const handleClearFilters = () => {
        setFilters(invoiceFiltersInit);
        setAppliedFilters(invoiceFiltersInit);
    };

    const dowloadBankStatementDocuments = async () => {
        setAppliedFilters(filters);
        var _startDate = filters.startDate === undefined ? "01-01-1990" : filters.startDate;
        var _endDate = filters.endDate === undefined ? "31-12-9999" : filters.endDate;
        console.log("Time frame:\n" + _startDate + "\n" + _endDate);
        downloadZippedDocuments(_startDate, _endDate);
    };

    const setQuery = () => {
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("page", page.toString());
        window.history.pushState(null, "Page", "?" + queryParams.toString());
    };
    useEffect(() => {
        setPage((loc.state as number) ?? 1);
    }, [loc.state]);

    useEffect(() => {
        fetchBankStatements();
        setQuery();
    }, [fetchBankStatements]);

    return !allowedRoles.includes(user.role!) ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="bank-statements-container">
            <div className="container-header">
                <h1>Bank statements</h1>
                {user.role === UserRole.Admin && (
                    <Button
                        as={Link}
                        to="/invoice-processing/bank-statements/upload"
                        secondary
                        content="Upload bank statements"
                        icon="upload"
                    />
                )}
            </div>
            <div className="bank-statements-display">
                <div className="bank-statements-table">
                    <Segment className="bank-statements-options">
                        <CalendarInput
                            date={filters.startDate}
                            handleDateChange={(e: any) =>
                                setFilters({
                                    ...filters,
                                    startDate: getISODateFormat(e),
                                })
                            }
                            placeholder="Start date"
                        />
                        <CalendarInput
                            date={filters.endDate}
                            handleDateChange={(e: any) =>
                                setFilters({
                                    ...filters,
                                    endDate: getISODateFormat(e),
                                })
                            }
                            placeholder="End date"
                        />
                        <Select
                            placeholder="Status"
                            selectOnBlur={false}
                            options={statusOptions}
                            value={filters.status}
                            onChange={(e, data: any) => {
                                setFilters({ ...filters, status: data.value });
                                setAppliedFilters({ ...appliedFilters, status: data.value });
                            }}
                        />
                        <Input
                            placeholder="Search..."
                            value={filters.query}
                            onChange={e => {
                                e.preventDefault();
                                setFilters({ ...filters, query: e.target.value });
                                setAppliedFilters({ ...filters, query: e.target.value });
                            }}
                        />
                        <div className="bulk-download-button">
                            <Button
                                secondary
                                content="Download invoices"
                                icon="download"
                                onClick={() => dowloadBankStatementDocuments()}
                            />
                        </div>
                        <div className="filters-buttons">
                            <Button
                                secondary
                                content="Apply filters"
                                icon="filter"
                                onClick={() => setAppliedFilters(filters)}
                            />
                            <Button
                                secondary
                                content="Clear filters"
                                icon="undo"
                                onClick={handleClearFilters}
                            />
                        </div>
                    </Segment>
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{ content: <Icon name="angle double left" /> }}
                                        lastItem={{ content: <Icon name="angle double right" /> }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>

                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Select</Table.HeaderCell>
                                            <Table.HeaderCell>Date</Table.HeaderCell>
                                            <Table.HeaderCell>Created</Table.HeaderCell>
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Payment receiver/Description
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing>
                                                Amount (HRK)
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>Category</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {bankStatements.map(x => (
                                            <BankStatementRow
                                                key={x.guid}
                                                bs={x}
                                                selected={forExport.includes(x)}
                                                handleForExport={handleForExport}
                                                handleRemoveBankStatement={
                                                    handleRemoveBankStatement
                                                }
                                                page={page + ""}
                                            />
                                        ))}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{ content: <Icon name="angle double left" /> }}
                                        lastItem={{ content: <Icon name="angle double right" /> }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
