import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Icon, Loader, Pagination, Segment, Table } from "semantic-ui-react";
import { UserRole } from "../../../actions/authentificationActions";
import { IWorkAttendance } from "../../../models/workAttendance";
import { getWorkAttendances } from "../../../services/workAttendancesService";
import { AppState } from "../../../store/configureStore";
import { ParentalLeaveRow } from "./ParentalLeaveRow";

export const ParentalLeaves = () => {
    const user = useSelector((state: AppState) => state.user);
    const [parentalLeaves, setParentalLeaves] = useState<IWorkAttendance[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);

    const fetchParentalLeaves = useCallback(async () => {
        setLoading(true);
        const res = await getWorkAttendances(page, "parental-leaves", -1);
        if (res.rows) {
            setParentalLeaves(res.rows as IWorkAttendance[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page]);

    useEffect(() => {
        fetchParentalLeaves();
    }, [fetchParentalLeaves]);

    return (
        <div className="parental-leaves-container">
            <div className="container-header">
                <h1>Parental leaves</h1>
                {user.role === UserRole.Admin && (
                    <Button
                        as={Link}
                        to={`/employee-data/parental-leaves/create`}
                        secondary
                        content="Create new parental leave"
                        icon="plus"
                    />
                )}
            </div>
            <div className="parental-leaves-display">
                <div className="parental-leaves-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment></Segment>
                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Start date</Table.HeaderCell>
                                            <Table.HeaderCell>End date</Table.HeaderCell>
                                            <Table.HeaderCell>Total days</Table.HeaderCell>
                                            {user.role === UserRole.Admin && (
                                                <Table.HeaderCell>Actions</Table.HeaderCell>
                                            )}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {parentalLeaves.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell textAlign="center" colSpan="5">
                                                    No matching records found
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            parentalLeaves.map(x => (
                                                <ParentalLeaveRow
                                                    key={x.guid}
                                                    parentalLeave={x}
                                                    handleRemoveParentalLeave={fetchParentalLeaves}
                                                    page={page + ""}
                                                />
                                            ))
                                        )}
                                        <Table.Row/>
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
