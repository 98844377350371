import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import { Button, Icon, Input, Loader, Pagination, Segment, Table } from "semantic-ui-react";
import { invoiceFiltersInit } from "../../models/bankStatement";
import { AppState } from "../../store/configureStore";
import { IInventoryItem } from "../../models/inventoryItem";
import { getInventoryItems } from "../../api/inventoryItems";
import { InventoryItemRow } from "./InventoryItemRow";

export const InventoryItems = () => {
    const user = useSelector((state: AppState) => state.user);
    const allowedRoles = ["admin", "accountant"];
    const loc = useLocation();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(loc.state ? (loc.state as number) : 1);
    const [filters, setFilters] = useState(invoiceFiltersInit);
    const [appliedFilters, setAppliedFilters] = useState(invoiceFiltersInit);
    const [inventoryItems, setInventoryItems] = useState<IInventoryItem[]>([]);

    const fetchInventoryItems = useCallback(async () => {
        setLoading(true);
        const res = await getInventoryItems(page, appliedFilters);
        if (res.rows) {
            setInventoryItems(res.rows as IInventoryItem[]);
            setTotalRows(res.totalRows);
        }
        setLoading(false);
    }, [page, appliedFilters]);

    const handleRemoveInventoryItem = (inventoryItem: IInventoryItem) =>
        setInventoryItems([...inventoryItems].filter(x => x !== inventoryItem));

    const setQuery = () => {
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("page", page.toString());
        window.history.pushState(null, "Page", "?" + queryParams.toString());
    };

    useEffect(() => {
        fetchInventoryItems();
        setQuery();
    }, [fetchInventoryItems]);

    return !allowedRoles.includes(user.role!) ? (
        <Redirect to="/unauthorized" />
    ) : (
        <div className="invoices-container">
            <div className="container-header">
                <h1>Inventory</h1>
                {user.role === "admin" && (
                    <div>
                        <Button
                            as={Link}
                            to="/company/inventories/create"
                            secondary
                            content="Create new inventory item"
                            icon="plus"
                        />
                    </div>
                )}
            </div>
            <div className="invoices-display">
                <div className="invoices-table">
                    {loading ? (
                        <Loader active inline="centered" size="huge" />
                    ) : (
                        <Segment.Group>
                            <Segment>
                                <Input
                                    placeholder="Search..."
                                    value={filters.query}
                                    onChange={e => {
                                        e.preventDefault();
                                        setFilters({ ...filters, query: e.target.value });
                                        setAppliedFilters({ ...filters, query: e.target.value });
                                    }}
                                />
                            </Segment>

                            <Segment className="table-container">
                                <Table basic="very">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Date of purchase</Table.HeaderCell>
                                            <Table.HeaderCell>Assigned to</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {inventoryItems.map(x => (
                                            <InventoryItemRow
                                                key={x.guid}
                                                inventoryItem={x}
                                                handleRemoveInventoryItem={
                                                    handleRemoveInventoryItem
                                                }
                                                page={page + ""}
                                            />
                                        ))}
                                        <Table.Row />
                                    </Table.Body>
                                </Table>
                            </Segment>
                            <Segment>
                                {totalRows > 10 && (
                                    <Pagination
                                        size="small"
                                        activePage={page}
                                        onPageChange={(e, data: any) => setPage(+data.activePage)}
                                        ellipsisItem={{
                                            content: <Icon name="ellipsis horizontal" />,
                                        }}
                                        firstItem={{
                                            content: <Icon name="angle double left" />,
                                        }}
                                        lastItem={{
                                            content: <Icon name="angle double right" />,
                                        }}
                                        prevItem={{ content: <Icon name="angle left" /> }}
                                        nextItem={{ content: <Icon name="angle right" /> }}
                                        totalPages={Math.ceil(totalRows / 10)}
                                    />
                                )}
                            </Segment>
                        </Segment.Group>
                    )}
                </div>
            </div>
        </div>
    );
};
